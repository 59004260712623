import React from 'react';
import { Button, Pane } from 'evergreen-ui';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { PortfolioTypeSelect } from '../../molecules';

const CreatePortfolioForm = ({ initialValues, submitForm, onSuccess }) => {
    const validationSchema = Yup.object().shape({
        portfolioType: Yup.string().required('Portfolio type is required'),
    });

    const handleFormSubmission = async (
        values,
        { setSubmitting, setFieldError }
    ) => {
        setSubmitting(true);

        try {
            await submitForm(values);
            onSuccess();
        } catch (error) {
            setFieldError(
                'portfolioType',
                _.get(error, 'response.data.message', error.message)
            );
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmission}
        >
            {({
                handleBlur,
                handleSubmit,
                isSubmitting,
                values,
                handleChange,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Pane marginTop="32px">
                        <PortfolioTypeSelect
                            name="portfolioType"
                            value={values.portfolioType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Pane>
                    <Pane display="flex" flexDirection="column">
                        <Button
                            appearance="primary"
                            height={40}
                            marginTop="32px"
                            type="submit"
                            isLoading={isSubmitting}
                        >
                            Create Building Use
                        </Button>
                    </Pane>
                </Form>
            )}
        </Formik>
    );
};

CreatePortfolioForm.propTypes = {
    initialValues: PropTypes.shape({}).isRequired,

    submitForm: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default CreatePortfolioForm;
