import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    portfolios: null,
    selectedPortfolio: null,
};

const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState,
    reducers: {
        setIsLoadingAction(state, action) {
            state.isLoading = action.payload;
        },
        setPortfoliosAction(state, action) {
            state.portfolios = action.payload;
        },
        setSelectedPortfolioAction(state, action) {
            state.selectedPortfolio = action.payload;
        },
    },
});

export const {
    setIsLoadingAction,
    setPortfoliosAction,
    setSelectedPortfolioAction,
} = portfolioSlice.actions;

export const portfolioSelector = (reducers) => reducers.portfolioReducer;

export default portfolioSlice.reducer;
