import React, { useMemo } from 'react';
import { Dialog, Pane, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import RelativeBuildingItem from './relative-building-item';
import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';

function RelativeBuildingsModal({ isShown, close, contactName, buildings }) {
    const { assets } = useSelector(assetSelector);

    const relativeBuildings = useMemo(() => {
        const rBuildings = [];
        buildings.forEach(({ building }) => {
            const buildingIndex = rBuildings.findIndex(
                (_building) => Number(_building?.id) === Number(building?.id)
            );
            if (buildingIndex < 0) {
                if (building) {
                    rBuildings.push(building);
                }
            }
        });
        return rBuildings;
    }, [buildings]);

    return (
        <Dialog
            title={`Relative Buildings for ${
                contactName || 'Business Contact'
            }`}
            isShown={isShown}
            onCloseComplete={close}
            preventBodyScrolling
            hasCancel={false}
            onConfirm={close}
            confirmLabel="Close"
        >
            <Pane display="flex" flexDirection="column" justifyContent="center">
                {relativeBuildings?.length ? (
                    relativeBuildings?.map((building) =>
                        building ? (
                            <RelativeBuildingItem
                                key={building?.id}
                                building={building}
                                close={close}
                                assets={assets}
                            />
                        ) : null
                    )
                ) : (
                    <Text>This Contact has no Relative Buildings.</Text>
                )}
            </Pane>
        </Dialog>
    );
}

RelativeBuildingsModal.propTypes = {
    isShown: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    contactName: PropTypes.string.isRequired,
    buildings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default RelativeBuildingsModal;
