import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Pane, Heading } from 'evergreen-ui';
import { colors } from '../../theme/theme';

import Breadcrumbs from '../../components/molecules/bread-crumbs';
import { useUserAccess } from '../../context/user-access-context';

import ManagementMenu from './components/ManagementMenu';

function AssetsHeader({
    asset,
    handleOpenSideBar,
    handleOpenArchive,
    handleOpenDelete,
}) {
    const { allRights } = useUserAccess();

    const rights = useMemo(
        () =>
            allRights?.info || {
                create: false,
                update: false,
                delete: false,
            },
        [allRights]
    );
    return (
        <Pane
            display="flex"
            backgroundColor={colors.white}
            paddingLeft={30}
            justifyContent="space-between"
            width="100%"
            zIndex={1}
            borderBottom="0.5px solid #C1C4D6"
            overflowY="auto"
        >
            <Pane
                display="flex"
                flexDirection="column"
                gap={10}
                paddingY={24}
                width="100%"
            >
                <Breadcrumbs />
                <Pane
                    display="flex"
                    alignItems="center"
                    width="100%"
                    paddingRight={25}
                    justifyContent="space-between"
                >
                    <LinkHeading size={900} fontSize={28} display="flex">
                        {asset?.assetName}
                    </LinkHeading>

                    {((rights.create &&
                        rights.update &&
                        asset?.type !== 'park') ||
                        rights.delete) && (
                        <ManagementMenu
                            asset={asset}
                            handleOpenSideBar={handleOpenSideBar}
                            handleOpenArchive={handleOpenArchive}
                            handleOpenDelete={handleOpenDelete}
                        />
                    )}
                </Pane>
            </Pane>
        </Pane>
    );
}

AssetsHeader.propTypes = {
    asset: PropTypes.string.isRequired,
    handleOpenSideBar: PropTypes.string.isRequired,
    handleOpenArchive: PropTypes.func.isRequired,
    handleOpenDelete: PropTypes.func.isRequired,
};

const LinkHeading = styled(Heading)`
    display: flex;
    cursor: default;
`;

export default AssetsHeader;
