import { Pane } from 'evergreen-ui';
import styled from 'styled-components';

import { colors } from '../../theme/theme';

const StyledContextMenu = styled(Pane)`
    background-color: ${colors.gray50};
    padding: 6px 0;
    border-radius: 2px;
`;

export default StyledContextMenu;
