import _ from 'lodash';

import {
    addAssetComplianceType,
    removeAssetComplianceType,
    setAssetComplianceProgressAction,
    updateAssetComplianceType,
    setAssetFullDetails,
    setCurrentAssetAction,
    setIsLoadingCurrentAssetAction,
    setIsLoadingFullAssetDetails,
    setAssetsAction,
    setParksAction,
    setBuildingsAction,
    setTenantsAction,
    setPortfoliosAction,
    setIsLoadingAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from './asset.reducer';
import {
    assetService,
    complianceService,
    uploadRequestService,
    documentService,
} from '../../services';
import { assetModel } from '../../models';
import { errorToast } from '../../components/toasts';

export const getAsset =
    (
        assetId,
        shouldSetLoading,
        shouldSetCurrent = true,
        shouldCheckForCachedAsset = false
    ) =>
    async (dispatch, getState) => {
        const { portfolios } = getState().portfolioReducer;
        if (shouldSetLoading) {
            dispatch(setIsLoadingCurrentAssetAction(true));
        }

        try {
            if (shouldCheckForCachedAsset) {
                const { assets } = getState().assetReducer;

                const cachedAsset = _.find(
                    assets,
                    (asset) => String(asset.id) === String(assetId)
                );

                if (cachedAsset && !cachedAsset.isLoadingDetails) {
                    dispatch(setIsLoadingCurrentAssetAction(false));
                    dispatch(setCurrentAssetAction(cachedAsset));
                }
            }
            const apiAsset = await assetService.getAssetById(assetId);
            const portfolio = _.find(
                portfolios,
                (_portfolio) =>
                    String(_portfolio.id) ===
                    String(_.get(apiAsset, 'portfolio_id'))
            );
            if (shouldSetCurrent) {
                dispatch(
                    setCurrentAssetAction(
                        assetModel(_.cloneDeep(apiAsset), portfolio)
                    )
                );
            }
            return apiAsset;
        } finally {
            dispatch(setIsLoadingCurrentAssetAction(false));
        }
    };

export function getBuilding(buildingId) {
    return async (dispatch) => {
        const building = assetModel(
            await assetService.getAssetById(buildingId)
        );
        dispatch(setCurrentBuildingAction(building));
        return building;
    };
}

export function getTenant(tenantId) {
    return async (dispatch) => {
        const tenant = assetModel(await assetService.getAssetById(tenantId));
        dispatch(setCurrentTenantAction(tenant));
        return tenant;
    };
}

export function getFullDetailsForAllAssets(shouldSetLoading) {
    return async (dispatch, getState) => {
        const { assets } = getState().assetReducer;
        const { portfolios } = getState().portfolioReducer;

        _.forEach(assets, async (asset) => {
            if (shouldSetLoading) {
                dispatch(
                    setIsLoadingFullAssetDetails({
                        id: asset.id,
                        isLoading: true,
                    })
                );
            }

            try {
                const apiAsset = await dispatch(
                    getAsset(asset.id, false, false)
                );

                const complianceTypeIdsWithUploadRequests = _.map(
                    _.get(apiAsset, 'asset_uploadrequests'),
                    (uploadRequest) =>
                        _.get(uploadRequest, 'compliance_type_id')
                );

                let completedCount = 0;
                let notStartedCount = 0;
                let notApplicable = 0;

                _.forEach(_.get(apiAsset, 'compliance_group'), (group) => {
                    _.forEach(
                        _.get(group, 'compliance_category'),
                        (category) => {
                            _.forEach(
                                _.get(category, 'compliance_types'),
                                (type) => {
                                    if (!_.get(type, 'applicable')) {
                                        notApplicable += 1;
                                        return;
                                    }

                                    if (!_.get(type, 'upload_pending')) {
                                        completedCount += 1;
                                        return;
                                    }

                                    if (
                                        !_.includes(
                                            complianceTypeIdsWithUploadRequests,
                                            _.get(type, 'id')
                                        )
                                    ) {
                                        notStartedCount += 1;
                                    }
                                }
                            );
                        }
                    );
                });

                const portfolio = _.find(
                    portfolios,
                    (_portfolio) =>
                        String(_portfolio.id) === String(asset.portfolioId)
                );
                dispatch(setAssetFullDetails(assetModel(apiAsset, portfolio)));

                dispatch(
                    setAssetComplianceProgressAction({
                        id: asset.id,
                        completed: completedCount + notApplicable,
                        uploadsRequested: _.size(
                            complianceTypeIdsWithUploadRequests
                        ),
                        notStarted: notStartedCount,
                        total:
                            completedCount +
                            notStartedCount +
                            notApplicable +
                            _.size(complianceTypeIdsWithUploadRequests),
                    })
                );
            } finally {
                dispatch(
                    setIsLoadingFullAssetDetails({
                        id: asset.id,
                        isLoading: false,
                    })
                );
            }
        });
    };
}

export function getAssets(search) {
    return async (dispatch) => {
        try {
            dispatch(setIsLoadingAction(true));
            const assets = await assetService.getAssets(search);
            const fileIds = {};

            _.forEach(assets, (asset) => {
                const featuredImage = asset.images.find(
                    (image) => image.is_featured
                );
                if (!featuredImage) {
                    return;
                }

                fileIds[featuredImage.file_id] = {
                    assetId: asset.bucket_id,
                    fileId: featuredImage.file_id,
                    fileKey: `${asset?.bucket_id}/${featuredImage?.file_id}`,
                    internalAssetId: asset.id,
                };
            });

            const fileUrls = await documentService.getFilesDownloadLink(
                Object.values(fileIds)
            );

            _.forEach(Object.keys(fileUrls), (fileId) => {
                const { internalAssetId } = fileIds[fileId];
                const asset = _.find(assets, (a) => a.id === internalAssetId);
                const image = _.find(asset.images, (i) => i.file_id === fileId);
                image.url = fileUrls[fileId];
            });

            Promise.all([
                dispatch(setAssetsAction(assets)),
                dispatch(setParksAction(assets)),
                dispatch(setBuildingsAction(assets)),
                dispatch(setTenantsAction(assets)),
                dispatch(setPortfoliosAction(assets)),
            ]);
        } finally {
            dispatch(setIsLoadingAction(false));
        }
    };
}

// Compliance

export const markAssetComplianceTypeApplicable =
    (
        assetId,
        complianceGroupId,
        complianceCategoryId,
        complianceType,
        applicable
    ) =>
    async (dispatch) => {
        dispatch(
            updateAssetComplianceType({
                assetId,
                complianceGroupId,
                complianceCategoryId,
                complianceTypeId: complianceType.id,
                updatedComplianceType: { ...complianceType, applicable },
            })
        );

        try {
            await complianceService.setComplianceTypeApplicable(
                complianceType,
                applicable
            );
        } catch (error) {
            dispatch(
                updateAssetComplianceType({
                    assetId,
                    complianceGroupId,
                    complianceCategoryId,
                    complianceTypeId: complianceType.id,
                    updatedComplianceType: {
                        ...complianceType,
                        applicable: !applicable,
                    },
                })
            );
            const errorMessage = `Failed to mark the compliance type as ${
                !applicable ? 'applicable' : 'not applicable'
            }. Error: ${_.get(error, 'response.data.message', error.message)}`;
            errorToast(errorMessage);
        }
    };

export const deleteAssetComplianceType =
    (complianceGroupId, complianceCategoryId, complianceType) =>
    async (dispatch) => {
        dispatch(
            removeAssetComplianceType({
                complianceGroupId,
                complianceCategoryId,
                complianceTypeId: complianceType.id,
            })
        );

        try {
            if (complianceType.uploadRequest) {
                await uploadRequestService.cancelUploadRequest(
                    complianceType.uploadRequest.id
                );
            }
            await complianceService.deleteComplianceType(complianceType.id);
        } catch (error) {
            dispatch(
                addAssetComplianceType({
                    complianceGroupId,
                    complianceCategoryId,
                    complianceType,
                })
            );
            const errorMessage = `Failed to delete the compliance type. Error: ${_.get(
                error,
                'response.data.message',
                error.message
            )}`;
            errorToast(errorMessage);
            throw error;
        }
    };

export const renameAssetComplianceType =
    (complianceGroupId, complianceCategoryId, complianceType, newName) =>
    async (dispatch) => {
        dispatch(
            updateAssetComplianceType({
                complianceGroupId,
                complianceCategoryId,
                complianceTypeId: complianceType.id,
                updatedComplianceType: {
                    ...complianceType,
                    name: newName,
                },
            })
        );

        try {
            await complianceService.renameComplianceType(
                complianceType,
                newName
            );
        } catch (error) {
            dispatch(
                updateAssetComplianceType({
                    complianceGroupId,
                    complianceCategoryId,
                    complianceTypeId: complianceType.id,
                    updatedComplianceType: complianceType,
                })
            );
            throw error;
        }
    };

const setCompletedFalse = (groups, complianceTypeId) =>
    (groups ?? []).map((group) => ({
        ...group,
        complianceCategories: (group.complianceCategories ?? []).map(
            (category) => ({
                ...category,
                complianceTypes: (category.complianceTypes ?? []).map(
                    (type) => {
                        if (String(type.id) === String(complianceTypeId)) {
                            return {
                                ...type,
                                completed: false,
                            };
                        }
                        return type;
                    }
                ),
            })
        ),
    }));

export const setComplianceTypeCompleted =
    (complianceTypeId) => async (dispatch, getState) => {
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;

        if (currentAsset) {
            dispatch(
                setCurrentAssetAction({
                    ...currentAsset,
                    complianceGroups: setCompletedFalse(
                        currentAsset.complianceGroups,
                        complianceTypeId
                    ),
                })
            );
        }

        if (currentBuilding) {
            dispatch(
                setCurrentBuildingAction({
                    ...currentBuilding,
                    complianceGroups: setCompletedFalse(
                        currentBuilding.complianceGroups,
                        complianceTypeId
                    ),
                })
            );
        }

        if (currentTenant) {
            dispatch(
                setCurrentTenantAction({
                    ...currentTenant,
                    complianceGroups: setCompletedFalse(
                        currentTenant.complianceGroups,
                        complianceTypeId
                    ),
                })
            );
        }
    };
