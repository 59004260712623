import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Pane,
    Text,
    Checkbox,
    ChevronRightIcon,
    TrashIcon,
    EditIcon,
    TextInput,
    Button,
    Spinner,
    Tooltip,
    CrossIcon,
} from 'evergreen-ui';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash';

import Collapsible from '../atoms/collapsible';
import { editRoles } from '../../reducers/user-management-reducer/user-management.actions';
import { errorToast } from '../toasts';

function RolesItem({ role, setDeleteRoleModal, RIGHTS, currentRights }) {
    const [showAccess, setShowAccess] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { access: modules } = useMemo(() => role, [role]);
    const dispatch = useDispatch();

    const handleEnter = useCallback(
        ({ key, target: { value } }) => {
            if (key === 'Enter' && value) {
                setIsLoading(true);
                try {
                    dispatch(editRoles(role, null, null, true, value));
                } catch (error) {
                    errorToast(
                        `An Error Occurred: ${get(
                            error,
                            'response.data.message',
                            `Failed to update ${role.name}`
                        )}`
                    );
                } finally {
                    setIsEditing(false);
                    setIsLoading(false);
                }
            }
        },
        [dispatch, role]
    );

    const handleChange = ({ target: { value } }) => {
        setName(value);
    };

    const toggleShowAccess = () => {
        setShowAccess((show) => !show);
    };

    const editRole = useCallback(
        (moduleName, right) => {
            if (currentRights?.update) {
                dispatch(editRoles(role, moduleName, right));
            }
        },
        [currentRights, dispatch, role]
    );

    const renderHeader = useCallback(() => {
        if (isEditing) {
            return isLoading ? (
                <Button width={200} height={40}>
                    <Spinner color="#696f8b" size={28} />
                </Button>
            ) : (
                <Tooltip isShown={isEditing} content="Press Enter to Confirm">
                    <TextInput
                        height={40}
                        value={name}
                        border="1px solid #c1c4d6"
                        onKeyDown={handleEnter}
                        onChange={handleChange}
                        placeholder={role.name}
                        paddingRight={0}
                        width={200}
                        autoFocus
                        onClick={(e) => e.stopPropagation()}
                    />
                </Tooltip>
            );
        }
        return (
            <>
                <ChevronRightIcon
                    transform={showAccess ? 'rotate(90deg)' : null}
                    transition="transform 250ms"
                />
                <Text textTransform="capitalize" fontSize={16} fontWeight={600}>
                    {role.name}
                </Text>
            </>
        );
    }, [handleEnter, isEditing, isLoading, name, role.name, showAccess]);

    return (
        <RoleContainer
            key={role.id}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            role="button"
            onClick={toggleShowAccess}
        >
            <Pane
                display="flex"
                alignItems="center"
                id="role"
                justifyContent="space-between"
            >
                <Pane display="flex" gap={6} width="100%">
                    {renderHeader()}
                </Pane>
                {(currentRights?.delete || currentRights?.update) &&
                    role.name.toLowerCase() !== 'admin' && (
                        <Pane
                            className="options"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {currentRights?.update && (
                                <Pane
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={8}
                                    className="edit-contact"
                                    hoverElevation={1}
                                    borderRadius="4px"
                                    role="button"
                                    background="#f5f6fa"
                                    onClick={() =>
                                        setIsEditing((prev) => !prev)
                                    }
                                >
                                    {isEditing ? (
                                        <CrossIcon color="#696f8b" />
                                    ) : (
                                        <EditIcon
                                            color="#696f8b"
                                            className="edit-role"
                                        />
                                    )}
                                </Pane>
                            )}
                            {currentRights?.delete && (
                                <Pane
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={8}
                                    className="edit-contact"
                                    hoverElevation={1}
                                    borderRadius="4px"
                                    role="button"
                                    background="#f5f6fa"
                                    onClick={() =>
                                        setDeleteRoleModal({
                                            isDeletingRole: true,
                                            role,
                                        })
                                    }
                                >
                                    <TrashIcon
                                        color="#c60b0b"
                                        className="delete-role"
                                    />
                                </Pane>
                            )}
                        </Pane>
                    )}
            </Pane>
            <Collapsible isOpen={showAccess && !isEditing}>
                <Pane
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    padding={6}
                    paddingLeft={24}
                    gap={6}
                    onClick={(e) => e.stopPropagation()}
                >
                    {modules.map((module, moduleIndex) => {
                        const notLastModule =
                            moduleIndex !== modules.length - 1;

                        const moduleName = module.name.replace('-', ' ');
                        return (
                            <Pane
                                key={module.name}
                                display="flex"
                                width="100%"
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom={
                                    notLastModule && '1px solid #696f8c'
                                }
                            >
                                <Text
                                    textTransform="capitalize"
                                    width="25%"
                                    cursor="default"
                                >
                                    {moduleName === 'info'
                                        ? 'Buildings'
                                        : moduleName}
                                </Text>
                                <Pane display="flex" gap={10}>
                                    {Object.keys(module?.rights)?.map(
                                        (right, index) => {
                                            const notLastRight =
                                                index !==
                                                Object.keys(module?.rights)
                                                    .length -
                                                    1;

                                            const isReadDisabled = Object.keys(
                                                module?.rights
                                            )
                                                .filter(
                                                    (_right) =>
                                                        _right !== 'read'
                                                )
                                                .some(
                                                    (key) =>
                                                        !!module?.rights[key]
                                                );

                                            const label =
                                                right === 'update'
                                                    ? 'Edit'
                                                    : RIGHTS[right];

                                            return (
                                                <Checkbox
                                                    key={`${module.name}-${role.name}-${role.id}-${right}`}
                                                    label={label}
                                                    paddingRight={10}
                                                    checked={
                                                        module.rights[right]
                                                    }
                                                    borderRight={
                                                        !!notLastRight &&
                                                        '1px solid #696f8c'
                                                    }
                                                    onChange={() => {
                                                        editRole(
                                                            module.name,
                                                            RIGHTS[
                                                                right
                                                            ].toLowerCase()
                                                        );
                                                    }}
                                                    disabled={
                                                        (right === 'read' &&
                                                            isReadDisabled) ||
                                                        (right === 'update' &&
                                                            !!module.rights
                                                                .create) ||
                                                        !currentRights?.update ||
                                                        role.name.toLowerCase() ===
                                                            'admin'
                                                    }
                                                />
                                            );
                                        }
                                    )}
                                </Pane>
                            </Pane>
                        );
                    })}
                </Pane>
            </Collapsible>
        </RoleContainer>
    );
}

const RoleContainer = styled(Pane)`
    #role {
        padding: 6px;
        border-radius: 4px;
        cursor: pointer;
        height: 48px;
        &:hover {
            background: #c2c4d630;

            .options {
                display: flex;
            }
        }
    }

    .options {
        display: none;
    }
`;

RolesItem.propTypes = {
    role: PropTypes.shape().isRequired,
    setDeleteRoleModal: PropTypes.func.isRequired,
    RIGHTS: PropTypes.shape({}).isRequired,
    currentRights: PropTypes.shape({
        create: PropTypes.bool,
        read: PropTypes.bool,
        update: PropTypes.bool,
        delete: PropTypes.bool,
    }).isRequired,
};

export default RolesItem;
