import fileIcons from '../assets/images/illustrations/file-types';

function getFileTypeIcon(type) {
    if (!type) {
        return fileIcons.UNK;
    }

    const fileType = type.toUpperCase();

    return fileIcons[fileType] ?? fileIcons.UNK;
}

export default getFileTypeIcon;
