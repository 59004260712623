import React, { useState, useRef } from 'react';
import { Pane, Tooltip, Text, InfoSignIcon } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import EditForm from '../forms-templates/EditForm';
import AddForm from '../forms-templates/AddForm';
import ListForm from '../forms-templates/ListForm';

import ComplianceTemplateCategory from '../compliance-category-template-form/ComplianceTemplateCategory';

import {
    addComplianceCategory,
    deleteComplianceGroup,
    renameComplianceGroup,
} from '../../../../reducers/compliance-template-reducer/compliance-template.actions';

function ComplianceTemplateGroup({ group }) {
    const dispatch = useDispatch();

    const [editingGroup, setEditingGroup] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [addingCategory, setAddingCategory] = useState({
        newCategory: '',
        groupId: null,
    });

    const setNewCategoryName = ({ target: { value } }) => {
        setAddingCategory((state) => ({
            ...state,
            newCategory: value,
        }));
    };

    const newName = useRef();

    const setEditGroup = () => {
        newName.current = group.Group;
        setEditingGroup(true);
    };

    const handleGroupEdit = ({ target, key }) => {
        newName.current = target.value;
        if (key === 'Enter') {
            dispatch(renameComplianceGroup(group.id, newName.current));
            cancelEdit();
        }
    };

    const deleteGroup = async () => {
        dispatch(deleteComplianceGroup(group.id));
    };

    const handleCategoryAdd = ({ key }) => {
        if (key === 'Enter') {
            dispatch(
                addComplianceCategory(
                    addingCategory.groupId,
                    addingCategory.newCategory
                )
            );
            cancelAdd();
        }
    };

    const cancelEdit = () => {
        newName.current = '';
        setEditingGroup(false);
    };

    const cancelAdd = () => {
        setAddingCategory({
            addingCategory: false,
            newCategory: '',
            groupId: null,
        });
    };

    return (
        <Pane>
            <Pane>
                {editingGroup ? (
                    <Pane paddingLeft={30} marginBottom={0}>
                        <EditForm
                            value={group.Group}
                            handleEdit={handleGroupEdit}
                            cancelEdit={cancelEdit}
                            ref={newName}
                        />
                    </Pane>
                ) : (
                    <Pane paddingRight={5}>
                        <ListForm
                            value={group}
                            setEdit={setEditGroup}
                            deleteList={deleteGroup}
                        />
                    </Pane>
                )}
            </Pane>

            {group.category.map((category) => (
                <ComplianceTemplateCategory
                    key={category.id}
                    category={category}
                    groupId={group.id}
                    editingCategory={editingCategory === category.name}
                    setEditingCategory={setEditingCategory}
                />
            ))}
            {addingCategory.groupId === group.id ? (
                <Pane paddingLeft={35}>
                    <AddForm
                        add={handleCategoryAdd}
                        placeholder="New Category"
                        handleChange={setNewCategoryName}
                        cancelAdd={cancelAdd}
                    />
                </Pane>
            ) : (
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    gap={10}
                >
                    <AddCategoryButton
                        marginLeft={40}
                        marginRight={30}
                        marginTop={14}
                        marginBottom={28}
                        onClick={() =>
                            setAddingCategory({
                                newCategory: '',
                                groupId: group.id,
                            })
                        }
                    >
                        <Text>+ Add Compliance Category</Text>
                    </AddCategoryButton>
                    <Tooltip content="Here you can further organize your documents by creating Compliance Categories. These Categories should reflect different aspects or subtopics within the Compliance Group. For Example, if your Compliance Group is 'Finance Compliance', you could have categories such as 'Financial Reporting', 'Tax Compliance'. or 'Internal Controls'.">
                        <InfoSignIcon
                            marginBottom={15}
                            size={14}
                            color="#c8cacf"
                        />
                    </Tooltip>
                </Pane>
            )}
        </Pane>
    );
}

const AddCategoryButton = styled(Pane)`
    span {
        color: #5ec090;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

ComplianceTemplateGroup.propTypes = {
    group: PropTypes.shape({
        id: PropTypes.string,
        Group: PropTypes.string.isRequired,
        category: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};

export default ComplianceTemplateGroup;
