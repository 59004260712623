import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Dialog,
    Pane,
    TextInputField,
    InfoSignIcon,
    Tooltip,
} from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { successToast, errorToast } from '../../toasts';

import {
    setSearchAction,
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from '../../../reducers/asset-reducer/asset.reducer';

import LinkedAssetList from './LinkedAssetList';

import { assetService } from '../../../services';

function AssetDelete({ asset, onOpen, handCloseDelete }) {
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorArchiving, setErrorArchiving] = useState(false);
    const [errorData, setErrorData] = useState({});

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleInputChange = useCallback((e) => {
        setInputValue(e.target.value);
    }, []);

    const isDeleteConfirmed = useMemo(
        () => inputValue === 'delete',
        [inputValue]
    );

    const { deleteAsset } = assetService;

    const onSuccess = useCallback(() => {
        setInputValue('');
        handCloseDelete();
        dispatch(setSearchAction(''));
        dispatch(setCurrentAssetAction(null));
        dispatch(setCurrentBuildingAction(null));
        dispatch(setCurrentTenantAction(null));
        navigate('/portfolio/properties');
        successToast('Asset successfully deleted');
    }, [dispatch, navigate, handCloseDelete]);

    const onFailure = useCallback((errorMessage, error) => {
        setInputValue('');
        setErrorArchiving(true);
        setErrorData(error);
        errorToast(errorMessage);
    }, []);

    const handleDeleteSubmit = useCallback(async () => {
        try {
            setIsLoading(true);
            await deleteAsset({
                ...asset,
            });

            onSuccess();
        } catch (error) {
            const errorMessage = _.get(
                error,
                'response.data.message',
                error.message
            );
            const getErrorData = _.get(
                error,
                'response.data.payload',
                error.payload
            );
            onFailure(errorMessage, getErrorData);
        } finally {
            setIsLoading(false);
        }
    }, [deleteAsset, asset, onSuccess, onFailure]);

    const handleCloseDialog = useCallback(() => {
        setInputValue('');
        handCloseDelete();
        setErrorArchiving(false);
    }, [handCloseDelete]);

    return (
        <Dialog
            isShown={onOpen}
            onConfirm={handleDeleteSubmit}
            intent="danger"
            title="Confirm Deletion"
            confirmLabel="Confirm"
            hasCancel={false}
            isConfirmDisabled={!isDeleteConfirmed}
            onCancel={handleCloseDialog}
            isConfirmLoading={isLoading}
        >
            <Pane>
                <TextInputField
                    label="To confirm deletion, type delete in the text input field"
                    placeholder="Type delete to confirm"
                    value={inputValue}
                    onChange={handleInputChange}
                />
                {!errorArchiving ? (
                    <Pane
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <p>Are you sure you want to proceed?</p>
                        <Tooltip content="When you delete the asset, all associated documents and the asset become inaccessible permanently.">
                            <InfoSignIcon
                                size={14}
                                marginBottom={15}
                                color="#c8cacf"
                            />
                        </Tooltip>
                    </Pane>
                ) : (
                    <LinkedAssetList errorData={errorData} asset={asset} />
                )}
            </Pane>
        </Dialog>
    );
}

AssetDelete.propTypes = {
    asset: PropTypes.string.isRequired,
    onOpen: PropTypes.string.isRequired,
    handCloseDelete: PropTypes.string.isRequired,
};

export default AssetDelete;
