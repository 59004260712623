import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Text, Card, Heading, Pane } from 'evergreen-ui';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Legend,
    Tooltip,
} from 'chart.js';

import { colors } from '../../theme/theme';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            labels: {
                display: false,
            },
        },
        title: {
            display: false,
            text: 'Scheduled maintenance',
        },
    },
    scales: {
        x: {
            grid: {
                drawBorder: false,
                drawOnChartArea: false,
                lineWidth: 0,
            },
            border: {
                display: false,
            },
        },
        y: {
            grid: {
                drawBorder: false,
                lineWidth: 0,
                drawOnChartArea: false,
            },
            border: {
                display: false,
            },
            ticks: {
                display: false,
            },
        },
    },
};

const BarGraph = ({ data, title }) => {
    const hasScheduledData = useMemo(() => {
        const count = data.datasets[0]?.data?.reduce(
            (prevVal, curVal) => prevVal + curVal,
            0
        );
        return !!count;
    }, [data]);

    return (
        <Card
            elevation={1}
            backgroundColor={colors.white}
            flex="1"
            borderRadius={10}
            height="400px"
            width="49%"
            margin={1}
            padding="1.5rem"
            display="flex"
            flexDirection="column"
            position="relative"
        >
            <Heading
                fontSize={22}
                marginBottom="8px"
                fontWeight="700"
                color={colors.primary500}
            >
                {title}
            </Heading>
            <Pane
                height="100%"
                margin="auto"
                marginBottom={-15}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Bar data={data} height={300} width={500} options={options} />
            </Pane>
            {!hasScheduledData && (
                <Text
                    fontWeight={800}
                    fontSize={28}
                    color="#8f95b2"
                    position="absolute"
                    textAlign="center"
                    top="50%"
                    left={0}
                    width="calc(100%)"
                >
                    No Scheduled Data
                </Text>
            )}
        </Card>
    );
};

BarGraph.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape({
        datasets: PropTypes.arrayOf(
            PropTypes.shape({
                data: PropTypes.arrayOf(PropTypes.shape()),
            })
        ),
    }).isRequired,
};

export default BarGraph;
