import React from 'react';
import { Pane } from 'evergreen-ui';

const AutoFocusConsumer = () => (
    <Pane maxWidth={0} maxHeight={0} overflow="hidden">
        {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
        <input autoFocus />
    </Pane>
);

export default AutoFocusConsumer;
