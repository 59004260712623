import React from 'react';
import { Pane, Text, TickIcon } from 'evergreen-ui';
import PropTypes from 'prop-types';

import { colors } from '../../theme/theme';

const StepperCount = ({ count, isCompleted }) => (
    <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={50}
        width={50}
        borderRadius={25}
        backgroundColor={isCompleted ? '#474D66' : colors.transparent}
        border="2px solid #474D66"
        marginX="1.25rem"
    >
        {isCompleted ? (
            <TickIcon size={24} color={colors.white} />
        ) : (
            <Text fontSize={20} fontWeight={700} color="#474D66">
                {count}
            </Text>
        )}
    </Pane>
);

StepperCount.defaultProps = {
    isCompleted: false,
};

StepperCount.propTypes = {
    count: PropTypes.number.isRequired,
    isCompleted: PropTypes.bool,
};

export default StepperCount;
