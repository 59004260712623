import React, { useMemo } from 'react';
import { ArrowRightIcon, Link, Pane, SideSheet, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import AssetSidebarInfoBrief from './asset-sidebar-info-brief';
import AssetSidebarTenants from './asset-sidebar-tenants';
import AssetSidebarAssetDetails from './asset-sidebar-asset-details';
import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';
import { AutoFocusConsumer } from '../../atoms';

const SelectedAssetSidebarPortfolioPage = ({
    selectedAssetId,
    setSelectedAssetId,
}) => {
    const { assets } = useSelector(assetSelector);

    const selectedAsset = useMemo(
        () => _.find(assets, (asset) => asset.id === selectedAssetId),
        [selectedAssetId, assets]
    );

    const closeSidebar = () => {
        setSelectedAssetId(null);
    };

    if (!selectedAsset) {
        return null;
    }

    const renderDetails = () => {
        if (selectedAsset.isLoadingDetails) {
            return (
                <Pane
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    marginTop={35}
                >
                    <Text marginTop="1rem">Loading...</Text>
                </Pane>
            );
        }

        return (
            <Pane marginTop={35}>
                <AssetSidebarTenants
                    isVisible
                    selectedAsset={selectedAsset}
                    shouldFetchSpaces
                />
                <AssetSidebarAssetDetails
                    selectedAsset={selectedAsset}
                    isVisible
                    canEditAsset={false}
                />
            </Pane>
        );
    };

    return (
        <SideSheet
            isShown={!!selectedAsset}
            onCloseComplete={closeSidebar}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
            }}
            width={400}
        >
            <Pane flex="1" overflowY="scroll" backgroundColor="red">
                <Pane padding={16}>
                    <AssetSidebarInfoBrief
                        selectedAsset={selectedAsset}
                        showAssetName
                        imageWidth={125}
                    />
                </Pane>
                <Pane height={1} backgroundColor="#F1F2F2" width="100%" />
                <Pane margin={44} marginTop={5}>
                    <Pane display="flex" flex={1} flexDirection="row-reverse">
                        <BackLink
                            is={RouterLink}
                            to={`/portfolio/buildings/${selectedAsset.id}`}
                        >
                            View Property
                            <ArrowRightIcon size={16} marginLeft={8} />
                        </BackLink>
                    </Pane>
                    {renderDetails()}
                </Pane>
            </Pane>
            <AutoFocusConsumer />
        </SideSheet>
    );
};

SelectedAssetSidebarPortfolioPage.propTypes = {
    selectedAssetId: PropTypes.shape({}),
    setSelectedAssetId: PropTypes.func.isRequired,
};

SelectedAssetSidebarPortfolioPage.defaultProps = {
    selectedAssetId: null,
};

export default SelectedAssetSidebarPortfolioPage;

const BackLink = styled(Link)`
    font-size: 14px;
    font-weight: bold;
    color: #5ec090;
    display: flex;
    align-items: center;

    &:hover {
        color: #3aa37c;
        text-decoration: underline;
    }
`;
