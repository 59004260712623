import _ from 'lodash';
import { assetModel } from './asset.models';

export const businessContactsModel = (businessContactsObject = {}) =>
    _.map(businessContactsObject, (businessContact) =>
        businessContactModel(businessContact)
    );

export const businessContactModel = (businessContactObject) => ({
    id: _.get(businessContactObject, 'id', ''),
    createdAt: _.get(businessContactObject, 'created_at', ''),
    businessId: _.get(businessContactObject, 'business_id', ''),
    businessName: _.get(businessContactObject, 'businessName', ''),
    businessFriendlyName: _.get(
        businessContactObject,
        'businessFriendlyName',
        ''
    ),
    relativeBuildings: _.map(
        _.get(businessContactObject, 'relativeBuildings', [], (building) =>
            assetModel(building)
        )
    ),
    name: _.get(businessContactObject, 'comp_name', ''),
    email: _.get(businessContactObject, 'email', ''),
    mobileNumber: _.get(businessContactObject, 'officenumber', ''),
    contacts: _.map(
        _.get(businessContactObject, 'contacts', []),
        (contact) => ({
            id: _.get(contact, 'id', ''),
            createdAt: _.get(contact, 'created_at', ''),
            businessId: _.get(contact, 'contacts_business_id', ''),
            firstName: _.get(contact, 'name', ''),
            lastName: _.get(contact, 'surname', ''),
            email: _.get(contact, 'email', ''),
            mobileNumber:
                _.get(contact, 'mobileno', '') || 'Mobile number not provided',
            registrationNumber:
                _.get(contact, 'profesional_code', '') || 'Not Provided',
            profession: _.get(contact, 'profesion', '') || 'Not Provided',
            inactive: false,
            companyName: _.get(businessContactObject, 'comp_name', ''),
        })
    ),
});

export const staffContactModel = (contact) => ({
    id: _.get(contact, 'id', ''),
    createdAt: _.get(contact, 'created_at', ''),
    businessId: _.get(contact, 'contacts_business_id', ''),
    firstName: _.get(contact, 'name', ''),
    lastName: _.get(contact, 'surname', ''),
    email: _.get(contact, 'email', ''),
    mobileNumber:
        _.get(contact, 'mobileno', '') || 'Mobile number not provided',
    registrationNumber:
        _.get(contact, 'profesional_code', '') || 'Not Provided',
    profession: _.get(contact, 'profesion', '') || 'Not Provided',
});

export const contactPersonDetailsFormModel = (
    contactPersonDetailsFormObject = {}
) => ({
    firstName: _.get(contactPersonDetailsFormObject, 'firstName', ''),
    lastName: _.get(contactPersonDetailsFormObject, 'lastName', ''),
    email: _.get(contactPersonDetailsFormObject, 'email', ''),
    mobileNumber: _.get(contactPersonDetailsFormObject, 'mobileNumber', ''),
    registrationNumber: _.get(
        contactPersonDetailsFormObject,
        'registrationNumber',
        ''
    ),
    profession: _.get(contactPersonDetailsFormObject, 'profession', ''),
});

export const contactBusinessDetailsFormModel = (
    contactBusinessDetailsFormObject = {}
) => ({
    id: _.get(contactBusinessDetailsFormObject, 'id', ''),
    name: _.get(contactBusinessDetailsFormObject, 'name', ''),
    email: _.get(contactBusinessDetailsFormObject, 'email', ''),
    mobileNumber: _.get(contactBusinessDetailsFormObject, 'mobileNumber', ''),
});

export const businessContactApiModel = (businessContactApiObject = {}) => ({
    business_id: _.get(businessContactApiObject, 'businessId', ''),
    comp_name: _.get(businessContactApiObject, 'name', ''),
    email: _.get(businessContactApiObject, 'email', ''),
    officenumber: _.get(businessContactApiObject, 'mobileNumber', ''),
});

export const contactApiModel = (contactApiObject = {}) => ({
    contacts_business_id: _.get(contactApiObject, 'businessContactId', ''),
    name: _.get(contactApiObject, 'firstName', ''),
    surname: _.get(contactApiObject, 'lastName', ''),
    email: _.get(contactApiObject, 'email', ''),
    mobileno: _.get(contactApiObject, 'mobileNumber', ''),
    profesional_code: _.get(contactApiObject, 'registrationNumber', ''),
    profesion: _.get(contactApiObject, 'profession', ''),
});
