import React, { useCallback, useEffect, useState } from 'react';
import {
    Pane,
    Text,
    Badge,
    TextInputField,
    Button,
    ErrorIcon,
    Group,
    Textarea,
    SelectMenu,
    CaretDownIcon,
} from 'evergreen-ui';
import _ from 'lodash';
import { useFormik } from 'formik';
import { useFilePicker } from 'use-file-picker';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getTaskModel } from '../../models/task.models';
import { colors } from '../../theme/theme';
import FileDragAndDrop from '../atoms/file-drag-and-drop';
import { getTasks, updateTask } from '../../reducers/task-reducer/task.actions';
import { userSelector } from '../../reducers/user-reducer/user.reducer';
import { useUploadManager } from '../../context/upload-manager-context';
import sleep from '../../helpers/sleep';

const STATUSES = [
    { label: 'New', value: 'new' },
    { label: 'In Progress', value: 'in progress' },
    { label: 'Pending', value: 'pending' },
    { label: 'Overdue', value: 'overdue' },
    { label: 'Completed', value: 'completed' },
];

const STATUS_COLORS = {
    completed: { color: '#50C878', backgroundColor: '#50C87840' },
    overdue: { color: '#B3261E', backgroundColor: '#B3261E50' },
    pending: { color: '#FFA500', backgroundColor: '#FFA50050' },
    notified: { color: '#696F8C', backgroundColor: '#696F8C50' },
    'in progress': { color: '#6495ED', backgroundColor: '#6495ED50' },
    new: { color: '#7F00FF', backgroundColor: '#7F00FF30' },
    default: {
        badgeColor: '#D3F5F7',
        textColor: '#0F5156',
    },
};

function UpdateTaskForm({ task, close }) {
    const { building, status, description, notes, progress } =
        getTaskModel(task);
    const { user } = useSelector(userSelector);
    const { enqueueUploads } = useUploadManager();
    const dispatch = useDispatch();

    const [openFileSelector, { plainFiles }] = useFilePicker({
        accept: '*',
        multiple: true,
        readFilesContent: true,
    });

    const [loading, setLoading] = useState(false);

    const onFilesDropped = (droppedFiles) => {
        formik.setValues({
            ...formik.values,
            attachments: droppedFiles,
        });
    };

    const onUploadComplete = async (values) => {
        const title =
            values.progress === 100 ? 'Task Completed' : 'Task Updated';
        await sleep(1000);
        await dispatch(
            updateTask({
                ...values,
                title,
                task_items_id: task.id,
                progress: values.progress / 100,
            })
        );
        dispatch(getTasks());
    };

    const handleSubmit = async (values) => {
        setLoading(true);

        const {
            building: { bucket_id: bucketId },
        } = task;
        try {
            if (values.attachments) {
                await enqueueUploads(plainFiles, {
                    assetId: bucketId,
                    userId: user.id,
                    requestType: 'task',
                    taskItemId: task.id,
                    onCompleted: () => onUploadComplete(values),
                });
            }
            close();
        } catch (error) {
            throw new Error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleStatusChange = ({ value }) => {
        if (value === 'completed') {
            formik.setValues({
                ...formik.values,
                progress: 100,
                status: value,
            });
        } else if (value === 'new') {
            formik.setValues({
                ...formik.values,
                progress: 0,
                status: value,
            });
        } else {
            formik.setValues({
                ...formik.values,
                progress:
                    formik.values.progress > 0 ? formik.values.progress : 1,
                status: value,
            });
        }
    };

    const validate = useCallback((values) => {
        const errors = {};
        if (values.progress < 100 && values.status === 'completed') {
            errors.progress = 'Progress cannot be less than 100%';
        }
        if (Number(values.progress) === 100 && values.status !== 'completed') {
            errors.progress =
                'Progress cannot be 100% unless status is Completed';
        }
        if (values.progress > 100 || values.progress < 0) {
            errors.progress =
                'Progress cannot be greater than 100% or less than 0%';
        }

        if (!values.attachments || values?.attachments?.length === 0) {
            errors.attachments = 'Please add an attachment';
        }

        return errors;
    }, []);

    const formik = useFormik({
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            status,
            progress: progress * 100,
            description: '',
            attachments: null,
        },
        validate,
        onSubmit: handleSubmit,
    });

    const handleChange = ({ target }) => {
        if (target.name === 'progress') {
            let newStatus = 'new';
            if (Number(target.value) === 100) {
                newStatus = 'completed';
            } else if (
                Number(target.value) >= 1 &&
                Number(target.value) <= 99
            ) {
                if (
                    formik.values.status === 'new' ||
                    formik.status === 'completed'
                ) {
                    newStatus = 'in progress';
                } else {
                    newStatus = formik.values.status;
                }
            }

            formik.setValues({
                ...formik.values,
                status: newStatus,
                progress: Number(target.value),
            });
        } else {
            formik.setValues({ ...formik.values, [target.name]: target.value });
        }
    };

    useEffect(() => {
        if (_.size(plainFiles) > 0) {
            formik.setValues({
                ...formik.values,
                attachments: [...plainFiles],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plainFiles]);

    return (
        <Pane height="calc(100vh - 160px)">
            <form
                onSubmit={formik.handleSubmit}
                style={{ height: 'calc(100% - 100px)' }}
            >
                <Pane
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    justifyContent="space-between"
                >
                    <Pane
                        display="flex"
                        flexDirection="column"
                        gap={12}
                        alignItems="start"
                    >
                        <Text
                            textTransform="uppercase"
                            color={colors.neutral700}
                            fontWeight={600}
                        >
                            {building?.name}
                        </Text>
                        <Text
                            textTransform="uppercase"
                            color="black"
                            fontWeight={900}
                            fontSize={18}
                        >
                            {description}
                        </Text>
                        <Text
                            textTransform="uppercase"
                            color={colors.neutral700}
                        >
                            {notes}
                        </Text>
                        <div className="divider mt-0 mb-0" />
                        <Pane
                            display="flex"
                            flexDirection="column"
                            alignItems="start"
                            width="100%"
                        >
                            <Text
                                width="100%"
                                fontWeight="500"
                                color="#101840"
                                marginBottom={6}
                            >
                                Status *
                            </Text>
                            <SelectMenu
                                options={STATUSES}
                                hasFilter={false}
                                hasTitle={false}
                                selected={formik.values.status}
                                onSelect={handleStatusChange}
                                closeOnSelect
                            >
                                <MenuInputBox>
                                    <Badge
                                        color={
                                            STATUS_COLORS[formik.values?.status]
                                                ?.backgroundColor ||
                                            STATUS_COLORS.default
                                                .backgroundColor
                                        }
                                        padding={12}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        cursor="pointer"
                                    >
                                        <Text
                                            lineHeight={22}
                                            fontWeight={700}
                                            color={
                                                STATUS_COLORS[
                                                    formik.values?.status
                                                ]?.color ||
                                                STATUS_COLORS.default.color
                                            }
                                        >
                                            {formik.values?.status}
                                        </Text>
                                    </Badge>
                                    <MenuInputCaret size={16} color="#696f8c" />
                                </MenuInputBox>
                            </SelectMenu>
                        </Pane>
                    </Pane>
                    <div className="divider" />
                    <TextInputField
                        name="progress"
                        placeholder="Progress"
                        type="number"
                        inputMode="num"
                        pattern="^[1-9][0-9]?$|^100$"
                        label="Task Progress(%) *"
                        value={formik.values.progress}
                        width="100%"
                        inputHeight={40}
                        validationMessage={formik.errors.progress}
                        onChange={handleChange}
                        marginBottom={0}
                    />
                    <div className="divider" />
                    <Pane display="flex" flexDirection="column" gap={8}>
                        <Text
                            width="100%"
                            fontWeight="500"
                            color="#101840"
                            marginBottom={6}
                        >
                            Update Description / Additional Note
                        </Text>
                        <Textarea
                            name="description"
                            minWidth="100%"
                            maxWidth="100%"
                            placeholder="Additional Note"
                            value={formik.values.description}
                            onChange={handleChange}
                            validationMessage={formik.errors.description}
                            spellCheck={false}
                            inputHeight={40}
                        />
                        <div className="divider" />
                    </Pane>
                    <Pane>
                        <FileDragAndDrop onFilesDropped={onFilesDropped}>
                            {({ dragging }) => (
                                <Pane
                                    display="flex"
                                    flexDirection="column"
                                    gap={8}
                                >
                                    <Text>Upload an Attachment *</Text>
                                    <Group
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        name="attachment"
                                    >
                                        <Pane
                                            height="40px"
                                            borderRadius={5}
                                            borderWidth={1}
                                            borderStyle={
                                                dragging ||
                                                _.size(plainFiles) > 0
                                                    ? 'solid'
                                                    : 'dashed'
                                            }
                                            borderColor={
                                                dragging ||
                                                _.size(plainFiles) > 0
                                                    ? colors.secondary500
                                                    : colors.gray500
                                            }
                                            label="Date Last Serviced"
                                            type="text"
                                            name="assetName"
                                            placeholder="Drag Image or browse"
                                            width="100%"
                                        >
                                            {formik.values.attachments ? (
                                                <Text
                                                    marginLeft="16px"
                                                    lineHeight="38px"
                                                >
                                                    {formik.values.attachments
                                                        .length > 1
                                                        ? `${formik.values.attachments.length} Files`
                                                        : formik.values.attachments[0]?.name?.split(
                                                              '.'
                                                          )[0]}
                                                </Text>
                                            ) : (
                                                <Text
                                                    marginLeft="16px"
                                                    lineHeight="38px"
                                                >
                                                    Drag files or browse
                                                </Text>
                                            )}
                                        </Pane>
                                        <Pane>
                                            <Button
                                                height="40px"
                                                type="button"
                                                onClick={openFileSelector}
                                                backgroundColor="#F4F5F9"
                                            >
                                                <Text fontSize={12}>
                                                    Browse
                                                </Text>
                                            </Button>
                                        </Pane>
                                    </Group>
                                    {!!formik.errors.attachments && (
                                        <Text
                                            lineHeight="18px"
                                            fontSize="12px"
                                            color="#D14343"
                                        >
                                            <ErrorIcon
                                                size={14}
                                                marginRight="6px"
                                            />
                                            {formik.errors.attachments}
                                        </Text>
                                    )}
                                </Pane>
                            )}
                        </FileDragAndDrop>
                        <div className="divider" />
                    </Pane>
                    <Button
                        isLoading={loading}
                        type="submit"
                        appearance="primary"
                        marginTop="auto"
                    >
                        Update
                    </Button>
                </Pane>
            </form>
        </Pane>
    );
}

UpdateTaskForm.propTypes = {
    task: PropTypes.shape({
        id: PropTypes.number,
        tenant: PropTypes.string,
        building: PropTypes.string,
        buildings_id: PropTypes.string,
    }).isRequired,
    close: PropTypes.func.isRequired,
};

export default UpdateTaskForm;

const MenuInputCaret = styled(CaretDownIcon)``;

const MenuInputBox = styled(Pane)`
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(193, 196, 214);
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
`;
