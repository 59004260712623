import _ from 'lodash';

export const createComplianceTypeApiModel = (
    createComplianceTypeObject = {}
) => ({
    compliance_category_id: _.get(
        createComplianceTypeObject,
        'complianceCategoryId'
    ),
    name: _.get(createComplianceTypeObject, 'name'),
});

export const updateComplianceTypeApiModel = (
    createComplianceTypeObject = {}
) => ({
    compliance_category_id: _.get(
        createComplianceTypeObject,
        'complianceCategoryId'
    ),
    name: _.get(createComplianceTypeObject, 'name'),
    applicable: _.get(createComplianceTypeObject, 'applicable'),
});

export const complianceCategoryApiModel = (complianceCategoryObject = {}) => ({
    compliancegroup_id: _.get(complianceCategoryObject, 'complianceGroupId'),
    compliance_group_id: _.get(complianceCategoryObject, 'complianceGroupId'),
    compliance_category_id: _.get(
        complianceCategoryObject,
        'complianceCategoryId'
    ),
    name: _.get(complianceCategoryObject, 'name'),
    asset_id: _.get(complianceCategoryObject, 'assetId'),
    spaces_id: _.get(complianceCategoryObject, 0),
});

export const complianceGroupApiModel = (complianceGroupObject = {}) => ({
    compliance_group_id: _.get(complianceGroupObject, 'id'),
    asset_id: _.get(complianceGroupObject, 'assetId'),
    name: _.get(complianceGroupObject, 'name'),
    space: false,
});
