import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Heading, Pane, Paragraph } from 'evergreen-ui';

import UploadRequestLayout from '../../components/layouts/upload-request-layout';
import ConsultantUpdateDetailsSidebar from '../../components/organisms/consultant-update-details-sidebar/consultant-update-details-sidebar';
import { userSelector } from '../../reducers/user-reducer/user.reducer';
import UploadRequestInfoSidebar from '../../components/organisms/upload-request-info-sidebar/upload-request-info-sidebar';
import { SelectedUploadRequestProvider } from './selected-upload-request-context';
import UploadRequestDocumentUpload from '../../components/organisms/upload-request-document-upload/upload-request-document-upload';
import UploadRequestAssetInfoSidebar from '../../components/organisms/upload-request-asset-info-sidebar/upload-request-asset-info-sidebar';
import { uploadRequestSelector } from '../../reducers/upload-request-reducer/upload-request-reducer';
import uploadRequestsComplete from '../../assets/images/illustrations/upload-requests-complete.svg';
import { Image } from '../../components/atoms';

const UploadRequestPage = () => {
    const { user } = useSelector(userSelector);
    const { uploadsCompleted } = useSelector(uploadRequestSelector);

    const [
        isConsultantUpdateDetailsSidebarShown,
        setIsConsultantUpdateDetailsSidebarShown,
    ] = useState(false);

    useEffect(() => {
        if (!user) {
            return;
        }

        if (!user.mobileNumber || !user.profession || !user.professionalCode) {
            setIsConsultantUpdateDetailsSidebarShown(true);
        }
    }, [user]);

    if (uploadsCompleted) {
        return (
            <UploadRequestLayout>
                <Pane display="flex" flexDirection="column" alignItems="center">
                    <Image
                        src={uploadRequestsComplete}
                        alt="upload requests complete illustration"
                        maxWidth="400px"
                        marginY={12}
                    />
                    <Heading size={900} marginTop={24}>
                        Thank you
                        {user && user.firstName && ` ${user.firstName}`}!
                    </Heading>
                    <Paragraph>
                        You have uploaded all the required documents.
                    </Paragraph>
                </Pane>
            </UploadRequestLayout>
        );
    }

    return (
        <UploadRequestLayout>
            <SelectedUploadRequestProvider>
                <Pane
                    marginLeft={368}
                    marginRight={313}
                    height="calc(100% - 191px)"
                >
                    <UploadRequestInfoSidebar />
                    <UploadRequestDocumentUpload />
                    <UploadRequestAssetInfoSidebar />
                </Pane>
                <ConsultantUpdateDetailsSidebar
                    isShown={isConsultantUpdateDetailsSidebarShown}
                    setIsShown={setIsConsultantUpdateDetailsSidebarShown}
                />
            </SelectedUploadRequestProvider>
        </UploadRequestLayout>
    );
};

export default UploadRequestPage;
