import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
    Dialog,
    Pane,
    PlusIcon,
    Button,
    Text,
    InfoSignIcon,
    Tooltip,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import { userManagementSelector } from '../../reducers/user-management-reducer/user-management.reducer';
import {
    createPropertyGroup,
    deletePropertyGroup,
    getProperties,
    getPropertyGroups,
} from '../../reducers/user-management-reducer/user-management.actions';
import PageLoading from '../molecules/page-loading';
import PropertyGroupItem from './property-group-item';
import AddPropertyGroupForm from './add-property-group-form';
import { errorToast, successToast } from '../toasts';
import { useUserAccess } from '../../context/user-access-context';

function PropertyGroupsDialog({ isShown, close }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
    const [isAddingPropertyGroup, setIsAddingPropertyGroup] = useState(false);
    const [deletingPropertyGroup, setDeletingPropertyGroup] = useState(null);
    const [newPropertyGroup, setNewPropertyGroup] = useState({
        name: '',
        buildingIds: [],
    });

    const { currentRights } = useUserAccess();
    const { create } = useMemo(
        () => currentRights || { create: false },
        [currentRights]
    );

    const { properties, propertyGroups } = useSelector(userManagementSelector);

    const dispatch = useDispatch();

    const getData = useCallback(async () => {
        setIsLoading(true);
        try {
            await Promise.all([
                dispatch(getProperties()),
                dispatch(getPropertyGroups()),
            ]);
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    const handleConfirm = useCallback(async () => {
        setIsConfirmLoading(true);
        try {
            if (isAddingPropertyGroup) {
                await dispatch(createPropertyGroup(newPropertyGroup));
                setNewPropertyGroup({
                    name: '',
                    buildingIds: [],
                });
            } else if (deletingPropertyGroup) {
                await dispatch(deletePropertyGroup(deletingPropertyGroup.id));
            } else {
                close();
            }
            if (isAddingPropertyGroup || deletingPropertyGroup) {
                successToast(
                    `Successfully  ${
                        deletingPropertyGroup ? 'delete' : 'created'
                    } property group: ${
                        deletingPropertyGroup
                            ? deletingPropertyGroup.name
                            : newPropertyGroup.name
                    }`
                );
            }
        } catch (error) {
            errorToast(
                `An Error Occurred: ${get(
                    error,
                    'response.data.message',
                    `Failed to ${
                        deletingPropertyGroup ? 'delete' : 'create'
                    } property group: ${newPropertyGroup.name}`
                )}`
            );
        } finally {
            setIsAddingPropertyGroup(false);
            setDeletingPropertyGroup(null);
            setIsConfirmLoading(false);
        }
    }, [
        dispatch,
        isAddingPropertyGroup,
        newPropertyGroup,
        close,
        deletingPropertyGroup,
    ]);

    const onClose = () => {
        setDeletingPropertyGroup(null);
        setIsAddingPropertyGroup(false);
        setNewPropertyGroup({
            name: '',
            buildingIds: [],
        });
        close();
    };

    const handleCancel = useCallback(() => {
        if (isAddingPropertyGroup) {
            setIsAddingPropertyGroup(false);
        } else if (deletingPropertyGroup) {
            setDeletingPropertyGroup(null);
        } else {
            close();
        }
    }, [close, isAddingPropertyGroup, deletingPropertyGroup]);

    const renderContent = useCallback(() => {
        if (isLoading) {
            return (
                <Pane marginY={40}>
                    <PageLoading />
                </Pane>
            );
        }
        if (deletingPropertyGroup) {
            return (
                <Pane marginTop={20}>
                    <Text fontSize={18}>
                        Are you sure you want to delete the group{' '}
                        <strong style={{ textTransform: 'capitalize' }}>
                            {deletingPropertyGroup.name}
                        </strong>
                        ?
                    </Text>
                </Pane>
            );
        }
        if (isAddingPropertyGroup) {
            return (
                <AddPropertyGroupForm
                    newPropertyGroup={newPropertyGroup}
                    setNewPropertyGroup={setNewPropertyGroup}
                    properties={properties}
                />
            );
        }

        return (
            <Pane>
                {create && (
                    <Button
                        iconBefore={PlusIcon}
                        marginTop={-6}
                        marginBottom={16}
                        appearance="minimal"
                        onClick={() => setIsAddingPropertyGroup(true)}
                    >
                        Add Property Group
                    </Button>
                )}
                <Pane display="flex" flexDirection="column" gap={12}>
                    {propertyGroups?.map((group) => (
                        <PropertyGroupItem
                            key={group.id}
                            group={group}
                            properties={properties}
                            setDeletingPropertyGroup={setDeletingPropertyGroup}
                            currentRights={currentRights}
                            setIsConfirmDisabled={setIsConfirmDisabled}
                        />
                    ))}
                </Pane>
            </Pane>
        );
    }, [
        isLoading,
        deletingPropertyGroup,
        isAddingPropertyGroup,
        create,
        propertyGroups,
        newPropertyGroup,
        properties,
        currentRights,
    ]);

    const title = useMemo(() => {
        if (isAddingPropertyGroup) {
            return 'Add Property Group';
        }
        if (deletingPropertyGroup) {
            return 'Delete Property Group';
        }
        return 'Property Groups';
    }, [deletingPropertyGroup, isAddingPropertyGroup]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <Dialog
            isShown={isShown}
            title={
                <Pane display="flex" alignItems="center" gap={6}>
                    <Text fontSize={18} fontWeight={700}>
                        {title}
                    </Text>
                    <Tooltip
                        content="This section is optional: here you can create a collection of properties sharing common attributes such as region, manager, portfolio, etc. Provide a descriptive name that captures the essence of this property group."
                        showDelay={250}
                    >
                        <InfoSignIcon color="#444a61" size={12} />
                    </Tooltip>
                </Pane>
            }
            onCloseComplete={onClose}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            hasClose={false}
            isConfirmLoading={isConfirmLoading}
            isConfirmDisabled={isConfirmDisabled}
            hasCancel={!isConfirmDisabled}
            width={750}
            intent={deletingPropertyGroup ? 'danger' : 'none'}
        >
            {renderContent()}
        </Dialog>
    );
}

PropertyGroupsDialog.propTypes = {
    isShown: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
};

export default PropertyGroupsDialog;
