import { notificationsService } from '../../services';
import {
    setNotificationsAction,
    setNotificationsPerBuildingAction,
    setNotificationsPerModuleAction,
} from './notifications.reducer';

export function getNotifications() {
    return async (dispatch, getState) => {
        const notifications = await notificationsService.getNotifications();
        const { currentTenant, currentBuilding, currentAsset } =
            getState().assetReducer;

        let filteredNotifications = notifications;

        const notificationsPerModule = {};
        const notificationsPerBuilding = {};

        if (currentTenant) {
            filteredNotifications = notifications.filter(
                ({ metadata: { building_id: buildingId } }) =>
                    Number(buildingId) === Number(currentTenant.id)
            );
        }
        if (currentBuilding) {
            filteredNotifications = notifications.filter(
                ({ metadata: { building_id: buildingId } }) =>
                    Number(buildingId) === Number(currentBuilding.id)
            );
        }
        if (currentAsset) {
            filteredNotifications = notifications.filter(
                ({ metadata: { building_id: buildingId } }) =>
                    Number(buildingId) === Number(currentAsset.id)
            );
        }

        filteredNotifications.forEach(
            ({ critical, metadata: { type, building_id: buildingId } }) => {
                if (critical) {
                    const key =
                        type === 'park' ||
                        type === 'building' ||
                        type === 'tenant'
                            ? 'info'
                            : type;
                    if (!notificationsPerModule[key]) {
                        notificationsPerModule[key] = 0;
                    }
                    notificationsPerModule[key] += 1;
                    if (!notificationsPerBuilding[buildingId]) {
                        notificationsPerBuilding[buildingId] = 0;
                    }
                    notificationsPerBuilding[buildingId] += 1;
                }
            }
        );

        await dispatch(
            setNotificationsPerBuildingAction(notificationsPerBuilding)
        );
        await dispatch(setNotificationsPerModuleAction(notificationsPerModule));
        await dispatch(setNotificationsAction(notifications));

        return {
            notifications,
            notificationsPerModule,
            notificationsPerBuilding,
        };
    };
}

export function markNotificationRead(notificationId) {
    return async (dispatch) => {
        await notificationsService.markNotificationRead(notificationId);
        dispatch(getNotifications());
    };
}
