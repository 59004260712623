import _ from 'lodash';

import networkService from '../network-service/network.service';
import uploadRequestUrls from './upload-request-service-urls';
import { createUploadRequestApiModel } from '../../../models';
import { XANO_ACCESS_TOKEN_KEY } from '../../../local-storage-keys';

function sendUploadRequest(uploadRequest) {
    const url = uploadRequestUrls.createUploadRequest();
    const data = createUploadRequestApiModel(uploadRequest);
    return networkService.makePost(url, data);
}

async function getAllUploadRequestsWithToken(token) {
    const url = uploadRequestUrls.getAllUploadRequestsWithTokenUrl();
    const config = { params: { token } };
    const data = await networkService.makeGet(url, config);

    localStorage.setItem(XANO_ACCESS_TOKEN_KEY, _.get(data, 'authToken'));

    return data;
}

function cancelUploadRequest(uploadRequestId) {
    const url = uploadRequestUrls.deleteUploadRequest(uploadRequestId);
    return networkService.makeDelete(url);
}

function completeUploadRequest(uploadRequestId) {
    const url = uploadRequestUrls.completeUploadRequestUrl();
    const data = { upload_id: uploadRequestId };
    return networkService.makePost(url, data);
}

export default {
    sendUploadRequest,
    getAllUploadRequestsWithToken,
    cancelUploadRequest,
    completeUploadRequest,
};
