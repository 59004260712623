import React, { useMemo } from 'react';
import { Pane, TextInput, Text, Button, SelectMenu } from 'evergreen-ui';
import PropTypes from 'prop-types';

function AddPropertyGroupForm({
    newPropertyGroup,
    setNewPropertyGroup,
    properties,
}) {
    const options = useMemo(() => {
        const sortedProperties = [...properties].sort(({ id }) => {
            if (newPropertyGroup.buildingIds?.includes(Number(id))) {
                return -1;
            }
            return 1;
        });

        return sortedProperties.map((property) => ({
            label: property.assetName,
            value: Number(property.id),
        }));
    }, [properties, newPropertyGroup]);

    const buttonText = useMemo(() => {
        if (!newPropertyGroup.buildingIds.length) {
            return 'Select Properties';
        }
        return options
            .map(({ label, value }) => {
                if (newPropertyGroup.buildingIds?.includes(value)) {
                    return label;
                }
                return null;
            })
            .filter((value) => !!value)
            .join(', ');
    }, [options, newPropertyGroup]);

    const handleChange = ({ target: { value } }) => {
        setNewPropertyGroup((propertyGroup) => ({
            ...propertyGroup,
            name: value,
        }));
    };

    const onSelect = ({ value }) => {
        setNewPropertyGroup((propertyGroup) => ({
            ...propertyGroup,
            buildingIds: [...propertyGroup.buildingIds, value],
        }));
    };

    const onDeselect = ({ value }) => {
        setNewPropertyGroup((propertyGroup) => ({
            ...propertyGroup,
            buildingIds: propertyGroup.buildingIds.filter(
                (id) => Number(id) !== Number(value)
            ),
        }));
    };

    return (
        <Pane
            marginTop={12}
            display="flex"
            alignItems="center"
            gap={12}
            justifyContent="space-between"
        >
            <TextInput
                height={40}
                value={newPropertyGroup.name}
                border="1px solid #c1c4d6"
                onChange={handleChange}
                placeholder="Group Name"
                autoFocus
            />
            <Pane display="flex" width="100%" maxWidth="75%">
                <SelectMenu
                    isMultiSelect
                    title="Select Properties"
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    options={options}
                    selected={newPropertyGroup.buildingIds}
                    filterPlaceholder="Search Properties"
                >
                    <Pane display="flex" width="100%" gap={6}>
                        <Button
                            justifyContent="space-between"
                            display="flex"
                            flex={11}
                            width="calc(100%)"
                            height={40}
                        >
                            <Text
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                            >
                                {buttonText}
                            </Text>
                        </Button>
                    </Pane>
                </SelectMenu>
            </Pane>
        </Pane>
    );
}

AddPropertyGroupForm.propTypes = {
    newPropertyGroup: PropTypes.shape({
        name: PropTypes.string,
        buildingIds: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    setNewPropertyGroup: PropTypes.func.isRequired,
    properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AddPropertyGroupForm;
