import React, { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { Pane, Text, Tooltip } from 'evergreen-ui';
import PropTypes from 'prop-types';

import { MultiColorProgressBar } from '../../atoms';

const AssetProgressBar = ({ asset }) => {
    const { completed, uploadsRequested, notStarted, total } = useMemo(
        () =>
            asset.progress
                ? asset.progress
                : {
                      completed: null,
                      uploadsRequested: null,
                      notStarted: null,
                      total: null,
                  },
        [asset]
    );
    const completedPercentage = useMemo(
        () => asset.progress && (completed / total) * 100,
        [asset, completed, total]
    );
    const uploadsRequestedPercentage = useMemo(
        () => asset.progress && (uploadsRequested / total) * 100,
        [asset, uploadsRequested, total]
    );
    const notStartedPercentage = useMemo(
        () => asset.progress && (notStarted / total) * 100,
        [asset, notStarted, total]
    );

    const complianceProgress = useMemo(() => {
        if (!asset.progress) {
            return [];
        }

        return [
            {
                value:
                    completedPercentage >= 100 || completedPercentage <= 0
                        ? completedPercentage
                        : completedPercentage - 5,
                color: '#58A81D',
            },
            {
                value:
                    completedPercentage < 100 && completedPercentage > 0
                        ? 5
                        : 0,
                color: '#8AC44D',
            },
            {
                value: uploadsRequestedPercentage,
                color: '#FFCE2F',
            },
            {
                value:
                    notStartedPercentage < 100 && notStartedPercentage > 0
                        ? 5
                        : 0,
                color: '#F69779',
            },
            {
                value:
                    notStartedPercentage >= 100 || notStartedPercentage <= 0
                        ? notStartedPercentage
                        : notStartedPercentage - 5,
                color: '#F16263',
            },
        ];
    }, [
        completedPercentage,
        uploadsRequestedPercentage,
        notStartedPercentage,
        asset,
    ]);

    if (!asset.progress) {
        return (
            <Tooltip content="Loading progress...">
                <Pane marginTop={16} height={10}>
                    <ContentLoader
                        speed={2}
                        width="100%"
                        height={10}
                        viewBox="0 0 400 10"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#8E93A2"
                    >
                        <rect
                            x="0"
                            y="0"
                            rx="0"
                            ry="0"
                            width="400"
                            height="10"
                        />
                    </ContentLoader>
                </Pane>
            </Tooltip>
        );
    }

    return (
        <Pane marginTop={16} height={10}>
            <Text
                lineHeight="20px"
                marginBottom={4}
                fontSize={12}
                fontWeight="700"
                color="#474D66"
            >
                Records Progress: {Math.round(completedPercentage)}%
            </Text>
            <MultiColorProgressBar progressValues={complianceProgress} />
        </Pane>
    );
};

AssetProgressBar.propTypes = {
    asset: PropTypes.shape({
        progress: PropTypes.shape({}),
    }).isRequired,
};

export default AssetProgressBar;
