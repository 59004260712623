import React, { useMemo } from 'react';
import {
    SideSheet,
    Pane,
    Heading,
    Paragraph,
    Text,
    Button,
    ArrowRightIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

function AssetDetailsSideSheet({ isShown, close, asset }) {
    const navigate = useNavigate();

    const {
        erfNumber,
        assetName,
        type,
        grossBuildingArea,
        grossLettableArea,
        builtFar,
        permissibleFar,
        builtCoverage,
        permissibleCoverage,
        buildingUse,
    } = useMemo(() => {
        if (asset) {
            return asset;
        }
        return {
            portfolioName: '',
            erfNumber: '',
            assetName: '',
            propertyType: '',
            grossBuildingArea: '',
            grossLettableArea: '',
            builtFar: '',
            permissibleFar: '',
            builtCoverage: '',
            permissibleCoverage: '',
        };
    }, [asset]);

    const setBuilding = async (assetId) => {
        let url = '/portfolio/properties';
        if (asset.type === 'park') {
            url += `/parks/${assetId}`;
        } else if (asset.type === 'building') {
            url += `/buildings/${assetId}`;
        } else if (asset.type === 'tenant') {
            url += `/tenants/${assetId}`;
        }
        url += '/info';
        navigate(url);
    };

    return (
        <SideSheet width={450} isShown={isShown} onCloseComplete={close}>
            <Pane
                margin={30}
                marginTop={10}
                paddingBottom={30}
                borderBottom="0.5px solid #696F8C"
            >
                <Heading textTransform="uppercase" lineHeight={3}>
                    {assetName}
                </Heading>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Erf No:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {erfNumber}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Building Use:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {buildingUse}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Property Type:&emsp;
                    <Text
                        fontSize={12}
                        fontWeight={600}
                        color="#333333"
                        textTransform="capitalize"
                    >
                        {type}
                    </Text>
                </Paragraph>
                <br />
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    GBA (Gross Building Area):&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {grossBuildingArea}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    GLA (Gross Lettable Area):&emsp;
                    {grossLettableArea && (
                        <Text fontSize={12} fontWeight={600} color="#333333">
                            {grossLettableArea}m&sup2;
                        </Text>
                    )}
                </Paragraph>
                <br />
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Built FAR (Floor Area Ratio):&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {builtFar}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Permissible FAR (Floor Area Ratio):&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {permissibleFar}
                    </Text>
                </Paragraph>
                <br />
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Built Coverage:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {builtCoverage}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Permissible Coverage:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {permissibleCoverage}
                    </Text>
                </Paragraph>
            </Pane>
            <Button
                appearance="minimal"
                height={40}
                marginTop={12}
                onClick={() => setBuilding(asset.id)}
                marginLeft={30}
            >
                <Text fontWeight="700" color="#8F95B2" marginRight={6}>
                    Property Page
                </Text>
                <ArrowRightIcon color="#8F95B2" />
            </Button>
        </SideSheet>
    );
}

AssetDetailsSideSheet.propTypes = {
    isShown: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    asset: PropTypes.shape({
        id: PropTypes.string.isRequired,
        assetName: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        parentId: PropTypes.string.isRequired,
    }).isRequired,
};

export default AssetDetailsSideSheet;
