import { get, map } from 'lodash';

export const userModel = (user) => {
    const modules = {
        menu_overview: 'portfolio',
        menu_info: 'info',
        menu_compliance: 'compliance',
        menu_maintenance: 'maintenance',
        menu_tasks: 'tasks',
        menu_contacts: 'contacts',
        user_management: 'user-management',
        template_management: 'template-management',
    };

    return {
        id: get(user, 'id', ''),
        createdAt: get(user, 'created_at', ''),
        name: get(user, 'name', ''),
        surname: get(user, 'surname', ''),
        fullName: `${get(user, 'name', '')} ${get(user, 'surname', '')}`,
        email: get(user, 'email', ''),
        mobileNo: get(user, 'mobileno', ''),
        emailConfirmed: get(user, 'email_confirmed', ''),
        tenantId: get(user, 'tenant_id', ''),
        active: get(user, 'active', ''),

        userAccess: {
            id: get(user, 'user_access.id', ''),
            createdAt: get(user, 'user_access.created_at', ''),
            userId: get(user, 'user_access.user_id', ''),
            roleId: get(user, 'user_access.user_roles_id', ''),
            type: get(user, 'user_access.role', ''),
            role: {
                id: get(user, 'user_access.user_roles.id', ''),
                createdAt: get(user, 'user_access.user_roles.created_at', ''),
                name: get(user, 'user_access.user_roles.name', ''),
                tenantId: get(user, 'user_access.user_roles.tenant_id', ''),
                access: map(
                    get(user, 'user_access.user_roles.access', []),

                    ({ item, create, read, update, delete: _delete }) => ({
                        name: modules[item],
                        rights: {
                            create,
                            read,
                            update,
                            delete: _delete,
                        },
                    })
                ),
            },
            propertyGroupIds: get(user, 'user_access.property_groups_id', []),
            buildingIds: get(user, 'user_access.buildings_id', []),
            tenantId: get(user, 'user_access.tenant_id', ''),
            businessId: get(user, 'user_access.business_id', ''),
        },
        business: {
            id: get(user, 'user_access.business.id', ''),
            legalName: get(user, 'user_access.business.legalname', ''),
            friendlyName: get(user, 'user_access.business.friendlyname', ''),
        },
    };
};

export const userApiModel = (user) => ({
    user_id: get(user, 'id', ''),
    name: get(user, 'name', ''),
    surname: get(user, 'surname', ''),
    email: get(user, 'email', ''),
    mobileno: get(user, 'mobileNo', ''),
    type: get(user, 'role', ''),
    user_roles_id: get(user, 'roleId', ''),
});

export const roleModel = (role) => {
    const modules = {
        menu_overview: 'portfolio',
        menu_info: 'info',
        menu_compliance: 'compliance',
        menu_maintenance: 'maintenance',
        menu_tasks: 'tasks',
        menu_contacts: 'contacts',
        user_management: 'user-management',
        template_management: 'template-management',
    };
    return {
        id: get(role, 'id', ''),
        createdAt: get(role, 'created_at', ''),
        name: get(role, 'name', ''),
        tenantId: get(role, 'tenant_id', ''),
        access: map(
            get(role, 'access', []),
            ({ item, create, read, update, delete: _delete }) => ({
                name: modules[item],
                rights: {
                    create,
                    read,
                    update,
                    delete: _delete,
                },
            })
        ),
    };
};

export const roleApiModel = (role) => {
    const modules = {
        portfolio: 'menu_overview',
        info: 'menu_info',
        compliance: 'menu_compliance',
        maintenance: 'menu_maintenance',
        tasks: 'menu_tasks',
        contacts: 'menu_contacts',
        'user-management': 'user_management',
        'template-management': 'template_management',
    };

    return {
        user_roles_id: get(role, 'id', ''),
        name: get(role, 'name', ''),
        access: map(
            get(role, 'access', []),
            ({ name, rights: { create, read, update, delete: _delete } }) => ({
                item: modules[name],
                create,
                read,
                update,
                delete: _delete,
            })
        ),
    };
};

export const propertyGroupModel = (propertyGroup) => ({
    id: get(propertyGroup, 'id', ''),
    createdAt: get(propertyGroup, 'created_at', ''),
    tenantId: get(propertyGroup, 'tenant_id', ''),
    name: get(propertyGroup, 'name', ''),
    buildingIds: map(
        get(propertyGroup, 'buildings_id', []).filter((value) => !!value),
        ({ buildings }) => buildings?.id
    ),
});

export const propertyGroupAPIModel = (propertyGroup) => ({
    id: get(propertyGroup, 'id', ''),
    name: get(propertyGroup, 'name', ''),
    buildings_id: get(propertyGroup, 'buildingIds', ''),
    property_groups_id: get(propertyGroup, 'id', ''),
    user_id: get(propertyGroup, 'userId', ''),
});

export const linkPropertiesAPIModel = (data) => ({
    link_property_group: get(data, 'isLinkingGroup', false),
    user_id: get(data, 'userId', ''),
    buildings_id: get(data, 'buildingIds', ''),
    property_groups_id: get(data, 'propertyGroupIds', ''),
});
