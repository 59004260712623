import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notifications: [],
    notificationsPerBuilding: {},
    notificationsPerModule: {},
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotificationsAction(state, { payload }) {
            state.notifications = payload;
        },
        setNotificationsPerBuildingAction(state, { payload }) {
            state.notificationsPerBuilding = payload;
        },
        setNotificationsPerModuleAction(state, { payload }) {
            state.notificationsPerModule = payload;
        },
    },
});

export const {
    setNotificationsAction,
    setNotificationsPerBuildingAction,
    setNotificationsPerModuleAction,
} = notificationsSlice.actions;

export const notificationsSelector = (reducers) =>
    reducers.notificationsReducer;

export default notificationsSlice.reducer;
