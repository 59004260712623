import React, { useMemo } from 'react';
import { Card, Pane, Text } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import BuildingPlaceholderImage from '../../../assets/images/illustrations/placeholder-building.svg';
import { Image } from '../../atoms';
import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';
import { BuildingImage } from '../../molecules';

const AssetSidebarInfoBrief = ({
    selectedAsset,
    showAssetName,
    imageWidth,
}) => {
    const { currentAsset } = useSelector(assetSelector);

    const featuredImage = useMemo(() => {
        if (_.size(_.get(currentAsset, 'images')) <= 0) {
            return null;
        }

        return _.find(currentAsset.images, (image) => image.isFeatured);
    }, [currentAsset]);

    const asset = useMemo(() => {
        if (selectedAsset) {
            return selectedAsset;
        }

        return currentAsset;
    }, [selectedAsset, currentAsset]);

    const renderNameAndAddress = () => {
        if (!showAssetName) {
            return null;
        }

        return (
            <Pane maxHeight={imageWidth} display="flex" flexDirection="column">
                <Text fontSize={18} fontWeight="bold" color="#333333">
                    {asset.assetName}
                </Text>
                <Text fontSize={16} color="#333333">
                    {asset.address.addressLine1},
                </Text>
                <Text fontSize={16} color="#333333">
                    {asset.address.suburb}, {asset.address.city}
                </Text>
            </Pane>
        );
    };

    const renderAddress = () => {
        if (showAssetName) {
            return null;
        }

        return (
            <Pane maxHeight={imageWidth} display="flex" flexDirection="column">
                <Text fontSize={16} fontWeight="bold" color="#333333">
                    {asset.address.addressLine1}
                </Text>
                <Text fontSize={16} color="#333333">
                    {asset.address.suburb}, {asset.address.city}
                </Text>
            </Pane>
        );
    };

    return (
        <Pane
            display="flex"
            alignItems="center"
            paddingX={12}
            justifyContent="space-between"
            flex={1}
        >
            <Pane height={imageWidth} display="flex" alignItems="center">
                {renderAddress()}
                {renderNameAndAddress()}
            </Pane>
            <Card
                width={imageWidth}
                height={imageWidth}
                elevation={3}
                borderRadius={10}
                overflow="hidden"
            >
                {featuredImage ? (
                    <BuildingImage
                        image={featuredImage}
                        width={imageWidth}
                        height={imageWidth}
                    />
                ) : (
                    <Image
                        src={BuildingPlaceholderImage}
                        width={imageWidth}
                        height={imageWidth}
                        borderRadius={10}
                    />
                )}
            </Card>
        </Pane>
    );
};

AssetSidebarInfoBrief.propTypes = {
    selectedAsset: PropTypes.shape({}),
    showAssetName: PropTypes.bool,
    imageWidth: PropTypes.number,
};

AssetSidebarInfoBrief.defaultProps = {
    selectedAsset: null,
    showAssetName: false,
    imageWidth: 83,
};

export default AssetSidebarInfoBrief;
