import React from 'react';
import { Heading, Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Image from './image';
import BlueLogoIcon from '../../assets/logo/logo-icon.png';
import WhiteLogoIcon from '../../assets/logo/logo-icon-white.png';
import { colors } from '../../theme/theme';
import { setCurrentAssetAction } from '../../reducers/asset-reducer/asset.reducer';

const Logo = ({ size, color, ...restProps }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const reset = () => {
        dispatch(setCurrentAssetAction(null));
        navigate('/portfolio');
    };

    return (
        <Pane
            display="flex"
            alignItems="center"
            role="button"
            cursor="pointer"
            onClick={reset}
            textDecoration="none"
            {...restProps}
        >
            <Image
                src={color === 'blue' ? BlueLogoIcon : WhiteLogoIcon}
                width={size === 'small' ? 40 : 75}
            />
            <Heading
                paddingLeft={size === 'small' ? '2px' : '16px'}
                fontSize={size === 'small' ? 16 : 32}
                fontWeight={700}
                color={color === 'blue' ? colors.primary500 : 'white'}
            >
                Asset{' '}
            </Heading>
            <Heading
                color={color === 'blue' ? colors.secondary500 : 'white'}
                paddingLeft="8px"
                fontSize={size === 'small' ? 16 : 32}
                fontWeight={700}
            >
                Records
            </Heading>
        </Pane>
    );
};

Logo.propTypes = {
    size: PropTypes.oneOf(['small', 'large']),
    color: PropTypes.oneOf(['white', 'blue']),
};

Logo.defaultProps = {
    size: 'small',
    color: 'blue',
};

export default Logo;
