import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pane, Spinner, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import { DashboardNavbar } from '../molecules';
import useQueryParams from '../../hooks/use-query-params';
import { uploadRequestSelector } from '../../reducers/upload-request-reducer/upload-request-reducer';
import { getAllUploadRequestsWithToken } from '../../reducers/upload-request-reducer/upload-request.actions';
import ErrorPage from '../../pages/other-pages/error-page';
import { userSelector } from '../../reducers/user-reducer/user.reducer';

const UploadRequestLayout = ({ children }) => {
    const dispatch = useDispatch();
    const { token } = useQueryParams();
    const { isLoading } = useSelector(uploadRequestSelector);
    const { user } = useSelector(userSelector);

    useEffect(() => {
        if (token) {
            dispatch(getAllUploadRequestsWithToken(token));
        }
    }, [dispatch, token]);

    const renderLoadingPlaceholder = () => {
        if (!isLoading && user) {
            return null;
        }

        return (
            <Pane
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Spinner />
                <Text marginTop="1rem">Loading...</Text>
            </Pane>
        );
    };

    const renderError = () => {
        if (!_.isEmpty(token)) {
            return null;
        }
        return <ErrorPage />;
    };

    const renderLayout = () => {
        if (isLoading || _.isEmpty(token)) {
            return null;
        }

        return (
            <Pane width="100%" height="100%" maxWidth="100%" overflowX="hidden">
                <DashboardNavbar isUploadRequestLayout />
                <Pane
                    display="flex"
                    flexDirection="column"
                    alignItems="stretch"
                    marginTop={64}
                    height="calc(100% - 64px)"
                >
                    {children}
                </Pane>
            </Pane>
        );
    };

    return (
        <>
            <Helmet>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
            </Helmet>
            {renderLoadingPlaceholder()}
            {renderError()}
            {renderLayout()}
        </>
    );
};

UploadRequestLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default UploadRequestLayout;
