const userManagementBaseUrl = '/user';
const rolesBaseUrl = '/roles';
const propertiesBaseUrl = '/properties';

export default {
    // Users
    getUsersUrl: () => userManagementBaseUrl,
    addUserUrl: () => `${userManagementBaseUrl}/add`,
    editUserUrl: () => userManagementBaseUrl,
    deleteUserUrl: (id) => `${userManagementBaseUrl}?user_id=${id}`,

    // Roles
    getRolesUrl: () => rolesBaseUrl,
    createRoleUrl: () => `${rolesBaseUrl}/new`,
    editRoleUrl: () => `${rolesBaseUrl}/update`,
    deleteRoleUrl: (id) => `${rolesBaseUrl}?user_roles_id=${id}`,

    // Properties
    getPropertiesUrl: () => propertiesBaseUrl,
    getPropertyGroupsUrl: () => `${propertiesBaseUrl}/groups`,
    createPropertiesGroupUrl: () => `${propertiesBaseUrl}/group/new`,
    editPropertiesGroupUrl: () => `${propertiesBaseUrl}/group`,
    linkPropertyGroupToUserUrl: () => `${propertiesBaseUrl}/link/user`,
    deletePropertiesGroupUrl: (id) =>
        `${propertiesBaseUrl}/group/delete?property_groups_id=${id}`,
};
