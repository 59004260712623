/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Dialog, Pane, Text, Textarea, TextInputField } from 'evergreen-ui';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { updateMaintenanceDocument } from '../../reducers/maintenance-reducer/maintenance-actions';
import { updateTaskAttachment } from '../../reducers/task-reducer/task.actions';

function EditDocumentModal({ document, isShown, close, isTask }) {
    const dispatch = useDispatch();

    const [editDocument, setEditDocument] = useState({
        originalName: document?.friendlyName,
        newName: document?.friendlyName,
        notes: document?.notes,
        id: document?.id,
        errors: {},
        loading: false,
    });

    const handleChange = ({ target }) => {
        setEditDocument((state) => ({
            ...state,
            [target.name]: target.value,
        }));
    };

    const handleConfirm = async () => {
        setEditDocument((state) => ({ ...state, loading: true }));
        try {
            if (!editDocument.newName) {
                setEditDocument((state) => ({
                    ...state,
                    errors: {
                        newName: 'Updated Document Name is Required!',
                    },
                }));
                return;
            }
            if (isTask) {
                dispatch(updateTaskAttachment(editDocument));
            } else {
                dispatch(updateMaintenanceDocument(editDocument));
            }
            close();
        } finally {
            setEditDocument((state) => ({ ...state, loading: false }));
        }
    };

    useEffect(
        () =>
            setEditDocument((state) => ({
                ...state,
                originalName: document?.friendlyName,
                newName: document?.friendlyName,
                notes: document?.notes,
                id: document?.id,
            })),
        [document]
    );
    return (
        <Dialog
            title={`Editing ${editDocument.originalName || 'Document'}`}
            isShown={isShown}
            onCloseComplete={close}
            shouldCloseOnOverlayClick={false}
            onConfirm={handleConfirm}
            isConfirmLoading={editDocument.loading}
        >
            <Pane>
                <EditNameField
                    name="newName"
                    value={editDocument.newName}
                    inputHeight={40}
                    onChange={handleChange}
                    validationMessage={editDocument.errors?.newName}
                />
                <Pane display="flex" flexDirection="column" gap={8}>
                    <Text>Notes:</Text>
                    <Textarea
                        value={editDocument.notes}
                        name="notes"
                        border="1px solid #a7a8ac"
                        maxWidth={496}
                        minWidth="100%"
                        onChange={handleChange}
                    />
                </Pane>
            </Pane>
        </Dialog>
    );
}

const EditNameField = styled(TextInputField)`
    border-color: #a7a8ac;
`;

EditDocumentModal.propTypes = {
    close: PropTypes.func.isRequired,
    document: PropTypes.shape({
        id: PropTypes.string,
        friendlyName: PropTypes.string,
        notes: PropTypes.string,
        taskItemId: PropTypes.string,
        maintenanceItemId: PropTypes.string,
    }).isRequired,
    isShown: PropTypes.bool.isRequired,
    isTask: PropTypes.bool,
};

EditDocumentModal.defaultProps = {
    isTask: false,
};

export default EditDocumentModal;
