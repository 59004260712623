import _ from 'lodash';

import {
    setIsLoadingAction,
    setUploadRequestsAction,
    setUploadRequestTokenAction,
    setUploadsCompletedAction,
} from './upload-request-reducer';
import { setUserAction } from '../user-reducer/user.reducer';
import { uploadRequestsModel, userModels } from '../../models';
import { uploadRequestService } from '../../services';
import { API_ACCESS_TOKEN_KEY } from '../../local-storage-keys';

/* eslint-disable import/prefer-default-export */
export function getAllUploadRequestsWithToken(token) {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));

        try {
            const uploadRequestResponse =
                await uploadRequestService.getAllUploadRequestsWithToken(token);
            const uploadRequests = uploadRequestsModel(
                _.get(uploadRequestResponse, 'uploadrequests', [])
            );

            dispatch(
                setUploadRequestTokenAction(
                    _.get(uploadRequestResponse, 'authToken')
                )
            );
            dispatch(setUserAction(uploadRequests[0].contact));
            dispatch(setUploadRequestsAction(uploadRequests));

            localStorage.setItem(
                API_ACCESS_TOKEN_KEY,
                _.get(uploadRequestResponse, 'v2AccessToken')
            );
        } catch (error) {
            localStorage.removeItem(API_ACCESS_TOKEN_KEY);
            const errorMessage = _.get(
                error,
                'response.data.message',
                error.message
            );
            if (errorMessage === 'No active upload requests found') {
                dispatch(setUploadsCompletedAction(true));
                dispatch(
                    setUserAction(
                        userModels(
                            _.get(
                                error,
                                'response.data.payload.contacts_business_staff',
                                error.message
                            )
                        )
                    )
                );
            }
        } finally {
            dispatch(setIsLoadingAction(false));
        }
    };
}
