import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    user: null,
    isAdmin: false,
    businessFriendlyName: null,
    businessIds: [],
    portfolioIds: [],
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setUserAction(state, action) {
            const roleName = action.payload?.userAccess?.userRoles?.name;
            state.isAdmin = roleName === 'admin';
            state.user = action.payload;
        },
        setBusinessIdsAction(state, action) {
            state.businessIds = action.payload;
        },
        setBusinessFriendlyNameAction(state, action) {
            state.businessFriendlyName = action.payload;
        },
        setPortfolioIdsAction(state, action) {
            state.portfolioIds = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setUserAction,
    setBusinessIdsAction,
    setBusinessFriendlyNameAction,
    setPortfolioIdsAction,
} = userSlice.actions;

export const userSelector = (reducers) => reducers.userReducer;

export default userSlice.reducer;
