import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const SegmentedControl = ({ name, segments, callback, defaultIndex = 0 }) => {
    const [activeIndex, setActiveIndex] = useState(defaultIndex);
    const componentReady = useRef();
    const controlRef = useRef();

    useEffect(() => {
        componentReady.current = true;
    }, []);

    useEffect(() => {
        if (!controlRef?.current) {
            return;
        }
        const activeSegmentRef = segments[activeIndex].ref;
        const { offsetWidth, offsetLeft, offsetHeight } =
            activeSegmentRef.current;
        const { style } = controlRef.current;
        style.setProperty('--highlight-width', `${offsetWidth}px`);
        style.setProperty('--highlight-height', `${offsetHeight}px`);
        style.setProperty('--highlight-x-pos', `${offsetLeft}px`);
    }, [activeIndex, callback, controlRef, segments]);

    const onInputChange = (value, index) => {
        setActiveIndex(index);
        callback(value, index);
    };

    return (
        <div className="controls-container" ref={controlRef}>
            <div
                className={`controls ${
                    componentReady.current ? 'ready' : 'idle'
                }`}
            >
                {segments?.map((item, i) => (
                    <div
                        key={item.value}
                        className={`segment ${
                            i === activeIndex ? 'active' : 'inactive'
                        }`}
                        ref={item.ref}
                    >
                        <input
                            type="radio"
                            value={item.value}
                            id={item.label}
                            name={name}
                            onChange={() => onInputChange(item.value, i)}
                            checked={i === activeIndex}
                        />
                        <label htmlFor={item.label}>{item.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

SegmentedControl.propTypes = {
    name: PropTypes.string.isRequired,
    segments: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            // eslint-disable-next-line react/forbid-prop-types
            ref: PropTypes.any.isRequired,
        })
    ).isRequired,
    callback: PropTypes.func.isRequired,
    defaultIndex: PropTypes.number,
};

SegmentedControl.defaultProps = {
    defaultIndex: 0,
};

export default SegmentedControl;
