import React from 'react';
import { Badge, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';

const STATUS_COLORS = {
    'up to date': { color: '#50C878', backgroundColor: '#50C87840' },
    overdue: { color: '#B3261E', backgroundColor: '#B3261E50' },
    pending: { color: '#FFA500', backgroundColor: '#FFA50050' },
    notified: { color: '#696F8C', backgroundColor: '#696F8C50' },
    'in progress': { color: '#6495ED', backgroundColor: '#6495ED50' },
    new: { color: '#7F00FF', backgroundColor: '#7F00FF30' },
    default: {
        badgeColor: '#D3F5F7',
        textColor: '#0F5156',
    },
};

const MaintenanceStatusBadge = ({ status }) => {
    const { color, backgroundColor } =
        STATUS_COLORS[status.title?.toLowerCase()] || STATUS_COLORS.default;
    return (
        <>
            <Badge
                display="flex"
                width="min-content"
                alignItems="center"
                color={backgroundColor}
                marginX="auto"
                marginY="auto"
            >
                <Text
                    color={color}
                    fontSize={12}
                    fontWeight={600}
                    whiteSpace="nowrap"
                >
                    {status.title}
                </Text>
            </Badge>
            {status.label && (
                <Text lineHeight="12px" fontSize={11}>
                    {status.label}
                </Text>
            )}
        </>
    );
};

export default MaintenanceStatusBadge;

MaintenanceStatusBadge.propTypes = {
    status: PropTypes.string.isRequired,
};
