import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pane, Spinner, Text } from 'evergreen-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { userSelector } from '../../reducers/user-reducer/user.reducer';
import { getUser } from '../../reducers/user-reducer/user.actions';
import { DashboardNavbar, DashboardSideNavbar } from '../molecules';
import SearchParamsProvider from '../../context/search-params-context';
import UserAccessProvider from '../../context/user-access-context';
import Outlet from './outlet';
import { getFullDetailsForAllAssets } from '../../reducers/asset-reducer/asset.actions';
import authService from '../../services/sub-services/auth-service/auth.service';

const DashboardLayout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isAuthenticated } = useSelector((state) => state.authReducer);
    const { isLoading, user, businessIds } = useSelector(userSelector);

    const { pathname } = useLocation();

    const handleSystemStatus = useCallback(async () => {
        const maintenance = await authService.getSystemStatus();
        if (maintenance?.under_maintenance) {
            localStorage.clear();
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        dispatch(getUser());
        dispatch(getFullDetailsForAllAssets());
    }, [dispatch]);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (_.isEmpty(businessIds) && !isLoading && user) {
            navigate('/create-business', { replace: true });
        }
    }, [businessIds, isLoading, user, navigate]);

    useEffect(() => {
        handleSystemStatus();
    }, [handleSystemStatus]);

    if (!user || isLoading) {
        return (
            <Pane
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Spinner />
                <Text marginTop="1rem">Loading...</Text>
            </Pane>
        );
    }

    return (
        <UserAccessProvider key={pathname}>
            <SearchParamsProvider>
                <Pane
                    display="flex"
                    width="100%"
                    minHeight="100vh"
                    height="100%"
                >
                    <DashboardNavbar />
                    <DashboardSideNavbar />
                    <Outlet />
                </Pane>
            </SearchParamsProvider>
        </UserAccessProvider>
    );
};

export default DashboardLayout;
