import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    complianceTemplate: [],
};

const complianceSlice = createSlice({
    name: 'compliance',
    initialState,
    reducers: {
        setComplianceTemplateAction(state, { payload }) {
            state.complianceTemplate = payload;
        },
    },
});

export const { setComplianceTemplateAction } = complianceSlice.actions;

export const complianceTemplateSelector = (reducers) =>
    reducers.complianceTemplateReducer;

export default complianceSlice.reducer;
