import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { assetModel } from '../../models';

const initialState = {
    search: '',
    isLoading: false,
    assets: [],
    parks: [],
    buildings: [],
    tenants: [],
    isLoadingCurrentAsset: false,
    currentAsset: null,
    currentBuilding: null,
    currentTenant: null,
    portfolios: [],
};

const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        setSearchAction(state, { payload }) {
            state.search = payload;
        },
        setIsLoadingAction(state, action) {
            state.isLoading = action.payload;
        },
        setAssetsAction(state, { payload }) {
            state.assets = payload.map((asset) => assetModel(asset));
        },
        setParksAction(state, { payload }) {
            state.parks = payload
                .filter(({ type }) => type === 'park')
                .map((asset) => assetModel(asset));
        },
        setBuildingsAction(state, { payload }) {
            state.buildings = payload
                .filter(({ type }) => type === 'building')
                .map((asset) => assetModel(asset));
        },
        setTenantsAction(state, { payload }) {
            state.tenants = payload
                .filter(({ type }) => type === 'tenant')
                .map((asset) => assetModel(asset));
        },
        setPortfoliosAction(state, { payload }) {
            state.portfolios = Array.from(
                new Set(payload.map(({ use }) => use))
            );
        },
        setIsLoadingCurrentAssetAction(state, action) {
            state.isLoadingCurrentAsset = action.payload;
        },
        setCurrentAssetAction(state, action) {
            state.currentAsset = action.payload;
        },
        setCurrentBuildingAction(state, action) {
            state.currentBuilding = action.payload;
        },
        setCurrentTenantAction(state, action) {
            state.currentTenant = action.payload;
        },
        setIsLoadingFullAssetDetails(state, action) {
            const assetIndex = _.findIndex(
                state.assets,
                (asset) => String(asset.id) === String(action.payload.id)
            );
            state.assets[assetIndex].isLoadingDetails =
                action.payload.isLoading;
        },
        setAssetFullDetails(state, action) {
            const assetIndex = _.findIndex(
                state.assets,
                (asset) => String(asset.id) === String(action.payload.id)
            );
            state.assets[assetIndex] = action.payload;
        },
        setAssetComplianceProgressAction(state, action) {
            const assetIndex = _.findIndex(
                state.assets,
                (asset) => String(asset.id) === String(action.payload.id)
            );

            state.assets[assetIndex] = {
                ...state.assets[assetIndex],
                progress: {
                    completed: action.payload.completed,
                    uploadsRequested: action.payload.uploadsRequested,
                    notStarted: action.payload.notStarted,
                    total: action.payload.total,
                },
            };
        },
        updateAssetComplianceType(state, action) {
            const { currentAsset, currentBuilding, currentTenant } = state;
            const asset = currentTenant || currentBuilding || currentAsset;

            const complianceGroup = _.find(
                asset.complianceGroups,
                (_complianceGroup) =>
                    String(_complianceGroup.id) ===
                    String(action.payload.complianceGroupId)
            );
            const complianceCategory = _.find(
                complianceGroup.complianceCategories,
                (_complianceCategory) =>
                    String(_complianceCategory.id) ===
                    String(action.payload.complianceCategoryId)
            );
            const complianceTypeIndex = _.findIndex(
                complianceCategory.complianceTypes,
                (_complianceType) =>
                    String(_complianceType.id) ===
                    String(action.payload.complianceTypeId)
            );

            complianceCategory.complianceTypes[complianceTypeIndex] =
                action.payload.updatedComplianceType;
        },
        removeAssetComplianceType(state, action) {
            const { currentAsset, currentBuilding, currentTenant } = state;
            const asset = currentTenant || currentBuilding || currentAsset;

            const complianceGroup = _.find(
                asset.complianceGroups,
                (_complianceGroup) =>
                    String(_complianceGroup.id) ===
                    String(action.payload.complianceGroupId)
            );
            const complianceCategory = _.find(
                complianceGroup.complianceCategories,
                (_complianceCategory) =>
                    String(_complianceCategory.id) ===
                    String(action.payload.complianceCategoryId)
            );
            _.remove(
                complianceCategory.complianceTypes,
                (_complianceType) =>
                    String(_complianceType.id) ===
                    String(action.payload.complianceTypeId)
            );
        },
        addAssetComplianceType(state, action) {
            const { currentAsset, currentBuilding, currentTenant } = state;
            const asset = currentTenant || currentBuilding || currentAsset;

            const complianceGroup = _.find(
                asset.complianceGroups,
                (_complianceGroup) =>
                    String(_complianceGroup.id) ===
                    String(action.payload.complianceGroupId)
            );
            const complianceCategory = _.find(
                complianceGroup.complianceCategories,
                (_complianceCategory) =>
                    String(_complianceCategory.id) ===
                    String(action.payload.complianceCategoryId)
            );
            complianceCategory.complianceTypes.push(
                action.payload.complianceType
            );
        },
    },
});

export const {
    setIsLoadingAction,
    setAssetsAction,
    setIsLoadingCurrentAssetAction,
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
    setIsLoadingFullAssetDetails,
    setAssetFullDetails,
    setAssetComplianceProgressAction,
    updateAssetComplianceType,
    removeAssetComplianceType,
    addAssetComplianceType,
    setSearchAction,
    setBuildingsAction,
    setParksAction,
    setTenantsAction,
    setPortfoliosAction,
} = assetsSlice.actions;

export const assetSelector = (reducers) => reducers.assetReducer;

export default assetsSlice.reducer;
