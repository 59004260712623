import React, { useMemo } from 'react';
import { Button, Heading, Pane, SideSheet } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

import {
    assetSelector,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from '../../../reducers/asset-reducer/asset.reducer';
import SpacesItem from './spaces-item';

const SpacesListSidebar = ({
    isShown,
    setIsShown,
    showCreateTenantSidebar,
    shouldFetchComplianceProgress,
    spaces,
    rights,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { create } = rights || {
        create: false,
    };
    const { assetId, buildingId } = useParams();

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const asset = useMemo(() => {
        if (currentTenant) {
            return currentTenant;
        }
        if (currentBuilding) {
            return currentBuilding;
        }

        return currentAsset;
    }, [currentAsset, currentBuilding, currentTenant]);

    const closeSidebar = () => {
        setIsShown(false);
    };

    const showAddTenantSidebar = () => {
        closeSidebar();
        showCreateTenantSidebar();
    };

    const onSpaceSelect = (_asset) => {
        if (_asset.type === 'building') {
            dispatch(setCurrentBuildingAction(_asset));
            navigate(
                `/portfolio/properties/parks/${assetId}/buildings/${_asset.id}/info`
            );
        } else if (_asset.type === 'tenant') {
            dispatch(setCurrentTenantAction(_asset));
            navigate(
                `/portfolio/properties/parks/${assetId}/buildings/${buildingId}/tenants/${_asset.id}/info`
            );
        }
        closeSidebar();
    };

    return (
        <SideSheet
            isShown={isShown}
            onCloseComplete={closeSidebar}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                backgroundColor: '#FAFBFF',
            }}
            shouldCloseOnEscapePress={false}
            width={400}
        >
            <Pane
                zIndex={1}
                flexShrink={0}
                elevation={0}
                backgroundColor="white"
            >
                <Pane padding={16}>
                    <Heading size={600}>
                        {asset.assetName} - {_.size(spaces)}{' '}
                        {asset.type === 'park' ? 'Building' : 'Tenant'}
                        {_.size(spaces) <= 1 ? '' : 's'}
                    </Heading>
                </Pane>
            </Pane>
            <Pane
                display="flex"
                justifyContent="space-between"
                padding={16}
                alignItems="center"
            >
                <Heading>
                    Select a {asset?.type === 'park' ? 'Building' : 'Tenant'}
                </Heading>
                {create && (
                    <Button
                        width={100}
                        appearance="primary"
                        onClick={showAddTenantSidebar}
                    >
                        + Add {asset?.type === 'park' ? 'Building' : 'Tenant'}
                    </Button>
                )}
            </Pane>
            <Pane>
                {spaces.map((_asset) => (
                    <SpacesItem
                        key={_asset.id}
                        asset={_asset}
                        onSpaceSelect={onSpaceSelect}
                        shouldFetchComplianceProgress={
                            shouldFetchComplianceProgress
                        }
                    />
                ))}
            </Pane>
        </SideSheet>
    );
};

SpacesListSidebar.propTypes = {
    isShown: PropTypes.bool.isRequired,
    shouldFetchComplianceProgress: PropTypes.bool,
    selectedAsset: PropTypes.shape({}),
    spaces: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setIsShown: PropTypes.func.isRequired,
    showCreateTenantSidebar: PropTypes.func.isRequired,
    rights: PropTypes.shape({
        create: PropTypes.bool,
        read: PropTypes.bool,
        update: PropTypes.bool,
        delete: PropTypes.bool,
    }).isRequired,
};

SpacesListSidebar.defaultProps = {
    selectedAsset: null,
    shouldFetchComplianceProgress: false,
};

export default SpacesListSidebar;
