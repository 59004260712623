import AI from './AI.svg';
import AVI from './AVI.svg';
import BMP from './BMP.svg';
import CRD from './CRD.svg';
import CSV from './CSV.svg';
import DOC from './DOC.svg';
import DOCX from './DOCX.svg';
import DWG from './DWG.svg';
import EPS from './EPS.svg';
import EXE from './EXE.svg';
import FLV from './FLV.svg';
import GIF from './GIF.svg';
import HTML from './HTML.svg';
import ISO from './ISO.svg';
import JAVA from './JAVA.svg';
import JPG from './JPG.svg';
import MDB from './MDB.svg';
import MID from './MID.svg';
import MOV from './MOV.svg';
import MP3 from './MP3.svg';
import MP4 from './MP4.svg';
import MPEG from './MPEG.svg';
import PDF from './PDF.svg';
import PNG from './PNG.svg';
import PPT from './PPT.svg';
import PPTX from './PPTX.svg';
import PS from './PS.svg';
import PSD from './PSD.svg';
import PUB from './PUB.svg';
import RAR from './RAR.svg';
import RAW from './RAW.svg';
import RSS from './RSS.svg';
import SVG from './SVG.svg';
import TIFF from './TIFF.svg';
import TXT from './TXT.svg';
import WAV from './WAV.svg';
import WMA from './WMA.svg';
import XML from './XML.svg';
import XLS from './XLS.svg';
import XLSX from './XLSX.svg';
import ZIP from './ZIP.svg';
import EML from './EML.svg';
import MSG from './MSG.svg';
import UNK from './UNK.svg';
import BATCH from './BATCH.svg';

export default {
    AI,
    AVI,
    BMP,
    CRD,
    CSV,
    DOC,
    DOCX,
    DWG,
    EPS,
    EXE,
    FLV,
    GIF,
    HTML,
    ISO,
    JAVA,
    JPG,
    JPEG: JPG,
    MDB,
    MID,
    MOV,
    MP3,
    MP4,
    MPEG,
    PDF,
    PNG,
    PPT,
    PPTX,
    PS,
    PSD,
    PUB,
    RAR,
    RAW,
    RSS,
    SVG,
    TIFF,
    TXT,
    WAV,
    WMA,
    XML,
    XLS,
    XLSX,
    ZIP,
    UNK,
    BATCH,
    EML,
    MSG,
};
