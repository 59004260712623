import React, { useMemo, useCallback, useEffect } from 'react';
import { Heading, Pane, Text } from 'evergreen-ui';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';
import { colors } from '../../../theme/theme';
import BuildingImageGallery from '../building-image-gallary/building-image-gallery';
import {
    getAsset,
    getBuilding,
    getTenant,
} from '../../../reducers/asset-reducer/asset.actions';

const containerStyle = {
    width: '100%',
    height: '100%',
};

const center = {
    lat: -25.85396,
    lng: 28.157665,
};

const AssetOverview = () => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const dispatch = useDispatch();

    const { assetId, buildingId, tenantId } = useParams();

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const { images, address } = useMemo(
        () =>
            currentTenant ||
            currentBuilding ||
            currentAsset || {
                images: [],
                address: {
                    addressLine1: '',
                    suburb: '',
                    city: '',
                    lat: 0,
                    long: 0,
                },
            },
        [currentAsset, currentBuilding, currentTenant]
    );

    const getMapCenter = () => {
        if (address?.lat && address?.long) {
            return {
                lat: address?.lat,
                lng: address?.long,
            };
        }
        return center;
    };

    const getAssets = useCallback(() => {
        if (!currentTenant && tenantId) {
            dispatch(getTenant(tenantId));
        }
        if (!currentBuilding && buildingId) {
            dispatch(getBuilding(buildingId));
        }
        if (!currentAsset && assetId) {
            dispatch(getAsset(assetId, false, true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, buildingId, assetId, dispatch]);

    useEffect(() => {
        getAssets();
    }, [getAssets]);

    return (
        <Pane
            display="flex"
            flex={1}
            flexDirection="column"
            paddingBottom={32}
            paddingTop={10}
            height="calc(100vh - 300px)"
            width="100%"
            gap={24}
            marginTop={-24}
        >
            <Pane
                display="flex"
                flexDirection="column"
                padding={30}
                backgroundColor={colors.white}
                gap={20}
                borderRadius={4}
            >
                <Pane>
                    <Heading
                        fontSize={20}
                        fontWeight={700}
                        marginBottom={16}
                        color="#333333"
                    >
                        Property Location
                    </Heading>
                    <Text fontSize={16} color="#333333">
                        {address?.addressLine1}, {address?.suburb},{' '}
                        {address?.city}
                    </Text>
                </Pane>
                {isLoaded ? (
                    <Pane display="flex" alignItems="stretch" height={350}>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={getMapCenter()}
                            zoom={15}
                        >
                            <Marker
                                position={{
                                    lat: address?.lat,
                                    lng: address?.long,
                                }}
                            />
                        </GoogleMap>
                    </Pane>
                ) : (
                    <Pane display="flex" height={350} width="100%" />
                )}
            </Pane>
            <Pane
                padding={20}
                paddingLeft={20}
                backgroundColor={colors.white}
                borderRadius={4}
                height={486}
            >
                <Heading
                    fontSize={20}
                    fontWeight={700}
                    marginBottom={16}
                    color="#333333"
                >
                    Image Gallery
                </Heading>
                <BuildingImageGallery images={images} />
            </Pane>
        </Pane>
    );
};

export default AssetOverview;
