import React, { useState } from 'react';
import { Button, ErrorIcon, Pane, Paragraph, TextInput } from 'evergreen-ui';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { AddressAutoCompleteField } from '../../molecules';

const AssetAddressForm = ({
    initialValues,
    handleFormSubmission,
    setCurrentStep,
    currentStep,
}) => {
    const validationSchema = Yup.object().shape({
        addressLine1: Yup.string().required('Street address is required'),
        addressLine2: Yup.string(),
        suburb: Yup.string(),
        city: Yup.string().required('City is required'),
        province: Yup.string().required('Province is required'),
        postalCode: Yup.string().required('Postal code is required'),
        country: Yup.string().required('Country is required'),
        lat: Yup.string(),
        long: Yup.string(),
    });

    const [loading, setLoading] = useState(false);

    const goBack = () => {
        setCurrentStep(currentStep - 1);
    };

    const onSubmit = async (values, { setFieldError }) => {
        setLoading(true);
        try {
            await handleFormSubmission(values);
        } catch (error) {
            const errorMessage = _.get(
                error,
                'response.data.message',
                error.message
            );
            setFieldError('searchAddress', errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Pane>
                        <AddressAutoCompleteField
                            label="Building Address"
                            type="text"
                            name="searchAddress"
                            placeholder="Search Address"
                            width="100%"
                            inputHeight={40}
                            required
                        />
                    </Pane>
                    <Pane marginTop="32px">
                        <TextInput
                            type="text"
                            name="addressLine2"
                            placeholder="Unit Number and Building Name"
                            value={values.addressLine2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                touched.addressLine2 && errors.addressLine2
                            }
                            validationMessage={
                                touched.addressLine2 && errors.addressLine2
                            }
                            spellCheck={false}
                            width="100%"
                            height={40}
                        />
                    </Pane>
                    <Pane marginTop={12}>
                        <TextInput
                            type="text"
                            name="addressLine1"
                            placeholder="Street Address"
                            value={values.addressLine1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                touched.addressLine1 && errors.addressLine1
                            }
                            validationMessage={
                                touched.addressLine1 && errors.addressLine1
                            }
                            spellCheck={false}
                            width="100%"
                            height={40}
                        />
                    </Pane>
                    <Pane marginTop={12}>
                        <TextInput
                            type="text"
                            name="suburb"
                            placeholder="Suburb"
                            value={values.suburb}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.suburb && errors.suburb}
                            validationMessage={touched.suburb && errors.suburb}
                            spellCheck={false}
                            width="100%"
                            height={40}
                        />
                    </Pane>
                    <Pane marginTop={12} display="flex">
                        <Pane flex={1} marginRight={6}>
                            <TextInput
                                type="text"
                                name="city"
                                placeholder="City"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.city && errors.city}
                                validationMessage={touched.city && errors.city}
                                spellCheck={false}
                                width="100%"
                                height={40}
                            />
                        </Pane>
                        <Pane flex={1} marginLeft={6}>
                            <TextInput
                                type="text"
                                name="province"
                                placeholder="Province"
                                value={values.province}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.province && errors.province}
                                validationMessage={
                                    touched.province && errors.province
                                }
                                spellCheck={false}
                                width="100%"
                                height={40}
                            />
                        </Pane>
                    </Pane>
                    <Pane marginTop={12} display="flex">
                        <Pane flex={1} marginRight={6}>
                            <TextInput
                                type="text"
                                name="postalCode"
                                placeholder="Postal Code"
                                value={values.postalCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    touched.postalCode && errors.postalCode
                                }
                                validationMessage={
                                    touched.postalCode && errors.postalCode
                                }
                                spellCheck={false}
                                width="100%"
                                height={40}
                            />
                        </Pane>
                        <Pane flex={1} marginLeft={6}>
                            <TextInput
                                type="text"
                                name="country"
                                placeholder="Country"
                                value={values.country}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.country && errors.country}
                                validationMessage={
                                    touched.country && errors.country
                                }
                                spellCheck={false}
                                width="100%"
                                height={40}
                            />
                        </Pane>
                    </Pane>
                    <TextInput
                        type="text"
                        name="lat"
                        value={values.lat}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        display="none"
                    />
                    <TextInput
                        type="text"
                        name="long"
                        value={values.long}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        display="none"
                    />
                    {!!errors.searchAddress && (
                        <Pane display="flex" marginTop={12} alignItems="center">
                            <ErrorIcon color="#D14343" size={14} />
                            <Paragraph color="#D14343" marginLeft={6}>
                                {errors.searchAddress}
                            </Paragraph>
                        </Pane>
                    )}
                    <Pane display="flex" flexDirection="column">
                        <Button
                            appearance="primary"
                            height={40}
                            marginTop="32px"
                            type="submit"
                            isLoading={loading}
                            disabled={loading}
                        >
                            Submit
                        </Button>
                        <Pane marginTop={12} display="flex">
                            <Button
                                appearance="minimal"
                                height={40}
                                type="button"
                                display="flex"
                                onClick={goBack}
                                flex={1}
                                disabled={loading}
                            >
                                Back
                            </Button>
                        </Pane>
                    </Pane>
                </Form>
            )}
        </Formik>
    );
};

AssetAddressForm.propTypes = {
    initialValues: PropTypes.shape({}).isRequired,
    currentStep: PropTypes.number.isRequired,

    handleFormSubmission: PropTypes.func.isRequired,
    setCurrentStep: PropTypes.func.isRequired,
};

export default AssetAddressForm;
