import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';

const Collapsible = ({ isOpen, children }) => {
    const [height, setHeight] = useState(0);

    const ref = useRef(null);

    const resize = useCallback(() => {
        if (isOpen) {
            setHeight(ref.current?.scrollHeight);
            setTimeout(resize, 100);
        } else {
            setHeight(0);
        }
    }, [isOpen]);

    useEffect(() => {
        resize();
    }, [isOpen, resize]);

    return (
        <Pane
            ref={ref}
            height="auto"
            maxHeight={isOpen ? height : 0}
            overflow="hidden"
            transition="max-height 0.3s ease"
            boxSizing="border-box"
        >
            {children}
        </Pane>
    );
};

Collapsible.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

export default Collapsible;
