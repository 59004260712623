import React, { useEffect, useMemo, useState } from 'react';
import {
    Heading,
    Pane,
    SideSheet,
    Text,
    IssueIcon,
    Avatar,
    Tooltip,
    InfoSignIcon,
    Spinner,
    EditIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import getDate from '../../helpers/get-date';
import { colors } from '../../theme/theme';
import { getMaintenanceItemModel } from '../../models/maintenance.models';
import { documentService } from '../../services';
import { getUpdateDocumentModel } from '../../models';
import EditDocumentModal from './edit-document-modal';
import { taskSelector } from '../../reducers/task-reducer/task.reducer';
import { getAvailableAssignees } from '../../reducers/task-reducer/task.actions';
import ConfirmDeleteModal from './confirm-delete-modal';
import { useUserAccess } from '../../context/user-access-context';
import { deleteMaintenanceDocument } from '../../reducers/maintenance-reducer/maintenance-actions';

const NOTIFICATIONS = {
    overdue: { color: '#B3261E', backgroundColor: '#B3261E50' },
    warning: { color: '#FFA500', backgroundColor: '#FFA50050' },
    urgent: { color: '#FFC300', backgroundColor: '#FFC30030' },
    notUrgent: { color: '#50C878', backgroundColor: '#50C87850' },
};

const AssetInfoSideBar = ({ isShown, close, asset }) => {
    const [downloading, setDownloading] = useState(null);
    const [editDocument, setEditDocument] = useState({
        isShown: false,
        document: null,
    });
    const [deleteDocument, setDeleteDocument] = useState({
        isShown: false,
        document: null,
    });

    const { assignees } = useSelector(taskSelector);

    const { allRights } = useUserAccess();

    const rights = allRights?.maintenance;

    const dispatch = useDispatch();

    const openEditDocument = (e, document) => {
        e.stopPropagation();
        setEditDocument({
            isShown: true,
            document: { ...getUpdateDocumentModel(document) },
        });
    };

    const closeEditDocument = () => {
        setEditDocument({
            isShown: false,
            document: null,
        });
    };

    // const openDeleteDocument = (e, document) => {
    //     e.stopPropagation();
    //     setDeleteDocument({
    //         isShown: true,
    //         document,
    //     });
    // };

    const closeDeleteDocument = () => {
        setDeleteDocument({
            isShown: false,
            document: null,
        });
    };

    const handleDeleteDocument = async () => {
        const { buildingId: assetId, id: maintenanceItemId } =
            getMaintenanceItemModel(asset);
        await dispatch(
            deleteMaintenanceDocument({
                assetId,
                documentId: deleteDocument.document?.id,
                requestType: 'maintenance',
                maintenanceItemId,
            })
        );
        closeDeleteDocument();
    };

    const {
        notifications = [],
        category,
        type,
        description,
        warrantyExpire,
        liabilityClause,
        serviceProvider,
        assignedUserIds,
    } = getMaintenanceItemModel(asset);

    const assignedUsers = useMemo(() => {
        let users = [];
        assignees.forEach(({ user_id: userId, name }) => {
            if (assignedUserIds.includes(userId)) {
                users = [...users, { id: userId, name }];
            }
        });
        return users;
    }, [assignedUserIds, assignees]);

    const calculateWarrantyPeriod = (timestamp) => {
        const startDate = new Date();
        const endDate = new Date(timestamp);
        const monthDiff = endDate.getMonth() - startDate.getMonth();
        const yearDiff = endDate.getYear() - startDate.getYear();

        return `${monthDiff + yearDiff * 12} Months (${getDate(timestamp)})`;
    };

    const download = async (link) => {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await fetch(link.href, {
                method: 'GET',
                headers: {},
            });
            const buffer = await response.arrayBuffer();

            const url = window.URL.createObjectURL(new Blob([buffer]));
            link.href = url;
            link.setAttribute('download', link.title);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            throw error;
        }
    };

    const downloadDocument = async (file) => {
        setDownloading(file.id);
        try {
            const fileLink = Object.values(
                await documentService.getFilesDownloadLink([
                    {
                        assetId: asset.bucketId,
                        fileId: file.file_id,
                        fileKey: file.file_key,
                    },
                ])
            )[0];

            const link = document.createElement('a');
            link.href = fileLink;
            link.setAttribute('download', file.friendly_name);
            link.setAttribute('title', file.friendly_name);
            link.setAttribute('target', '_blank');
            await download(link);
            link.parentNode.removeChild(link);
        } finally {
            setDownloading(null);
        }
    };

    const previousReports = useMemo(() => asset?.documents || [], [asset]);

    useEffect(() => {
        dispatch(getAvailableAssignees());
    }, [dispatch]);

    return (
        <>
            <SideSheet
                isShown={isShown}
                onCloseComplete={close}
                containerProps={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column',
                }}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEscapePress={false}
                width={450}
            >
                <Pane>
                    <Heading
                        width="max-content"
                        fontSize={22}
                        fontWeight="700"
                        color={colors.primary500}
                        marginLeft={24}
                        marginTop={24}
                    >
                        [{category.abbreviation}] {category.name}
                        <br /> <Text>{type.name}</Text>
                    </Heading>
                    <div className="divider mb-0" />
                    <Pane
                        paddingTop={15}
                        paddingX={24}
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        gap={30}
                    >
                        <Pane display="flex" flexDirection="column" gap={4}>
                            <SectionHeading>Notifications</SectionHeading>
                            <Pane
                                display="flex"
                                flexDirection="column"
                                gap={5}
                                maxHeight={180}
                                overflowY="auto"
                            >
                                {notifications.length ? (
                                    notifications.map((notification) => (
                                        <Pane
                                            key={notification.description}
                                            backgroundColor={
                                                NOTIFICATIONS[
                                                    notification.priority
                                                ].backgroundColor
                                            }
                                            height={35}
                                            elevation={1}
                                            color={
                                                NOTIFICATIONS[
                                                    notification.priority
                                                ].color
                                            }
                                            display="flex"
                                            alignItems="center"
                                            padding={10}
                                            gap={10}
                                            borderRadius={4}
                                        >
                                            <IssueIcon
                                                color={
                                                    NOTIFICATIONS[
                                                        notification.priority
                                                    ].color
                                                }
                                            />
                                            <Text
                                                color={
                                                    NOTIFICATIONS[
                                                        notification.priority
                                                    ].color
                                                }
                                                opacity="initial"
                                                fontWeight={600}
                                            >
                                                {notification.description}
                                            </Text>
                                        </Pane>
                                    ))
                                ) : (
                                    <Text>No Notifications.</Text>
                                )}
                            </Pane>
                        </Pane>
                        <Pane display="flex" flexDirection="column" gap={4}>
                            <SectionHeading>Specifications</SectionHeading>
                            <Text>{description}</Text>
                        </Pane>
                        <Pane display="flex" flexDirection="column" gap={4}>
                            <SectionHeading>Warranty Period</SectionHeading>
                            <Text>
                                {calculateWarrantyPeriod(warrantyExpire)}
                            </Text>
                        </Pane>
                        <Pane display="flex" flexDirection="column" gap={4}>
                            <SectionHeading>Liability Clause</SectionHeading>
                            <Text>{liabilityClause}</Text>
                        </Pane>
                        <Pane display="flex" flexDirection="column" gap={4}>
                            <SectionHeading>Service Provider</SectionHeading>
                            <Pane display="flex">
                                <Text width={200}>Company Name:</Text>
                                <Text>
                                    {serviceProvider?.companyName || 'N/A'}
                                </Text>
                            </Pane>
                            <Pane display="flex">
                                <Text width={200}>
                                    {`${
                                        serviceProvider?.profession ||
                                        'Provider'
                                    }:`}
                                </Text>
                                <Text>
                                    {`${serviceProvider?.name} ${serviceProvider?.surname}` ||
                                        'N/A'}
                                </Text>
                            </Pane>
                            <Pane display="flex">
                                <Text width={200}>Contact Number:</Text>
                                <Text>{serviceProvider?.number || 'N/A'}</Text>
                            </Pane>
                            <Pane display="flex">
                                <Text width={200}>Email Address:</Text>
                                <Text>{serviceProvider?.email || 'N/A'}</Text>
                            </Pane>
                        </Pane>
                        <Pane display="flex" flexDirection="column" gap={4}>
                            <SectionHeading>Assigned Users</SectionHeading>
                            {assignedUsers.length ? (
                                assignedUsers.map(({ id, name }) => (
                                    <Text key={id}>{name}</Text>
                                ))
                            ) : (
                                <Text>No Assigned Users.</Text>
                            )}
                        </Pane>
                        <Pane display="flex" flexDirection="column" gap={4}>
                            <Pane display="flex" alignItems="center" gap={10}>
                                <SectionHeading>
                                    Previous Reports
                                </SectionHeading>
                                <Tooltip
                                    content="Click Report To Download"
                                    position="top"
                                >
                                    <InfoSignIcon
                                        color={`${colors.neutral700}`}
                                    />
                                </Tooltip>
                            </Pane>
                            {previousReports.length ? (
                                <Pane
                                    display="flex"
                                    flexDirection="column"
                                    flex={1}
                                    border={`1px solid ${colors.neutral500}`}
                                    borderRadius={4}
                                    maxHeight={295}
                                    overflowY="auto"
                                >
                                    {previousReports.map((document, index) => {
                                        const notLast =
                                            index !==
                                            previousReports.length - 1;
                                        return (
                                            <ReportCard
                                                download
                                                key={document.file_id}
                                                display="flex"
                                                alignItems="center"
                                                gap={10}
                                                borderBottom={`${
                                                    notLast
                                                        ? `1px solid ${colors.neutral500}`
                                                        : 'none'
                                                }`}
                                                padding={8}
                                                role="button"
                                                onClick={() =>
                                                    downloadDocument(document)
                                                }
                                            >
                                                {downloading ===
                                                document?.id ? (
                                                    <Spinner marginX="auto" />
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            name={`${document?.user?.name} ${document?.user?.surname}`}
                                                            size={36}
                                                        />
                                                        <Pane
                                                            display="flex"
                                                            flexDirection="column"
                                                        >
                                                            <Text
                                                                fontWeight={600}
                                                            >
                                                                {
                                                                    document?.friendly_name
                                                                }
                                                            </Text>
                                                            <Text fontSize={12}>
                                                                {getDate(
                                                                    document?.created_at
                                                                )}
                                                            </Text>
                                                        </Pane>
                                                        <Pane
                                                            display="flex"
                                                            gap={6}
                                                            alignItems="center"
                                                            className="options"
                                                        >
                                                            <Pane
                                                                role="button"
                                                                className="edit-button"
                                                                hoverElevation={
                                                                    1
                                                                }
                                                                onClick={(e) =>
                                                                    openEditDocument(
                                                                        e,
                                                                        document
                                                                    )
                                                                }
                                                            >
                                                                <EditIcon color="#696f8c" />
                                                            </Pane>
                                                        </Pane>
                                                    </>
                                                )}
                                            </ReportCard>
                                        );
                                    })}
                                </Pane>
                            ) : (
                                <Text>No Previous Reports.</Text>
                            )}
                        </Pane>
                    </Pane>
                </Pane>
            </SideSheet>
            {rights?.update && (
                <EditDocumentModal
                    isShown={editDocument.isShown}
                    document={editDocument.document}
                    close={closeEditDocument}
                    isMaintenance
                />
            )}
            {rights?.delete && (
                <ConfirmDeleteModal
                    isShown={deleteDocument.isShown}
                    title="Delete Document"
                    close={closeDeleteDocument}
                    onConfirm={handleDeleteDocument}
                    itemName={deleteDocument.document?.friendly_name}
                    shouldDispatch={false}
                />
            )}
        </>
    );
};

AssetInfoSideBar.propTypes = {
    isShown: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    asset: PropTypes.shape({
        assetName: PropTypes.string,
        bucketId: PropTypes.string,
        notifications: PropTypes.arrayOf(PropTypes.shape({})),
        specifications: PropTypes.string,
        warrantyPeriod: PropTypes.string,
        liabilityClause: PropTypes.string,
        serviceProvider: PropTypes.shape({
            company: PropTypes.string,
            technician: PropTypes.string,
            contact: PropTypes.string,
            email: PropTypes.string,
        }),
        documents: PropTypes.arrayOf(PropTypes.shape({})),
        previousReports: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

AssetInfoSideBar.defaultProps = {
    asset: null,
};

const SectionHeading = styled(Text)`
    font-weight: 700;
`;

const ReportCard = styled(Pane)`
    cursor: pointer;
    .edit-button {
        display: none;
        padding: 10px;
        border-radius: 4px;
        background-color: white;
    }
    .options {
        margin-left: auto;
    }
    &:hover {
        background-color: ${colors.neutral300};
        .edit-button {
            display: block;
        }
    }
`;

export default AssetInfoSideBar;
