import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import useQueryParams from '../../hooks/use-query-params';
import { uploadRequestSelector } from '../../reducers/upload-request-reducer/upload-request-reducer';

const SelectedUploadRequest = createContext({});

const SelectedUploadRequestProvider = ({ children }) => {
    const { uploadRequestId } = useQueryParams();
    const { uploadRequests } = useSelector(uploadRequestSelector);

    const [selectedUploadRequest, setSelectedUploadRequest] = useState({
        id: '',
    });

    useEffect(() => {
        const uploadRequest = _.find(
            uploadRequests,
            (uploadRequestItem) =>
                _.toString(uploadRequestId) === _.toString(uploadRequestItem.id)
        );

        if (!uploadRequest) {
            setSelectedUploadRequest(_.first(uploadRequests));
        } else {
            setSelectedUploadRequest(uploadRequest);
        }
    }, [uploadRequestId, uploadRequests]);

    const value = useMemo(
        () => ({ selectedUploadRequest }),
        [selectedUploadRequest]
    );

    return (
        <SelectedUploadRequest.Provider value={value}>
            {children}
        </SelectedUploadRequest.Provider>
    );
};

SelectedUploadRequestProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { SelectedUploadRequest, SelectedUploadRequestProvider };
