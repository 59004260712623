import React, { useCallback, useEffect, useState } from 'react';
import { Pane, Paragraph, Popover } from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ComplianceCategory from './compliance-category';
import { ContextMenu, ContextMenuItem } from '../../molecules';
import { colors } from '../../../theme/theme';
import ComplianceDialog from './compliance-dialog';
import { complianceService } from '../../../services';

const ComplianceGroup = ({ group, rights, isFirst }) => {
    const [contextMenuShown, setContextMenuShown] = useState(false);
    const [complianceDialog, setComplianceDialog] = useState({
        isShown: false,
        isDeleting: false,
        isEditing: false,
        isGroup: true,
        complianceItem: {},
    });
    const [initialContextMenuShown, setInitialContextMenuShown] =
        useState(false);

    const handleBodyContextClick = useCallback(() => {
        if (initialContextMenuShown) {
            setInitialContextMenuShown(false);
            setContextMenuShown(false);
        } else {
            setInitialContextMenuShown(true);
        }
    }, [
        initialContextMenuShown,
        setInitialContextMenuShown,
        setContextMenuShown,
    ]);

    useEffect(() => {
        if (contextMenuShown) {
            document.addEventListener('contextmenu', handleBodyContextClick);
        } else {
            document.removeEventListener('contextmenu', handleBodyContextClick);
        }

        return () => {
            document.removeEventListener('contextmenu', handleBodyContextClick);
        };
    }, [contextMenuShown, handleBodyContextClick]);

    const showContextMenu = (event) => {
        event.preventDefault();
        if (!(rights?.create || rights?.update || rights?.delete)) {
            return;
        }
        setContextMenuShown(true);
    };

    const hideContextMenu = () => {
        setContextMenuShown(false);
    };

    const openAddComplianceCategoryDialog = () => {
        setComplianceDialog({
            isShown: true,
            isDeleting: false,
            isEditing: false,
            isGroup: false,
            complianceItem: {},
        });
    };

    const openRenameComplianceGroupDialog = () => {
        setComplianceDialog({
            isShown: true,
            isDeleting: false,
            isEditing: true,
            isGroup: true,
            complianceItem: group,
        });
    };

    const openDeleteComplianceGroupDialog = () => {
        setComplianceDialog({
            isShown: true,
            isDeleting: true,
            isEditing: false,
            isGroup: true,
            complianceItem: group,
        });
    };

    const closeComplianceDialog = () => {
        setComplianceDialog({
            isShown: false,
            isDeleting: false,
            isEditing: false,
            isGroup: true,
            complianceItem: null,
        });
    };

    const handleConfirm = useCallback(
        async (_complianceItem) => {
            if (complianceDialog.isDeleting) {
                return complianceService.deleteComplianceGroup(
                    _complianceItem.id
                );
            }
            if (complianceDialog.isEditing) {
                return complianceService.renameComplianceGroup(_complianceItem);
            }
            return complianceService.createComplianceCategory({
                ..._complianceItem,
                complianceGroupId: group.id,
            });
        },
        [complianceDialog.isDeleting, complianceDialog.isEditing, group]
    );

    return (
        <>
            <Pane>
                <Popover
                    isShown={contextMenuShown}
                    shouldCloseOnExternalClick
                    onClose={hideContextMenu}
                    content={
                        (rights?.create ||
                            rights?.update ||
                            rights?.delete) && (
                            <ContextMenu
                                id={`group-${group.id}`}
                                preventHideOnContextMenu
                            >
                                {rights?.update && (
                                    <ContextMenuItem
                                        onClick={
                                            openRenameComplianceGroupDialog
                                        }
                                    >
                                        <Paragraph>Edit Group Name</Paragraph>
                                    </ContextMenuItem>
                                )}
                                {rights?.create && (
                                    <ContextMenuItem
                                        onClick={
                                            openAddComplianceCategoryDialog
                                        }
                                    >
                                        <Paragraph>Add Category</Paragraph>
                                    </ContextMenuItem>
                                )}
                                {rights?.delete && (
                                    <ContextMenuItem
                                        onClick={
                                            openDeleteComplianceGroupDialog
                                        }
                                    >
                                        <Paragraph color={colors.red500}>
                                            Delete Group
                                        </Paragraph>
                                    </ContextMenuItem>
                                )}
                            </ContextMenu>
                        )
                    }
                >
                    <Pane onContextMenu={showContextMenu}>
                        <Paragraph
                            fontWeight="500"
                            fontSize={11}
                            color="#696F8C"
                            paddingTop={isFirst ? 18 : 24}
                            paddingLeft={22}
                            cursor="default"
                            paddingX={30}
                        >
                            {_.upperCase(group.name)}
                        </Paragraph>
                    </Pane>
                </Popover>
                {_.map(
                    group.complianceCategories,
                    (category, categoryIndex) => (
                        <ComplianceCategory
                            key={`${category.id}-${categoryIndex}`}
                            category={category}
                            groupId={group.id}
                            rights={rights}
                        />
                    )
                )}
            </Pane>
            {complianceDialog.isShown &&
                (rights.create || rights.update || rights.delete) && (
                    <ComplianceDialog
                        key={group.id}
                        complianceItem={complianceDialog.complianceItem}
                        isShown={complianceDialog.isShown}
                        isDeleting={complianceDialog.isDeleting}
                        onClose={closeComplianceDialog}
                        isGroup={complianceDialog.isGroup}
                        isCategory={!complianceDialog.isGroup}
                        isEditing={complianceDialog.isEditing}
                        onConfirm={handleConfirm}
                    />
                )}
        </>
    );
};

ComplianceGroup.propTypes = {
    group: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        complianceCategories: PropTypes.shape({}),
    }).isRequired,
    rights: PropTypes.shape({
        create: PropTypes.bool,
        read: PropTypes.bool,
        update: PropTypes.bool,
        delete: PropTypes.bool,
    }).isRequired,
    isFirst: PropTypes.bool.isRequired,
};

export default ComplianceGroup;
