import React from 'react';
import { SelectField } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';

function LinkSelectField({ propertyType, handleLinkedProperty, parentId }) {
    const { parks, buildings } = useSelector(assetSelector);

    const attributes = {
        label: '',
        placeHolder: '',
        required: false,
    };

    if (propertyType === 'tenant') {
        attributes.label = 'Select Building';
        attributes.required = true;
    } else if (propertyType === 'building') {
        attributes.label = 'Select Park';
        attributes.required = false;
    }

    return (
        <SelectField
            display={propertyType === 'park' ? 'none' : 'block'}
            width="100%"
            inputHeight={40}
            label={attributes.label}
            isInvalid={false}
            validationMessage={false}
            required={attributes.required}
            onChange={handleLinkedProperty}
            value={parentId}
        >
            <option disabled value="">
                {attributes.label}
            </option>
            {propertyType === 'building' &&
                parks.map(({ id, assetName }) => (
                    <option key={id} value={id}>
                        {assetName}
                    </option>
                ))}
            {propertyType === 'building' && <option value="">None</option>}
            {propertyType === 'tenant' &&
                buildings.map(({ id, assetName }) => (
                    <option key={id} value={id}>
                        {assetName}
                    </option>
                ))}
        </SelectField>
    );
}

LinkSelectField.propTypes = {
    propertyType: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
    handleLinkedProperty: PropTypes.func.isRequired,
};

export default LinkSelectField;
