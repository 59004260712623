import React, { useState } from 'react';
import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AssetBasicDetailsForm from './asset-basic-details-form';
import AssetsComlianceForm from './assets-comliance-form';
import AssetAddressForm from './asset-address-form';
import VerticalStepperStep from '../../molecules/vertical-stepper-step';
import VerticalStepper from '../../molecules/vertical-stepper';
import {
    assetAddressModel,
    assetBasicDetailsModel,
    assetPropertiesModel,
} from '../../../models';
import { portfolioSelector } from '../../../reducers/portfolio-reducer/portfolio.reducer';
import {
    createPortfolio,
    getAllPortfoliosForBusiness,
} from '../../../reducers/portfolio-reducer/portfolio.actions';
import UserManagementSidebar from '../../user-management/user-management-sidebar';
import PropertyGroupsDialog from '../../user-management/property-groups-dialog';
import UserRolesDialog from '../../user-management/user-roles-dialog';

import ComplianceModal from '../../molecules/compliance-modal';
import { errorToast } from '../../toasts';
import { userManagementSelector } from '../../../reducers/user-management-reducer/user-management.reducer';

const CreateAssetForm = ({ submitForm, onSuccess }) => {
    const dispatch = useDispatch();

    const { assetId, buildingId } = useParams();

    const [currentStep, setCurrentStep] = useState(0);
    const [showUserManagementSidebar, setShowUserManagementSidebar] =
        useState(false);
    const [showComplianceModal, setShowComplianceModal] = useState(false);
    const [showPropertyGroupsDialog, setShowPropertyGroupsDialog] =
        useState(false);
    const [showUserRolesDialog, setShowUserRolesDialog] = useState(false);
    const [assetDetails, setAssetDetails] = useState({
        ...assetBasicDetailsModel(),
        ...assetPropertiesModel(),
    });

    const { portfolios } = useSelector(portfolioSelector);
    const { properties, propertyGroups, roles } = useSelector(
        userManagementSelector
    );

    const createAsset = async (assetAddress) => {
        try {
            let portfolio = _.find(portfolios, {
                name: assetDetails.buildingUse,
            });
            if (!portfolio) {
                portfolio = await dispatch(
                    createPortfolio({
                        portfolioType: assetDetails.buildingUse,
                    })
                );
                await dispatch(getAllPortfoliosForBusiness());
            }

            const asset = await submitForm({
                portfolioId: portfolio.id,
                ...assetDetails,
                address: assetAddress,
            });

            onSuccess(asset);
        } catch (error) {
            errorToast(
                `Error creating asset: ${get(
                    error,
                    'response.data.message',
                    error.message
                )}`
            );
        }
    };

    const setAssetValues = (values) => {
        setAssetDetails(values);
        setCurrentStep(1);
    };

    const submitAssetAddressForm = async (addressDetails) => {
        await createAsset(addressDetails);
    };

    const openUserManagementSidebar = () => {
        setShowUserManagementSidebar(true);
    };

    const closeUserManagementSidebar = () => {
        setShowUserManagementSidebar(false);
    };

    const closePropertyGroupsDialog = () => {
        setShowPropertyGroupsDialog(false);
    };

    const closeUserRolesDialog = () => {
        setShowUserRolesDialog(false);
    };

    return (
        <>
            <VerticalStepper currentStep={currentStep}>
                <VerticalStepperStep title="Property Details">
                    <Pane marginX={2}>
                        <AssetBasicDetailsForm
                            initialValues={{
                                ...assetDetails,
                                parentId:
                                    Number(buildingId) || Number(assetId) || '',
                            }}
                            handleFormSubmission={setAssetValues}
                            openUserManagementSidebar={
                                openUserManagementSidebar
                            }
                        />
                    </Pane>
                </VerticalStepperStep>
                <VerticalStepperStep title="Compliance">
                    <Pane marginX={2}>
                        <AssetsComlianceForm
                            setCurrentStep={setCurrentStep}
                            currentStep={currentStep}
                            type={assetDetails.propertyType}
                        />
                    </Pane>
                </VerticalStepperStep>
                <VerticalStepperStep title="Property Address">
                    <Pane marginX={2}>
                        <AssetAddressForm
                            initialValues={assetAddressModel()}
                            handleFormSubmission={submitAssetAddressForm}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                        />
                    </Pane>
                </VerticalStepperStep>
            </VerticalStepper>
            <ComplianceModal
                isShown={showComplianceModal}
                type={assetDetails.propertyType}
                close={() => setShowComplianceModal(false)}
            />
            <UserManagementSidebar
                isShown={showUserManagementSidebar}
                isEditing={false}
                close={closeUserManagementSidebar}
                setShowPropertyGroupsDialog={setShowPropertyGroupsDialog}
                setShowRolesDialog={setShowUserRolesDialog}
                properties={properties}
                propertyGroups={propertyGroups}
                roles={roles}
            />
            <PropertyGroupsDialog
                isShown={showPropertyGroupsDialog}
                close={closePropertyGroupsDialog}
            />
            <UserRolesDialog
                isShown={showUserRolesDialog}
                close={closeUserRolesDialog}
            />
        </>
    );
};

CreateAssetForm.propTypes = {
    submitForm: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default CreateAssetForm;
