const complianceTypeBaseUrl = '/compliancetype';
const complianceCategoryBaseUrl = '/compliancecategory';
const complianceGroupBaseUrl = '/compliancegroup';
const complianceTemplatesBaseUrl = '/compliance/templates';

export default {
    createComplianceTypeUrl: () => `${complianceTypeBaseUrl}`,
    updateComplianceTypeUrl: (complianceTypeId) =>
        `${complianceTypeBaseUrl}/${complianceTypeId}`,
    deleteComplianceTypeUrl: () => `${complianceTypeBaseUrl}`,

    createComplianceCategory: () => complianceCategoryBaseUrl,
    updateComplianceCategory: (id) => `${complianceCategoryBaseUrl}/${id}`,
    deleteComplianceCategory: (id) => `${complianceCategoryBaseUrl}/${id}`,

    createComplianceGroup: () => complianceGroupBaseUrl,
    updateComplianceGroup: (id) => `${complianceGroupBaseUrl}/${id}`,
    deleteComplianceGroup: (id) => `${complianceGroupBaseUrl}/${id}`,

    // Templates

    getComplianceTemplatesUrl: () => complianceTemplatesBaseUrl,
    addComplianceGroupUrl: () => `${complianceTemplatesBaseUrl}/add`,
    deleteComplianceTemplate: (id) =>
        `${complianceTemplatesBaseUrl}/delete?id=${id}`,
};
