import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    Pane,
    Paragraph,
    Popover,
    DisableIcon,
    Tooltip,
    Dialog,
    ErrorIcon,
} from 'evergreen-ui';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { colors } from '../../../theme/theme';
import { ContextMenu, ContextMenuItem } from '../../molecules';
import useQueryParams from '../../../hooks/use-query-params';
import { SelectedComplianceItem } from '../../../pages/asset-pages/selected-complianceI-item-context';
import ComplianceTypeStatusIcon from '../../molecules/compliance-type-status-icon';
import {
    deleteAssetComplianceType,
    getAsset,
    markAssetComplianceTypeApplicable,
} from '../../../reducers/asset-reducer/asset.actions';
import { AutoFocusConsumer } from '../../atoms';
import { uploadRequestService } from '../../../services';
import { errorToast } from '../../toasts';
import RenameComplianceTypeDialog from './rename-compliance-type-dialog';

const ComplianceType = ({ type, groupId, categoryId, rights }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { assetId, buildingId, tenantId } = useParams();
    const { typeId } = useQueryParams();
    const { selectComplianceType } = useContext(SelectedComplianceItem);

    const [contextMenuShown, setContextMenuShown] = useState(false);
    const [
        isShowConfirmUploadRequestCancel,
        setIsShowConfirmUploadRequestCancel,
    ] = useState(false);
    const [initialContextMenuShown, setInitialContextMenuShown] =
        useState(false);
    const [isCanceling, setIsCanceling] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isConfirmDeleteDialogShown, setIsConfirmDeleteDialogShown] =
        useState(false);
    const [isRenameDialogShown, setIsRenameDialogShown] = useState(false);

    const handleBodyContextClick = useCallback(() => {
        if (initialContextMenuShown) {
            setInitialContextMenuShown(false);
            setContextMenuShown(false);
        } else {
            setInitialContextMenuShown(true);
        }
    }, [
        initialContextMenuShown,
        setInitialContextMenuShown,
        setContextMenuShown,
    ]);

    useEffect(() => {
        if (contextMenuShown) {
            document.addEventListener('contextmenu', handleBodyContextClick);
        } else {
            document.removeEventListener('contextmenu', handleBodyContextClick);
        }

        return () => {
            document.removeEventListener('contextmenu', handleBodyContextClick);
        };
    }, [contextMenuShown, handleBodyContextClick]);

    const showContextMenu = (event) => {
        event.preventDefault();
        if (
            !(rights?.update || rights?.delete) ||
            (type.isDefault && rights?.delete && !rights?.update)
        ) {
            return;
        }
        setContextMenuShown(true);
    };

    const showConfirmUploadRequestCancel = () => {
        setIsShowConfirmUploadRequestCancel(true);
    };

    const hideConfirmUploadRequestCancel = () => {
        setIsShowConfirmUploadRequestCancel(true);
    };

    const showConfirmDeleteDialog = () => {
        setIsConfirmDeleteDialogShown(true);
    };

    const hideConfirmDeleteDialog = () => {
        setIsConfirmDeleteDialogShown(false);
    };

    const showRenameDialog = () => {
        setIsRenameDialogShown(true);
    };

    const hideContextMenu = () => setContextMenuShown(false);

    const selectType = () => {
        if (contextMenuShown) {
            return;
        }

        selectComplianceType(groupId, categoryId, type, false);
        let url = '/portfolio/properties';
        if (assetId) {
            url += `/parks/${assetId}`;
        }
        if (buildingId) {
            url += `/buildings/${buildingId}`;
        }
        if (tenantId) {
            url += `/tenants/${tenantId}`;
        }
        url += '/compliance';
        navigate(
            `${url}?groupId=${groupId}&categoryId=${categoryId}&typeId=${type.id}`
        );
    };

    const markAsNotApplicable = () => {
        if (type.uploadRequest) {
            showConfirmUploadRequestCancel();
            return;
        }

        dispatch(
            markAssetComplianceTypeApplicable(
                tenantId || buildingId || assetId,
                groupId,
                categoryId,
                type,
                false
            )
        );
    };

    const markAsApplicable = () => {
        dispatch(
            markAssetComplianceTypeApplicable(
                tenantId || buildingId || assetId,
                groupId,
                categoryId,
                type,
                true
            )
        );
    };

    const cancelUploadRequest = async () => {
        setIsCanceling(true);
        try {
            await uploadRequestService.cancelUploadRequest(
                type.uploadRequest.id
            );

            dispatch(getAsset(assetId));
            dispatch(
                markAssetComplianceTypeApplicable(
                    tenantId || buildingId || assetId,
                    groupId,
                    categoryId,
                    type,
                    false
                )
            );
            hideConfirmUploadRequestCancel();
        } catch (error) {
            errorToast(_.get(error, 'response.data.message', error.message));
        } finally {
            setIsCanceling(false);
        }
    };

    const deleteComplianceType = async () => {
        setIsDeleting(true);
        try {
            dispatch(deleteAssetComplianceType(groupId, categoryId, type));
            hideConfirmDeleteDialog();
        } finally {
            setIsDeleting(false);
        }
    };

    if (!type?.applicable) {
        return (
            <Pane>
                <Popover
                    isShown={contextMenuShown}
                    shouldCloseOnExternalClick
                    onClose={hideContextMenu}
                    content={
                        (rights?.update || rights?.delete) && (
                            <ContextMenu
                                id={`type-${type.id}`}
                                preventHideOnContextMenu
                            >
                                {rights?.update && (
                                    <ContextMenuItem onClick={markAsApplicable}>
                                        <Paragraph>
                                            Mark as Applicable
                                        </Paragraph>
                                    </ContextMenuItem>
                                )}
                                {!type.isDefault && rights?.delete && (
                                    <ContextMenuItem
                                        onClick={showConfirmDeleteDialog}
                                    >
                                        <Paragraph color="#D14343">
                                            Delete
                                        </Paragraph>
                                    </ContextMenuItem>
                                )}
                            </ContextMenu>
                        )
                    }
                >
                    <Pane
                        display="flex"
                        paddingLeft={28}
                        paddingY={6}
                        alignItems="center"
                        onContextMenu={showContextMenu}
                        paddingX={30}
                    >
                        {contextMenuShown ? (
                            <Pane display="flex" alignItems="center">
                                <DisableIcon color="#d8dae5" marginRight={12} />
                                <Paragraph
                                    size={400}
                                    color="#d8dae5"
                                    fontSize={12}
                                    cursor="default"
                                >
                                    {type.name}
                                </Paragraph>
                            </Pane>
                        ) : (
                            <Tooltip content="Not Applicable" showDelay={1000}>
                                <Pane display="flex" alignItems="center">
                                    <DisableIcon
                                        color="#d8dae5"
                                        marginRight={12}
                                    />
                                    <Paragraph
                                        size={400}
                                        color="#d8dae5"
                                        fontSize={12}
                                        cursor="default"
                                    >
                                        {type.name}
                                    </Paragraph>
                                </Pane>
                            </Tooltip>
                        )}
                    </Pane>
                </Popover>
            </Pane>
        );
    }

    return (
        <TypeWrapper
            onClick={selectType}
            isSelected={typeId === type.id.toString()}
        >
            <Popover
                isShown={contextMenuShown}
                shouldCloseOnExternalClick
                onClose={hideContextMenu}
                content={
                    (rights?.update || rights?.delete) && (
                        <ContextMenu
                            id={`type-${type.id}`}
                            preventHideOnContextMenu
                        >
                            {rights?.update && (
                                <ContextMenuItem onClick={markAsNotApplicable}>
                                    <Paragraph>
                                        Mark as Not Applicable
                                    </Paragraph>
                                </ContextMenuItem>
                            )}
                            {!type.isDefault && rights?.update && (
                                <ContextMenuItem onClick={showRenameDialog}>
                                    <Paragraph>Rename</Paragraph>
                                </ContextMenuItem>
                            )}
                            {!type.isDefault && rights?.delete && (
                                <ContextMenuItem
                                    onClick={showConfirmDeleteDialog}
                                >
                                    <Paragraph color="#D14343">
                                        Delete
                                    </Paragraph>
                                </ContextMenuItem>
                            )}
                        </ContextMenu>
                    )
                }
            >
                <Pane
                    display="flex"
                    paddingLeft={28}
                    paddingY={6}
                    alignItems="center"
                    onContextMenu={showContextMenu}
                    paddingX={30}
                >
                    <ComplianceTypeStatusIcon type={type} />
                    <Paragraph size={400} color="#474D66" fontSize={12}>
                        {type.name}
                    </Paragraph>
                </Pane>
            </Popover>
            {rights?.update && (
                <Dialog
                    isShown={isShowConfirmUploadRequestCancel}
                    title="Cancel Upload Requests"
                    intent="danger"
                    onCloseComplete={hideConfirmUploadRequestCancel}
                    onConfirm={cancelUploadRequest}
                    confirmLabel="Cancel Upload Requests"
                    isConfirmLoading={isCanceling}
                >
                    <Paragraph>
                        Marking the compliance type <strong>{type.name}</strong>{' '}
                        as not applicable will also cancel all its pending
                        upload requests. Are you sure you want to cancel all
                        pending upload requests?
                    </Paragraph>
                    <AutoFocusConsumer />
                </Dialog>
            )}
            {rights?.delete && (
                <Dialog
                    isShown={isConfirmDeleteDialogShown}
                    title="Are you sure?"
                    intent="danger"
                    onCloseComplete={hideConfirmDeleteDialog}
                    onConfirm={deleteComplianceType}
                    confirmLabel={`Delete ${type.name}`}
                    isConfirmLoading={isDeleting}
                >
                    <Paragraph>
                        Are you sure you want to delete the{' '}
                        <strong>{type.name}</strong> compliance type?
                    </Paragraph>
                    <Pane display="flex" marginTop={12} alignItems="center">
                        <ErrorIcon color="#D14343" size={14} />
                        <Paragraph color="#D14343" marginLeft={6}>
                            This action is not reversible.
                        </Paragraph>
                    </Pane>

                    <AutoFocusConsumer />
                </Dialog>
            )}
            {rights?.update && (
                <RenameComplianceTypeDialog
                    groupId={groupId}
                    categoryId={categoryId}
                    complianceType={type}
                    isShown={isRenameDialogShown}
                    setIsShown={setIsRenameDialogShown}
                />
            )}
        </TypeWrapper>
    );
};

ComplianceType.propTypes = {
    type: PropTypes.shape({
        id: PropTypes.number,
        completed: PropTypes.string,
        name: PropTypes.string,
        applicable: PropTypes.bool,
        isDefault: PropTypes.bool,
        uploadRequest: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.number]),
        }),
    }).isRequired,
    groupId: PropTypes.string.isRequired,
    categoryId: PropTypes.string.isRequired,
    rights: PropTypes.shape({
        create: PropTypes.bool,
        read: PropTypes.bool,
        update: PropTypes.bool,
        delete: PropTypes.bool,
    }).isRequired,
};

export default ComplianceType;

const TypeWrapper = styled(Paragraph)`
    cursor: pointer;
    background-color: ${({ isSelected }) =>
        isSelected ? '#d7d8e8' : colors.transparent};

    &:hover {
        background-color: #d7d8e8;
    }
`;
