import React from 'react';
import { Button, Pane, TextInputField } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import BusinessNameAutocomplete from './business-name-autocomplete';

const ContactBusinessDetailsForm = ({
    initialValues,
    businessContact,
    handleFormSubmission,
    isSubmitting,
}) => {
    const validationSchema = Yup.object().shape({
        name: Yup.mixed().test(
            'business-name-autocomplete',
            'Business name is required',
            (value) => {
                if (typeof value === 'string' && value) {
                    return true;
                }
                return typeof value === 'object';
            }
        ),
        mobileNumber: Yup.string().required('Business number is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
    });

    const getBusinessNameAutocompleteValue = (value) => {
        if (typeof value === 'object') {
            return _.get(value, 'name');
        }

        return value;
    };

    return (
        <Formik
            initialValues={businessContact || initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmission}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
            }) => (
                <Form onSubmit={handleSubmit}>
                    {!businessContact ? (
                        <BusinessNameAutocomplete
                            name="name"
                            label="Business Name"
                            placeholder="Company"
                            value={getBusinessNameAutocompleteValue(
                                values.name
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    ) : (
                        <TextInputField
                            label="Business Name"
                            type="text"
                            name="name"
                            placeholder="Company"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.name && !!errors.name}
                            validationMessage={touched.name && errors.name}
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            disabled={typeof values.name === 'object'}
                        />
                    )}
                    <Pane marginTop="16px">
                        <TextInputField
                            label="Business Phone Number"
                            type="text"
                            name="mobileNumber"
                            placeholder="+27712526996"
                            value={values.mobileNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                touched.mobileNumber && !!errors.mobileNumber
                            }
                            validationMessage={
                                touched.mobileNumber && errors.mobileNumber
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            disabled={typeof values.name === 'object'}
                        />
                    </Pane>
                    <Pane marginTop="16px">
                        <TextInputField
                            label="Business Email"
                            type="text"
                            name="email"
                            placeholder="business@email.com"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.email && !!errors.email}
                            validationMessage={touched.email && errors.email}
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            disabled={typeof values.name === 'object'}
                        />
                    </Pane>
                    <Pane marginTop="16px">
                        <Button
                            appearance="primary"
                            height={40}
                            type="submit"
                            width="100%"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            {businessContact ? 'Confirm' : 'Next'}
                        </Button>
                    </Pane>
                </Form>
            )}
        </Formik>
    );
};

ContactBusinessDetailsForm.propTypes = {
    initialValues: PropTypes.shape({}).isRequired,
    businessContact: PropTypes.shape({}),
    handleFormSubmission: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
};

ContactBusinessDetailsForm.defaultProps = {
    businessContact: null,
    isSubmitting: false,
};

export default ContactBusinessDetailsForm;
