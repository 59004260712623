const tasksBaseURL = '/tasks';
const tasksCategoriesBaseURL = '/categories';
const dashboardBaseURL = '/dashboard';

export default {
    getTasksURL: () => tasksBaseURL,
    createTaskURL: () => `${tasksBaseURL}/new`,
    editTaskURL: () => tasksBaseURL,
    updateTaskURL: () => `${tasksBaseURL}/update`,
    deleteTaskURL: () => `${tasksBaseURL}`,

    getTasksCategoriesURL: () => tasksCategoriesBaseURL,
    deleteTasksCategoriesURL: () => tasksCategoriesBaseURL,
    createTasksCategoriesURL: () => `${tasksCategoriesBaseURL}/create`,
    updateTasksCategoriesURL: () => `${tasksCategoriesBaseURL}/update`,
    createTasksTypeURL: () => `${tasksCategoriesBaseURL}/type/create`,
    updateTasksTypeURL: () => `${tasksCategoriesBaseURL}/type/update`,
    deleteTasksTypeURL: (id) =>
        `${tasksCategoriesBaseURL}/type/delete?id=${id}`,

    getAvailableBuildingsURL: () => `/buildings`,

    getAvailableAssigneesURL: () => `/assignment`,

    getAttachmentUploadLinkRequestURL: () => '/Documents/linkrequest',
    setAttachmentsUploadedSuccessfullyURL: () => '/Documents/docsuploaded/v2',

    getTasksOverviewData: () => `${dashboardBaseURL}/cards`,
    getTasksOverallGraphData: () => `${dashboardBaseURL}/graph`,
};
