import userManagementUrls from './user-management-service-urls';
import networkService from '../network-service/network.service';
import {
    linkPropertiesAPIModel,
    propertyGroupAPIModel,
    roleApiModel,
    userApiModel,
} from '../../../models/user-management.models';

const baseURL = 'https://xkrn-4n4o-aozy.e2.xano.io/api:WqpYIs4N';

// Users

function getUsers(id) {
    const url = userManagementUrls.getUsersUrl();
    const config = { params: { user_id: id }, baseURL };
    return networkService.makeGet(url, config);
}

function addUser(user) {
    const url = userManagementUrls.addUserUrl();
    const data = userApiModel(user);
    return networkService.makePost(url, data, { baseURL });
}

function editUser(user) {
    const url = userManagementUrls.editUserUrl();
    const data = userApiModel(user);
    return networkService.makePut(url, data, { baseURL });
}

function deleteUser(id) {
    const url = userManagementUrls.deleteUserUrl(id);
    return networkService.makeDelete(url, null, { baseURL });
}

// Roles

function getRoles() {
    const url = userManagementUrls.getRolesUrl();
    return networkService.makeGet(url, { baseURL });
}

function createRole(role) {
    const url = userManagementUrls.createRoleUrl();
    const data = roleApiModel(role);
    return networkService.makePost(url, data, { baseURL });
}

function editRole(role) {
    const url = userManagementUrls.editRoleUrl();
    const data = roleApiModel(role);
    return networkService.makePut(url, data, { baseURL });
}

function deleteRole(id) {
    const url = userManagementUrls.deleteRoleUrl(id);
    return networkService.makeDelete(url, null, { baseURL });
}

// Properties

function getProperties() {
    const url = userManagementUrls.getPropertiesUrl();
    return networkService.makeGet(url, { baseURL });
}

function getPropertyGroups() {
    const url = userManagementUrls.getPropertyGroupsUrl();
    return networkService.makeGet(url, { baseURL });
}

function createPropertyGroup(propertyGroup) {
    const url = userManagementUrls.createPropertiesGroupUrl();
    const data = propertyGroupAPIModel(propertyGroup);
    return networkService.makePost(url, data, { baseURL });
}

function updatePropertyGroup(propertyGroup) {
    const url = userManagementUrls.editPropertiesGroupUrl();
    const data = propertyGroupAPIModel(propertyGroup);
    return networkService.makePut(url, data, { baseURL });
}

function deletePropertyGroup(id) {
    const url = userManagementUrls.deletePropertiesGroupUrl(id);
    return networkService.makeDelete(url, null, { baseURL });
}

function linkPropertyGroupToUser(data) {
    const url = userManagementUrls.linkPropertyGroupToUserUrl();
    const Data = linkPropertiesAPIModel(data);
    return networkService.makePost(url, Data, { baseURL });
}

export default {
    // Users
    getUsers,
    addUser,
    editUser,
    deleteUser,

    // Roles
    getRoles,
    createRole,
    editRole,
    deleteRole,

    // Properties
    getProperties,
    getPropertyGroups,
    createPropertyGroup,
    updatePropertyGroup,
    deletePropertyGroup,
    linkPropertyGroupToUser,
};
