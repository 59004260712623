import { maintenanceService, documentService } from '../../services/index';
import { getUpdateDocumentModel } from '../../models';
import { maintenanceOverviewCardsModel } from '../../models/maintenance.models';

import {
    setLoadingAction,
    setGetMaintenanceCategoriesGraphData,
    setGetAssetRegisterPaginationAction,
    setGetScheduledMaintenanceDataAction,
    setGetAssetRegisterDataAction,
    setGetMaintenanceCategoriesAction,
    setNewMaintenanceItemIdAction,
    setOverviewDataAction,
    setViewAssetSidebar,
} from './maintenance-reducer';

const onCompleted = async (dispatch, getState, buildingId) => {
    const { currentAsset, currentBuilding, currentTenant } =
        getState().assetReducer;
    const { search, page, sort } = getState().maintenanceReducer;
    const portfolioLevel = !currentTenant && !currentBuilding && !currentAsset;

    dispatch(setNewMaintenanceItemIdAction(null));
    dispatch(
        getAssetRegisterData(portfolioLevel, search, page, sort, buildingId)
    );
};

export function getMaintenanceCategoriesGraphs() {
    return async (dispatch, getState) => {
        const { dateRange, search } = getState().maintenanceReducer;
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;
        const buildingId =
            currentTenant?.id || currentBuilding?.id || currentAsset?.id || 0;

        const maintenanceCategoriesGraphData =
            await maintenanceService.getMaintenanceCategoriesGraphs({
                ...dateRange,
                buildingId,
                search,
            });
        dispatch(
            setGetMaintenanceCategoriesGraphData(maintenanceCategoriesGraphData)
        );
    };
}

export function getScheduledMaintenanceData() {
    return async (dispatch, getState) => {
        const { dateRange, search } = getState().maintenanceReducer;
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;
        const buildingId =
            currentTenant?.id || currentBuilding?.id || currentAsset?.id || 0;

        const scheduledMaintenanceData =
            await maintenanceService.getScheduledMaintenanceData({
                ...dateRange,
                buildingId,
                search,
            });
        dispatch(
            setGetScheduledMaintenanceDataAction(scheduledMaintenanceData)
        );
    };
}

export function getAssetRegisterData(shouldSetLoading = false) {
    return async (dispatch, getState) => {
        if (shouldSetLoading) {
            dispatch(setLoadingAction(true));
        }
        const { dateRange, search, sort, page } = getState().maintenanceReducer;
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;
        const portfolioLevel =
            !currentAsset && !currentBuilding && !currentTenant;
        const buildingId =
            currentTenant?.id || currentBuilding?.id || currentAsset?.id || 0;

        const assetRegister = await maintenanceService.getAssetRegisterData(
            dateRange,
            portfolioLevel,
            search,
            page,
            sort,
            buildingId
        );
        dispatch(setGetAssetRegisterDataAction(assetRegister));
        dispatch(getMaintenanceCategoriesGraphs());
        dispatch(getMaintenanceOverviewCards());
        dispatch(getScheduledMaintenanceData());
        dispatch(setGetAssetRegisterPaginationAction(assetRegister));
        dispatch(setLoadingAction(false));
    };
}

export function deleteAssetRegisterItem(assetRegisterId) {
    return async (dispatch, getState) => {
        const { search, sort, page } = getState().maintenanceReducer;
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;

        const buildingId =
            currentTenant?.id || currentBuilding?.id || currentAsset?.id || 0;
        const portfolioLevel =
            !currentTenant && !currentBuilding && !currentAsset;

        await maintenanceService.deleteAssetRegisterItem(assetRegisterId);
        dispatch(
            getAssetRegisterData(portfolioLevel, search, page, sort, buildingId)
        );
    };
}

export function addAssetRegisterItem(maintenanceItem, files, enqueueUploads) {
    return async (dispatch, getState) => {
        const { user } = getState().userReducer;
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;
        const asset = currentAsset || currentBuilding || currentTenant;
        const portfolioLevel =
            !currentTenant && !currentBuilding && !currentAsset;

        const { id, buildings_id: buildingId } =
            await maintenanceService.addAssetRegisterItem(maintenanceItem);
        dispatch(setNewMaintenanceItemIdAction(id));

        enqueueUploads(files, {
            assetId: asset.bucketId,
            userId: user.id,
            requestType: 'maintenance',
            maintenanceItemId: id,
            onCompleted: () => onCompleted(dispatch, getState, buildingId),
        });

        dispatch(getAssetRegisterData(portfolioLevel));
    };
}

export function editAssetRegisterItem(maintenanceItem) {
    return async (dispatch, getState) => {
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;
        const portfolioLevel =
            !currentTenant && !currentBuilding && !currentAsset;
        await maintenanceService.editAssetRegisterItem(maintenanceItem);

        dispatch(getAssetRegisterData(portfolioLevel));
    };
}

// Categories

export function getMaintenanceCategories() {
    return async (dispatch) => {
        const maintenanceCategories =
            await maintenanceService.getMaintenanceCategories();
        dispatch(setGetMaintenanceCategoriesAction(maintenanceCategories));
    };
}

export function deleteMaintenanceCategory(categoryId) {
    return async (dispatch) => {
        await maintenanceService.deleteMaintenanceCategory(categoryId);
        dispatch(getMaintenanceCategories());
    };
}

export function createMaintenanceCategory(
    categoryName,
    abbreviation,
    isDefault = false,
    isActive = true
) {
    return async (dispatch) => {
        const category = await maintenanceService.createMaintenanceCategory(
            categoryName,
            abbreviation,
            isDefault,
            isActive
        );
        await dispatch(getMaintenanceCategories());
        return category;
    };
}

export function updateMaintenanceCategory(categoryId, name, abbreviation) {
    return async (dispatch) => {
        const category = await maintenanceService.updateMaintenanceCategory(
            categoryId,
            name,
            abbreviation
        );
        await dispatch(getMaintenanceCategories());
        return category;
    };
}

export function createMaintenanceType(categoryId, name) {
    return async (dispatch) => {
        const type = await maintenanceService.createMaintenanceType(
            categoryId,
            name
        );
        dispatch(getMaintenanceCategories());
        return type;
    };
}

export function deleteMaintenanceType(typeId) {
    return async (dispatch) => {
        await maintenanceService.deleteMaintenanceType(typeId);
        dispatch(getMaintenanceCategories());
    };
}

export function updateMaintenanceType(typeId, name) {
    return async (dispatch) => {
        const type = await maintenanceService.updateMaintenanceType(
            typeId,
            name
        );
        dispatch(getMaintenanceCategories());
        return type;
    };
}

export function getMaintenanceItemDocuments() {
    return async () => maintenanceService.getMaintenanceItemDocuments();
}

export function getMaintenanceOverviewCards() {
    return async (dispatch, getState) => {
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;
        const { dateRange } = getState().maintenanceReducer;

        const buildingId =
            currentTenant?.id || currentBuilding?.id || currentAsset?.id || 0;

        const maintenanceOverviewCards =
            await maintenanceService.getMaintenanceOverviewCards({
                ...dateRange,
                buildingId,
            });
        dispatch(
            setOverviewDataAction(
                maintenanceOverviewCardsModel(maintenanceOverviewCards)
            )
        );
    };
}

export function markMaintenanceItemServiced(maintenanceItemId, serviceDate) {
    return async (dispatch) => {
        await maintenanceService.markMaintenanceItemServiced(
            maintenanceItemId,
            serviceDate
        );
        dispatch(getAssetRegisterData());
    };
}

export function updateMaintenanceDocument(editDocument) {
    return async (dispatch, getState) => {
        const { dateRange, search, page, sort } = getState().maintenanceReducer;
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;

        const portfolioLevel =
            !currentAsset && !currentBuilding && !currentTenant;
        const buildingId =
            currentTenant?.id || currentBuilding?.id || currentAsset?.id || 0;

        const updatedDocument = getUpdateDocumentModel(
            await documentService.updateDocument({
                documentId: editDocument?.id,
                friendlyName: editDocument.newName,
                notes: editDocument.notes,
                requestType: 'maintenance',
            })
        );

        const assetRegister = await maintenanceService.getAssetRegisterData(
            dateRange,
            portfolioLevel,
            search,
            page,
            sort,
            buildingId
        );
        const asset = assetRegister.items.find(
            (_asset) => _asset.id === updatedDocument.maintenanceItemId
        );
        dispatch(setViewAssetSidebar({ asset, isShown: true }));
    };
}

export function deleteMaintenanceDocument(document) {
    return async (dispatch, getState) => {
        const { dateRange, search, page, sort } = getState().maintenanceReducer;
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;

        const portfolioLevel =
            !currentAsset && !currentBuilding && !currentTenant;
        const buildingId =
            currentTenant?.id || currentBuilding?.id || currentAsset?.id || 0;

        await documentService.deleteDocument(document);
        const assetRegister = await maintenanceService.getAssetRegisterData(
            dateRange,
            portfolioLevel,
            search,
            page,
            sort,
            buildingId
        );
        const asset = assetRegister.items.find(
            (_asset) => Number(_asset.id) === Number(document.maintenanceItemId)
        );
        dispatch(setViewAssetSidebar({ asset, isShown: true }));
    };
}
