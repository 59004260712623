import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Dialog,
    Pane,
    TextInputField,
    InfoSignIcon,
    Tooltip,
} from 'evergreen-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { successToast, errorToast } from '../../toasts';

import { portfolioSelector } from '../../../reducers/portfolio-reducer/portfolio.reducer';
import { assetService } from '../../../services';
import { assetModel } from '../../../models';
import {
    getAsset,
    getBuilding,
} from '../../../reducers/asset-reducer/asset.actions';
import {
    createPortfolio,
    getAllPortfoliosForBusiness,
} from '../../../reducers/portfolio-reducer/portfolio.actions';
import {
    setSearchAction,
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from '../../../reducers/asset-reducer/asset.reducer';

import LinkedAssetList from './LinkedAssetList';

function AssetArchiving({ asset, onOpen, handleCloseArchive }) {
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorArchiving, setErrorArchiving] = useState(false);
    const [errorData, setErrorData] = useState({});

    const { portfolios } = useSelector(portfolioSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleInputChange = useCallback((e) => {
        setInputValue(e.target.value);
    }, []);

    const isDeleteConfirmed = useMemo(
        () => inputValue === 'archive',
        [inputValue]
    );

    const { updateArchivedAsset } = assetService;

    const onSuccess = useCallback(
        async (updatedAsset) => {
            const url = '/portfolio/properties';
            if (updatedAsset.type === 'park') {
                dispatch(setCurrentAssetAction(assetModel(updatedAsset)));
            }
            if (updatedAsset.type === 'building') {
                dispatch(setCurrentBuildingAction(assetModel(updatedAsset)));
                if (updatedAsset.parentId) {
                    dispatch(getAsset(updatedAsset.parentId, false, false));
                }
            }
            if (updatedAsset.type === 'tenant') {
                dispatch(setCurrentTenantAction(assetModel(updatedAsset)));
                const building = await dispatch(
                    getBuilding(updatedAsset.parentId)
                );
                if (building.parentId) {
                    dispatch(getAsset(building.parentId, false, false));
                }
            }
            handleCloseArchive();
            setInputValue('');
            dispatch(setSearchAction(''));
            dispatch(setCurrentAssetAction(null));
            dispatch(setCurrentBuildingAction(null));
            dispatch(setCurrentTenantAction(null));
            navigate(url);
            successToast('Asset successfully archived');
        },
        [dispatch, navigate, handleCloseArchive]
    );

    const onFailure = useCallback((errorMessage, error) => {
        setInputValue('');
        setErrorArchiving(true);
        setErrorData(error);
        errorToast(errorMessage);
    }, []);

    const handleArchiveSubmit = useCallback(async () => {
        const ArchivedPortfolio = 'Archived';
        try {
            setIsLoading(true);
            let portfolio = _.find(portfolios, {
                name: ArchivedPortfolio,
            });
            if (!portfolio) {
                portfolio = await dispatch(
                    createPortfolio({
                        portfolioType: ArchivedPortfolio,
                    })
                );
                await dispatch(getAllPortfoliosForBusiness());
            }

            await updateArchivedAsset({
                ...asset,
                assetArchive: true,
                portfolioId: portfolio.id,
            });

            const updatedAsset = await dispatch(
                getAsset(asset.id, false, false, false)
            );
            onSuccess(updatedAsset);
        } catch (error) {
            const errorMessage = _.get(
                error,
                'response.data.message',
                error.message
            );
            const getErrorData = _.get(
                error,
                'response.data.payload',
                error.payload
            );
            onFailure(errorMessage, getErrorData);
        } finally {
            setIsLoading(false);
        }
    }, [
        portfolios,
        dispatch,
        onSuccess,
        onFailure,
        updateArchivedAsset,
        asset,
    ]);

    const handleCloseDialog = useCallback(() => {
        handleCloseArchive();
        setErrorArchiving(false);
    }, [handleCloseArchive]);

    return (
        <Dialog
            isShown={onOpen}
            onConfirm={handleArchiveSubmit}
            title="Confirm Archiving"
            confirmLabel="Confirm"
            hasCancel={false}
            isConfirmDisabled={!isDeleteConfirmed}
            onCancel={handleCloseDialog}
            isConfirmLoading={isLoading}
        >
            <Pane>
                <TextInputField
                    label="To confirm archiving, type archive in the text input field"
                    placeholder="Type archive to confirm"
                    value={inputValue}
                    onChange={handleInputChange}
                />
                {!errorArchiving ? (
                    <Pane
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <p>Are you sure you want to proceed?</p>
                        <Tooltip content="Archiving an asset makes it and all associated documents inaccessible. You'll need to unarchive the asset to regain access, which usually takes about three days to complete.">
                            <InfoSignIcon
                                size={14}
                                marginBottom={15}
                                color="#c8cacf"
                            />
                        </Tooltip>
                    </Pane>
                ) : (
                    <LinkedAssetList errorData={errorData} asset={asset} />
                )}
            </Pane>
        </Dialog>
    );
}

AssetArchiving.propTypes = {
    asset: PropTypes.string.isRequired,
    onOpen: PropTypes.string.isRequired,
    handleCloseArchive: PropTypes.string.isRequired,
};

export default AssetArchiving;
