import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Heading, Pane, Paragraph, Text, EditIcon } from 'evergreen-ui';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { BuildingImage } from '../../molecules';

import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';

const AssetSidebarAssetDetails = ({
    isVisible,
    asset,
    showEditAssetSidebar,
    create,
    update,
}) => {
    const { currentAsset, currentBuilding, currentTenant, parks, buildings } =
        useSelector(assetSelector);
    const { isLoadingParams } = useSearchParams();

    const { assetName, address } = useMemo(() => {
        if (isLoadingParams) {
            return { assetName: '' };
        }
        return (
            asset ||
            currentTenant ||
            currentBuilding ||
            currentAsset || { assetName: '' }
        );
    }, [isLoadingParams, currentTenant, currentBuilding, currentAsset, asset]);

    const assetImage = useMemo(() => {
        if (isLoadingParams) {
            return null;
        }

        const assetDetails = currentTenant || currentBuilding || currentAsset;

        if (assetDetails) {
            const featuredImage = assetDetails.images?.find(
                (image) => image.isFeatured
            );

            if (featuredImage) {
                return featuredImage;
            }
            if (assetDetails.images.length > 0) {
                return assetDetails.images[0];
            }

            return null;
        }

        return null;
    }, [isLoadingParams, currentTenant, currentBuilding, currentAsset]);

    const {
        erfNumber,
        grossBuildingArea,
        grossLettableArea,
        builtFar,
        buildingUse,
        type,
        permissibleFar,
        permissibleCoverage,
        builtCoverage,
        parentId,
    } = useMemo(
        () =>
            asset ||
            currentTenant ||
            currentBuilding ||
            currentAsset || {
                parentId: '',
                portfolioName: '',
                erfNumber: '',
                grossBuildingArea: '',
                grossLettableArea: '',
                builtFar: '',
                permissibleFar: '',
                builtCoverage: '',
                permissibleCoverage: '',
                propertyType: '',
            },
        [currentAsset, currentBuilding, currentTenant, asset]
    );

    const { parentInfo } = useMemo(() => {
        const details = [...parks, ...buildings];
        const info = details.find(({ id }) => Number(parentId) === Number(id));

        return { parentInfo: info };
    }, [parks, buildings, parentId]);

    if (!isVisible) {
        return null;
    }

    return (
        <PaneBar>
            <Pane
                paddingBottom={20}
                paddingTop={20}
                borderBottom="1.5px solid #F1F2F2"
            >
                <Pane
                    display="flex"
                    justifyContent="space-evenly"
                    alignItems="center"
                    gap={1}
                >
                    <Text>{`${address?.addressLine1}, ${address?.suburb}, ${address?.city}, ${address?.postalCode}, ${address?.province}, ${address?.country}`}</Text>
                    <BuildingImage
                        key={assetName}
                        width={102}
                        height={95}
                        borderRadius={9}
                        image={assetImage}
                    />
                </Pane>
            </Pane>
            <Pane
                borderBottom="1.5px solid #F1F2F2"
                paddingBottom={20}
                paddingTop={20}
            >
                <Pane
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="95%"
                    paddingBottom={10}
                >
                    <Heading>Asset Information</Heading>
                    {(create || update) && (
                        <EditIcon
                            onClick={showEditAssetSidebar}
                            color="#52BD94"
                            cursor="pointer"
                        />
                    )}
                </Pane>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Erf No:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {erfNumber}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Building Use:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {buildingUse}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Property Type:&emsp;
                    <Text
                        fontSize={12}
                        fontWeight={600}
                        color="#333333"
                        textTransform="capitalize"
                    >
                        {type}
                    </Text>
                </Paragraph>
                {parentInfo?.type && (
                    <Paragraph size={500} fontSize={12} color="#696F8C">
                        {`Linked to ${_.capitalize(parentInfo?.type)}:`}&emsp;
                        <Text
                            fontSize={12}
                            fontWeight={600}
                            color="#333333"
                            textTransform="capitalize"
                        >
                            {parentInfo?.assetName}
                        </Text>
                    </Paragraph>
                )}
                <br />
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    GBA (Gross Building Area):&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {grossBuildingArea}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    GLA (Gross Lettable Area):&emsp;
                    {grossLettableArea && (
                        <Text fontSize={12} fontWeight={600} color="#333333">
                            {grossLettableArea}m&sup2;
                        </Text>
                    )}
                </Paragraph>
                <br />
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Built FAR (Floor Area Ratio):&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {builtFar}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Permissible FAR (Floor Area Ratio):&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {permissibleFar}
                    </Text>
                </Paragraph>
                <br />
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Built Coverage:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {builtCoverage}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Permissible Coverage:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {permissibleCoverage}
                    </Text>
                </Paragraph>
            </Pane>
        </PaneBar>
    );
};

AssetSidebarAssetDetails.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    asset: PropTypes.shape({}),
    showEditAssetSidebar: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
};

AssetSidebarAssetDetails.defaultProps = {
    asset: null,
};

const PaneBar = styled(Pane)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export default AssetSidebarAssetDetails;
