import React, { createRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const FileDragAndDrop = ({ children, onFilesDropped }) => {
    const dragCounter = createRef();
    const dropRef = createRef();

    const [dragging, setDragging] = useState(false);

    const handleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragIn = useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            dragCounter.current += 1;
            if (
                event.dataTransfer.items &&
                event.dataTransfer.items.length > 0
            ) {
                setDragging(true);
            }
        },
        [dragCounter]
    );

    const handleDragOut = useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            dragCounter.current -= 1;
            if (dragCounter.current === 0) {
                return;
            }
            setDragging(false);
        },
        [dragCounter]
    );

    const handleDrop = useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (
                event.dataTransfer.files &&
                event.dataTransfer.files.length > 0
            ) {
                onFilesDropped(event.dataTransfer.files);
                event.dataTransfer.clearData();
            }
        },
        [onFilesDropped]
    );

    useEffect(() => {
        const dragWrapper = dropRef.current;

        dragCounter.current = 0;

        dragWrapper.addEventListener('dragenter', handleDragIn);
        dragWrapper.addEventListener('dragleave', handleDragOut);
        dragWrapper.addEventListener('dragover', handleDrag);
        dragWrapper.addEventListener('drop', handleDrop);

        return () => {
            dragWrapper.removeEventListener('dragenter', handleDragIn);
            dragWrapper.removeEventListener('dragleave', handleDragOut);
            dragWrapper.removeEventListener('dragover', handleDrag);
            dragWrapper.removeEventListener('drop', handleDrop);
        };
    }, [dragCounter, dropRef, handleDragIn, handleDragOut, handleDrop]);

    return <div ref={dropRef}>{children({ dragging })}</div>;
};

FileDragAndDrop.propTypes = {
    children: PropTypes.func.isRequired,

    onFilesDropped: PropTypes.func.isRequired,
};

export default FileDragAndDrop;
