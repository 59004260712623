import { createSlice } from '@reduxjs/toolkit';
import { getTaskModel } from '../../models/task.models';

const initialState = {
    search: '',
    sort: null,
    statusFilter: '',
    typeFilter: '',
    userFilter: '',
    dateRange: { startDate: null, endDate: null },
    tasks: [],
    tasksPagination: {},
    categories: [],
    properties: [],
    assignees: [],
    overviewData: {
        newTasks: 0,
        totalTasks: {
            count: 0,
            alerts: 0,
        },
        overdueTasks: {
            count: 0,
            alerts: 0,
        },
        completedTasks: 0,
    },
    overAllGraph: {},
    costGraph: {
        scheduled: 0,
        onceOff: 0,
    },
    tablePage: 1,
    taskToView: null,
};

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setSearchAction(state, { payload }) {
            state.search = payload;
        },
        setSortAction(state, { payload }) {
            state.sort = payload;
        },
        setDateRangeAction(state, { payload }) {
            state.dateRange = payload;
        },
        setTasksAction(state, { payload }) {
            state.tasks = payload.items;
        },
        setTasksPaginationAction(state, { payload }) {
            state.tasksPagination = payload;
            if (state.tasksPagination.items) {
                delete state.tasksPagination.items;
            }
        },
        setTasksCategoriesAction(state, { payload }) {
            state.categories = payload;
        },
        setTasksPropertiesAction(state, { payload }) {
            state.properties = payload;
        },
        setAvailableTaskAssigneesAction(state, { payload }) {
            state.assignees = payload;
        },
        setTasksOverviewDataAction(state, { payload }) {
            state.overviewData = {
                newTasks: payload.new_tasks_assigned,
                totalTasks: payload.total_tasks,
                overdueTasks: payload.overdue_tasks,
                completedTasks: payload.completed_tasks,
            };
            state.costGraph = {
                scheduled: payload.scheduled,
                onceOff: payload.once_off,
            };
        },
        setTasksOverallGraphDataAction(state, { payload }) {
            state.overAllGraph = payload;
        },
        setStatusFilterAction(state, { payload }) {
            state.statusFilter = payload;
        },
        setTypeFilterAction(state, { payload }) {
            state.typeFilter = payload;
        },
        setUserFilterAction(state, { payload }) {
            state.userFilter = payload;
        },
        setTablePageAction(state, { payload }) {
            state.tablePage = payload;
        },
        setTaskToViewAction(state, { payload }) {
            state.taskToView = getTaskModel(payload);
        },
    },
});

export const {
    setSearchAction,
    setSortAction,
    setDateRangeAction,
    setTasksAction,
    setTasksPaginationAction,
    setTasksCategoriesAction,
    setAvailableTaskAssigneesAction,
    setTasksPropertiesAction,
    setTasksOverviewDataAction,
    setTasksOverallGraphDataAction,
    setStatusFilterAction,
    setTypeFilterAction,
    setUserFilterAction,
    setTablePageAction,
    setTaskToViewAction,
} = taskSlice.actions;

export const taskSelector = (reducers) => reducers.taskReducer;

export default taskSlice.reducer;
