import { get } from 'lodash';

export const getTaskModel = (task) => ({
    id: get(task, 'id', ''),
    createdAt: get(task, 'created_at', ''),
    tenantId: get(task, 'tenant_id', ''),
    buildingId: get(task, 'buildings_id', ''),
    description: get(task, 'description', ''),
    uploadPending: get(task, 'uploadPending', ''),
    type: get(task, 'type', ''),
    taskCategoryId: get(task, 'task_category_id', ''),
    taskTypeId: get(task, 'task_types_id', ''),
    priority: get(task, 'priority', ''),
    dueDate: get(task, 'due_date', ''),
    assignedUserId: get(task, 'assigned_user_id', ''),
    notes: get(task, 'notes', ''),
    status: get(task, 'status', ''),
    progress: get(task, 'progress', ''),
    completedDate: get(task, 'completed_date', ''),
    rrule: get(task, 'rrule', ''),
    jobId: get(task, 'jobId', ''),
    repeatJobKey: get(task, 'repeatJobKey', ''),
    updates: get(task, 'task_updates', ''),
    assignedUser: get(task, 'assigned_user', ''),
    taskCategory: get(task, 'tasks_categories', ''),
    taskType: get(task, 'task_types', ''),
    building: get(task, 'building', ''),
    documents: get(task, 'task_documents', ''),
});

export const setTaskModel = (task) => ({
    task_items_id: get(task, 'id', ''),
    buildings_id: get(task, 'property.id', ''),
    description: get(task, 'description', ''),
    type: get(task, 'type', ''),
    task_category_id: get(task, 'category.id', ''),
    task_categories_id: get(task, 'category.id', ''),
    tasks_categories_id: get(task, 'category.id', ''),
    task_types_id: get(task, 'subCategory.id', ''),
    priority: get(task, 'priority', ''),
    due_date: get(task, 'dueDate', ''),
    assigned_user_id: get(task, 'assignee.id', ''),
    notes: get(task, 'notes', ''),
    status: get(task, 'status', ''),
    progress: get(task, 'progress', ''),
    rrule: get(task, 'rrule', ''),
    attachments: get(task, 'attachments', ''),
    next_startDate: get(task, 'nextOccurrence', ''),
});

export const taskUpdateModel = (taskUpdate) => ({
    id: get(taskUpdate, 'id', ''),
    taskId: get(taskUpdate, 'tasks_items_id', ''),
    createdAt: get(taskUpdate, 'created_at', ''),
    tenantId: get(taskUpdate, 'tenant_id', ''),
    title: get(taskUpdate, 'title', ''),
    description: get(taskUpdate, 'description', ''),
});

export const taskUserModel = (taskUser) => ({
    id: get(taskUser, 'id', ''),
    createdAt: get(taskUser, 'created_at', ''),
    name: get(taskUser, 'name', ''),
    surname: get(taskUser, 'surname', ''),
    email: get(taskUser, 'email', ''),
    mobileNo: get(taskUser, 'mobileno', ''),
    tenantId: get(taskUser, 'tenant_id', ''),
});

export const getTaskCategoryModel = (taskCategory) => ({
    id: get(taskCategory, 'id', ''),
    createdAt: get(taskCategory, 'created_at', ''),
    name: get(taskCategory, 'name', ''),
    tenantId: get(taskCategory, 'tenant_id', ''),
    businessId: get(taskCategory, 'business_id', ''),
    isDefault: get(taskCategory, 'is_default', ''),
    isActive: get(taskCategory, 'is_active', ''),
    types: get(taskCategory, 'task_types', ''),
});

export const setTaskCategoryModel = (taskCategory) => ({
    id: get(taskCategory, 'id', ''),
    created_at: get(taskCategory, 'createdAt', ''),
    name: get(taskCategory, 'name', ''),
    tenant_id: get(taskCategory, 'tenantId', ''),
    business_id: get(taskCategory, 'businessId', ''),
    is_default: get(taskCategory, 'isDefault', ''),
    is_active: get(taskCategory, 'isActive', ''),
});

export const getTaskTypeModel = (taskType) => ({
    id: get(taskType, 'id', ''),
    createdAt: get(taskType, 'created_at', ''),
    name: get(taskType, 'name', ''),
    categoryId: get(taskType, 'tasks_categories_id', ''),
});

export const setTaskTypeModel = (taskType) => ({
    id: get(taskType, 'id', ''),
    created_at: get(taskType, 'createdAt', ''),
    name: get(taskType, 'name', ''),
    tasks_categories_id: get(taskType, 'categoryId', ''),
});

export const getTaskBuildingModel = (building) => ({
    id: get(building, 'id', ''),
    createdAt: get(building, 'created_at', ''),
    name: get(building, 'name', ''),
    tenantId: get(building, 'tenant_id', ''),
    portfolioId: get(building, 'portfolio_id', ''),
    storey: get(building, 'storey', ''),
    floorAmount: get(building, 'qtyfloors', ''),
    gla: get(building, 'gla', ''),
    gba: get(building, 'gba', ''),
    erfNumber: get(building, 'erf_number', ''),
    use: get(building, 'use', ''),
    builtFar: get(building, 'built_FAR', ''),
    permissibleFar: get(building, 'permissible_FAR', ''),
    builtCoverage: get(building, 'built_coverage', ''),
    permissibleCoverage: get(building, 'permissible_coverage', ''),
    parkingBaysLettable: get(building, 'parking_bays_lettable', ''),
    parkingBaysPublic: get(building, 'parking_bays_public', ''),
    spaceIds: get(building, 'space_id', ''),
    parentIds: get(building, 'parent_id', ''),
    type: get(building, 'type', ''),
    address: get(building, 'address', ''),
    contacts: get(building, 'contacts', ''),
    images: get(building, 'images', ''),
});

export const taskAssigneeModel = (taskAssignee) => ({
    id: get(taskAssignee, 'id', ''),
    createdAt: get(taskAssignee, 'created_at', ''),
    name: get(taskAssignee, 'name', ''),
    role: get(taskAssignee, 'role', ''),
    businessId: get(taskAssignee, 'business_id', ''),
    tenantId: get(taskAssignee, 'tenant_id', ''),
    active: get(taskAssignee, 'active', ''),
});
