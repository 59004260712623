import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Pane, ThemeProvider } from 'evergreen-ui';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppRoutes from './router/routes';
import store from './reducers/store';
import theme from './theme/theme';
import UploadManager from './components/organisms/upload-manager/upload-manager';
import { UploadManagerProvider } from './context/upload-manager-context';

import AuthStateLoader from './components/auth/AuthStateLoader';

function App() {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.type = 'text/javascript';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <ThemeProvider value={theme}>
            <Provider store={store}>
                <UploadManager>
                    <UploadManagerProvider>
                        <AuthStateLoader>
                            <Pane display="flex" width="100%" height="100%">
                                <RouterProvider router={AppRoutes} />
                            </Pane>
                        </AuthStateLoader>
                    </UploadManagerProvider>
                </UploadManager>
                <ToastContainer />
            </Provider>
        </ThemeProvider>
    );
}

export default App;
