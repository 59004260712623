import React, { useMemo, useCallback } from 'react';
import {
    ArrowRightIcon,
    Button,
    Card,
    Heading,
    Pane,
    Paragraph,
    Text,
} from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import AssetProgressBar from './asset-progress-bar';
import {
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from '../../../reducers/asset-reducer/asset.reducer';
import { BuildingImage } from '../../molecules';
import { notificationsSelector } from '../../../reducers/notifications-reducer/notifications.reducer';

const AssetCard = ({ asset, showAssetDetails, handleOpenUnarchive }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { notificationsPerBuilding } = useSelector(notificationsSelector);

    const { buildings, tenants } = useMemo(() => {
        const filtered = asset.children?.reduce(
            (acc, child) => {
                if (child.type === 'building') {
                    acc.buildings.push(child);
                } else if (child.type === 'tenant') {
                    acc.tenants.push(child);
                }
                return acc;
            },
            { buildings: [], tenants: [] }
        ) || { buildings: [], tenants: [] };

        return filtered;
    }, [asset.children]);

    const currentNotifications = useMemo(
        () => notificationsPerBuilding[asset.id] || 0,
        [asset, notificationsPerBuilding]
    );

    const featuredImage = useMemo(() => {
        if (_.size(asset.images) <= 0) {
            return null;
        }

        return _.find(asset.images, (image) => image.isFeatured);
    }, [asset]);

    const selectAsset = async () => {
        let url = '/portfolio?';
        if (asset.type === 'park') {
            dispatch(setCurrentAssetAction(asset));
            dispatch(setCurrentBuildingAction(null));
            dispatch(setCurrentTenantAction(null));
            url += `currentAssetId=${asset.id}`;
        } else if (asset.type === 'building') {
            dispatch(setCurrentAssetAction(null));
            dispatch(setCurrentBuildingAction(asset));
            dispatch(setCurrentTenantAction(null));
            url += `currentBuildingId=${asset.id}`;
        } else if (asset.type === 'tenant') {
            dispatch(setCurrentAssetAction(null));
            dispatch(setCurrentBuildingAction(null));
            dispatch(setCurrentTenantAction(asset));
            url += `currentTenantId=${asset.id}`;
        }
        navigate(url);
    };

    const handleShowDetails = useCallback(
        (e) => showAssetDetails(e, asset),
        [showAssetDetails, asset]
    );

    const buttonContent = useMemo(() => {
        if (asset.assetArchive) {
            if (asset.assetUnArchiving) {
                return (
                    <Pane
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        paddingTop={20}
                    >
                        <Button appearance="primary" height={40} marginTop={12}>
                            <Text
                                fontWeight="500"
                                color="white"
                                marginRight={6}
                            >
                                Restoring
                            </Text>
                        </Button>
                    </Pane>
                );
            }
            return (
                <Pane
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    paddingTop={20}
                >
                    <Button
                        appearance="primary"
                        height={40}
                        marginTop={12}
                        onClick={handleOpenUnarchive}
                    >
                        <Text fontWeight="500" color="white" marginRight={6}>
                            Unarchive
                        </Text>
                    </Button>
                </Pane>
            );
        }
        return (
            <Pane
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                paddingTop={20}
            >
                <Button
                    appearance="minimal"
                    height={40}
                    marginTop={12}
                    onClick={handleShowDetails}
                >
                    <Text fontWeight="700" color="#8F95B2" marginRight={6}>
                        View Info
                    </Text>
                    <ArrowRightIcon color="#8F95B2" />
                </Button>
            </Pane>
        );
    }, [
        asset.assetArchive,
        asset.assetUnArchiving,
        handleOpenUnarchive,
        handleShowDetails,
    ]);

    return (
        <Card
            elevation={3}
            backgroundColor={
                asset.assetArchive ? 'rgba(240, 240, 240, 0.7)' : 'white'
            }
            borderRadius={10}
            maxWidth={318}
            width="100%"
            cursor={asset.assetArchive ? 'default' : 'pointer'}
            onClick={asset.assetArchive ? undefined : () => selectAsset(asset)}
            hoverElevation={4}
            position="relative"
        >
            {!!currentNotifications && (
                <Pane
                    borderRadius={9999}
                    background="red"
                    position="absolute"
                    top={-4}
                    right={-4}
                    padding={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={14}
                    height={14}
                    zIndex={10}
                >
                    <Text fontSize={12} color="white">
                        {currentNotifications}
                    </Text>
                </Pane>
            )}

            <Pane padding="1rem">
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Heading
                        size={500}
                        fontSize={16}
                        fontWeight="700"
                        color={asset.assetArchive ? '#A0A0A0' : '#252C32'}
                        width="100%"
                    >
                        {asset.assetName}
                    </Heading>
                    <Text
                        border="2px #5dc091 solid"
                        backgroundColor={
                            asset.assetArchive
                                ? 'rgba(240, 240, 240, 0.7)'
                                : 'white'
                        }
                        color={asset.assetArchive ? '#A0A0A0' : '#5dc091'}
                        fontWeight="700"
                        paddingX={8}
                        paddingY={3}
                        borderRadius={5}
                        fontSize={12}
                        textTransform="capitalize"
                    >
                        {asset.type}
                    </Text>
                </Pane>
                <Text size={300} color="#9B9B9B" fontSize={12}>
                    {asset.address.addressLine1}, {asset.address.suburb}
                </Text>
            </Pane>
            <BuildingImage
                image={featuredImage}
                width={318}
                height={318 * (2 / 3)}
                asset={asset}
            />
            <Pane paddingX={16} paddingY={16}>
                <Paragraph fontSize={12} fontWeight="700" color="#474D66">
                    Total GLA:&emsp;
                    <Text fontSize={14} color="#474D66" fontWeight={400}>
                        {asset.grossLettableArea ? (
                            <>{asset.grossLettableArea}m&sup2;</>
                        ) : (
                            ''
                        )}
                    </Text>
                </Paragraph>
                <Paragraph
                    fontSize={12}
                    fontWeight="700"
                    color="#474D66"
                    display={asset.type === 'park' && 'flex'}
                >
                    {asset.type === 'park' && buildings.length >= 0 && (
                        <Pane>
                            <Text
                                fontSize={14}
                                color="#474D66"
                                fontWeight={400}
                            >
                                Buildings: {buildings.length}
                            </Text>
                        </Pane>
                    )}
                    {(asset.type === 'park' || asset.type === 'building') && (
                        <Pane>
                            {tenants.length > 0 ? (
                                <Text
                                    paddingLeft={asset.type === 'park' ? 80 : 0}
                                    fontSize={14}
                                    color="#474D66"
                                    fontWeight={400}
                                >
                                    Tenants: {tenants.length}
                                </Text>
                            ) : (
                                asset.type === 'building' && (
                                    <Text>Tenants: 0</Text>
                                )
                            )}
                        </Pane>
                    )}
                    {asset.type === 'tenant' && <br />}
                </Paragraph>
                <Pane display="flex" alignItems="center" marginY={5} gap={5}>
                    <Text
                        backgroundColor="#EDEFF5"
                        color={asset.assetArchive ? '#A0A0A0' : '#474D66'}
                        fontWeight="400"
                        paddingX={8}
                        paddingY={3}
                        borderRadius={5}
                        fontSize={12}
                    >
                        {asset.portfolioName}
                    </Text>
                </Pane>
                <AssetProgressBar asset={asset} />
                {buttonContent}
            </Pane>
        </Card>
    );
};

AssetCard.propTypes = {
    asset: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        assetName: PropTypes.string,
        assetArchive: PropTypes.bool,
        assetUnArchiving: PropTypes.bool,
        address: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        progress: PropTypes.shape({}),
        portfolioName: PropTypes.string,
        numberOfTenants: PropTypes.number,
        grossLettableArea: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.shape()),
        type: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        parentId: PropTypes.number.isRequired,
    }).isRequired,
    showAssetDetails: PropTypes.func.isRequired,
    handleOpenUnarchive: PropTypes.func.isRequired,
};

export default AssetCard;
