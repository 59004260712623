import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    CrossIcon,
    Dialog,
    Heading,
    Pane,
    Paragraph,
    SelectField,
    Text,
    Textarea,
} from 'evergreen-ui';
import styled from 'styled-components';
import _ from 'lodash';
import { useFilePicker } from 'use-file-picker';
import { useSelector, useDispatch } from 'react-redux';

import { FileDragAndDrop, Image } from '../atoms';
import { colors } from '../../theme/theme';
import { useUploadManager } from '../../context/upload-manager-context';
import documentUpload from '../../assets/images/illustrations/document-upload-dialog.svg';
import getFileTypeIcon from '../../helpers/get-file-type-icon';
import { getFileType } from '../../helpers/get-file-type';
import { contactsSelector } from '../../reducers/contacts-reducer/contacts.reducer';
import { getBusinessContacts } from '../../reducers/contacts-reducer/contacts.actions';
import {
    businessContactModel,
    complianceItemDocumentsModel,
} from '../../models';
import { SelectedUploadRequest } from '../../pages/upload-request/selected-upload-request-context';
import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import { SelectedComplianceItem } from '../../pages/asset-pages/selected-complianceI-item-context';
import { userSelector } from '../../reducers/user-reducer/user.reducer';
import { setAssetIdAction } from '../../reducers/upload-manager/upload-manager.reducer';
import {
    getAsset,
    getBuilding,
    getTenant,
} from '../../reducers/asset-reducer/asset.actions';
import { documentService } from '../../services';

const UploadManagerDialog = ({
    isShown,
    setIsShown,
    isRequestedUpload,
    requestedUploadBucketId,
    requestedUploadComplianceTypeId,
    requestedUploadContactsBusinessStaffId,
    requestedUploadUserId,
    requestedUploadContactsBusinessId,
    requestedUploadRequestType,
    requestedUploadMaintenanceItemId,
    requestedUploadOnCompleted,
}) => {
    const { selectedUploadRequest } = useContext(SelectedUploadRequest);
    const { enqueueUploads } = useUploadManager();
    const dispatch = useDispatch();
    const { businessContacts } = useSelector(contactsSelector);
    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);
    const { selectedComplianceItem, setSelectedComplianceItemDocuments } =
        useContext(SelectedComplianceItem);
    const { user } = useSelector(userSelector);

    const [openFileSelector, { plainFiles }] = useFilePicker({
        accept: '*',
        multiple: true,
        readFilesContent: true,
    });

    const [isFilesSelected, setIsFilesSelected] = useState();
    const [files, setFiles] = useState(null);
    const [comment, setComment] = useState('');
    const [selectedContactsBusiness, setSelectedContactsBusiness] =
        useState('');

    const asset = useMemo(
        () => currentTenant || currentBuilding || currentAsset,
        [currentTenant, currentBuilding, currentAsset]
    );

    const fileType = useMemo(() => {
        if (!files) {
            return null;
        }

        if (_.size(files) > 1) {
            return 'batch';
        }

        return getFileType(files[0]);
    }, [files]);

    useEffect(() => {
        if (_.size(plainFiles) > 0) {
            setFiles(plainFiles);
            setIsFilesSelected(true);
        }
    }, [plainFiles]);

    const closeDialog = useCallback(() => {
        setIsShown(false);
        setSelectedContactsBusiness(null);
    }, [setIsShown]);

    const handleContactsChange = ({ target }) => {
        const businessContact = businessContacts.find(
            (_businessContact) =>
                Number(_businessContact.id) === Number(target.value)
        );
        if (businessContact) {
            setSelectedContactsBusiness(businessContact);
        }
    };

    const onCompleted = useCallback(async () => {
        if (currentTenant?.id) {
            await dispatch(getTenant(currentTenant.id));
        }
        if (currentBuilding?.id) {
            await dispatch(getBuilding(currentBuilding?.id));
        }
        if (asset.id) {
            await dispatch(getAsset(asset?.id, false, true));
        }
        const documents = complianceItemDocumentsModel(
            await documentService.getComplianceTypeDocuments(
                selectedComplianceItem?.id
            )
        );
        const thumbnailIds = documents.reduce((acc, document) => {
            if (document.thumbnailKey) {
                acc.push({
                    assetId: asset.bucketId,
                    fileId: document.thumbnailId,
                    fileKey: document.thumbnailKey,
                });
            }
            return acc;
        }, []);

        const thumbnailUrls = await documentService.getFilesDownloadLink(
            thumbnailIds
        );

        Object.keys(thumbnailUrls).forEach((key) => {
            const document = documents.find((doc) => doc.thumbnailId === key);
            if (document) {
                document.fileThumbnailLink = thumbnailUrls[key];
            }
        });

        setSelectedComplianceItemDocuments(documents);
    }, [
        asset,
        currentBuilding,
        currentTenant,
        dispatch,
        selectedComplianceItem,
        setSelectedComplianceItemDocuments,
    ]);

    useEffect(() => {
        if (selectedUploadRequest) {
            return;
        }
        dispatch(getBusinessContacts());
    }, [dispatch, selectedUploadRequest]);

    const addDocumentsToQueue = useCallback(async () => {
        closeDialog(false);
        if (isRequestedUpload) {
            enqueueUploads(files, {
                complianceTypeId: requestedUploadComplianceTypeId,
                assetId: requestedUploadBucketId,
                comment,
                contactsBusinessId: requestedUploadContactsBusinessId,
                contactsBusinessStaffId: requestedUploadContactsBusinessStaffId,
                maintenanceItemId: requestedUploadMaintenanceItemId,
                userId: requestedUploadUserId,
                requestType: requestedUploadRequestType,
                onCompleted: requestedUploadOnCompleted,
            });

            return;
        }
        await dispatch(setAssetIdAction(asset?.id));
        enqueueUploads(files, {
            complianceTypeId: selectedComplianceItem?.id,
            assetId: asset?.bucketId,
            comment,
            contactsBusinessId: selectedContactsBusiness?.id,
            userId: user?.id,
            requestType: 'compliance',
            onCompleted,
        });
    }, [
        dispatch,
        asset,
        closeDialog,
        enqueueUploads,
        files,
        selectedComplianceItem,
        comment,
        selectedContactsBusiness,
        user,
        onCompleted,
        isRequestedUpload,
        requestedUploadBucketId,
        requestedUploadComplianceTypeId,
        requestedUploadContactsBusinessStaffId,
        requestedUploadUserId,
        requestedUploadContactsBusinessId,
        requestedUploadRequestType,
        requestedUploadMaintenanceItemId,
        requestedUploadOnCompleted,
    ]);

    const onFilesDropped = (droppedFiles) => {
        setFiles(Object.values(droppedFiles));
        setIsFilesSelected(true);
    };

    const setCommentValue = (event) => {
        setComment(event.target.value);
    };

    useEffect(() => {
        if (selectedUploadRequest) {
            setSelectedContactsBusiness(
                businessContactModel(selectedUploadRequest.contact.business)
            );
        }
    }, [selectedUploadRequest]);

    const renderDropZone = () => {
        if (isFilesSelected) {
            return null;
        }

        return (
            <>
                <Image
                    src={documentUpload}
                    alt="document upload illustration"
                    maxWidth="150px"
                    marginY={12}
                />

                <Heading>
                    Drop your documents here, or{' '}
                    <BrowseButton onClick={openFileSelector}>
                        browse
                    </BrowseButton>
                </Heading>
                <Paragraph color={colors.gray500}>
                    Supports any file type.
                </Paragraph>
            </>
        );
    };

    const renderComment = () => {
        if (!isFilesSelected) {
            return null;
        }

        return (
            <Pane>
                <Pane
                    display="flex"
                    flexDirection="column"
                    marginY={12}
                    gap={12}
                >
                    {!selectedUploadRequest && (
                        <SelectField
                            name="businessContact"
                            label="Select a Business Contact (Optional)"
                            value={selectedContactsBusiness?.id}
                            onChange={handleContactsChange}
                            marginBottom={0}
                            disabled={!!selectedUploadRequest}
                        >
                            <option value="" selected disabled>
                                Select a Business
                            </option>
                            {businessContacts.length &&
                            !selectedUploadRequest ? (
                                businessContacts.map((business) => {
                                    const { id, name } =
                                        businessContactModel(business);
                                    return (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    );
                                })
                            ) : (
                                <option value={selectedContactsBusiness.id}>
                                    {selectedContactsBusiness.name}
                                </option>
                            )}
                        </SelectField>
                    )}
                </Pane>
                <Textarea
                    placeholder={`Add any extra comments that will be attached to the document${
                        _.size(files) > 1 ? 's' : ''
                    } upload`}
                    onChange={setCommentValue}
                    width="100%"
                    height={60}
                    minHeight={60}
                    rows={2}
                    marginBottom={6}
                    resize="vertical"
                />
                <Pane display="flex" width="100%" flexDirection="row-reverse">
                    <Button
                        appearance="primary"
                        height={40}
                        onClick={addDocumentsToQueue}
                    >
                        Start Upload
                    </Button>
                </Pane>
            </Pane>
        );
    };

    const renderUploadComment = () => {
        if (!isFilesSelected) {
            return null;
        }

        return (
            <Pane display="flex" flexDirection="column" width="100%">
                <Pane
                    display="flex"
                    flexDirection="row"
                    flex={1}
                    width="100%"
                    height="100%"
                >
                    <Image
                        src={getFileTypeIcon(fileType)}
                        width={_.size(files) > 1 ? 75 : 50}
                    />
                    <Pane
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        justifyContent="center"
                        marginLeft={18}
                    >
                        <Text
                            size={500}
                            color={colors.gray500}
                            marginBottom={8}
                        >
                            {_.size(files) > 1
                                ? 'Batch uploading files'
                                : _.get(files[0], 'name')}
                        </Text>
                    </Pane>
                </Pane>
                {renderComment()}
            </Pane>
        );
    };

    return (
        <Dialog
            isShown={isShown}
            onCloseComplete={closeDialog}
            hasHeader={false}
            hasFooter={false}
            contentContainerProps={{
                padding: 0,
            }}
            topOffset="auto"
        >
            <>
                <CloseButton
                    position="fixed"
                    right={-32}
                    top={-32}
                    borderRadius={16}
                    height={32}
                    width={32}
                    onClick={closeDialog}
                >
                    <CrossIcon size={16} color="white" />
                </CloseButton>
                <FileDragAndDrop onFilesDropped={onFilesDropped}>
                    {({ dragging }) => (
                        <Pane
                            margin={24}
                            borderWidth={3}
                            borderRadius={5}
                            borderStyle={
                                dragging || _.size(plainFiles) > 0
                                    ? 'solid'
                                    : 'dashed'
                            }
                            borderColor={
                                dragging || _.size(plainFiles) > 0
                                    ? colors.secondary500
                                    : colors.gray500
                            }
                            display="flex"
                            flexDirection="column"
                            flex={1}
                            padding={24}
                            alignItems="center"
                        >
                            {isFilesSelected
                                ? renderUploadComment()
                                : renderDropZone()}
                        </Pane>
                    )}
                </FileDragAndDrop>
            </>
        </Dialog>
    );
};

UploadManagerDialog.propTypes = {
    isShown: PropTypes.bool.isRequired,
    isRequestedUpload: PropTypes.bool,
    requestedUploadBucketId: PropTypes.string,
    requestedUploadComplianceTypeId: PropTypes.string,
    requestedUploadContactsBusinessStaffId: PropTypes.string,
    requestedUploadContactsBusinessId: PropTypes.string,
    requestedUploadUserId: PropTypes.string,
    requestedUploadRequestType: PropTypes.string,
    requestedUploadMaintenanceItemId: PropTypes.string,
    requestedUploadOnCompleted: PropTypes.func,

    setIsShown: PropTypes.func.isRequired,
};

UploadManagerDialog.defaultProps = {
    isRequestedUpload: false,
    requestedUploadBucketId: null,
    requestedUploadComplianceTypeId: null,
    requestedUploadContactsBusinessStaffId: null,
    requestedUploadContactsBusinessId: null,
    requestedUploadUserId: null,
    requestedUploadRequestType: null,
    requestedUploadMaintenanceItemId: null,
    requestedUploadOnCompleted: null,
};

export default UploadManagerDialog;

const BrowseButton = styled.span`
    cursor: pointer;
    color: ${colors.secondary500};
    tab-index: 1;
`;

const CloseButton = styled(Button)`
    border: none;
    background-color: rgba(255, 255, 255, 0.4);
    transition: background-color 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    tab-index: 2;

    &:hover {
        background-color: rgba(255, 255, 255, 0.6) !important;
        border: none;
    }

    &:focus {
        box-shadow: none !important;
    }
`;
