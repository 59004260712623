import { authService } from '../../services';
import cleanAuthState from './clean-auth-state.actions';
import {
    setEmailAction,
    setFetchAuthTokenError,
    setIsAuthenticatedAction,
    setIsFetchingAuthToken,
    setMaintenanceAction,
} from './auth.reducer';

export function register(registerModel) {
    return () => authService.register(registerModel);
}

export function login(loginModel) {
    return (dispatch) => {
        dispatch(setFetchAuthTokenError(null));
        dispatch(setEmailAction(loginModel.email));
        return authService.login(loginModel);
    };
}

export function getAuthToken(magicToken, shouldConfirmEmail, setSearchParams) {
    return async (dispatch) => {
        dispatch(setIsFetchingAuthToken(true));

        try {
            await authService.getAuthToken(magicToken, shouldConfirmEmail);
            dispatch(setFetchAuthTokenError(null));
            dispatch(setIsAuthenticatedAction(true));
            setSearchParams({});
        } catch (error) {
            dispatch(
                setFetchAuthTokenError(
                    shouldConfirmEmail
                        ? 'The link is no longer valid, please sign up again'
                        : 'Invalid link, please try logging in again.'
                )
            );
        } finally {
            dispatch(setIsFetchingAuthToken(false));
        }
    };
}

export function logout(setSessionDidExpire) {
    return (dispatch) => {
        authService.logout();
        dispatch(cleanAuthState(setSessionDidExpire));
    };
}

export function refreshAuthToken() {
    return async (dispatch) => {
        dispatch(setIsFetchingAuthToken(true));

        try {
            await authService.refreshAuthToken();
            dispatch(setFetchAuthTokenError(null));
            dispatch(setIsAuthenticatedAction(true));
        } catch (error) {
            dispatch(setFetchAuthTokenError('Failed to refresh auth token.'));
        } finally {
            dispatch(setIsFetchingAuthToken(false));
        }
    };
}

export function getSystemStatus() {
    return async (dispatch) => {
        const { under_maintenance: isUnderMaintenance, message } =
            await authService.getSystemStatus();
        dispatch(setMaintenanceAction({ isUnderMaintenance, message }));
    };
}
