import React, { useMemo } from 'react';
import { Pane } from 'evergreen-ui';
import { Outlet as RouterOutlet } from 'react-router-dom';

import { useUserAccess } from '../../context/user-access-context';

function Outlet() {
    const { modules } = useUserAccess();

    const canViewBuildings = useMemo(() => modules.info, [modules]);

    return (
        <Pane
            display="flex"
            marginTop={canViewBuildings ? 144 : 64}
            height={`calc(100vh - ${canViewBuildings ? 144 : 64}px)`}
            paddingLeft={85}
            width="100%"
            justifyContent="center"
            overflowY="auto"
        >
            <RouterOutlet />
        </Pane>
    );
}

export default Outlet;
