import networkService from '../network-service/network.service';
import imageUrls from './images-service-urls';
import {
    imageLinkRequestModel,
    setImageUploadSuccessfullyApiModel,
} from '../../../models';

function getImageUploadUrl(linkRequest, controller) {
    const url = imageUrls.getImageUploadUrl();
    const data = imageLinkRequestModel(linkRequest);
    const config = {
        signal: controller.signal,
    };
    return networkService.makePost(url, data, config);
}

function setImageUploadedSuccessfully(successfulUpload) {
    const url = imageUrls.imageUploadSuccessUrl();
    const data = setImageUploadSuccessfullyApiModel(successfulUpload);
    return networkService.makePost(url, data);
}

function getImageDownloadLink({ assetId, imageId }) {
    const url = imageUrls.getImageDownloadLinkUrl();
    const config = { params: { image_id: imageId, asset_id: assetId } };
    return networkService.makeGet(url, config);
}

export default {
    getImageUploadUrl,
    setImageUploadedSuccessfully,
    getImageDownloadLink,
};
