import React from 'react';
import {
    Card,
    Text,
    Avatar,
    Pane,
    PhoneIcon,
    EnvelopeIcon,
    EditIcon,
    TrashIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function ContactCard({
    id,
    firstName,
    lastName,
    profession,
    mobileNumber,
    email,
    registrationNumber,
    businessId,
    handleEditStaffContact,
    openConfirmModal,
    rights,
}) {
    return (
        <StyledCard
            width="100%"
            border="1px solid #c2c4d6"
            padding={10}
            alignItems="center"
            cursor="pointer"
            className="contact-card"
            display="flex"
            gap={15}
            marginRight={10}
        >
            <Avatar name={`${firstName} ${lastName}`} size={40} />
            <Text
                width="20%"
                fontWeight={700}
            >{`${firstName} ${lastName}`}</Text>
            <Text width="17%">{profession}</Text>
            <Pane display="flex" alignItems="center" gap={10} width="25%">
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding={6}
                >
                    <EnvelopeIcon color="#696f8c" />
                </Pane>
                <Text>{email}</Text>
            </Pane>
            <Pane display="flex" alignItems="center" gap={10} width="15%">
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding={6}
                >
                    <PhoneIcon color="#696f8c" />
                </Pane>
                <Text>{mobileNumber}</Text>
            </Pane>
            {(rights?.update || rights?.delete) && (
                <Pane display="flex" gap={5} marginLeft="auto">
                    {rights?.update && (
                        <Pane display="flex" gap={10}>
                            <Pane
                                alignItems="center"
                                justifyContent="center"
                                padding={12}
                                className="edit-contact"
                                hoverElevation={1}
                                role="button"
                                onClick={() =>
                                    handleEditStaffContact({
                                        id,
                                        firstName,
                                        lastName,
                                        profession,
                                        mobileNumber,
                                        email,
                                        registrationNumber,
                                        businessContactId: businessId,
                                    })
                                }
                            >
                                <EditIcon
                                    color="#696f8c"
                                    className="edit-contact"
                                />
                            </Pane>
                        </Pane>
                    )}
                    {rights?.delete && (
                        <Pane display="flex" gap={10}>
                            <Pane
                                alignItems="center"
                                justifyContent="center"
                                padding={12}
                                className="delete-contact"
                                hoverElevation={1}
                                role="button"
                                onClick={() =>
                                    openConfirmModal({
                                        id,
                                        firstName,
                                        lastName,
                                        profession,
                                        mobileNumber,
                                        email,
                                        registrationNumber,
                                        businessId,
                                        handleEditStaffContact,
                                        openConfirmModal,
                                        isStaff: true,
                                    })
                                }
                            >
                                <TrashIcon
                                    color="#c60b0b"
                                    className="delete-contact"
                                />
                            </Pane>
                        </Pane>
                    )}
                </Pane>
            )}
        </StyledCard>
    );
}

const StyledCard = styled(Card)`
    .edit-contact,
    .delete-contact {
        display: none;
        margin-left: auto;
        border-radius: 4px;
        background-color: #f5f6fa;
    }

    &:hover {
        .edit-contact {
            display: flex;
        }
        .delete-contact {
            display: flex;
        }
    }
`;

ContactCard.propTypes = {
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    mobileNumber: PropTypes.string.isRequired,
    profession: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    registrationNumber: PropTypes.string.isRequired,
    businessId: PropTypes.string.isRequired,
    handleEditStaffContact: PropTypes.func.isRequired,
    openConfirmModal: PropTypes.func.isRequired,
    rights: PropTypes.shape({
        create: PropTypes.bool,
        read: PropTypes.bool,
        update: PropTypes.bool,
        delete: PropTypes.bool,
    }).isRequired,
};

export default ContactCard;
