function readFileAsync(file, type) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        if (type === 'Text') {
            reader.readAsText(file);
        } else if (type === 'DataUrl') {
            reader.readAsDataURL(file);
        } else if (type === 'BinaryString') {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        }
    });
}

export default readFileAsync;
