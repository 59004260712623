import axios from 'axios';
import readFileAsync from '../../../helpers/read-file-async';

const uploadFile = async (file, uploadUrl, controller, onUploadProgress) => {
    const binaryData = await readFileAsync(file);

    return axios.put(uploadUrl, binaryData, {
        headers: {
            'Content-Type': file.type,
        },
        signal: controller.signal,
        onUploadProgress,
    });
};

const uploadBase64Data = async (
    data,
    dataFileType,
    uploadUrl,
    controller,
    onUploadProgress
) => {
    const blob = await (await fetch(data)).blob();

    return axios.put(uploadUrl, blob, {
        headers: {
            'Content-Type': dataFileType,
        },
        signal: controller.signal,
        onUploadProgress,
    });
};

export default {
    uploadFile,
    uploadBase64Data,
};
