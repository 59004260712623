import React, { useEffect, useMemo } from 'react';
import { SideSheet, Pane, Heading } from 'evergreen-ui';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../theme/theme';
import AddTaskForm from './add-task-form';
import UpdateTaskForm from './update-task-form';
import { taskSelector } from '../../reducers/task-reducer/task.reducer';
import TaskAttachments from './task-attachments';
import {
    getTasksCategories,
    getAvailableAssignees,
    getAvailableBuildings,
} from '../../reducers/task-reducer/task.actions';
import EditTaskForm from './edit-task-form';
import { userSelector } from '../../reducers/user-reducer/user.reducer';

function TaskSideSheet({
    isShown,
    isUpdating,
    isEditing,
    isViewingDocuments,
    close,
    task,
}) {
    const dispatch = useDispatch();

    const { categories, assignees, properties, taskToView } =
        useSelector(taskSelector);
    const { user } = useSelector(userSelector);

    const memoAssignees = useMemo(() => {
        const newAssignees = assignees.map(({ user_id: userId, name }) => ({
            id: userId,
            name,
        }));
        const isUserAssignee =
            assignees.findIndex(
                (assignee) => Number(assignee.user_id) === Number(user.id)
            ) >= 0;
        if (!isUserAssignee) {
            newAssignees.push({
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
            });
        }
        return newAssignees;
    }, [assignees, user]);

    const heading = useMemo(() => {
        if (isUpdating) {
            return `Update ${task?.description || 'Task'}`;
        }
        if (isEditing) {
            return `Edit ${task?.description || 'Task'}`;
        }
        if (isViewingDocuments) {
            return `${task?.description || 'Task'} - Attachments`;
        }
        if (!isEditing && !isUpdating) {
            return 'Add Task';
        }
        return '';
    }, [isEditing, isUpdating, isViewingDocuments, task?.description]);

    useEffect(() => {
        Promise.all([
            dispatch(getTasksCategories()),
            dispatch(getAvailableAssignees()),
            dispatch(getAvailableBuildings()),
        ]);
    }, [dispatch]);

    const renderForm = () => {
        if (isEditing) {
            return (
                <EditTaskForm
                    close={close}
                    categories={categories}
                    properties={properties}
                    assignees={memoAssignees}
                    task={task}
                    user={user}
                />
            );
        }
        if (isUpdating) {
            return (
                <UpdateTaskForm
                    close={close}
                    categories={categories}
                    properties={properties}
                    assignees={memoAssignees}
                    task={task}
                    user={user}
                />
            );
        }
        if (isViewingDocuments) {
            return <TaskAttachments task={taskToView} close={close} />;
        }

        return (
            <AddTaskForm
                close={close}
                categories={categories}
                properties={properties}
                assignees={memoAssignees}
                user={user}
            />
        );
    };

    return (
        <SideSheet
            isShown={isShown}
            onCloseComplete={close}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
            }}
            preventBodyScrolling
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
            width={450}
        >
            <Pane>
                <Heading
                    width="max-content"
                    fontSize={22}
                    fontWeight="700"
                    color={colors.primary500}
                    marginLeft={24}
                    marginTop={24}
                >
                    {heading}
                </Heading>
                <div className="divider mb-0" />
                <Pane
                    paddingTop={20}
                    paddingX={24}
                    overflowY="auto"
                    background={colors.tint1}
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    maxHeight="calc(100vh - 66px)"
                >
                    {renderForm()}
                </Pane>
            </Pane>
        </SideSheet>
    );
}

TaskSideSheet.propTypes = {
    close: PropTypes.func.isRequired,
    task: PropTypes.shape({
        description: PropTypes.string,
    }),
    isShown: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isViewingDocuments: PropTypes.bool.isRequired,
};

TaskSideSheet.defaultProps = {
    task: null,
};

export default TaskSideSheet;
