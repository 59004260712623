import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Heading, Pane, SideSheet } from 'evergreen-ui';
import PropTypes from 'prop-types';

import { colors } from '../../../theme/theme';
import RegisterAssetForm from './asset-register-form';
import MaintenanceCategoriesModal from '../../maintenance/maintenance-categories-modal';
import EditRegisterAssetForm from './edit-asset-form';
import { contactsSelector } from '../../../reducers/contacts-reducer/contacts.reducer';
import { taskSelector } from '../../../reducers/task-reducer/task.reducer';
import { getAllContactsForBusiness } from '../../../reducers/contacts-reducer/contacts.actions';
import '../../../App.css';
import { getAvailableAssignees } from '../../../reducers/task-reducer/task.actions';
import { userSelector } from '../../../reducers/user-reducer/user.reducer';

const AddAssetSidebar = ({ isShown, close, isEditing, itemToEdit }) => {
    const dispatch = useDispatch();
    const [showCategoriesModal, setShowCategoriesModal] = useState(false);

    const { contacts } = useSelector(contactsSelector);
    const { assignees } = useSelector(taskSelector);
    const { user } = useSelector(userSelector);

    const getData = useCallback(async () => {
        dispatch(getAvailableAssignees());
        dispatch(getAllContactsForBusiness());
    }, [dispatch]);

    const assigneeOptions = useMemo(() => {
        let newAssignees = assignees.map(({ user_id: userId, name }) => ({
            value: Number(userId),
            label: `${name}`,
        }));

        const isUserAssignee =
            assignees.findIndex(
                (assignee) => Number(assignee.user_id) === Number(user.id)
            ) >= 0;

        if (!isUserAssignee) {
            newAssignees = [
                ...newAssignees,
                {
                    value: Number(user.id),
                    label: `${user.firstName} ${user.lastName}`,
                },
            ];
        }
        return newAssignees;
    }, [assignees, user]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <>
            <SideSheet
                isShown={isShown}
                onCloseComplete={close}
                containerProps={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column-reverse',
                    overflowY: 'hidden',
                }}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEscapePress={false}
                width={450}
                scrollTop={0}
            >
                <Pane>
                    <Heading
                        width="max-content"
                        fontSize={22}
                        fontWeight="700"
                        color={colors.primary500}
                        marginLeft={24}
                        marginTop={24}
                    >
                        {isEditing ? 'Edit Asset' : 'Add New Asset'}
                    </Heading>
                    <div className="divider mb-0" />
                    <Pane
                        id="form"
                        paddingTop={20}
                        paddingX={24}
                        overflowY="auto"
                        background={colors.tint1}
                        height="100%"
                        maxHeight="calc(100vh - 66px)"
                        display="flex"
                        flexDirection="column"
                    >
                        {isEditing ? (
                            <EditRegisterAssetForm
                                isShown={isShown}
                                close={close}
                                selectedItem={itemToEdit}
                                openCategoriesModal={() =>
                                    setShowCategoriesModal(true)
                                }
                                contacts={contacts}
                                assignees={assigneeOptions}
                            />
                        ) : (
                            <RegisterAssetForm
                                isShown={isShown}
                                close={close}
                                openCategoriesModal={() =>
                                    setShowCategoriesModal(true)
                                }
                                contacts={contacts}
                                assignees={assigneeOptions}
                            />
                        )}
                    </Pane>
                </Pane>
            </SideSheet>
            <MaintenanceCategoriesModal
                close={() => setShowCategoriesModal(false)}
                isShown={showCategoriesModal}
            />
        </>
    );
};

AddAssetSidebar.propTypes = {
    isShown: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    selectedBuilding: PropTypes.shape({ id: PropTypes.number }).isRequired,
    selectedItem: PropTypes.shape({}).isRequired,
    isEditing: PropTypes.bool,
    itemToEdit: PropTypes.shape({}).isRequired,
};

AddAssetSidebar.defaultProps = {
    isEditing: false,
};

export default AddAssetSidebar;
