import React, { useEffect } from 'react';
import {
    Position,
    SideSheet,
    Heading,
    Paragraph,
    Pane,
    Spinner,
    Text,
} from 'evergreen-ui';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { userSelector } from '../../../reducers/user-reducer/user.reducer';
import { Logo } from '../../atoms';
import ContactPersonDetailsForm from '../../forms/contact-forms/contact-person-details-form';
import { contactsService } from '../../../services';
import useQueryParams from '../../../hooks/use-query-params';
import { getAllUploadRequestsWithToken } from '../../../reducers/upload-request-reducer/upload-request.actions';

const ConsultantUpdateDetailsSidebar = ({ isShown, setIsShown }) => {
    const dispatch = useDispatch();
    const { token } = useQueryParams();
    const { user } = useSelector(userSelector);

    useEffect(() => {
        const sidebar = document.getElementsByClassName(
            'consultant-sidebar-container'
        )[0];

        if (!sidebar) {
            return;
        }
        const closeButton = sidebar.parentElement.firstChild;

        if (closeButton.firstChild.nodeName === 'svg') {
            closeButton.remove();
        }
    }, []);

    const submitForm = async (values) => {
        await contactsService.consultantUpdateContact(values);
        setIsShown(false);
        await dispatch(getAllUploadRequestsWithToken(token));
    };

    return (
        <SideSheet
            isShown={isShown}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
            position={Position.LEFT}
            preventBodyScrolling
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                className: 'consultant-sidebar-container',
                minWidth: '620px',
            }}
            width="70%"
        >
            {!user ? (
                <Pane
                    height="100%"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Spinner />
                    <Text marginTop="1rem">Loading...</Text>
                </Pane>
            ) : (
                <Pane margin={40}>
                    <Logo size="small" />
                    <Pane
                        display="flex"
                        flexDirection="column"
                        maxWidth="400px"
                        width="100%"
                        marginTop={40}
                        marginX="auto"
                    >
                        <Heading size={900}>Welcome {user.firstName}!</Heading>
                        <Paragraph>
                            Before uploading the required documents please
                            update your details.
                        </Paragraph>
                        <Pane marginTop={30}>
                            <ContactPersonDetailsForm
                                handleFormSubmission={submitForm}
                                initialValues={user}
                                isBasicDetailsOnly={false}
                            />
                        </Pane>
                    </Pane>
                </Pane>
            )}
        </SideSheet>
    );
};

ConsultantUpdateDetailsSidebar.propTypes = {
    isShown: PropTypes.bool.isRequired,

    setIsShown: PropTypes.func.isRequired,
};

export default ConsultantUpdateDetailsSidebar;
