const portfolioBaseUrl = '/portfolio';
const businessBaseUrl = '/business';

export default {
    getPortfolioByIdUrl: (id) => `${portfolioBaseUrl}/${id}`,
    getPortfoliosForBusinessUrl: (businessId) =>
        `${businessBaseUrl}/portfolio/${businessId}`,
    createPortfolioUrl: () => `${portfolioBaseUrl}`,
    getPortfolioBuildingsUrl: (search) =>
        `buildings?search=${search ? `${encodeURIComponent(search)}` : search}`,
};
