import React, { useContext, useState } from 'react';
import { Button, Heading, Pane, Paragraph } from 'evergreen-ui';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';

import { SelectedUploadRequest } from '../../../pages/upload-request/selected-upload-request-context';
import { colors } from '../../../theme/theme';
import { Image } from '../../atoms';
import documentUpload from '../../../assets/images/illustrations/document-upload.svg';
import { uploadRequestService } from '../../../services';
import { userSelector } from '../../../reducers/user-reducer/user.reducer';
import { getAllUploadRequestsWithToken } from '../../../reducers/upload-request-reducer/upload-request.actions';
import useQueryParams from '../../../hooks/use-query-params';
import { successToast } from '../../toasts';
import UploadManagerDialog from '../../molecules/upload-manager-dialog';

const UploadRequestDocumentUpload = () => {
    const dispatch = useDispatch();
    const { token } = useQueryParams();
    const { user } = useSelector(userSelector);
    const { selectedUploadRequest } = useContext(SelectedUploadRequest);
    const [isUploadDialogShown, setIsUploadDialogShown] = useState(false);
    const showUploadDialog = () => {
        setIsUploadDialogShown(true);
    };

    const uploadSuccess = async () => {
        await uploadRequestService.completeUploadRequest(
            selectedUploadRequest.id
        );
        successToast(
            `Successfully uploaded the ${
                selectedUploadRequest.complianceTypeName || ''
            } document.`
        );
        await dispatch(getAllUploadRequestsWithToken(token));
    };

    if (!selectedUploadRequest || !selectedUploadRequest.id) {
        return null;
    }

    return (
        <Pane height="calc(100vh - 65px - 147px)">
            <Pane
                paddingX={24}
                paddingTop={32}
                paddingBottom={18}
                width="100%"
                backgroundColor={colors.white}
                top={65}
                zIndex={5}
                position="sticky"
                borderBottomColor={colors.neutral500}
                borderBottomWidth={0.5}
                borderBottomStyle="solid"
                height={147}
            >
                <Paragraph
                    size={400}
                    fontSize={14}
                    fontWeight={600}
                    color="#C1C4D6"
                >
                    {selectedUploadRequest.complianceGroupName},{' '}
                    {selectedUploadRequest.complianceCategoryName}
                </Paragraph>
                <Heading fontWeight={700} fontSize={28} marginY={8}>
                    {selectedUploadRequest.complianceTypeName}
                </Heading>
                <Pane display="flex" justifyContent="space-between">
                    <Paragraph
                        size={400}
                        fontSize={14}
                        fontWeight={600}
                        color="#8F95B2"
                    >
                        From: {selectedUploadRequest.requester}
                    </Paragraph>
                    <Paragraph
                        size={400}
                        fontSize={14}
                        fontWeight={600}
                        color="#C1C4D6"
                    >
                        REQUESTED:{' '}
                        {DateTime.fromMillis(
                            selectedUploadRequest.createdAt
                        ).toFormat('EEE, d MMM yyyy ')}{' '}
                        AT{' '}
                        {DateTime.fromMillis(
                            selectedUploadRequest.createdAt
                        ).toFormat('T')}
                    </Paragraph>
                </Pane>
            </Pane>
            <Pane
                display="flex"
                flex={1}
                flexDirection="column"
                backgroundColor={colors.white}
                paddingX={24}
                paddingY={24}
                height="100%"
            >
                {selectedUploadRequest.note && (
                    <Pane>
                        <Heading>
                            Note from {selectedUploadRequest.requester}:
                        </Heading>
                        <Paragraph>{selectedUploadRequest.note}</Paragraph>
                    </Pane>
                )}
                <Pane
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Image
                        src={documentUpload}
                        alt="document upload illustration"
                        maxWidth="200px"
                    />
                    <Button
                        appearance="primary"
                        width={300}
                        height={40}
                        marginTop={30}
                        onClick={showUploadDialog}
                    >
                        Upload Document
                    </Button>
                    {isUploadDialogShown && (
                        <UploadManagerDialog
                            isShown={isUploadDialogShown}
                            setIsShown={setIsUploadDialogShown}
                            uploadSuccess={uploadSuccess}
                            isRequestedUpload
                            requestedUploadBucketId={
                                selectedUploadRequest?.asset?.bucketId
                            }
                            requestedUploadComplianceTypeId={
                                selectedUploadRequest?.complianceTypeId
                            }
                            requestedUploadContactsBusinessStaffId={user?.id}
                            requestedUploadUserId={user?.id}
                            requestedUploadContactsBusinessId={
                                selectedUploadRequest?.contact?.business?.id
                            }
                            requestedUploadRequestType={
                                selectedUploadRequest?.requestType
                            }
                            requestedUploadMaintenanceItemId={
                                selectedUploadRequest?.maintenanceItemId
                            }
                            requestedUploadOnCompleted={uploadSuccess}
                        />
                    )}
                </Pane>
            </Pane>
        </Pane>
    );
};

export default UploadRequestDocumentUpload;
