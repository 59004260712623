import networkService from '../network-service/network.service';
import portfolioUrls from './portfolio-service-urls';
import { portfolioApiModel } from '../../../models';

function getPortfolioById(id) {
    const url = portfolioUrls.getPortfolioByIdUrl(id);
    return networkService.makeGet(url);
}

function getPortfoliosForBusiness(businessId) {
    const url = portfolioUrls.getPortfoliosForBusinessUrl(businessId);
    return networkService.makeGet(url);
}

function createPortfolio(portfolioObject, businessId) {
    const url = portfolioUrls.createPortfolioUrl();
    const data = portfolioApiModel({ ...portfolioObject, businessId });
    return networkService.makePost(url, data);
}

export default {
    getPortfolioById,
    getPortfoliosForBusiness,
    createPortfolio,
};
