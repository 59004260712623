import React, { useState, useCallback, useMemo } from 'react';
import {
    Pane,
    Text,
    Heading,
    Checkbox,
    SelectField,
    Radio,
    Button,
} from 'evergreen-ui';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { assetSelector } from '../../../../reducers/asset-reducer/asset.reducer';

const TENANT_LINKING = ['park', 'building'];

const AssetMigrationLinking = ({ assetDetails, handleMigrationFormSubmit }) => {
    const { parks, buildings } = useSelector(assetSelector);
    const assetType = assetDetails.type === 'building';

    const handleLinkedProperty = useCallback((setValues, values, value) => {
        setValues({
            ...values,
            parentId: !value ? '' : Number(value),
        });
    }, []);

    return (
        <Formik
            initialValues={{
                ...assetDetails,
                portfolioType: assetDetails?.portfolioName,
                linkingTypeArray: assetType
                    ? parks
                    : { park: parks, building: buildings },
            }}
            onSubmit={handleMigrationFormSubmit}
        >
            {({ values, handleSubmit, isSubmitting, setValues }) => (
                <Form style={{ height: '100%' }} onSubmit={handleSubmit}>
                    <Pane
                        marginBottom={16}
                        display="flex"
                        flexDirection="column"
                        height="100%"
                        justifyContent="space-evenly"
                    >
                        {assetType ? (
                            <LinkBuildingTo
                                asset={assetDetails}
                                assetDetails={values}
                                handleLinkedProperty={handleLinkedProperty}
                                setValue={setValues}
                                values={values}
                            />
                        ) : (
                            <LinkTenantTo
                                assetDetails={values}
                                handleLinkedProperty={({ target: { value } }) =>
                                    handleLinkedProperty(
                                        setValues,
                                        values,
                                        value
                                    )
                                }
                            />
                        )}
                    </Pane>
                    <Button
                        appearance="primary"
                        height={40}
                        type="submit"
                        width="100%"
                        marginBottom={32}
                        isLoading={isSubmitting}
                    >
                        Confirm Migration
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

function LinkBuildingTo({
    asset,
    assetDetails,
    handleLinkedProperty,
    setValue,
    values,
}) {
    const [disableLinking, setDisableLinking] = useState(
        Number.isNaN(assetDetails.parentId)
    );
    const assetType = assetDetails.type === 'building';

    const attributes = useMemo(() => {
        const attributesObject = {
            label: '',
            required: false,
        };

        if (assetType) {
            attributesObject.label = 'Link Building to Park';
            attributesObject.required = false;
        }

        return attributesObject;
    }, [assetType]);

    const handleUnlinking = (e) => {
        setDisableLinking(e.target.checked);
        handleLinkedProperty(setValue, values, null);
    };

    const handleLinking = (e) => {
        handleLinkedProperty(setValue, values, e.target.value);
    };

    return (
        <Pane
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            height="100%"
        >
            <Pane
                display="flex"
                flexDirection="row"
                alignItems="center"
                marginBottom={20}
            >
                <Heading marginRight="10px">
                    Current Type: {_.capitalize(assetDetails.type) || ''}
                </Heading>
            </Pane>
            <Text
                lineHeight="25px"
                fontSize="16px"
                display="flex"
                alignItems="center"
                marginBottom={20}
                gap={5}
            >
                {asset.parentId
                    ? `You can link this building to another park by selecting a different park, or you can delink this building from the park by checking the delink option.`
                    : `You can link this building to a park by selecting a park to link to.`}
            </Text>
            <SelectField
                display={assetDetails.type === 'parks' ? 'none' : 'block'}
                width="100%"
                inputHeight={40}
                label={attributes.label}
                isInvalid={false}
                validationMessage={false}
                onChange={handleLinking}
                value={assetDetails.parentId || ''}
                disabled={disableLinking}
            >
                <option disabled value="">
                    {attributes.label}
                </option>
                {assetDetails.type === 'building' &&
                    assetDetails.linkingTypeArray.map(({ id, assetName }) => (
                        <option key={id} value={id}>
                            {disableLinking ? 'None' : assetName}
                        </option>
                    ))}
                {assetDetails.type === 'building' && (
                    <option value="">None</option>
                )}
            </SelectField>
            {asset.parentId ? (
                <Pane
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Text fontWeight={500} color="#000">
                        Delink Building
                    </Text>
                    <Checkbox
                        checked={disableLinking}
                        onChange={handleUnlinking}
                    />
                </Pane>
            ) : null}
        </Pane>
    );
}

function LinkTenantTo({ assetDetails, handleLinkedProperty }) {
    const [propertyType, setPropertyType] = useState('building');

    const attributes = useMemo(
        () => ({
            label:
                propertyType === 'building'
                    ? 'Choose a Building'
                    : 'Choose a Park',
            required: false,
        }),
        [propertyType]
    );

    const selectedValue = useMemo(() => {
        const found = assetDetails.linkingTypeArray[propertyType]?.find(
            (prop) => Number(prop.id) === assetDetails.parentId
        );
        return found ? assetDetails.parentId : '';
    }, [assetDetails.linkingTypeArray, propertyType, assetDetails.parentId]);

    const handTenantTypeChange = (value) => {
        setPropertyType(value);
    };

    return (
        <Pane
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            height="100%"
        >
            <Pane display="flex" flexDirection="row" alignItems="center">
                <Heading marginRight="10px">
                    Current Type: {_.capitalize(assetDetails.type) || ''}
                </Heading>
            </Pane>
            <Text
                lineHeight="25px"
                fontSize="16px"
                display="flex"
                alignItems="center"
                gap={5}
            >
                You can link this Tenant to a Building or you can link the
                Tenant to a Park
            </Text>
            <Pane>
                <Text fontWeight={500} color="#000">
                    Link this Tenant to a Building or Park
                </Text>
                <Pane display="flex" flexDirection="row" alignItems="center">
                    <Text marginRight="10px">To: </Text>
                    {TENANT_LINKING.map((typeValue) => (
                        <Radio
                            checked={propertyType === typeValue}
                            onChange={({ target: { value } }) =>
                                handTenantTypeChange(value)
                            }
                            size={16}
                            value={typeValue}
                            label={_.capitalize(typeValue)}
                            marginRight={10}
                        />
                    ))}
                </Pane>
            </Pane>
            <SelectField
                display={assetDetails.type === 'parks' ? 'none' : 'block'}
                width="100%"
                inputHeight={40}
                label={attributes.label}
                isInvalid={false}
                validationMessage={false}
                onChange={handleLinkedProperty}
                value={selectedValue}
            >
                <option disabled value="">
                    {attributes.label}
                </option>
                {assetDetails.type === 'tenant' &&
                    assetDetails.linkingTypeArray[propertyType].map(
                        ({ id, assetName }) => (
                            <option key={id} value={id}>
                                {assetName}
                            </option>
                        )
                    )}
            </SelectField>
        </Pane>
    );
}

AssetMigrationLinking.propTypes = {
    assetDetails: PropTypes.string.isRequired,
    handleMigrationFormSubmit: PropTypes.func.isRequired,
};

LinkBuildingTo.propTypes = {
    asset: PropTypes.string.isRequired,
    assetDetails: PropTypes.string.isRequired,
    handleLinkedProperty: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    values: PropTypes.string.isRequired,
};

LinkTenantTo.propTypes = {
    assetDetails: PropTypes.string.isRequired,
    handleLinkedProperty: PropTypes.func.isRequired,
};

export default AssetMigrationLinking;
