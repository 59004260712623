import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    cardData: null,
    chartData: null,
    scheduledMaintenanceData: null,
    maintenanceCategoriesGraphData: null,
    assetRegister: [],
    assetRegisterPagination: {},
    categories: [],
    newMaintenanceItemId: null,
    serviceProviders: [],
    dateRange: { startDate: null, endDate: null },
    overviewData: {},
    search: '',
    sort: null,
    page: 1,
    viewAssetSidebar: { asset: null, isShown: false },
    loading: true,
};

const maintenanceSlice = createSlice({
    name: 'maintenance',
    initialState,
    reducers: {
        setIsLoadingAction(state, action) {
            state.isLoading = action.payload;
        },
        setGetCardDataAction(state, action) {
            state.cardData = action.payload;
        },
        setGetScheduledMaintenanceDataAction(state, { payload }) {
            state.scheduledMaintenanceData = payload;
        },
        setGetAssetRegisterPaginationAction(state, { payload }) {
            state.assetRegisterPagination = payload;
            if (state.assetRegisterPagination.items) {
                delete state.assetRegisterPagination.items;
            }
        },
        setGetAssetRegisterDataAction(state, action) {
            state.assetRegister = action.payload.items;
        },
        setGetMaintenanceCategoriesAction(state, { payload }) {
            state.categories = payload;
        },
        setGetMaintenanceCategoriesGraphData(state, { payload }) {
            state.maintenanceCategoriesGraphData = payload;
        },
        setNewMaintenanceItemIdAction(state, { payload }) {
            state.newMaintenanceItemId = payload;
        },
        setServiceProvidersAction(state, { payload }) {
            state.serviceProviders = payload;
        },
        setDateRangeAction(state, { payload }) {
            state.dateRange = payload;
        },
        setOverviewDataAction(state, { payload }) {
            state.overviewData = payload;
        },
        setSearchAction(state, { payload }) {
            state.search = payload;
        },
        setSortAction(state, { payload }) {
            state.sort = payload;
        },
        setPageAction(state, { payload }) {
            state.page = payload;
        },
        setLoadingAction(state, { payload }) {
            state.loading = payload;
        },
        setViewAssetSidebar(state, { payload }) {
            state.viewAssetSidebar = payload;
        },
    },
});

export const {
    setIsLoadingAction,
    setNewMaintenanceItemIdAction,
    setGetCardDataAction,
    setGetMaintenanceCategoriesGraphData,
    setGetChartDataAction,
    setGetScheduledMaintenanceDataAction,
    setGetAssetRegisterDataAction,
    setGetMaintenanceCategoriesAction,
    setSelectedCategoryAction,
    setServiceProvidersAction,
    setGetAssetRegisterPaginationAction,
    setDateRangeAction,
    setOverviewDataAction,
    setSearchAction,
    setSortAction,
    setPageAction,
    setViewAssetSidebar,
    setLoadingAction,
} = maintenanceSlice.actions;

export const maintenanceSelector = (reducers) => reducers.maintenanceReducer;

export default maintenanceSlice.reducer;
