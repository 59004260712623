import React, { useCallback } from 'react';
import { Heading, Pane, SideSheet } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import CreatePortfolioForm from '../../forms/portfolio-forms/create-portfolio-form';
import { portfolioModel } from '../../../models';
import {
    createPortfolio,
    getAllPortfoliosForBusiness,
} from '../../../reducers/portfolio-reducer/portfolio.actions';
import { colors } from '../../../theme/theme';
import { AutoFocusConsumer } from '../../atoms';

const CreatePortfolioSidebarForm = ({ isShown, close }) => {
    const dispatch = useDispatch();

    const submitCreatePortfolioForm = async (portfolioObject) => {
        await dispatch(createPortfolio(portfolioObject));
        await dispatch(getAllPortfoliosForBusiness());
    };

    const onCreatePortfolioSuccess = useCallback(() => {
        close();
    }, [close]);

    return (
        <SideSheet
            isShown={isShown}
            onCloseComplete={close}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
            }}
            preventBodyScrolling
        >
            <AutoFocusConsumer />
            <Pane
                zIndex={1}
                flexShrink={0}
                elevation={0}
                backgroundColor="white"
            >
                <Pane padding={16}>
                    <Heading size={600}>Add Building Use</Heading>
                </Pane>
            </Pane>
            <Pane
                flex="1"
                overflowY="scroll"
                background={colors.tint1}
                padding={16}
            >
                <Pane maxWidth={400} marginX="auto">
                    {isShown && (
                        <CreatePortfolioForm
                            submitForm={submitCreatePortfolioForm}
                            initialValues={portfolioModel()}
                            onSuccess={onCreatePortfolioSuccess}
                        />
                    )}
                </Pane>
            </Pane>
        </SideSheet>
    );
};

CreatePortfolioSidebarForm.propTypes = {
    isShown: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
};

export default CreatePortfolioSidebarForm;
