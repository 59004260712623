import React, { useEffect, useState } from 'react';
import {
    Card,
    ChevronDownIcon,
    ChevronUpIcon,
    CrossIcon,
    Dialog,
    Pane,
    Portal,
    Text,
} from 'evergreen-ui';
import styled from 'styled-components';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import { useUploadManager } from '../../../context/upload-manager-context';
import UploadManagerMenuItem from './upload-manager-menu-item';

const UploadManagerMenu = () => {
    const {
        uploads,
        activeUploadsCount,
        waitingUploadsCount,
        completedUploadsCount,
        failedUploadsCount,
        canceledUploadsCount,
        uploadTimeRemaining,
        cancelAllUploads,
        clearUploadQueue,
        isExpanded,
        setIsExpanded,
    } = useUploadManager();

    const location = useLocation();

    const [isCloseConfirmationDialogShown, setIsCloseConfirmationDialogShown] =
        useState(false);

    useEffect(() => setIsExpanded(false), [location, setIsExpanded]);

    const waitingAndActiveUploadsCount =
        waitingUploadsCount + activeUploadsCount;

    const closeCloseConfirmationDialog = () => {
        setIsCloseConfirmationDialogShown(false);
    };

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const closeMenu = () => {
        if (waitingAndActiveUploadsCount > 0) {
            setIsCloseConfirmationDialogShown(true);
            return;
        }
        clearUploadQueue();
    };

    const cancelUploadsAndCloseMenu = () => {
        cancelAllUploads(true);
        closeCloseConfirmationDialog();
    };

    const getHeaderText = () => {
        if (waitingAndActiveUploadsCount > 0) {
            return `Uploading ${waitingAndActiveUploadsCount} item${
                waitingAndActiveUploadsCount > 1 ? 's' : ''
            }`;
        }

        if (failedUploadsCount > 0) {
            return `${failedUploadsCount} upload${
                failedUploadsCount > 1 ? 's' : ''
            } failed`;
        }

        if (canceledUploadsCount > 0) {
            return `${canceledUploadsCount} upload${
                canceledUploadsCount > 1 ? 's' : ''
            } canceled`;
        }

        return `${completedUploadsCount} upload${
            completedUploadsCount > 1 ? 's' : ''
        } completed`;
    };

    if (_.size(uploads) < 1) {
        return null;
    }

    return (
        <>
            <Portal>
                <Card
                    backgroundColor="white"
                    position="fixed"
                    bottom={0}
                    right={24}
                    elevation={4}
                    width={362}
                    height={isExpanded ? 'auto' : '54px'}
                    borderBottomLeftRadius={0}
                    borderBottomRightRadius={0}
                    zIndex={20}
                >
                    <Pane>
                        <Pane
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            height={54}
                            paddingX={24}
                            backgroundColor="#0C2138"
                            borderTopLeftRadius={4}
                            borderTopRightRadius={4}
                        >
                            <Text color="white" fontWeight="500" fontSize={14}>
                                {getHeaderText()}
                            </Text>
                            <Pane display="flex">
                                <IconButton
                                    marginRight={12}
                                    onClick={toggleExpanded}
                                >
                                    {isExpanded ? (
                                        <ChevronDownIcon
                                            color="white"
                                            size={24}
                                        />
                                    ) : (
                                        <ChevronUpIcon
                                            color="white"
                                            size={24}
                                        />
                                    )}
                                </IconButton>
                                <IconButton onClick={closeMenu}>
                                    <CrossIcon color="white" size={24} />
                                </IconButton>
                            </Pane>
                        </Pane>
                        {waitingAndActiveUploadsCount > 0 && (
                            <Pane
                                height={30}
                                backgroundColor="#f5f5f5"
                                borderBottom="solid 1px #eee"
                                paddingX={18}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text color="rgba(0,0,0, 0)" fontSize={13}>
                                    {uploadTimeRemaining}
                                </Text>
                                <LinkButton
                                    color="#5EC090"
                                    cursor="pointer"
                                    onClick={cancelAllUploads}
                                >
                                    CANCEL
                                </LinkButton>
                            </Pane>
                        )}
                    </Pane>
                    <Pane height="100%" overflowY="scroll" maxHeight={240}>
                        {_.map(_.values(uploads), (upload) => (
                            <UploadManagerMenuItem
                                upload={upload}
                                key={upload.id}
                            />
                        ))}
                    </Pane>
                </Card>
            </Portal>
            <Dialog
                isShown={isCloseConfirmationDialogShown}
                title="Are you sure?"
                intent="danger"
                onCloseComplete={closeCloseConfirmationDialog}
                confirmLabel="Cancel All Uploads"
                onConfirm={cancelUploadsAndCloseMenu}
            >
                Are you sure you want to cancel all uploads?
            </Dialog>
        </>
    );
};

export default UploadManagerMenu;

const IconButton = styled(Pane)`
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;

    &:hover {
        background-color: #24374b;
    }

    &:active {
        background-color: #1d2e3d;
    }
`;

const LinkButton = styled(Text)`
    &:hover {
        text-decoration: underline;
    }
`;
