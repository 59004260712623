import { SelectField, Dialog, Pane, TextInputField, Text } from 'evergreen-ui';
import DatePicker from 'react-datepicker';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RRule } from 'rrule';
import getDate from '../../helpers/get-date';

import DatepickerCustomHeader from '../organisms/date-picker/date-picker-custom-header';
import DailyRecurrenceForm from './daily-recurrence-form';
import WeeklyRecurrenceForm from './weekly-recurrence-form';
import MonthlyRecurrenceForm from './monthly-recurrence-form';
import YearlyRecurrenceForm from './yearly-recurrence-form';

const RECURRENCE_OPTIONS = ['Daily', 'Weekly', 'Monthly', 'Yearly'];
const END_OPTIONS = ['On This Day', 'After', 'No End Date'];

function SchedulerModal({ isShown, setRecurrence, close }) {
    const now = new Date();

    const [rrule, setRrule] = useState(new RRule({}));
    const [recurrenceForm, setRecurrenceForm] = useState({
        dtstart: new Date(),
        freq: 'Daily',
        interval: 1,
        byweekday: [],
        bymonthday: 1,
        bymonth: 1,
        nextServiceDate: null,
        end: {
            option: 'On This Day',
            count: 10,
            until: new Date(now.setMonth(now.getMonth() + 6)),
        },
    });

    const handleChange = ({ target }) => {
        if (!target.name) {
            if (recurrenceForm.byweekday.includes(target.value)) {
                setRecurrenceForm((state) => {
                    const byweekday = state.byweekday.filter(
                        (item) => item !== target.value
                    );
                    return {
                        ...state,
                        byweekday,
                    };
                });
            } else {
                setRecurrenceForm((state) => ({
                    ...state,
                    byweekday: [...state.byweekday, target.value],
                }));
                return;
            }
        } else if (target.name === 'freq' && target.value === 'Yearly') {
            const startDay = recurrenceForm.dtstart.getDate();
            setRecurrenceForm((state) => ({
                ...state,
                freq: target.value,
                interval: 1,
                bymonthday: startDay,
            }));
            return;
        } else if (target.name === 'freq' && target.value === 'Monthly') {
            const startDay = recurrenceForm.dtstart.getDate();
            setRecurrenceForm((state) => ({
                ...state,
                freq: target.value,
                bymonthday: startDay,
            }));
            return;
        }
        setRecurrenceForm((state) => ({
            ...state,
            [target.name]: target.value,
        }));
    };

    const handleStartDate = (date) => {
        setRecurrenceForm((state) => ({
            ...state,
            dtstart: new Date(
                Date.UTC(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    12,
                    0,
                    0,
                    0
                )
            ),
        }));
    };

    const handleEndChange = ({ target }) => {
        if (target.name === 'option') {
            if (target.value === 'After') {
                setRecurrenceForm((state) => ({
                    ...state,
                    end: { [target.name]: target.value, count: 1 },
                }));
            } else {
                setRecurrenceForm((state) => ({
                    ...state,
                    end: { [target.name]: target.value },
                }));
            }
            return;
        }
        setRecurrenceForm((state) => ({
            ...state,
            end: { ...state.end, [target.name]: target.value },
        }));
    };

    const handleEndDate = (date) => {
        setRecurrenceForm((state) => ({
            ...state,
            end: {
                ...state.end,
                until: new Date(
                    Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        12,
                        0,
                        0,
                        0
                    )
                ),
            },
        }));
    };

    const renderNextServiceDate = () => {
        const nextServiceDate = getDate(
            RRule.fromString(rrule.toString()).after(new Date(), false)
        );

        return `${rrule.toText()} ${
            nextServiceDate && `-- Next Service Date: ${nextServiceDate}`
        }`;
    };

    useEffect(() => {
        if (recurrenceForm.freq === 'Daily') {
            if (recurrenceForm.end.option === 'On This Day') {
                setRrule(
                    new RRule({
                        freq: RRule.DAILY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                        until: recurrenceForm.end.until,
                    })
                );
            } else if (recurrenceForm.end.option === 'After') {
                setRrule(
                    new RRule({
                        freq: RRule.DAILY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                        count: recurrenceForm.end.count,
                    })
                );
            } else {
                setRrule(
                    new RRule({
                        freq: RRule.DAILY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                    })
                );
            }
        } else if (recurrenceForm.freq === 'Weekly') {
            if (recurrenceForm.end.option === 'On This Day') {
                setRrule(
                    new RRule({
                        freq: RRule.WEEKLY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                        byweekday: recurrenceForm.byweekday.map(
                            (weekday) => RRule[weekday]
                        ),
                        until: recurrenceForm.end.until,
                    })
                );
            } else if (recurrenceForm.end.option === 'After') {
                setRrule(
                    new RRule({
                        freq: RRule.WEEKLY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                        byweekday: recurrenceForm.byweekday.map(
                            (weekday) => RRule[weekday]
                        ),
                        count: recurrenceForm.end.count,
                    })
                );
            } else {
                setRrule(
                    new RRule({
                        freq: RRule.WEEKLY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                        byweekday: recurrenceForm.byweekday.map(
                            (weekday) => RRule[weekday]
                        ),
                    })
                );
            }
        } else if (recurrenceForm.freq === 'Monthly') {
            if (recurrenceForm.end.option === 'On This Day') {
                setRrule(
                    new RRule({
                        freq: RRule.MONTHLY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                        bymonthday: recurrenceForm.bymonthday,
                        until: recurrenceForm.end.until,
                    })
                );
            } else if (recurrenceForm.end.option === 'After') {
                setRrule(
                    new RRule({
                        freq: RRule.MONTHLY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                        bymonthday: recurrenceForm.bymonthday,
                        count: recurrenceForm.end.count,
                    })
                );
            } else {
                setRrule(
                    new RRule({
                        freq: RRule.MONTHLY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                        bymonthday: recurrenceForm.bymonthday,
                    })
                );
            }
        } else if (recurrenceForm.freq === 'Yearly') {
            if (recurrenceForm.end.option === 'On This Day') {
                setRrule(
                    new RRule({
                        freq: RRule.YEARLY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                        bymonth: recurrenceForm.bymonth,
                        bymonthday: recurrenceForm.bymonthday,
                        until: recurrenceForm.end.until,
                    })
                );
            } else if (recurrenceForm.end.option === 'After') {
                setRrule(
                    new RRule({
                        freq: RRule.YEARLY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                        bymonth: recurrenceForm.bymonth,
                        bymonthday: recurrenceForm.bymonthday,
                        count: recurrenceForm.end.count,
                    })
                );
            } else {
                setRrule(
                    new RRule({
                        freq: RRule.YEARLY,
                        interval: recurrenceForm.interval,
                        dtstart: recurrenceForm.dtstart,
                        bymonth: recurrenceForm.bymonth,
                        bymonthday: recurrenceForm.bymonthday,
                    })
                );
            }
        }
    }, [recurrenceForm]);

    useEffect(() => {
        if (
            !recurrenceForm.nextServiceDate ||
            recurrenceForm.end.until <
                RRule.fromString(rrule.toString()).after(new Date(), false)
        ) {
            setRecurrenceForm((state) => ({
                ...state,
                end: {
                    ...state.end,
                    until: new Date(
                        RRule.fromString(rrule.toString()).after(
                            new Date(),
                            false
                        )
                    ),
                },
                nextServiceDate: RRule.fromString(rrule.toString()).after(
                    new Date(),
                    false
                ),
            }));
        }
    }, [rrule, recurrenceForm.end.until, recurrenceForm.nextServiceDate]);

    return (
        <Dialog
            isShown={isShown}
            title="Set Maintenance Recurrence"
            onConfirm={() =>
                setRecurrence(
                    rrule,
                    Date.parse(
                        RRule.fromString(rrule.toString()).after(
                            new Date(),
                            false
                        )
                    )
                )
            }
            onCloseComplete={close}
        >
            <Pane display="flex" flexDirection="column">
                <Pane>
                    <Pane
                        display="flex"
                        alignItems="center"
                        gap={20}
                        width="100%"
                    >
                        <Text textAlign="end" width="15%" marginTop={-16}>
                            Start
                        </Text>
                        <DatePicker
                            name="dtstart"
                            placeholderText="Pick Start Date"
                            calendarClassName="calendar"
                            popperClassName="calendar"
                            minDate={Date.now()}
                            dateFormat="dd/MM/yyyy"
                            onChange={handleStartDate}
                            disabled={false}
                            selected={recurrenceForm.dtstart}
                            customInput={
                                <TextInputField
                                    name="dtstart"
                                    value={recurrenceForm.dtstart}
                                    width="100%"
                                    inputHeight={40}
                                />
                            }
                            renderCustomHeader={(props) => (
                                <DatepickerCustomHeader {...props} />
                            )}
                        />
                    </Pane>
                    <Pane display="flex" alignItems="center" gap={20}>
                        <Text width="15%" marginTop={-16}>
                            Recurrence
                        </Text>
                        <SelectField
                            name="freq"
                            width="35%"
                            inputHeight={40}
                            onChange={handleChange}
                            value={recurrenceForm.freq}
                        >
                            <option value="" disabled>
                                Select Recurrence Period
                            </option>
                            {RECURRENCE_OPTIONS.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </SelectField>
                    </Pane>
                </Pane>
                {recurrenceForm.freq === 'Daily' && (
                    <DailyRecurrenceForm
                        handleChange={handleChange}
                        recurrenceForm={recurrenceForm}
                    />
                )}
                {recurrenceForm.freq === 'Weekly' && (
                    <WeeklyRecurrenceForm
                        handleChange={handleChange}
                        recurrenceForm={recurrenceForm}
                    />
                )}
                {recurrenceForm.freq === 'Monthly' && (
                    <MonthlyRecurrenceForm
                        handleChange={handleChange}
                        recurrenceForm={recurrenceForm}
                    />
                )}
                {recurrenceForm.freq === 'Yearly' && (
                    <YearlyRecurrenceForm
                        handleChange={handleChange}
                        recurrenceForm={recurrenceForm}
                    />
                )}
                <Pane display="flex" alignItems="center" gap={20}>
                    <Text textAlign="end" width="15%" marginTop={-16}>
                        End
                    </Text>
                    <SelectField
                        name="option"
                        width="35%"
                        inputHeight={40}
                        onChange={handleEndChange}
                        value={recurrenceForm.end.option}
                    >
                        {END_OPTIONS.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </SelectField>
                    {recurrenceForm.end.option === 'On This Day' && (
                        <DatePicker
                            name="until"
                            placeholderText="Pick End Date"
                            calendarClassName="calendar"
                            popperClassName="calendar"
                            dateFormat="dd/MM/yyyy"
                            onChange={handleEndDate}
                            disabled={false}
                            minDate={RRule.fromString(rrule.toString()).after(
                                new Date(),
                                false
                            )}
                            selected={recurrenceForm.end.until}
                            customInput={
                                <TextInputField
                                    name="until"
                                    value={recurrenceForm.end.until}
                                    width="100%"
                                    inputHeight={40}
                                />
                            }
                            renderCustomHeader={(props) => (
                                <DatepickerCustomHeader {...props} />
                            )}
                        />
                    )}
                    {recurrenceForm.end.option === 'After' && (
                        <>
                            <TextInputField
                                inputHeight={40}
                                name="count"
                                type="number"
                                defaultValue={1}
                                value={recurrenceForm.end.count}
                                width="20%"
                                onChange={handleEndChange}
                            />
                            <Text marginTop={-16}>Occurrences</Text>
                        </>
                    )}
                </Pane>
                <Text
                    textTransform="capitalize"
                    marginTop={10}
                    textAlign="center"
                >
                    {renderNextServiceDate()}
                </Text>
            </Pane>
        </Dialog>
    );
}

SchedulerModal.propTypes = {
    isShown: PropTypes.bool.isRequired,
    setRecurrence: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};

export default SchedulerModal;
