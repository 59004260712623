import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Pane, TextInputField } from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { loginModel, loginShape } from '../../../models';

const LoginForm = ({ initialValues, submitForm, onSuccess }) => {
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email'),
    });

    const dispatch = useDispatch();

    const handleFormSubmission = async (
        values,
        { setSubmitting, setFieldError }
    ) => {
        if (!values.email) {
            return;
        }
        setSubmitting(true);

        try {
            await dispatch(submitForm(loginModel(values)));
            onSuccess(values.email);
        } catch (error) {
            setFieldError(
                'email',
                _.get(error, 'response.data.message', error.message)
            );
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmission}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Pane marginTop="16px">
                        <TextInputField
                            label="Email"
                            type="email"
                            name="email"
                            placeholder="john@example.com"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!touched.email && !!errors.email}
                            validationMessage={touched.email && errors.email}
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                    </Pane>
                    <Button
                        appearance="primary"
                        marginTop={8}
                        type="submit"
                        isLoading={isSubmitting}
                        height={40}
                        width="100%"
                    >
                        Login
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

LoginForm.propTypes = {
    initialValues: loginShape.isRequired,

    submitForm: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default LoginForm;
