import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Pane, Paragraph, Text } from 'evergreen-ui';
import _ from 'lodash';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { uploadRequestSelector } from '../../../reducers/upload-request-reducer/upload-request-reducer';
import BuildingPlaceholderImage from '../../../assets/images/illustrations/placeholder-building.svg';
import { SelectedUploadRequest } from '../../../pages/upload-request/selected-upload-request-context';
import useQueryParams from '../../../hooks/use-query-params';

const UploadRequestsList = () => {
    const navigate = useNavigate();
    const { token } = useQueryParams();
    const { uploadRequests } = useSelector(uploadRequestSelector);
    const { selectedUploadRequest } = useContext(SelectedUploadRequest);

    const onSelect = (uploadRequestId) => () => {
        navigate(
            `/upload-request?uploadRequestId=${uploadRequestId}&token=${token}`
        );
    };

    return (
        <Pane borderBottom="0.75px solid #C1C4D6">
            {_.map(uploadRequests, (uploadRequest) => {
                const heading =
                    uploadRequest.requestType === 'maintenance'
                        ? 'Maintenance'
                        : `${uploadRequest.complianceGroupName}, ${uploadRequest.complianceCategoryName}`;

                const subHeading =
                    uploadRequest.requestType === 'maintenance'
                        ? uploadRequest.maintenanceItem?.description
                        : uploadRequest.complianceTypeName;

                return (
                    <UploadRequestItem
                        key={uploadRequest.id}
                        paddingX={12}
                        paddingY={20}
                        backgroundColor={
                            uploadRequest.id === selectedUploadRequest.id
                                ? ' #eef8f4'
                                : '#FFFFFF'
                        }
                        borderTop="0.75px solid #C1C4D6"
                        borderLeft="0.75px solid #C1C4D6"
                        borderRight="0.75px solid #C1C4D6"
                        display="flex"
                        alignItems="center"
                        position="relative"
                        minHeight={87}
                        cursor="pointer"
                        onClick={onSelect(uploadRequest.id)}
                    >
                        <Avatar size={56} src={BuildingPlaceholderImage} />
                        <Pane
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            marginLeft={12}
                            height="100%"
                            flex={1}
                        >
                            <Pane display="flex" justifyContent="space-between">
                                <Paragraph
                                    fontWeight={600}
                                    fontSize={12}
                                    color="#C1C4D6"
                                    lineHeight="16px"
                                >
                                    {heading}
                                </Paragraph>
                                <Text
                                    fontWeight={400}
                                    fontSize={11}
                                    color="#696F8C"
                                    lineHeight="17px"
                                    width={100}
                                    textAlign="right"
                                >
                                    {DateTime.fromMillis(
                                        uploadRequest.createdAt
                                    ).toFormat('EEE, d MMM')}
                                </Text>
                            </Pane>
                            <Paragraph
                                fontWeight={600}
                                fontSize={13}
                                color="#0C2138"
                                textTransform="uppercase"
                                lineHeight="16px"
                            >
                                {subHeading}
                            </Paragraph>
                            <Paragraph
                                fontWeight={400}
                                fontSize={12}
                                color="#C1C4D6"
                                lineHeight="16px"
                            >
                                {uploadRequest.asset.assetName}
                            </Paragraph>
                        </Pane>
                    </UploadRequestItem>
                );
            })}
        </Pane>
    );
};

export default UploadRequestsList;

const UploadRequestItem = styled(Pane)`
    &:hover {
        background-color: #eef8f4;
    }
`;
