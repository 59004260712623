import React, { useEffect, useState } from 'react';
import { Pane, Dialog, Button, PlusIcon, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { maintenanceSelector } from '../../reducers/maintenance-reducer/maintenance-reducer';
import {
    createMaintenanceCategory,
    deleteMaintenanceCategory,
    getMaintenanceCategories,
    updateMaintenanceType,
    createMaintenanceType,
    updateMaintenanceCategory,
} from '../../reducers/maintenance-reducer/maintenance-actions';
import { getMaintenanceCategoryModel } from '../../models/maintenance.models';
import MaintenanceCategoryForm from './maintenance-category-form';
import CategoryItem from './category-item';
import { colors } from '../../theme/theme';
import { errorToast, successToast } from '../toasts';

function MaintenanceCategoriesModal({ close, isShown }) {
    const dispatch = useDispatch();

    const { categories } = useSelector(maintenanceSelector);

    const [addingCategory, setAddingCategory] = useState(false);
    const [editingCategory, setEditingCategory] = useState(false);
    const [deletingCategory, setDeletingCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addCategoryForm, setAddCategoryForm] = useState({
        values: {
            name: '',
            abbreviation: '',
        },
        errors: { name: null, abbreviation: null },
    });

    const [addingTypes, setAddingTypes] = useState({});
    const [confirmError, setConfirmError] = useState(false);

    const handleAddTypes = (categoryId, value, typeId = null) => {
        if (typeId) {
            setAddingTypes((state) => ({
                ...state,
                [categoryId]: {
                    ...state[categoryId],
                    value,
                    typeId,
                },
            }));
        } else {
            setAddingTypes((state) => ({
                ...state,
                [categoryId]: {
                    ...state[categoryId],
                    value,
                },
            }));
        }
    };

    const handleCancelAddTypes = (categoryId) => {
        setAddingTypes((state) => {
            const newState = { ...state };
            delete newState[categoryId];
            return newState;
        });
    };

    const handleCategoryChange = (e) => {
        setAddCategoryForm((state) => ({
            ...state,
            values: { ...state.values, [e.target.name]: e.target.value },
            errors: { ...state.errors, [e.target.name]: null },
        }));
    };

    const handleCategorySubmit = async ({ name, abbreviation, categoryId }) => {
        if (!name) {
            setAddCategoryForm((state) => ({
                ...state,
                errors: { ...state.errors, name: 'Please provide a Name' },
            }));
            return;
        }
        if (!abbreviation) {
            setAddCategoryForm((state) => ({
                ...state,
                errors: {
                    ...state.errors,
                    abbreviation: 'Please provide an Abbreviation',
                },
            }));
            return;
        }

        try {
            setLoading(true);
            if (addingCategory) {
                await dispatch(
                    createMaintenanceCategory(name, abbreviation, false, true)
                );
            } else if (editingCategory) {
                await dispatch(
                    updateMaintenanceCategory(categoryId, name, abbreviation)
                );
            }
            setAddingCategory(false);
            setEditingCategory(false);
            setAddCategoryForm({
                values: {
                    name: '',
                    abbreviation: '',
                },
                errors: {
                    name: null,
                    abbreviation: null,
                },
            });
            successToast(
                `Successfully ${
                    editingCategory ? 'updated' : 'created'
                } ${abbreviation} - ${name}`
            );
        } catch (error) {
            setAddCategoryForm((form) => ({
                ...form,
                errors: {
                    ...form.errors,
                    abbreviation: get(
                        error,
                        'response.data.message',
                        error.message
                    ),
                },
            }));
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        if (addingCategory || editingCategory) {
            setAddingCategory(false);
            setEditingCategory(false);
            setAddCategoryForm({
                values: {
                    name: '',
                    abbreviation: '',
                },
                errors: { name: null, abbreviation: null },
            });
        }
        if (!addingCategory && !editingCategory) {
            close();
        }
    };

    const submitType = async () => {
        const newTypes = Object.keys(addingTypes);
        if (newTypes.length) {
            newTypes.map((categoryId) => {
                const { value, typeId } = addingTypes[categoryId];
                if (typeId) {
                    return dispatch(updateMaintenanceType(typeId, value));
                }
                return dispatch(createMaintenanceType(categoryId, value));
            });
        }
    };

    const handleConfirm = async () => {
        const hasEmptyCategoriesTypes = categories.some((category) => {
            const { types } = getMaintenanceCategoryModel(category);

            if (!types.length) return true;

            return false;
        });

        if (hasEmptyCategoriesTypes) {
            setConfirmError(true);
        }

        if (addingCategory || editingCategory) {
            handleCategorySubmit(addCategoryForm.values);
            return;
        }

        if (Object.keys(addingTypes).length > 0) {
            setLoading(true);
            try {
                await submitType();
            } catch (error) {
                errorToast(error.message);
            } finally {
                setAddingTypes({});
                setLoading(false);
                close();
            }
            return;
        }

        if (Object.keys(addingTypes).length === 0 && !hasEmptyCategoriesTypes) {
            close();
        }
    };

    const handleCategoryDelete = async (e, categoryId) => {
        e.stopPropagation();
        try {
            setDeletingCategory(categoryId);
            await dispatch(deleteMaintenanceCategory(categoryId));
            successToast('Category Successfully Deleted');
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setDeletingCategory(null);
        }
    };

    const setEditCategoryForm = (e, name, abbreviation, categoryId) => {
        e.stopPropagation();
        setAddCategoryForm({
            values: {
                categoryId,
                name,
                abbreviation,
            },
            errors: { name: null, abbreviation: null },
        });
        setAddingCategory(false);
        setEditingCategory(true);
    };

    useEffect(() => {
        dispatch(getMaintenanceCategories());
    }, [dispatch]);

    return (
        <Dialog
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            title="Edit Asset Categories"
            isShown={isShown}
            isConfirmLoading={loading}
            onCloseComplete={close}
        >
            <Pane>
                {!addingCategory && !editingCategory ? (
                    <>
                        <Button
                            iconBefore={<PlusIcon />}
                            marginLeft={-8}
                            onClick={() => setAddingCategory(true)}
                            appearance="minimal"
                        >
                            Add New Category
                        </Button>

                        <Pane overflowY="auto">
                            {categories.length ? (
                                categories.map((category) => {
                                    const { abbreviation, name, types, id } =
                                        getMaintenanceCategoryModel(category);

                                    return (
                                        <CategoryItem
                                            key={id}
                                            name={name}
                                            abbreviation={abbreviation}
                                            categoryId={id}
                                            types={types}
                                            handleCategoryDelete={
                                                handleCategoryDelete
                                            }
                                            setEditCategoryForm={
                                                setEditCategoryForm
                                            }
                                            handleCancelAddTypes={
                                                handleCancelAddTypes
                                            }
                                            handleAddTypes={handleAddTypes}
                                            deletingCategory={deletingCategory}
                                            confirmError={confirmError}
                                            setConfirmError={setConfirmError}
                                        />
                                    );
                                })
                            ) : (
                                <Pane
                                    borderBottom={`1px solid ${colors.neutral500}`}
                                    display="flex"
                                    width="100%"
                                    height={40}
                                    alignItems="center"
                                >
                                    <Text fontWeight={500} fontSize={15}>
                                        There are no Categories
                                    </Text>
                                </Pane>
                            )}
                        </Pane>
                    </>
                ) : (
                    <MaintenanceCategoryForm
                        form={addCategoryForm}
                        isEditing={editingCategory}
                        handleSubmit={handleCategorySubmit}
                        handleChange={handleCategoryChange}
                    />
                )}
            </Pane>
        </Dialog>
    );
}

MaintenanceCategoriesModal.propTypes = {
    close: PropTypes.func.isRequired,
    isShown: PropTypes.bool.isRequired,
};

export default MaintenanceCategoriesModal;
