import React, {
    useContext,
    useRef,
    useState,
    useMemo,
    useCallback,
    useEffect,
} from 'react';
import {
    Card,
    CircleArrowDownIcon,
    Heading,
    Pane,
    Paragraph,
    Dialog,
    Button,
    TextInputField,
    Textarea,
    Text,
    Spinner,
    EditIcon,
    TrashIcon,
    Menu,
    Popover,
    MoreIcon,
    CircleArrowRightIcon,
    CircleArrowLeftIcon,
    Tooltip,
    CrossIcon,
} from 'evergreen-ui';
import { useDispatch, useSelector } from 'react-redux';
import _, { get } from 'lodash';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../../theme/theme';
import { AutoFocusConsumer, Image } from '../../atoms';
import { SelectedComplianceItem } from '../../../pages/asset-pages/selected-complianceI-item-context';
import { documentService } from '../../../services';
import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';
import { getUpdateDocumentModel } from '../../../models';
import { errorToast } from '../../toasts';
import { setComplianceTypeCompleted } from '../../../reducers/asset-reducer/asset.actions';
import getFileTypeIcon from '../../../helpers/get-file-type-icon';

const DocumentDialog = ({ isShown, setIsShown, rights }) => {
    const dispatch = useDispatch();

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);
    const [showOptions, setShowOptions] = useState(false);
    const [downloadFileLink, setDownloadFileLink] = useState(null);

    const asset = useMemo(
        () => currentTenant || currentBuilding || currentAsset || null,
        [currentTenant, currentBuilding, currentAsset]
    );

    const {
        selectedComplianceItem,
        selectedDocument,
        nextDocument,
        prevDocument,
        setSelectedDocument,
        getComplianceTypeDocuments,
        selectedComplianceItemDocuments,
    } = useContext(SelectedComplianceItem);

    const [dialogManager, setDialogManager] = useState({
        isDeleting: false,
        isEditing: false,
        originalName: '',
        newName: '',
        notes: '',
        errors: {},
        loading: false,
    });

    const [isDownloading, setIsDownloading] = useState(false);

    const openEditDocument = () => {
        setDialogManager({
            isDeleting: false,
            isEditing: true,
            originalName: _.get(selectedDocument, 'friendlyName', ''),
            newName: _.get(selectedDocument, 'friendlyName', ''),
            notes: _.get(selectedDocument, 'notes', ''),
            errors: {},
            loading: false,
        });
    };

    const openDeleteDocument = () => {
        setDialogManager({
            isDeleting: true,
            isEditing: false,
            originalName: _.get(selectedDocument, 'friendlyName', ''),
            newName: _.get(selectedDocument, 'friendlyName', ''),
            notes: _.get(selectedDocument, 'notes', ''),
            errors: {},
            loading: false,
        });
    };

    const resetDocumentManager = () => {
        setDialogManager({
            isDeleting: false,
            isEditing: false,
            originalName: '',
            newName: '',
            notes: '',
            errors: {},
        });
    };

    const handleDocumentChange = ({ target }) => {
        setDialogManager((state) => ({
            ...state,
            [target.name]: target.value,
        }));
    };

    const editDocument = async () => {
        if (!dialogManager.newName) {
            setDialogManager((state) => ({
                ...state,
                errors: {
                    newName: 'Updated Document Name is Required!',
                },
            }));
            return;
        }

        const { fileThumbnailLink } = selectedDocument;

        const document = getUpdateDocumentModel(
            await documentService.updateDocument({
                documentId: selectedDocument?.id,
                friendlyName: dialogManager.newName,
                notes: dialogManager.notes,
                requestType: 'compliance',
            })
        );

        await getComplianceTypeDocuments();

        setSelectedDocument({ ...document, fileThumbnailLink });
    };

    const deleteDocument = async () => {
        await documentService.deleteDocument({
            documentId: selectedDocument?.id,
            requestType: 'compliance',
            assetId: asset?.id,
        });
        await getComplianceTypeDocuments();

        if (selectedComplianceItemDocuments?.length === 1) {
            dispatch(setComplianceTypeCompleted(selectedComplianceItem.id));
        }

        setSelectedDocument(null);
        closeDialog();
    };

    const onConfirm = async () => {
        setDialogManager((state) => ({ ...state, loading: true }));

        try {
            if (dialogManager.isDeleting) {
                await deleteDocument();
                setDialogManager((state) => ({ ...state, isDeleting: false }));
                return;
            }

            if (dialogManager.isEditing) {
                await editDocument();
                setDialogManager((state) => ({ ...state, isEditing: false }));
                return;
            }
        } catch (error) {
            const errorMessage = get(
                error,
                'response.data.message',
                error.message
            );
            errorToast(`An Error Occurred: ${errorMessage}`);
        } finally {
            setDialogManager((state) => ({ ...state, loading: false }));
        }
    };

    const thumbnailImageRef = useRef(null);

    const closeDialog = () => {
        resetDocumentManager();
        setIsShown(false);
    };

    const onCancel = () => {
        if (dialogManager.isEditing || dialogManager.isDeleting) {
            resetDocumentManager();
            return;
        }
        closeDialog();
    };

    const getFileDownloadLink = useCallback(async () => {
        setIsDownloading(true);
        try {
            const fileLink = Object.values(
                await documentService.getFilesDownloadLink([
                    {
                        assetId: asset?.bucketId,
                        fileId: selectedDocument?.fileId,
                        fileKey: selectedDocument?.fileKey,
                    },
                ])
            )[0];
            setDownloadFileLink(fileLink);
        } finally {
            setIsDownloading(false);
        }
    }, [asset, selectedDocument]);

    useEffect(() => {
        getFileDownloadLink();
    }, [getFileDownloadLink]);

    const renderHeader = () => (
        <Pane display="flex" zIndex={5}>
            <Pane width="100%">
                <Pane display="flex" gap={10} alignItems="center" width="100%">
                    <Heading
                        cursor="pointer"
                        size={800}
                        fontSize={24}
                        role="button"
                        marginRight="auto"
                    >
                        {_.get(selectedComplianceItem, 'name')} -{' '}
                        {_.get(selectedDocument, 'friendlyName')}
                    </Heading>
                    {(rights?.update || rights?.delete) &&
                        !(
                            dialogManager.isEditing || dialogManager.isDeleting
                        ) && (
                            <Popover
                                isShown={showOptions}
                                onOpen={() => setShowOptions(true)}
                                position="bottom-left"
                                onCloseComplete={() => setShowOptions(false)}
                                shouldCloseOnExternalClick
                                content={
                                    <Menu>
                                        {rights?.update && (
                                            <Menu.Option
                                                onSelect={() => {
                                                    openEditDocument();
                                                    setShowOptions(false);
                                                }}
                                            >
                                                <Pane
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="start"
                                                    gap={6}
                                                >
                                                    <EditIcon
                                                        marginRight={10}
                                                    />
                                                    <Text width="100%">
                                                        Edit
                                                    </Text>
                                                </Pane>
                                            </Menu.Option>
                                        )}
                                        {rights?.delete && (
                                            <Menu.Option
                                                onSelect={() => {
                                                    openDeleteDocument();
                                                    setShowOptions(false);
                                                }}
                                            >
                                                <Pane
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="start"
                                                    gap={6}
                                                >
                                                    <TrashIcon
                                                        marginRight={10}
                                                        color="#bc3d3d"
                                                    />
                                                    <Text
                                                        width="100%"
                                                        color="#bc3d3d"
                                                    >
                                                        Delete
                                                    </Text>
                                                </Pane>
                                            </Menu.Option>
                                        )}
                                    </Menu>
                                }
                            >
                                <Button
                                    appearance="minimal"
                                    onClick={() => setShowOptions(true)}
                                >
                                    <MoreIcon />
                                </Button>
                            </Popover>
                        )}

                    <Button onClick={closeDialog}>
                        <CrossIcon />
                    </Button>
                </Pane>

                {showOptions && (
                    <Pane
                        position="fixed"
                        top={0}
                        right={0}
                        height="100vh"
                        width="100vw"
                        onClick={() => setShowOptions(false)}
                    />
                )}
                {!dialogManager.isDeleting && (
                    <Paragraph
                        color="#0C2138"
                        fontSize={14}
                        textTransform="uppercase"
                    >
                        Date Uploaded:{' '}
                        {DateTime.fromMillis(
                            _.get(selectedDocument, 'createdAt', 0)
                        ).toFormat('d LLL yyyy')}
                    </Paragraph>
                )}
            </Pane>
        </Pane>
    );

    const renderUploaderDetails = useCallback(() => {
        if (!selectedDocument || !selectedDocument.uploaderDetails) {
            return null;
        }
        const {
            firstName,
            lastName,
            profession,
            professionalCode,
            email,
            mobileNumber,
            businessesName,
        } = selectedDocument.uploaderDetails;

        return (
            <>
                <Paragraph>{businessesName}</Paragraph>
                <Paragraph>
                    {firstName} {lastName}
                </Paragraph>
                {profession && (
                    <Paragraph>
                        {profession} (Reg: {professionalCode})
                    </Paragraph>
                )}
                <Paragraph>
                    <Link href={`mailto:${email}`}>{email}</Link>
                </Paragraph>
                <Paragraph>
                    <Link href={`tel:${mobileNumber}`}>{mobileNumber}</Link>
                </Paragraph>
            </>
        );
    }, [selectedDocument]);

    const renderThumbnail = useCallback(() => {
        if (!selectedDocument) {
            return null;
        }

        let thumbnailSrc = '';
        let isFileIcon = false;

        if (selectedDocument.fileThumbnailLink) {
            thumbnailSrc = _.get(selectedDocument, 'fileThumbnailLink');
        } else {
            thumbnailSrc = getFileTypeIcon(selectedDocument.fileExtension);
            isFileIcon = true;
        }

        return (
            <Pane display="flex" flex={1} flexDirection="row">
                <Pane
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    width={400}
                >
                    <Link to={downloadFileLink} target="_blank" download>
                        <DownloadCard
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            elevation={4}
                            flex={1}
                            borderRadius={15}
                            overflow="hidden"
                            cursor="pointer"
                            position="relative"
                        >
                            <AspectRatioImage
                                src={thumbnailSrc}
                                alt="document"
                                width={400}
                                padding={isFileIcon ? 125 : 0}
                            />
                            <Pane
                                id="download-overlay"
                                backgroundColor={colors.black50}
                                position="absolute"
                                height="100%"
                                width="100%"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                ref={thumbnailImageRef}
                            >
                                {!isDownloading ? (
                                    <>
                                        <CircleArrowDownIcon
                                            color={colors.white}
                                            size={80}
                                        />
                                        <Heading
                                            color={colors.white}
                                            marginTop={10}
                                        >
                                            Download
                                        </Heading>
                                    </>
                                ) : (
                                    <SpinnerContainer>
                                        <Spinner
                                            className="spinner"
                                            size={80}
                                        />
                                    </SpinnerContainer>
                                )}
                            </Pane>
                        </DownloadCard>
                    </Link>
                </Pane>
                <Pane flex={1} width="100%" marginLeft={50}>
                    <Paragraph fontSize={16}>
                        <strong>Uploaded By:</strong>
                    </Paragraph>
                    {renderUploaderDetails()}
                    <Paragraph fontSize={16} marginTop={32}>
                        <strong>Comments:</strong>
                    </Paragraph>
                    <Paragraph width={300}>
                        {selectedDocument.notes
                            ? selectedDocument.notes
                            : 'No comments added.'}
                    </Paragraph>
                </Pane>
            </Pane>
        );
    }, [
        selectedDocument,
        downloadFileLink,
        isDownloading,
        renderUploaderDetails,
    ]);

    const renderContent = useCallback(() => {
        if (dialogManager.isDeleting) {
            return (
                <Pane>
                    <Text fontSize={16}>
                        Are you sure you want to delete{' '}
                        <strong>
                            {get(
                                selectedDocument,
                                'friendlyName',
                                'this document'
                            )}
                        </strong>
                        ?
                    </Text>
                </Pane>
            );
        }

        if (dialogManager.isEditing) {
            return (
                <Pane>
                    <EditNameField
                        name="newName"
                        value={dialogManager.newName}
                        inputHeight={40}
                        onChange={handleDocumentChange}
                        validationMessage={dialogManager.errors?.newName}
                    />
                    <Pane display="flex" flexDirection="column" gap={8}>
                        <Text>Notes:</Text>
                        <Textarea
                            value={dialogManager.notes}
                            name="notes"
                            border="1px solid #a7a8ac"
                            maxWidth={845}
                            minWidth="100%"
                            onChange={handleDocumentChange}
                        />
                    </Pane>
                </Pane>
            );
        }
        return (
            <>
                <Pane paddingX={16} paddingBottom={24}>
                    {renderThumbnail()}
                </Pane>
                <AutoFocusConsumer />
            </>
        );
    }, [dialogManager, renderThumbnail, selectedDocument]);

    return (
        <Dialog
            isShown={isShown}
            onCloseComplete={closeDialog}
            hasFooter={dialogManager.isEditing || dialogManager.isDeleting}
            onConfirm={onConfirm}
            onCancel={onCancel}
            isConfirmLoading={dialogManager.loading}
            width={845}
            hasClose={false}
            title={renderHeader()}
            intent={dialogManager.isDeleting ? 'danger' : 'none'}
        >
            <Pane
                display={
                    dialogManager.isDeleting || dialogManager.isEditing
                        ? 'none'
                        : 'flex'
                }
                marginY={16}
                position="absolute"
                top={0}
                bottom={0}
                left={-100}
                right={-100}
                alignItems="center"
                justifyContent="space-between"
                zIndex={-1}
            >
                <Tooltip showDelay={200} content="View Previous Document">
                    <Button
                        disabled={!prevDocument}
                        onClick={() => setSelectedDocument(prevDocument)}
                        size="large"
                        width={60}
                        height={60}
                        padding={0}
                        borderRadius={9999}
                    >
                        <CircleArrowLeftIcon size={32} />
                    </Button>
                </Tooltip>
                <Tooltip showDelay={200} content="View Next Document">
                    <Button
                        disabled={!nextDocument}
                        onClick={() => setSelectedDocument(nextDocument)}
                        size="large"
                        width={60}
                        height={60}
                        padding={0}
                        borderRadius={9999}
                    >
                        <CircleArrowRightIcon size={32} />
                    </Button>
                </Tooltip>
            </Pane>
            {renderContent()}
        </Dialog>
    );
};

DocumentDialog.propTypes = {
    isShown: PropTypes.bool,
    setIsShown: PropTypes.func.isRequired,
    rights: PropTypes.shape({
        create: PropTypes.bool,
        read: PropTypes.bool,
        update: PropTypes.bool,
        delete: PropTypes.bool,
    }).isRequired,
};

DocumentDialog.defaultProps = {
    isShown: false,
};

export default DocumentDialog;

const SpinnerContainer = styled(Pane)`
    circle {
        stroke: #fff;
    }
`;

const EditNameField = styled(TextInputField)`
    border-color: #a7a8ac;
`;

const DownloadCard = styled(Card)`
    #download-overlay {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    &:hover {
        #download-overlay {
            visibility: visible;
            opacity: 100;
        }
    }
`;

const AspectRatioImage = styled(Image)`
    aspect-ratio: 1 / 1.4142;
    object-fit: contain;
`;
