import React from 'react';
import { Pane, TextInputField, Text, Checkbox } from 'evergreen-ui';
import PropTypes from 'prop-types';

function AddRoleForm({ RIGHTS, name, setName, modules, handleChange }) {
    return (
        <Pane display="flex" flexDirection="column">
            <TextInputField
                label="Role Name"
                placeholder="Admin"
                required
                inputHeight={40}
                value={name}
                onChange={({ target }) => setName(target.value)}
            />
            <Pane
                display="flex"
                flexDirection="column"
                flex={1}
                padding={6}
                gap={6}
                onClick={(e) => e.stopPropagation()}
            >
                {modules.map((module, moduleIndex) => {
                    const notLastModule = moduleIndex !== modules.length - 1;
                    const moduleName = module.name.replace('-', ' ');
                    return (
                        <Pane
                            key={module.name}
                            display="flex"
                            width="100%"
                            alignItems="center"
                            justifyContent="space-between"
                            borderBottom={notLastModule && '1px solid #696f8c'}
                        >
                            <Text
                                textTransform="capitalize"
                                width="25%"
                                cursor="default"
                            >
                                {moduleName === 'info'
                                    ? 'Buildings'
                                    : moduleName}
                            </Text>
                            <Pane display="flex" gap={10}>
                                {Object.keys(module?.rights)?.map(
                                    (right, index) => {
                                        const notLastRight =
                                            index !==
                                            Object.keys(module?.rights).length -
                                                1;

                                        const isReadDisabled = Object.keys(
                                            module?.rights
                                        )
                                            .filter(
                                                (_right) => _right !== 'read'
                                            )
                                            .some(
                                                (key) => !!module?.rights[key]
                                            );

                                        const label =
                                            right === 'update'
                                                ? 'Edit'
                                                : RIGHTS[right];

                                        return (
                                            <Checkbox
                                                key={`${module.name}-${right}`}
                                                label={label}
                                                paddingRight={10}
                                                checked={module?.rights[right]}
                                                borderRight={
                                                    !!notLastRight &&
                                                    '1px solid #696f8c'
                                                }
                                                onChange={() =>
                                                    handleChange(
                                                        module?.name,
                                                        right
                                                    )
                                                }
                                                disabled={
                                                    (right === 'read' &&
                                                        isReadDisabled) ||
                                                    (right === 'update' &&
                                                        !!module?.rights.create)
                                                }
                                            />
                                        );
                                    }
                                )}
                            </Pane>
                        </Pane>
                    );
                })}
            </Pane>
        </Pane>
    );
}

AddRoleForm.propTypes = {
    RIGHTS: PropTypes.shape({}).isRequired,
    handleChange: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    modules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AddRoleForm;
