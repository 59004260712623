import React, { useMemo, useCallback } from 'react';
import { SideSheet, Heading, Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';

import { colors } from '../../theme/theme';
import EditUserForm from './edit-user-form';
import AddUserForm from './add-user-form';

function UserManagementSidebar({
    isShown,
    isEditing,
    close,
    user,
    roles,
    properties,
    propertyGroups,
    setShowPropertyGroupsDialog,
    setShowRolesDialog,
}) {
    const heading = useMemo(() => {
        if (isEditing) {
            return `Edit User ${!!user?.name && `(${user.name})`}`;
        }
        return 'Add User';
    }, [isEditing, user]);

    const renderForm = useCallback(
        () =>
            isEditing ? (
                <EditUserForm
                    roles={roles}
                    properties={properties}
                    propertyGroups={propertyGroups}
                    close={close}
                    user={user}
                    setShowPropertyGroupsDialog={setShowPropertyGroupsDialog}
                    setShowRolesDialog={setShowRolesDialog}
                />
            ) : (
                <AddUserForm
                    roles={roles}
                    properties={properties}
                    propertyGroups={propertyGroups}
                    close={close}
                    setShowPropertyGroupsDialog={setShowPropertyGroupsDialog}
                    setShowRolesDialog={setShowRolesDialog}
                />
            ),
        [
            isEditing,
            roles,
            properties,
            propertyGroups,
            close,
            user,
            setShowPropertyGroupsDialog,
            setShowRolesDialog,
        ]
    );

    return (
        <SideSheet
            isShown={isShown}
            onCloseComplete={close}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
            }}
            preventBodyScrolling
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
            width={450}
        >
            <Heading
                width="max-content"
                fontSize={22}
                fontWeight="700"
                color={colors.primary500}
                marginLeft={24}
                marginTop={24}
            >
                {heading}
            </Heading>
            <div className="divider mb-0" />
            <Pane
                paddingTop={20}
                paddingX={24}
                overflowY="auto"
                background={colors.tint1}
                height="100%"
                display="flex"
                flexDirection="column"
                maxHeight="calc(100vh - 66px)"
            >
                {renderForm()}
            </Pane>
        </SideSheet>
    );
}

UserManagementSidebar.propTypes = {
    isShown: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    user: PropTypes.shape({
        name: PropTypes.string,
    }),
    roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    propertyGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    setShowPropertyGroupsDialog: PropTypes.func.isRequired,
    setShowRolesDialog: PropTypes.func.isRequired,
};

UserManagementSidebar.defaultProps = {
    user: null,
};

export default UserManagementSidebar;
