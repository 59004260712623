import React, { useState, useRef } from 'react';
import {
    Pane,
    Paragraph,
    TextInputField,
    Tooltip,
    CrossIcon,
    TrashIcon,
    EditIcon,
    Text,
    InfoSignIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import ComplianceTemplateCategory from './compliance-template-category';
import {
    addComplianceCategory,
    deleteComplianceGroup,
    renameComplianceGroup,
} from '../../reducers/compliance-template-reducer/compliance-template.actions';

function ComplianceTemplateGroup({ group }) {
    const dispatch = useDispatch();

    const [editingGroup, setEditingGroup] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [addingCategory, setAddingCategory] = useState({
        newCategory: '',
        groupId: null,
    });

    const setNewCategoryName = ({ target: { value } }) => {
        setAddingCategory((state) => ({
            ...state,
            newCategory: value,
        }));
    };

    const newName = useRef();

    const setEditGroup = () => {
        newName.current = group.Group;
        setEditingGroup(true);
    };

    const handleGroupEdit = ({ target, key }) => {
        newName.current = target.value;
        if (key === 'Enter') {
            dispatch(renameComplianceGroup(group.id, newName.current));
            cancelEdit();
        }
    };

    const deleteGroup = async () => {
        dispatch(deleteComplianceGroup(group.id));
    };

    const handleCategoryAdd = ({ key }) => {
        if (key === 'Enter') {
            dispatch(
                addComplianceCategory(
                    addingCategory.groupId,
                    addingCategory.newCategory
                )
            );
            cancelAdd();
        }
    };

    const cancelEdit = () => {
        newName.current = '';
        setEditingGroup(false);
    };

    const cancelAdd = () => {
        setAddingCategory({
            addingCategory: false,
            newCategory: '',
            groupId: null,
        });
    };

    return (
        <Pane>
            <Pane>
                {editingGroup ? (
                    <Pane display="flex" width="100%" alignItems="center">
                        <Tooltip content="Press Enter to Confirm">
                            <TextInputField
                                label={`Editing ${group.Group}`}
                                placeholder={group.Group}
                                paddingLeft={30}
                                onKeyDown={handleGroupEdit}
                                inputWidth="420px"
                                ref={newName}
                                marginRight={10}
                                autoFocus
                            />
                        </Tooltip>
                        <Pane
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            hoverElevation={1}
                            borderRadius={9999}
                            padding={4}
                            role="button"
                            onClick={cancelEdit}
                            height={25}
                            marginY="auto"
                        >
                            <CrossIcon
                                size={20}
                                color="#8f95b2"
                                cursor="pointer"
                            />
                        </Pane>
                    </Pane>
                ) : (
                    <Group display="flex" alignItems="center">
                        <Paragraph
                            key={group.id}
                            fontWeight="500"
                            fontSize={14}
                            color="#696F8C"
                            cursor="default"
                            paddingX={30}
                        >
                            {group.Group}
                        </Paragraph>
                        <Pane display="flex" gap={5} marginLeft="auto">
                            <Pane display="flex" gap={10}>
                                <Pane
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={8}
                                    className="edit-contact"
                                    hoverElevation={1}
                                    role="button"
                                    onClick={setEditGroup}
                                >
                                    <EditIcon
                                        color="#696f8c"
                                        className="edit-contact"
                                    />
                                </Pane>
                            </Pane>
                            <Pane display="flex" gap={10}>
                                <Pane
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={8}
                                    className="delete-contact"
                                    hoverElevation={1}
                                    role="button"
                                    onClick={deleteGroup}
                                >
                                    <TrashIcon
                                        color="#c60b0b"
                                        className="delete-contact"
                                    />
                                </Pane>
                            </Pane>
                        </Pane>
                    </Group>
                )}
            </Pane>

            {group.category.map((category) => (
                <ComplianceTemplateCategory
                    key={category.id}
                    category={category}
                    groupId={group.id}
                    editingCategory={editingCategory === category.name}
                    setEditingCategory={setEditingCategory}
                />
            ))}
            {addingCategory.groupId === group.id ? (
                <Pane display="flex" width="100%" alignItems="center">
                    <Tooltip content="Press Enter to Confirm">
                        <TextInputField
                            padding={0}
                            margin={0}
                            placeholder="New Category"
                            onKeyDown={handleCategoryAdd}
                            inputWidth={400}
                            value={addingCategory.newCategory}
                            marginRight={10}
                            marginLeft={30}
                            autoFocus
                            marginTop={-6}
                            onChange={setNewCategoryName}
                        />
                    </Tooltip>
                    <Pane
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        hoverElevation={1}
                        borderRadius={9999}
                        padding={4}
                        role="button"
                        onClick={cancelAdd}
                        height={25}
                        marginY="auto"
                    >
                        <CrossIcon size={20} color="#8f95b2" cursor="pointer" />
                    </Pane>
                </Pane>
            ) : (
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    gap={10}
                >
                    <AddCategoryButton
                        marginLeft={58}
                        marginRight={30}
                        marginTop={14}
                        marginBottom={28}
                        onClick={() =>
                            setAddingCategory({
                                newCategory: '',
                                groupId: group.id,
                            })
                        }
                    >
                        <Text>+ Add Compliance Category</Text>
                    </AddCategoryButton>
                    <Tooltip content="Here you can further organize your documents by creating Compliance Categories. These Categories should reflect different aspects or subtopics within the Compliance Group. For Example, if your Compliance Group is 'Finance Compliance', you could have categories such as 'Financial Reporting', 'Tax Compliance'. or 'Internal Controls'.">
                        <InfoSignIcon size={14} color="#c8cacf" />
                    </Tooltip>
                </Pane>
            )}
        </Pane>
    );
}

const Group = styled(Pane)`
    height: 40px;
    .edit-contact,
    .delete-contact {
        display: none;
        margin-left: auto;
        border-radius: 4px;
        background-color: #f5f6fa;
        cursor: pointer;
    }

    &:hover {
        .edit-contact {
            display: flex;
        }
        .delete-contact {
            display: flex;
        }
    }
`;

const AddCategoryButton = styled(Pane)`
    span {
        color: #5ec090;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

ComplianceTemplateGroup.propTypes = {
    group: PropTypes.shape({
        id: PropTypes.string,
        Group: PropTypes.string.isRequired,
        category: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};

export default ComplianceTemplateGroup;
