import React, { useContext } from 'react';
import { Card, Paragraph, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import { Image } from '../../components/atoms';
import { SelectedComplianceItem } from './selected-complianceI-item-context';
import getFileTypeIcon from '../../helpers/get-file-type-icon';

const DocumentCard = ({ document, showSelectedDocumentDialog }) => {
    const { setSelectedDocument } = useContext(SelectedComplianceItem);

    const selectDocument = () => {
        setSelectedDocument(document);
        showSelectedDocumentDialog();
    };

    const renderThumbnail = () => {
        if (document.fileThumbnailLink) {
            return (
                <Image
                    src={document.fileThumbnailLink}
                    alt="document"
                    width={200}
                    height={287}
                    borderRadius={10}
                    paddingTop={40}
                />
            );
        }
        return (
            <Image
                src={getFileTypeIcon(document.fileExtension)}
                alt="document"
                width={200}
                height={287}
                padding={60}
                paddingTop={85}
                style={{ objectFit: 'contain' }}
                borderRadius={10}
            />
        );
    };

    return (
        <Card
            elevation={3}
            backgroundColor="white"
            borderRadius={10}
            maxWidth={200}
            width="100%"
            height={287}
            margin={12}
            onClick={selectDocument}
            hoverElevation={4}
            position="relative"
            cursor="pointer"
        >
            <Card
                position="absolute"
                width={200}
                height={50}
                backgroundColor="white"
                borderTopRightRadius={10}
                borderTopLeftRadius={10}
                borderBottomRightRadius={0}
                borderBottomLeftRadius={0}
                elevation={1}
                paddingY={8}
                paddingX={16}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
            >
                <Text
                    color="#252C32"
                    fontSize={16}
                    fontWeight="700"
                    lineHeight="16px"
                >
                    {document.friendlyName}
                </Text>
                <Paragraph color="#9B9B9B" fontSize={12} fontWeight="600">
                    {DateTime.fromMillis(document.createdAt).toFormat(
                        'd LLL yyyy'
                    )}
                </Paragraph>
            </Card>
            {renderThumbnail()}
        </Card>
    );
};

DocumentCard.propTypes = {
    document: PropTypes.shape().isRequired,
    showSelectedDocumentDialog: PropTypes.func.isRequired,
};

export default DocumentCard;
