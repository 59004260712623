import React, { useState } from 'react';
import { Dialog, Pane, TextInputField, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import getDate from '../../helpers/get-date';
import DatepickerCustomHeader from '../organisms/date-picker/date-picker-custom-header';
import { markMaintenanceItemServiced } from '../../reducers/maintenance-reducer/maintenance-actions';
import { successToast, errorToast } from '../toasts';

function MaintenanceServiceDateModal({ isShown, close, maintenanceItemId }) {
    const dispatch = useDispatch();

    const [servicedDate, setServicedDate] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleServicedDate = (date) => {
        const parsedDate = Date.parse(date);
        setServicedDate(parsedDate);
    };

    const handleClose = () => {
        setServicedDate(null);
        close();
    };

    const handleConfirm = async () => {
        if (!servicedDate) {
            return;
        }
        try {
            setLoading(true);
            await dispatch(
                markMaintenanceItemServiced(maintenanceItemId, servicedDate)
            );
            successToast('Successfully marked maintenance item as serviced.');
            close();
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog
            isConfirmDisabled={!servicedDate}
            onConfirm={handleConfirm}
            title="Set Serviced Date"
            isShown={isShown}
            onCloseComplete={handleClose}
            isConfirmLoading={loading}
        >
            <Pane display="flex" flexDirection="column">
                <DatePicker
                    placeholderText="Pick Serviced Date"
                    calendarClassName="calendar"
                    popperClassName="calendar"
                    dateFormat="dd/MM/yyyy"
                    onChange={handleServicedDate}
                    selected={servicedDate}
                    customInput={
                        <TextInputField
                            value={servicedDate}
                            width="100%"
                            inputHeight={40}
                            className="add-category-input"
                        />
                    }
                    renderCustomHeader={(props) => (
                        <DatepickerCustomHeader {...props} />
                    )}
                />
                {servicedDate ? (
                    <Text width="100%" textAlign="center">
                        Serviced on {getDate(servicedDate)}
                    </Text>
                ) : (
                    <Text width="100%" textAlign="center">
                        Please Select Serviced Date
                    </Text>
                )}
            </Pane>
        </Dialog>
    );
}

MaintenanceServiceDateModal.propTypes = {
    isShown: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    maintenanceItemId: PropTypes.number.isRequired,
};

export default MaintenanceServiceDateModal;
