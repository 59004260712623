import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    businesses: [],
    contacts: [],
    search: '',
    page: 1,
    pagination: {},
    businessContacts: [],
    professionFilter: '',
};

const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setIsLoadingAction(state, action) {
            state.isLoading = action.payload;
        },
        setBusinessesAction(state, action) {
            state.businesses = action.payload;
        },
        setContactsAction(state, action) {
            state.contacts = action.payload;
        },
        setBusinessContactsAction(state, { payload }) {
            state.businessContacts = payload.items;
        },
        setBusinessContactsPaginationAction(state, { payload }) {
            state.pagination = payload;
            if (state.pagination.items) {
                delete state.pagination.items;
            }
        },
        setPageAction(state, { payload }) {
            state.page = payload;
        },
        setProfessionFilterAction(state, { payload }) {
            state.professionFilter = payload;
        },
        setSearchAction(state, { payload }) {
            state.search = payload;
        },
    },
});

export const {
    setIsLoadingAction,
    setBusinessesAction,
    setContactsAction,
    setBusinessContactsAction,
    setBusinessContactsPaginationAction,
    setPageAction,
    setProfessionFilterAction,
    setSearchAction,
} = contactsSlice.actions;

export const contactsSelector = (reducers) => reducers.contactsReducer;

export default contactsSlice.reducer;
