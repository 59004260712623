import { cloneDeep } from 'lodash';

import userManagementService from '../../services/sub-services/user-management-service/user-management.service';
import {
    setUsersAction,
    setRolesAction,
    setPropertyGroupsAction,
    setPropertiesAction,
} from './user-management.reducer';
import {
    propertyGroupModel,
    roleModel,
} from '../../models/user-management.models';
import { assetModel } from '../../models/asset.models';

// Users

export function getUsers() {
    return async (dispatch, getState) => {
        const { user } = getState().userReducer;
        const users = await userManagementService.getUsers(user.id);
        dispatch(setUsersAction(users));
    };
}
export function addUser(user) {
    return async (dispatch) => {
        const newUser = await userManagementService.addUser(user);
        await dispatch(getUsers());
        return newUser;
    };
}
export function editUser(user) {
    return async (dispatch) => {
        const updatedUser = await userManagementService.editUser(user);
        await dispatch(getUsers());
        return updatedUser;
    };
}
export function deleteUser(id) {
    return async (dispatch) => {
        await userManagementService.deleteUser(id);
        await dispatch(getUsers());
    };
}

// Roles

export function getRoles() {
    return async (dispatch) => {
        const roles = await userManagementService.getRoles();
        dispatch(setRolesAction(roles.map((role) => roleModel(role))));
        return roles;
    };
}

export function createRole(role) {
    return async (dispatch) => {
        const newRole = await userManagementService.createRole(role);
        await dispatch(getRoles());
        return newRole;
    };
}

export function editRoles(
    role,
    moduleName = '',
    right = '',
    isName = false,
    name = ''
) {
    return async (dispatch, getState) => {
        const { roles } = getState().userManagementReducer;
        const rolesClone = cloneDeep(roles);

        const currentRole = rolesClone.find(
            (_role) => Number(_role.id) === Number(role.id)
        );

        if (isName) {
            currentRole.name = name;
        } else {
            if (!currentRole) {
                throw Error('Role not found');
            }
            const module = currentRole.access.find(
                (_module) => _module.name === moduleName
            );
            if (!module) {
                throw Error('Module not found');
            }

            if (right === 'read') {
                if (module?.rights?.read) {
                    Object.keys(module?.rights).forEach((key) => {
                        module.rights[key] = false;
                    });
                } else {
                    module.rights.read = !module?.rights?.read;
                }
            } else if (right === 'create') {
                if (module.rights.create) {
                    module.rights.create = false;
                } else {
                    module.rights.create = true;
                    module.rights.update = true;
                    module.rights.read = true;
                }
            } else if (!module?.rights[right] && !module?.rights?.read) {
                module.rights[right] = true;
                module.rights.read = true;
            } else {
                module.rights[right] = !module?.rights[right];
            }
        }

        dispatch(setRolesAction(rolesClone));
        await userManagementService.editRole(currentRole);
    };
}

export function deleteRole(id) {
    return async (dispatch) => {
        await userManagementService.deleteRole(id);
        await dispatch(getRoles());
    };
}

// Properties

export function getProperties() {
    return async (dispatch) => {
        const properties = await userManagementService.getProperties();
        dispatch(
            setPropertiesAction(
                properties.map((property) => assetModel(property))
            )
        );
        return properties;
    };
}

export function getPropertyGroups() {
    return async (dispatch) => {
        const propertyGroups = await userManagementService.getPropertyGroups();
        dispatch(
            setPropertyGroupsAction(
                propertyGroups.map((group) => propertyGroupModel(group))
            )
        );
        return propertyGroups;
    };
}

export function createPropertyGroup(propertyGroup) {
    return async (dispatch) => {
        const newPropertyGroup =
            await userManagementService.createPropertyGroup(propertyGroup);
        await dispatch(getPropertyGroups());
        return newPropertyGroup;
    };
}

export function updatePropertyGroup(propertyGroup) {
    return async (dispatch) => {
        const updatedPropertyGroup =
            await userManagementService.updatePropertyGroup(propertyGroup);
        await dispatch(getPropertyGroups());
        return updatedPropertyGroup;
    };
}

export function deletePropertyGroup(propertyGroupId) {
    return async (dispatch) => {
        await userManagementService.deletePropertyGroup(propertyGroupId);
        dispatch(getPropertyGroups());
    };
}

export function linkPropertiesToUser(data) {
    return async (dispatch) => {
        const response = await userManagementService.linkPropertyGroupToUser(
            data
        );
        await dispatch(getUsers());
        return response;
    };
}
