import React, { useMemo, useCallback, useState } from 'react';
import {
    Pane,
    Heading,
    Card,
    SearchInput,
    EyeOpenIcon,
    RefreshIcon,
    Text,
    EditIcon,
    TrashIcon,
    Button,
    PlusIcon,
    Pagination,
    TickCircleIcon,
    CloudUploadIcon,
    Checkbox,
} from 'evergreen-ui';
import { useTable } from 'react-table';
import { debounce, get } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { colors } from '../../theme/theme';
import MaintenanceStatusBadge from './MaintenanceStatusBadge';
import MaintenanceServiceDateModal from './maintenance-serviced-date-modal';
import Options from '../atoms/table-options-popup';
import {
    deleteAssetRegisterItem,
    getAssetRegisterData,
} from '../../reducers/maintenance-reducer/maintenance-actions';
import getDate from '../../helpers/get-date';
import useQueryParams from '../../hooks/use-query-params';
import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import {
    maintenanceSelector,
    setPageAction,
    setSearchAction,
} from '../../reducers/maintenance-reducer/maintenance-reducer';
import ConfirmDeleteModal from '../molecules/confirm-delete-modal';
import { errorToast, successToast } from '../toasts';
import { useUserAccess } from '../../context/user-access-context';
import { userSelector } from '../../reducers/user-reducer/user.reducer';
import DocumentUploadRequestSidebar from '../organisms/document-upload-request-sidebar/document-upload-request-sidebar';
import CreateContactSidebar from '../organisms/create-contact-sidebar/create-contact-sidebar';

function MaintenanceAssetRegisterTable({
    title,
    data,
    setShowEditAssetSidebar,
    setShowAddAssetSidebar,
    getTableData,
    viewAsset,
    pagination: { setTablePage, page, paginationInfo },
}) {
    const dispatch = useDispatch();
    const { allRights } = useUserAccess();

    const {
        create,
        read,
        update,
        delete: _delete,
    } = useMemo(
        () =>
            allRights?.maintenance || {
                create: false,
                read: false,
                update: false,
                delete: false,
            },
        [allRights]
    );

    const contactRights = useMemo(
        () =>
            allRights?.contacts || {
                create: false,
                read: false,
                update: false,
                delete: false,
            },
        [allRights]
    );

    const [showServicedDatePicker, setShowServicedDatePicker] = useState(false);
    const [maintenanceItemId, setMaintenanceItemId] = useState(null);
    const [maintenanceBuildingId, setMaintenanceBuildingId] = useState(null);
    const [
        showDocumentUploadRequestSidebar,
        setShowDocumentUploadRequestSidebar,
    ] = useState(false);
    const [showCreateContactsSidebar, setShowCreateContactsSidebar] =
        useState(false);
    const [confirmDelete, setConfirmDelete] = useState({
        isShown: false,
        item: null,
    });
    const [isAssignedToMe, setIsAssignedToMe] = useState(false);
    const { user } = useSelector(userSelector);

    const filteredData = useMemo(() => {
        const userId = user.id;
        if (isAssignedToMe) {
            return data.filter(({ assigned_user_id: userIds }) =>
                userIds.includes(userId)
            );
        }
        return data;
    }, [data, isAssignedToMe, user.id]);

    const { maintenanceItemId: maintenanceId } = useQueryParams();

    const { search } = useSelector(maintenanceSelector);
    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const renderStatus = useCallback(
        ({ value }) => <MaintenanceStatusBadge status={value} />,
        []
    );

    const renderDate = useCallback((value) => {
        const isNextServiceDate = value.column.Header === 'NEXT SERVICE DATE';
        const nextServiceDate = value.cell.row.original.next_service_date;

        const lastServiceDate = value.cell.row.original.last_service_date;

        const isLastServiceDate =
            value.column.Header === 'PREVIOUS SERVICE DATE';

        if (isLastServiceDate) {
            return lastServiceDate ? (
                <Text color="#696f8c">{getDate(lastServiceDate)}</Text>
            ) : (
                <Text color="#696f8c">N/A</Text>
            );
        }
        if (isNextServiceDate) {
            return nextServiceDate ? (
                <Text
                    color={
                        value.cell.row.original.next_service_date > new Date()
                            ? '#'
                            : '#696f8c'
                    }
                >
                    {getDate(nextServiceDate)}
                </Text>
            ) : (
                <Text color="#696f8c">-</Text>
            );
        }
        return <Text color="#696f8c">N/A</Text>;
    }, []);

    const searchMaintenanceItems = debounce(async () => {
        dispatch(getAssetRegisterData());
    }, 500);

    const handleSearch = async (_search) => {
        dispatch(setPageAction(1));
        dispatch(setSearchAction(_search));
        searchMaintenanceItems(_search);
    };

    const handleDelete = useCallback(async (maintenanceItem) => {
        setConfirmDelete({
            isShown: true,
            item: maintenanceItem,
        });
    }, []);

    const onDeleteConfirm = async () => {
        try {
            await dispatch(deleteAssetRegisterItem(confirmDelete?.item?.id));
            successToast(
                `Successfully deleted ${confirmDelete?.item?.description}`
            );
            closeConfirmDelete();
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        }
    };

    const closeConfirmDelete = () =>
        setConfirmDelete({
            isShown: false,
            item: null,
        });

    const options = useMemo(
        () => [
            {
                label: 'view',
                icon: {
                    Icon: EyeOpenIcon,
                    props: { size: 20, color: '#696f8c' },
                },
                hasRight: true,
                onClick: (e) => {
                    viewAsset(e);
                },
            },
            {
                label: 'edit',
                icon: { Icon: EditIcon, props: { size: 20, color: '#696f8c' } },
                hasRight: update,
                onClick: (e) => {
                    setShowEditAssetSidebar(e);
                },
            },
            {
                label: 'Mark as Serviced',
                icon: {
                    Icon: TickCircleIcon,
                    props: { size: 18, color: '#696f8c' },
                },
                hasRight: update,
                onClick: ({ id }) => {
                    setMaintenanceItemId(id);
                    setShowServicedDatePicker(true);
                },
            },
            {
                label: 'Document Upload Request',
                icon: {
                    Icon: CloudUploadIcon,
                    props: { size: 18, color: '#696f8c' },
                },
                hasRight: update,
                onClick: (item) => {
                    setMaintenanceItemId(item.id);
                    setMaintenanceBuildingId(item.buildings_id);
                    setShowDocumentUploadRequestSidebar(true);
                },
            },
            {
                label: 'delete',
                icon: {
                    Icon: TrashIcon,
                    props: { size: 18, color: '#d14343' },
                },
                hasRight: _delete,
                onClick: handleDelete,
            },
        ],
        [_delete, handleDelete, setShowEditAssetSidebar, update, viewAsset]
    );

    const renderOptions = useCallback(
        ({ row: { original } }) => (
            <Options
                key={`${original.id}-${original.name}`}
                options={options}
                selectedItem={original}
            />
        ),
        [options]
    );

    const currentProperty = useMemo(
        () => currentTenant || currentBuilding || currentAsset || null,
        [currentAsset, currentBuilding, currentTenant]
    );

    const columns = useMemo(
        () =>
            currentProperty
                ? [
                      {
                          Header: 'CATEGORY',
                          accessor: 'maintenance_categories',
                      },
                      {
                          Header: 'ASSET TYPE',
                          accessor: 'maintenance_types',
                      },
                      {
                          Header: 'RESPONSIBILITY TO REPAIR & MAINTAIN',
                          accessor: 'responsible_party',
                      },
                      {
                          Header: 'PREVIOUS SERVICE DATE',
                          accessor: 'last_service_date',
                          Cell: renderDate,
                      },
                      {
                          Header: 'NEXT SERVICE DATE',
                          accessor: 'next_service_date',
                          Cell: renderDate,
                      },
                      {
                          Header: 'STATUS',
                          accessor: 'status',
                          Cell: renderStatus,
                      },
                      {
                          Header: 'ACTIONS',
                          Cell: renderOptions,
                      },
                  ]
                : [
                      {
                          Header: 'CATEGORY',
                          accessor: 'maintenance_categories',
                      },
                      {
                          Header: 'ASSET TYPE',
                          accessor: 'maintenance_types',
                      },
                      {
                          Header: 'PROPERTY',
                          accessor: 'buildingName',
                      },
                      {
                          Header: 'RESPONSIBILITY TO REPAIR & MAINTAIN',
                          accessor: 'responsible_party',
                      },
                      {
                          Header: 'PREVIOUS SERVICE DATE',
                          accessor: 'last_service_date',
                          Cell: renderDate,
                      },
                      {
                          Header: 'NEXT SERVICE DATE',
                          accessor: 'next_service_date',
                          Cell: renderDate,
                      },
                      {
                          Header: 'STATUS',
                          accessor: 'status',
                          Cell: renderStatus,
                      },
                      {
                          Header: 'ACTIONS',
                          Cell: renderOptions,
                      },
                  ],
        [currentProperty, renderDate, renderStatus, renderOptions]
    );

    const tableInstance = useTable({
        columns,
        data: filteredData,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    const handlePrevPage = () => {
        if (page > 1) {
            setTablePage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < paginationInfo.maxPage) {
            setTablePage(page + 1);
        }
    };

    return (
        <>
            <Card
                elevation={1}
                backgroundColor="white"
                borderRadius={10}
                height="100%"
                margin={1}
                flex={1}
                display="flex"
                flexDirection="column"
            >
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="1.125rem"
                    borderBottom={`1px solid ${colors.neutral500}`}
                >
                    <Pane
                        display="flex"
                        gap={16}
                        alignItems="center"
                        justifyContent="space-between"
                        flex={1}
                    >
                        <Heading
                            fontSize={22}
                            fontWeight="700"
                            color={colors.primary500}
                        >
                            {title || 'Asset Register'}
                        </Heading>
                        <Pane display="flex" gap={16} alignItems="center">
                            <Checkbox
                                label="Assigned to me"
                                checked={isAssignedToMe}
                                onChange={() =>
                                    setIsAssignedToMe((prev) => !prev)
                                }
                            />
                            {false && (
                                <RefreshIcon
                                    size={20}
                                    color={colors.neutral700}
                                    onClick={getTableData}
                                />
                            )}
                            <SearchInput
                                style={{
                                    background: '#fafbff',
                                    borderRadius: '10px',
                                }}
                                placeholder="Search Asset"
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            {(currentTenant ||
                                currentBuilding ||
                                currentAsset) &&
                                create && (
                                    <Button
                                        appearance="primary"
                                        iconBefore={<PlusIcon />}
                                        onClick={setShowAddAssetSidebar}
                                    >
                                        Add new Asset
                                    </Button>
                                )}
                        </Pane>
                    </Pane>
                </Pane>
                <Pane padding={20}>
                    <StyledTable>
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps()}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-around',
                                                    }}
                                                >
                                                    <div>
                                                        {column.render(
                                                            'Header'
                                                        )}
                                                    </div>
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {data.length && read ? (
                                    rows.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr
                                                {...row.getRowProps()}
                                                style={{
                                                    backgroundColor:
                                                        Number(
                                                            row.original.id
                                                        ) ===
                                                        Number(maintenanceId)
                                                            ? '#f2f4fa'
                                                            : 'transparent',
                                                }}
                                            >
                                                {row.cells.map((cell) => (
                                                    <td
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <div className="empty-row">
                                            No Items to Display
                                        </div>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </StyledTable>
                </Pane>
                <Pagination
                    marginLeft="auto"
                    marginRight={20}
                    marginBottom={15}
                    page={page}
                    totalPages={paginationInfo.maxPage}
                    onPageChange={setTablePage}
                    onPreviousPage={handlePrevPage}
                    onNextPage={handleNextPage}
                />
            </Card>
            {update && (
                <>
                    <MaintenanceServiceDateModal
                        isShown={showServicedDatePicker}
                        maintenanceItemId={maintenanceItemId}
                        close={() => setShowServicedDatePicker(false)}
                    />
                    <DocumentUploadRequestSidebar
                        requestType="maintenance"
                        isShown={showDocumentUploadRequestSidebar}
                        setIsShown={setShowDocumentUploadRequestSidebar}
                        showCreateContactsSidebar={() =>
                            setShowCreateContactsSidebar(true)
                        }
                        maintenanceItemId={maintenanceItemId}
                        propertyId={maintenanceBuildingId}
                    />
                </>
            )}
            {contactRights?.create && (
                <CreateContactSidebar
                    isShown={showCreateContactsSidebar}
                    setIsShown={setShowCreateContactsSidebar}
                    onClose={() => setShowCreateContactsSidebar(false)}
                />
            )}
            {_delete && (
                <ConfirmDeleteModal
                    itemName={confirmDelete.item?.description}
                    shouldDispatch={false}
                    onConfirm={onDeleteConfirm}
                    isShown={confirmDelete.isShown}
                    close={closeConfirmDelete}
                    title="Delete Maintenance Item"
                />
            )}
        </>
    );
}

const StyledTable = styled.div`
    table {
        border: '1px solid ${colors.neutral500}';
        padding: 0;
        width: 100%;
        border-collapse: collapse;
        position: relative;
    }
    th {
        background-color: #fafbff;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        width: 168px;
        height: 60px;
        color: #696f8c;
        align-items: center;
        border: 1px solid ${colors.neutral500};
        gap: 8px;
        padding: 0 10px;
    }
    tr,
    .date {
        width: 168px;
        height: 60px;
        font-size: 14px;
        text-transform: capitalize;
    }
    td {
        border: 0.4579px solid #d8dae5;
        text-align: center;
        color: #696f8c;
        position: relative;
    }
    div {
        display: flex;
        align-items: center;
    }
    .empty-row {
        display: flex;
        width: 100%;
        position: absolute;
        align-items: center;
        justify-content: center;
        height: 60px;
        border: 1px solid ${colors.neutral500};
        border-top: none;
    }
    tbody: {
        height: 200px;
    }
`;

MaintenanceAssetRegisterTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selectedBuilding: PropTypes.shape({ id: PropTypes.string }),
    setShowAddAssetSidebar: PropTypes.func.isRequired,
    setShowEditAssetSidebar: PropTypes.func.isRequired,
    getTableData: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    viewAsset: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
        page: PropTypes.number,
        setTablePage: PropTypes.func,
        paginationInfo: PropTypes.shape({
            maxPage: PropTypes.number,
        }),
    }).isRequired,
    title: PropTypes.string,
};

MaintenanceAssetRegisterTable.defaultProps = {
    selectedBuilding: null,
    title: '',
};

export default MaintenanceAssetRegisterTable;
