import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import useQueryParams from '../../hooks/use-query-params';
import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import { documentService } from '../../services';
import { complianceItemDocumentsModel } from '../../models';

const SelectedComplianceItem = createContext({});

const SelectedComplianceItemProvider = ({ children }) => {
    const { groupId, categoryId, typeId } = useQueryParams();
    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedBatchId, setSelectedBatchId] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedTypeId, setSelectedTypeId] = useState(null);
    const [selectedComplianceItem, setSelectedComplianceItem] = useState(null);

    const [
        selectedComplianceItemDocuments,
        setSelectedComplianceItemDocuments,
    ] = useState(null);
    const [
        isLoadingSelectedComplianceItemDocuments,
        setIsLoadingSelectedComplianceItemDocuments,
    ] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [nextDocument, setNextDocument] = useState(null);
    const [prevDocument, setPrevDocument] = useState(null);
    const [isTenant, setIsTenant] = useState(false);

    const showDocumentUpload = useMemo(
        () =>
            groupId &&
            categoryId &&
            typeId &&
            selectedGroupId &&
            selectedCategoryId &&
            selectedTypeId,
        [
            selectedGroupId,
            selectedCategoryId,
            selectedTypeId,
            groupId,
            categoryId,
            typeId,
        ]
    );

    const asset = useMemo(
        () => currentTenant || currentBuilding || currentAsset,
        [currentTenant, currentBuilding, currentAsset]
    );

    useEffect(() => {
        if (!asset || !asset.complianceGroups) {
            return;
        }

        if (!selectedGroupId) {
            setSelectedGroupId(groupId);
        }

        if (!selectedCategoryId) {
            setSelectedCategoryId(categoryId);
        }

        setSelectedTypeId(typeId);

        const group = _.find(
            asset.complianceGroups,
            (_group) => _group.id.toString() === groupId
        );

        if (!group || !group.complianceCategories) {
            return;
        }

        const category = _.find(
            group.complianceCategories,
            (_category) => _category.id.toString() === categoryId
        );

        if (!category || !category.complianceTypes) {
            return;
        }

        const type = _.find(
            category.complianceTypes,
            (_type) => _type.id.toString() === typeId
        );

        setSelectedComplianceItem(type);
    }, [
        asset,
        selectedGroupId,
        selectedCategoryId,
        selectedTypeId,
        groupId,
        categoryId,
        typeId,
    ]);

    const setDocument = useCallback(
        (document) => {
            if (!document || !selectedComplianceItemDocuments) {
                setSelectedDocument(null);
                setNextDocument(null);
                setPrevDocument(null);
                return;
            }

            setSelectedDocument(document);

            const currentIndex = selectedComplianceItemDocuments?.findIndex(
                (doc) => doc.id === document.id
            );

            if (currentIndex === 0) {
                setPrevDocument(null);
                setNextDocument(
                    selectedComplianceItemDocuments[currentIndex + 1] ?? null
                );
            } else if (
                currentIndex ===
                selectedComplianceItemDocuments.length - 1
            ) {
                setNextDocument(null);
                setPrevDocument(
                    selectedComplianceItemDocuments[currentIndex - 1] ?? null
                );
            } else {
                setPrevDocument(
                    selectedComplianceItemDocuments[currentIndex - 1]
                );
                setNextDocument(
                    selectedComplianceItemDocuments[currentIndex + 1]
                );
            }
        },
        [selectedComplianceItemDocuments]
    );

    const getComplianceTypeDocuments = useCallback(async () => {
        if (!typeId) {
            setSelectedComplianceItemDocuments(null);
            return;
        }

        setIsLoadingSelectedComplianceItemDocuments(true);

        try {
            const documents = complianceItemDocumentsModel(
                await documentService.getComplianceTypeDocuments(typeId)
            );

            if (_.isEmpty(documents)) {
                setSelectedComplianceItemDocuments(null);
                setDocument(null);
            } else {
                const thumbnailIds = documents.reduce((acc, document) => {
                    if (document.thumbnailKey) {
                        acc.push({
                            assetId: asset.bucketId,
                            fileId: document.thumbnailId,
                            fileKey: document.thumbnailKey,
                        });
                    }
                    return acc;
                }, []);

                const thumbnailUrls =
                    await documentService.getFilesDownloadLink(thumbnailIds);

                Object.keys(thumbnailUrls).forEach((key) => {
                    const document = documents.find(
                        (doc) => doc.thumbnailId === key
                    );
                    if (document) {
                        document.fileThumbnailLink = thumbnailUrls[key];
                    }
                });

                setSelectedComplianceItemDocuments(documents);
                setDocument(documents[0]);
            }
        } catch (error) {
            setSelectedComplianceItemDocuments(null);
            setDocument(null);
        } finally {
            setIsLoadingSelectedComplianceItemDocuments(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeId, asset]);

    useEffect(() => {
        getComplianceTypeDocuments();
    }, [asset, typeId, getComplianceTypeDocuments]);

    const selectComplianceType = useCallback(
        (_groupId, _categoryId, type, _isTenant) => {
            setSelectedGroupId(_groupId);
            setSelectedCategoryId(_categoryId);
            setSelectedTypeId(type?.id || null);
            setSelectedComplianceItem(type);
            setIsTenant(_isTenant);
        },
        []
    );

    const value = useMemo(
        () => ({
            groupId: selectedGroupId,
            categoryId: selectedCategoryId,
            typeId: selectedTypeId,
            selectedComplianceItem,
            showDocumentUpload,
            isLoadingSelectedComplianceItemDocuments,
            selectedComplianceItemDocuments,
            selectComplianceType,
            getComplianceTypeDocuments,
            selectedDocument,
            nextDocument,
            prevDocument,
            setSelectedDocument: setDocument,
            isTenant,
            selectedBatchId,
            setSelectedBatchId,
            setSelectedComplianceItemDocuments,
        }),
        [
            selectedGroupId,
            selectedCategoryId,
            selectedTypeId,
            selectedComplianceItem,
            showDocumentUpload,
            isLoadingSelectedComplianceItemDocuments,
            selectedComplianceItemDocuments,
            selectComplianceType,
            getComplianceTypeDocuments,
            selectedDocument,
            nextDocument,
            prevDocument,
            setDocument,
            isTenant,
            selectedBatchId,
            setSelectedBatchId,
            setSelectedComplianceItemDocuments,
        ]
    );

    return (
        <SelectedComplianceItem.Provider value={value}>
            {children}
        </SelectedComplianceItem.Provider>
    );
};

SelectedComplianceItemProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { SelectedComplianceItem, SelectedComplianceItemProvider };
