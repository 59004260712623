import { get } from 'lodash';

export const getMaintenanceItemModel = (asset) => ({
    buildingId: get(asset, 'buildings_id', ''),
    category: get(asset, 'category', ''),
    contactsBusiness: get(asset, 'contacts_businessname', ''),
    createdAt: get(asset, 'created_at', ''),
    description: get(asset, 'description', ''),
    documents: get(asset, 'documents', ''),
    id: get(asset, 'id', ''),
    identifier: get(asset, 'internal_identifier', ''),
    jobId: get(asset, 'jobId', ''),
    lastServiceDate: get(asset, 'last_service_date', ''),
    installationDate: get(asset, 'installation_date', ''),
    liabilityClause: get(asset, 'liability_clause', ''),
    lifetime: get(asset, 'lifetime', ''),
    categoryId: get(asset, 'maintenance_categories_id', ''),
    type: get(asset, 'type', ''),
    nextServiceDate: get(asset, 'next_service_date', ''),
    ownership: get(asset, 'ownership', ''),
    repeatJobKey: get(asset, 'repeatJobKey', ''),
    responsibleParty: get(asset, 'responsible_party', ''),
    rrule: get(asset, 'rrule', ''),
    buildingName: get(asset, 'buildingName', ''),
    bucketId: get(asset, 'bucketId', ''),
    serviceProvider: {
        id: get(asset, 'service_provider_staff.id', ''),
        name: get(asset, 'service_provider_staff.name', ''),
        surname: get(asset, 'service_provider_staff.surname', ''),
        profession: get(asset, 'service_provider_staff.profesion', ''),
        number: get(asset, 'service_provider_staff.mobileno', ''),
        email: get(asset, 'service_provider_staff.email', ''),
        companyName: get(
            asset,
            'service_provider_staff.business.comp_name',
            ''
        ),
    },
    status: get(asset, 'status', ''),
    underWarranty: get(asset, 'under_warranty', ''),
    uploadPending: get(asset, 'upload_pending', ''),
    warrantyExpire: get(asset, 'warranty_expire', ''),
    isNew: get(asset, 'is_new', true),
    assignedUserIds: get(asset, 'assigned_user_id', []),
});

export const maintenanceItemAPIModel = (asset) => ({
    buildings_id: get(asset, 'buildingId', ''),
    category: get(asset, 'category', ''),
    contacts_businessname: get(asset, 'contactsBusiness', ''),
    created_at: get(asset, 'createdAt', ''),
    description: get(asset, 'description', ''),
    documents: get(asset, 'documents', ''),
    id: get(asset, 'id', ''),
    internal_identifier: get(asset, 'identifier', ''),
    jobId: get(asset, 'jobId', ''),
    last_service_date: get(asset, 'lastServiceDate', ''),
    installation_date: get(asset, 'installationDate', ''),
    liability_clause: get(asset, 'liabilityClause', ''),
    lifetime: get(asset, 'lifetime', ''),
    maintenance_categories_id: get(asset, 'category.id', ''),
    maintenance_categories: get(asset, 'category.name', ''),
    maintenance_types: get(asset, 'type.name', ''),
    maintenance_types_id: get(asset, 'type.id', ''),
    type: get(asset, 'type', ''),
    next_service_date: get(asset, 'nextServiceDate', ''),
    ownership: get(asset, 'ownership', ''),
    repeatJobKey: get(asset, 'repeatJobKey', ''),
    responsible_party: get(asset, 'responsibleParty', ''),
    rrule: get(asset, 'rrule', ''),
    service_provider: get(asset, 'serviceProvider', ''),
    status: get(asset, 'status', ''),
    under_warranty: get(asset, 'underWarranty', ''),
    upload_pending: get(asset, 'uploadPending', ''),
    warranty_expire: get(asset, 'warrantyExpire', ''),
    is_new: get(asset, 'isNew', ''),
    assigned_user_id: get(asset, 'assignedUserIds', []),
});

export const getMaintenanceCategoryModel = (category) => ({
    abbreviation: get(category, 'abbreviation', ''),
    businessId: get(category, 'business_id', ''),
    id: get(category, 'id', ''),
    isActive: get(category, 'isActive', ''),
    isDefault: get(category, 'is_default', ''),
    types: get(category, 'maintenance_types', ''),
    name: get(category, 'name', ''),
    tenantId: get(category, 'tenant_id', ''),
});

export const setMaintenanceCategoryModel = (category) => ({
    abbreviation: get(category, 'abbreviation', ''),
    business_id: get(category, 'businessId', ''),
    id: get(category, 'id', ''),
    isActive: get(category, 'isActive', ''),
    is_default: get(category, 'isDefault', ''),
    maintenance_types: get(category, 'types', ''),
    name: get(category, 'name', ''),
    tenant_id: get(category, 'tenantId', ''),
});

export const getMaintenanceTypeModel = (type) => ({
    id: get(type, 'id', ''),
    categoryId: get(type, 'maintenance_categories_id', ''),
    name: get(type, 'name', ''),
});

export const setMaintenanceTypeModel = (type) => ({
    id: get(type, 'id', ''),
    maintenance_category_id: get(type, 'categoryId', ''),
    name: get(type, 'name', ''),
});

export const maintenanceOverviewCardsModel = (data) => ({
    overdue: get(data, 'overdue', ''),
    due: get(data, 'due', ''),
    warrantyData: get(data, 'warrantyData', ''),
    serviced: get(data, 'qtyServiced', ''),
});

export default {
    maintenanceItemAPIModel,
    getMaintenanceItemModel,
    getMaintenanceCategoryModel,
    setMaintenanceCategoryModel,
    getMaintenanceTypeModel,
    setMaintenanceTypeModel,
    maintenanceOverviewCardsModel,
};
