import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    email: '',
    isFetchingAuthToken: false,
    fetchAuthTokenError: null,
    isAuthenticated: false,
    sessionExpired: false,
    maintenance: {
        isUnderMaintenance: false,
        message: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setEmailAction(state, action) {
            state.email = action.payload;
        },
        setIsFetchingAuthToken(state, action) {
            state.isFetchingAuthToken = action.payload;
        },
        setFetchAuthTokenError(state, action) {
            state.fetchAuthTokenError = action.payload;
        },
        setIsAuthenticatedAction(state, action) {
            state.isAuthenticated = action.payload;
        },
        setSessionExpiredAction(state, action) {
            state.sessionExpired = action.payload;
        },
        setMaintenanceAction(state, { payload }) {
            state.maintenance = payload;
        },
        clearState() {
            return initialState;
        },
    },
});

export const {
    setEmailAction,
    setIsFetchingAuthToken,
    setFetchAuthTokenError,
    setSessionExpiredAction,
    setIsAuthenticatedAction,
    setMaintenanceAction,
} = authSlice.actions;

export const authSelector = (reducers) => reducers.authReducer;

export default authSlice.reducer;
