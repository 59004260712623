import complianceUrls from './compliance-service-urls';
import {
    complianceGroupApiModel,
    createComplianceTypeApiModel,
    updateComplianceTypeApiModel,
    complianceCategoryApiModel,
} from '../../../models';
import networkService from '../network-service/network.service';

// Types

function createComplianceType(complianceTypeObject) {
    const url = complianceUrls.createComplianceTypeUrl();
    const data = createComplianceTypeApiModel(complianceTypeObject);
    return networkService.makePost(url, data);
}

function setComplianceTypeApplicable(complianceType, applicable) {
    const url = complianceUrls.updateComplianceTypeUrl(complianceType.id);
    const data = updateComplianceTypeApiModel({
        ...complianceType,
        applicable,
    });
    return networkService.makePut(url, data);
}

function renameComplianceType(complianceType, newName) {
    const url = complianceUrls.updateComplianceTypeUrl(complianceType.id);
    const data = updateComplianceTypeApiModel({
        ...complianceType,
        name: newName,
    });
    return networkService.makePut(url, data);
}

function deleteComplianceType(complianceTypeId) {
    const url = complianceUrls.deleteComplianceTypeUrl();
    const config = { params: { compliance_type_id: complianceTypeId } };
    return networkService.makeDelete(url, null, config);
}

// Categories

function createComplianceCategory(complianceCategory) {
    const url = complianceUrls.createComplianceCategory();
    const data = complianceCategoryApiModel(complianceCategory);
    return networkService.makePost(url, data);
}

function renameComplianceCategory(complianceCategory) {
    const url = complianceUrls.updateComplianceCategory(complianceCategory.id);
    const data = complianceCategoryApiModel(complianceCategory);
    return networkService.makePut(url, data);
}

function deleteComplianceCategory(complianceCategoryId) {
    const url = complianceUrls.deleteComplianceCategory(complianceCategoryId);
    return networkService.makeDelete(url);
}

// Groups

function createComplianceGroup(complianceGroup) {
    const url = complianceUrls.createComplianceGroup();
    const data = complianceGroupApiModel(complianceGroup);
    return networkService.makePost(url, data);
}

function renameComplianceGroup(complianceGroup) {
    const url = complianceUrls.updateComplianceGroup(complianceGroup.id);
    const data = complianceGroupApiModel(complianceGroup);
    return networkService.makePut(url, data);
}

function deleteComplianceGroup(complianceGroupId) {
    const url = complianceUrls.deleteComplianceGroup(complianceGroupId);
    return networkService.makeDelete(url);
}

// Templates

function getComplianceTemplates(businessId, type) {
    const url = complianceUrls.getComplianceTemplatesUrl();
    const config = {
        params: {
            business_id: businessId,
            type,
        },
    };
    return networkService.makeGet(url, config);
}

function addComplianceTemplate({ group, category, order, type }) {
    const url = complianceUrls.addComplianceGroupUrl();
    return networkService.makePost(url, {
        group,
        category,
        order,
        type,
    });
}

function deleteComplianceTemplateGroup(id) {
    const url = complianceUrls.deleteComplianceTemplate(id);
    return networkService.makeDelete(url);
}

function editComplianceTemplate({ complianceItemId, group, order, category }) {
    const url = complianceUrls.getComplianceTemplatesUrl();
    const data = {
        compliance_default_id: complianceItemId,
        group,
        order,
        category,
    };
    return networkService.makePut(url, data);
}

export default {
    createComplianceType,
    setComplianceTypeApplicable,
    deleteComplianceType,
    renameComplianceType,

    createComplianceCategory,
    renameComplianceCategory,
    deleteComplianceCategory,

    createComplianceGroup,
    renameComplianceGroup,
    deleteComplianceGroup,

    // Templates
    getComplianceTemplates,
    addComplianceTemplate,
    deleteComplianceTemplateGroup,
    editComplianceTemplate,
};
