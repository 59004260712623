import React, {
    createContext,
    useContext,
    useMemo,
    useCallback,
    useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import { userSelector } from '../reducers/user-reducer/user.reducer';
import { assetSelector } from '../reducers/asset-reducer/asset.reducer';

const MODULES = {
    menu_overview: 'portfolio',
    menu_info: 'info',
    menu_compliance: 'compliance',
    menu_maintenance: 'maintenance',
    menu_tasks: 'tasks',
    menu_contacts: 'contacts',
    user_management: 'user-management',
    template_management: 'template-management',
};

const UserAccess = createContext({
    modules: {
        portfolio: false,
        info: false,
        compliance: false,
        maintenance: false,
        tasks: false,
        contacts: false,
        'user-management': false,
        'template-management': false,
    },
    allRights: {
        portfolio: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
        info: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
        compliance: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
        maintenance: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
        tasks: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
        contacts: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
        'user-management': {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
        'template-management': {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
    },
    currentRights: {
        create: false,
        read: false,
        update: false,
        delete: false,
    },
});

function UserAccessProvider({ children }) {
    const { user } = useSelector(userSelector);

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const isAssetSelected = useMemo(
        () => !!currentAsset || !!currentBuilding || !!currentTenant,
        [currentAsset, currentBuilding, currentTenant]
    );

    const currentPath = useMemo(() => pathname.split('/'), [pathname]);

    const rootPath = useMemo(() => {
        if (
            currentPath.includes('compliance') ||
            currentPath.includes('info')
        ) {
            return currentPath[currentPath.length - 1];
        }
        return currentPath[1];
    }, [currentPath]);

    const access = useMemo(() => {
        const modules = {};
        const rights = {};
        user?.userAccess?.userRoles?.access.forEach(
            ({ item, create, read, update, delete: _delete }) => {
                modules[MODULES[item]] = read;
                rights[MODULES[item]] = {
                    create,
                    read,
                    update: create || update,
                    delete: _delete,
                };
            }
        );
        if (!modules.tasks && !modules.maintenance) {
            modules.portfolio = false;
        }

        return { modules, rights };
    }, [user]);

    const redirect = useCallback(() => {
        const { modules } = access;

        if (
            !modules.portfolio &&
            modules.info &&
            pathname === '/portfolio/properties'
        ) {
            return;
        }
        if (!modules[rootPath]) {
            let newPath = Object.values(MODULES).find(
                (value) => !!modules[value]
            );

            if (newPath === 'info' || newPath === 'compliance') {
                if (isAssetSelected) {
                    const module = newPath;
                    newPath = '/portfolio/properties';
                    if (currentAsset) {
                        newPath += `/parks/${currentAsset.id}`;
                    }
                    if (currentBuilding) {
                        newPath += `/buildings/${currentBuilding.id}`;
                    }
                    if (currentTenant) {
                        newPath += `/tenants/${currentTenant.id}`;
                    }
                    newPath += `/${module}`;
                    navigate(`${newPath}`, { replace: true });
                    return;
                }
                newPath = Object.values(MODULES)
                    .filter(
                        (module) => module !== 'info' && module !== 'compliance'
                    )
                    .find((value) => !!modules[value]);
            }

            if (newPath) {
                navigate(`/${newPath}`, { replace: true });
            }
        }
    }, [
        access,
        pathname,
        rootPath,
        isAssetSelected,
        currentAsset,
        currentBuilding,
        currentTenant,
        navigate,
    ]);

    const value = useMemo(
        () => ({
            modules: access?.modules,
            allRights: access?.rights,
            currentRights: access?.rights[rootPath],
        }),
        [access, rootPath]
    );

    useEffect(() => {
        redirect();
    }, [redirect, currentPath]);

    return <UserAccess.Provider value={value}>{children}</UserAccess.Provider>;
}

UserAccessProvider.propTypes = {
    children: PropTypes.element.isRequired,
};

export const useUserAccess = () => useContext(UserAccess);

export default UserAccessProvider;
