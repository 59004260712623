import networkService from '../network-service/network.service';
import spacesUrls from './spaces-service-urls';
import { spaceApiModel } from '../../../models';

function getAssetSpaces(assetId) {
    const url = spacesUrls.getAssetSpacesUrl();
    const config = { params: { asset_id: assetId } };
    return networkService.makeGet(url, config);
}

function getSpace(spaceId) {
    const url = spacesUrls.getSpaceUrl(spaceId);
    return networkService.makeGet(url);
}

function createSpace(spaceObject, assetId) {
    const url = spacesUrls.createSpace();
    const data = spaceApiModel({ ...spaceObject, assetId });
    return networkService.makePost(url, data);
}

function updateSpace(space) {
    const url = spacesUrls.updateSpaceUrl();
    const data = spaceApiModel(space);
    return networkService.makePut(url, data);
}

export default {
    getAssetSpaces,
    getSpace,
    createSpace,
    updateSpace,
};
