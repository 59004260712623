import React, { useCallback } from 'react';
import { Heading, Pane, SideSheet } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CreateAssetForm from '../../forms/asset-forms/create-asset-form';
import { colors } from '../../../theme/theme';
import { AutoFocusConsumer } from '../../atoms';
import { assetService } from '../../../services';
import { successToast } from '../../toasts';
import {
    assetSelector,
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from '../../../reducers/asset-reducer/asset.reducer';
import { assetModel } from '../../../models';
import { getAssets } from '../../../reducers/asset-reducer/asset.actions';

const CreateAssetSidebarForm = ({ isShown, close }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentAsset, currentBuilding } = useSelector(assetSelector);

    const submitCreateAssetForm = assetService.createAsset;

    const onCreateAssetSuccess = useCallback(
        async (asset) => {
            if (asset.type === 'park') {
                dispatch(setCurrentAssetAction(assetModel(asset)));
                navigate(`/portfolio/properties/parks/${asset.id}/info`);
            }
            if (asset.type === 'building') {
                dispatch(setCurrentBuildingAction(assetModel(asset)));
                navigate(`/portfolio/properties/buildings/${asset.id}/info`);
            }
            if (asset.type === 'tenant') {
                dispatch(setCurrentTenantAction(assetModel(asset)));
                navigate(`/portfolio/properties/tenants/${asset.id}/info`);
            }
            dispatch(getAssets());
            successToast(
                `Successfully created new ${asset.type} ${asset.name}`
            );
            close();
        },
        [close, dispatch, navigate]
    );

    return (
        <SideSheet
            isShown={isShown}
            onCloseComplete={close}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
            }}
            preventBodyScrolling
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
        >
            <AutoFocusConsumer />
            <Pane
                zIndex={1}
                flexShrink={0}
                elevation={0}
                backgroundColor="white"
            >
                <Pane padding={16}>
                    <Heading size={600}>
                        Add New{' '}
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {currentBuilding
                            ? 'Tenant'
                            : currentAsset
                            ? 'Building'
                            : 'Property'}
                    </Heading>
                </Pane>
            </Pane>
            <Pane
                flex="1"
                overflowY="scroll"
                background={colors.tint1}
                padding={16}
            >
                <Pane>
                    {isShown && (
                        <CreateAssetForm
                            submitForm={submitCreateAssetForm}
                            onSuccess={onCreateAssetSuccess}
                        />
                    )}
                </Pane>
            </Pane>
        </SideSheet>
    );
};

CreateAssetSidebarForm.propTypes = {
    isShown: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
};

export default CreateAssetSidebarForm;
