import React from 'react';
import PropTypes from 'prop-types';
import { Image as EvergreenImage } from 'evergreen-ui';
import styled from 'styled-components';

const Image = React.forwardRef(({ archived, ...props }, ref) => (
    <StyledImage
        width="100%"
        height="auto"
        archived={archived}
        ref={ref}
        {...props}
    />
));

Image.propTypes = {
    archived: PropTypes.string.isRequired,
};

export default Image;

const StyledImage = styled(EvergreenImage)`
    object-fit: cover;
    ${({ archived }) =>
        archived &&
        `
        filter: grayscale(100%) opacity(50%);
    `}
`;
