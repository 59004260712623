import React from 'react';
import { Pane, SelectField, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import _ from 'lodash';

const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

function YearlyRecurrenceForm({ handleChange, recurrenceForm }) {
    return (
        <Pane display="flex" flexDirection="column">
            <Pane display="flex" alignItems="center" gap={20}>
                <Text textAlign="end" width="15%" marginTop={-16}>
                    Every
                </Text>
                <SelectField
                    name="bymonth"
                    width="35%"
                    inputHeight={40}
                    onChange={handleChange}
                    value={recurrenceForm.bymonth}
                >
                    <option value="" disabled>
                        Select Recurrence Period
                    </option>
                    {MONTHS.map((item, index) => (
                        <option key={item} value={Number(index + 1)}>
                            {item}
                        </option>
                    ))}
                </SelectField>
            </Pane>
            <Pane display="flex" alignItems="center" gap={20}>
                <Text textAlign="end" width="15%" marginTop={-16}>
                    On
                </Text>
                <SelectField
                    name="bymonthday"
                    width="35%"
                    inputHeight={40}
                    onChange={handleChange}
                    value={recurrenceForm.bymonthday}
                >
                    <option value="" disabled>
                        Select Recurrence Period
                    </option>
                    {_.range(1, 32).map((item, index) => (
                        <option key={item} value={Number(index + 1)}>
                            {item}
                        </option>
                    ))}
                </SelectField>
                <Text marginTop={-16}>Day</Text>
            </Pane>
        </Pane>
    );
}

YearlyRecurrenceForm.propTypes = {
    handleChange: PropTypes.func.isRequired,
    recurrenceForm: PropTypes.shape({
        bymonth: PropTypes.string.isRequired,
        bymonthday: PropTypes.number.isRequired,
    }).isRequired,
};

export default YearlyRecurrenceForm;
