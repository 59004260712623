import { API_BASE_URL } from '../network-service/network.service';

const documentsBaseUrl = '/Documents';

export default {
    getUploadUrl: () => `${API_BASE_URL}/documents/generateUploadUrls`,
    documentUploadSuccessUrl: () => `${documentsBaseUrl}/docsuploaded/v2`,
    getComplianceTypeDocumentsUrl: () => `${documentsBaseUrl}/v2`,
    getFilesDownloadLinkUrl: () =>
        `${API_BASE_URL}/documents/generateDownloadUrls`,
    updateDocumentFriendlyName: () => `${documentsBaseUrl}/update/v2`,
    deleteDocument: () => `${documentsBaseUrl}/delete`,
};
