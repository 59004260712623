import React from 'react';
import { toast } from 'react-toastify';
import { ErrorIcon } from 'evergreen-ui';

function showErrorToastMessage(message) {
    toast.error(message, {
        icon: <ErrorIcon color="danger" size={16} />,
    });
}

export default showErrorToastMessage;
