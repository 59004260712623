import { maintenanceItemAPIModel } from '../../../models/maintenance.models';
import networkService from '../network-service/network.service';
import { setDocumentUploadSuccessfullyModel } from '../../../models';
import maintenanceUrls from './maintenance-service-urls';

const baseURL = 'https://xkrn-4n4o-aozy.e2.xano.io/api:EXlzFI6D';

export function getMaintenanceCategoriesGraphs({
    buildingId = 0,
    startDate,
    endDate,
    search,
}) {
    let url = `${maintenanceUrls.getMaintenanceCategoriesGraphsURL()}?building_id=${buildingId}&search=${search}`;

    if (endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
    }

    return networkService.makeGet(url, {
        baseURL,
    });
}

export function getScheduledMaintenanceData({
    startDate,
    endDate,
    buildingId = 0,
    search,
}) {
    let url = `${maintenanceUrls.getScheduledMaintenanceGraphsURL()}?buildings_id=${buildingId}&search=${search}`;
    if (endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
    }
    return networkService.makeGet(url, {
        baseURL,
    });
}

export async function getAssetRegisterData(
    dateRange,
    portfolioLevel = false,
    search = '',
    page = 1,
    sort = null,
    buildingId = 0
) {
    let url = `${maintenanceUrls.getAssetRegisterDataByUrl()}?portfolioLevel=${portfolioLevel}&search=${search}&page=${page}&sort=${sort}&buildings_id=${buildingId}`;

    if (dateRange.endDate) {
        url += `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
    }

    return networkService.makeGet(url, {
        baseURL,
    });
}

export function deleteAssetRegisterItem(assetRegisterItemId) {
    const url = maintenanceUrls.deleteAssetRegisterItem();
    return networkService.makeDelete(
        `${url}?maintenance_items_id=${assetRegisterItemId}`,
        {},
        {
            baseURL,
        }
    );
}
export function addAssetRegisterItem(maintenanceItem) {
    const url = maintenanceUrls.addAssetRegisterItem();
    const data = maintenanceItemAPIModel(maintenanceItem);
    return networkService.makePost(url, data, {
        baseURL,
    });
}

export function editAssetRegisterItem(maintenanceItem) {
    const url = maintenanceUrls.editAssetRegisterItem();
    const data = maintenanceItemAPIModel(maintenanceItem);
    return networkService.makePut(url, data, {
        baseURL,
    });
}

export function getMaintenanceItemUploadLinkRequest(
    maintenanceItemId,
    fileType
) {
    const url = maintenanceUrls.getMaintenanceUploadLinkRequest();
    return networkService.makePost(
        `${url}?filetype=${fileType}&asset_id=${maintenanceItemId}`,
        null,
        {
            baseURL,
        }
    );
}

export function getMaintenanceItemDocuments(maintenanceItemId) {
    const url = maintenanceUrls.getMaintenanceDocuments();
    return networkService.makeGet(
        `${url}?request_type=maintenance&maintenance_items_id=${maintenanceItemId}`,
        {
            baseURL,
        }
    );
}

export function setMaintenanceDocumentUploadedSuccessfully(values) {
    const url = maintenanceUrls.setMaintenanceDocumentUploadedSuccessfullyURL();
    const data = setDocumentUploadSuccessfullyModel(values);
    return networkService.makePost(url, data, {
        baseURL: 'https://xkrn-4n4o-aozy.e2.xano.io/api:KIF-JcPf',
    });
}

export function getMaintenanceCategories() {
    const url = maintenanceUrls.getMaintenanceCategoriesURL();
    return networkService.makeGet(url, {
        baseURL,
    });
}

export function deleteMaintenanceCategory(categoryId) {
    const url = maintenanceUrls.deleteMaintenanceCategoryURL();
    return networkService.makeDelete(
        `${url}?maintenance_categories_id=${categoryId}`,
        null,
        {
            baseURL,
        }
    );
}

export function createMaintenanceCategory(
    categoryName,
    abbreviation,
    isDefault = false,
    isActive = true
) {
    const url = maintenanceUrls.createMaintenanceCategoryURL();

    return networkService.makePost(
        `${url}?name=${categoryName}&abbreviation=${abbreviation}&is_default=${isDefault}&isActive=${isActive}`,
        null,
        {
            baseURL,
        }
    );
}

export function updateMaintenanceCategory(categoryId, name, abbreviation) {
    const url = maintenanceUrls.updateMaintenanceCategoryURL();
    const data = {
        maintenance_categories_id: categoryId,
        name,
        abbreviation,
    };
    return networkService.makePut(url, data, {
        baseURL,
    });
}

export function createMaintenanceType(categoryId, name) {
    const url = maintenanceUrls.createMaintenanceTypeURL();
    const data = {
        maintenance_categories_id: categoryId,
        name,
    };
    return networkService.makePost(url, data, {
        baseURL,
    });
}

export function deleteMaintenanceType(typeId) {
    const url = maintenanceUrls.deleteMaintenanceTypeURL();
    return networkService.makeDelete(
        `${url}?maintenance_types_id=${typeId}`,
        null,
        { baseURL }
    );
}

export function updateMaintenanceType(typeId, name) {
    const url = maintenanceUrls.updateMaintenanceTypeURL();
    const data = {
        maintenance_types_id: typeId,
        name,
    };
    return networkService.makePut(url, data, {
        baseURL,
    });
}

export function getMaintenanceOverviewCards({
    buildingId = 0,
    startDate,
    endDate,
}) {
    let url = `${maintenanceUrls.getMaintenanceOverviewCardsURL()}?buildings_id=${buildingId}`;

    if (startDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
    }

    return networkService.makeGet(url, {
        baseURL,
    });
}

export function markMaintenanceItemServiced(maintenanceItemId, serviceDate) {
    const url = maintenanceUrls.markMaintenanceItemServicedURL();
    return networkService.makePost(
        url,
        {
            maintenance_items_id: maintenanceItemId,
            date_serviced: serviceDate,
        },
        {
            baseURL,
        }
    );
}

export default {
    getMaintenanceCategoriesGraphs,
    getMaintenanceItemUploadLinkRequest,
    setMaintenanceDocumentUploadedSuccessfully,
    getMaintenanceItemDocuments,
    getScheduledMaintenanceData,
    getAssetRegisterData,
    getMaintenanceCategories,
    deleteAssetRegisterItem,
    addAssetRegisterItem,
    editAssetRegisterItem,
    deleteMaintenanceCategory,
    createMaintenanceCategory,
    updateMaintenanceCategory,
    createMaintenanceType,
    updateMaintenanceType,
    deleteMaintenanceType,
    getMaintenanceOverviewCards,
    markMaintenanceItemServiced,
};
