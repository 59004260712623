import React, { useRef, useState } from 'react';
import { Dialog, TextInputField } from 'evergreen-ui';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { renameAssetComplianceType } from '../../../reducers/asset-reducer/asset.actions';

const RenameComplianceTypeDialog = ({
    groupId,
    categoryId,
    complianceType,
    isShown,
    setIsShown,
}) => {
    const dispatch = useDispatch();
    const inputRef = useRef();

    const [complianceTypeName, setComplianceTypeName] = useState(
        complianceType.name
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    const selectFocusText = () => {
        inputRef.current.select();
    };

    const close = () => {
        setIsShown(false);
    };

    const onChange = (event) => {
        setComplianceTypeName(event.target.value);
    };

    const submit = async () => {
        setIsSubmitting(true);

        try {
            dispatch(
                renameAssetComplianceType(
                    groupId,
                    categoryId,
                    complianceType,
                    complianceTypeName
                )
            );
            close();
        } catch (error) {
            setSubmitError(
                _.get(error, 'response.data.message', error.message)
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog
            isShown={isShown}
            title="Rename Compliance Type"
            onCloseComplete={close}
            confirmLabel="Rename"
            hasClose={false}
            onConfirm={submit}
            isConfirmLoading={isSubmitting}
        >
            <TextInputField
                isInvalid={!!submitError}
                required
                label="Compliance Type Name"
                validationMessage={submitError}
                onChange={onChange}
                value={complianceTypeName}
                tabindex="0"
                onFocus={selectFocusText}
                ref={inputRef}
            />
        </Dialog>
    );
};

RenameComplianceTypeDialog.propTypes = {
    groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    complianceType: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
    }).isRequired,
    isShown: PropTypes.bool.isRequired,

    setIsShown: PropTypes.func.isRequired,
};

export default RenameComplianceTypeDialog;
