import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';
import SpacesListSidebar from '../spaces-list-sidebar/spaces-list-sidebar';
import CreateAssetSidebarForm from '../create-asset-sidebar-form/create-asset-sidebar-form';
import Tenant from '../../../assets/images/icons/tenant';

const AssetSidebarTenants = ({ selectedAsset, shouldFetchSpaces, rights }) => {
    const [shouldShowTenantsSidebar, setShouldShowTenantsSidebar] =
        useState(false);
    const [isCreateAssetSidebarShown, setIsCreateAssetSidebarShown] =
        useState(false);
    const [isSpacesListSidebarShown, setIsSpacesListSidebarShown] =
        useState(false);

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const { create } = rights || { create: false };

    const { children: spaces } = useMemo(
        () => currentBuilding || currentAsset || [],
        [currentAsset, currentBuilding]
    );

    const showCreateAssetSidebar = () => {
        setIsCreateAssetSidebarShown(true);
    };

    const showSpacesListSidebarShown = () => {
        setIsSpacesListSidebarShown(true);
    };

    const showCreateTenantSidebar = () => {
        showCreateAssetSidebar();
        setShouldShowTenantsSidebar(true);
    };

    const onCreateAssetSidebarClose = () => {
        setIsCreateAssetSidebarShown(false);
        if (shouldShowTenantsSidebar) {
            showSpacesListSidebarShown();
        }
    };

    const buttonText = useMemo(() => {
        if (currentBuilding) {
            if (spaces.length) {
                if (spaces.length > 1) {
                    return `${spaces.length} Tenants`;
                }
                return '1 Tenant';
            }
            return 'Add Tenant';
        }
        if (currentAsset) {
            if (spaces.length) {
                if (spaces.length > 1) {
                    return `${spaces.length} Buildings`;
                }
                return '1 Building';
            }
            return 'Add Building';
        }
        return '';
    }, [currentAsset, currentBuilding, spaces]);

    const handleTenantButton = () => {
        if (_.size(spaces) > 0) {
            showSpacesListSidebarShown();
            return;
        }
        if (create) {
            showCreateAssetSidebar();
        }
    };

    const tenantButtonDisabled = useMemo(() => {
        const hasSpaces = spaces.length > 0;
        if (!hasSpaces && !create) {
            return true;
        }
        return false;
    }, [create, spaces]);

    return (
        <>
            {!currentTenant && (
                <Pane marginBottom={20}>
                    <TenantButton
                        intent="success"
                        iconBefore={
                            <Tenant
                                color={
                                    tenantButtonDisabled
                                        ? '#c2c4d690'
                                        : '#8f95b2'
                                }
                            />
                        }
                        onClick={handleTenantButton}
                        disabled={tenantButtonDisabled}
                    >
                        {buttonText}
                    </TenantButton>
                </Pane>
            )}
            {create && (
                <CreateAssetSidebarForm
                    isShown={isCreateAssetSidebarShown}
                    close={onCreateAssetSidebarClose}
                />
            )}

            <SpacesListSidebar
                isShown={isSpacesListSidebarShown}
                setIsShown={setIsSpacesListSidebarShown}
                showCreateTenantSidebar={showCreateTenantSidebar}
                selectedAsset={selectedAsset}
                spaces={spaces}
                shouldFetchComplianceProgress={shouldFetchSpaces}
                rights={rights}
            />
        </>
    );
};

AssetSidebarTenants.propTypes = {
    shouldFetchSpaces: PropTypes.bool,
    selectedAsset: PropTypes.shape(),
    rights: PropTypes.shape({
        create: PropTypes.bool,
        read: PropTypes.bool,
        update: PropTypes.bool,
        delete: PropTypes.bool,
    }).isRequired,
};

AssetSidebarTenants.defaultProps = {
    selectedAsset: null,
    shouldFetchSpaces: false,
};

export default AssetSidebarTenants;

const TenantButton = styled(Button)`
    border-color: #5ec090;
    background-color: transparent;
    border-radius: 9999px;
    font-weight: 600;
    font-size: 14px;
    color: #8f95b2;
`;
