import _ from 'lodash';

export const businessModel = (businessObject = {}) => ({
    id: _.get(businessObject, 'id', ''),
    legalName: _.get(businessObject, 'legalname', ''),
    friendlyName: _.get(businessObject, 'friendlyname', ''),
    officeNumber: _.get(businessObject, 'officenumber', ''),
    officeMobile: _.get(businessObject, 'officemobile', ''),
    adminEmail: _.get(businessObject, 'email', ''),
    address: {
        addressLine1: _.get(businessObject, 'address.address1', ''),
        addressLine2: _.get(businessObject, 'address.address2', ''),
        suburb: _.get(businessObject, 'address.suburb', ''),
        city: _.get(businessObject, 'address.city', ''),
        province: _.get(businessObject, 'address.province', ''),
        postalCode: _.get(businessObject, 'address.postalcode', ''),
        country: _.get(businessObject, 'address.country', ''),
    },
    billingContactName: _.get(businessObject, 'billing_contactname', ''),
    companyRegistrationNumber: _.get(businessObject, 'regnumber', ''),
    companyVatNumber: _.get(businessObject, 'vatnumber', ''),
    billingContactEmail: _.get(businessObject, 'billing_contactemail', ''),
});

export const businessApiModel = (businessObject = {}) => ({
    legalname: _.get(businessObject, 'legalName', ''),
    friendlyname: _.get(businessObject, 'friendlyName', ''),
    officenumber: _.get(businessObject, 'officeNumber', ''),
    officemobile: _.get(businessObject, 'officeMobile', ''),
    email: _.get(businessObject, 'adminEmail', ''),
    address: {
        address1: _.get(businessObject, 'address.addressLine1', ''),
        address2: _.get(businessObject, 'address.addressLine2', ''),
        suburb: _.get(businessObject, 'address.suburb', ''),
        city: _.get(businessObject, 'address.city', ''),
        province: _.get(businessObject, 'address.province', ''),
        postalcode: _.get(businessObject, 'address.postalCode', ''),
        country: _.get(businessObject, 'address.country', ''),
    },
    billing_contactname: _.get(businessObject, 'billingContactName', ''),
    regnumber: _.get(businessObject, 'companyRegistrationNumber', ''),
    vatnumber: _.get(businessObject, 'companyVatNumber', ''),
    billing_contactemail: _.get(businessObject, 'billingContactEmail', ''),
});

export const minimalBusinessModel = (businessObject) => ({
    legalName: _.get(businessObject, 'legalname', ''),
    friendlyName: _.get(businessObject, 'friendlyname', ''),
});

export const minimalBusinessApiModel = (businessObject) => ({
    legalname: _.get(businessObject, 'legalName', ''),
    friendlyname: _.get(businessObject, 'friendlyName', ''),
});
