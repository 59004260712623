import React from 'react';
import { Heading, Pane, SideSheet } from 'evergreen-ui';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { colors } from '../../../theme/theme';
import { AutoFocusConsumer } from '../../atoms';
import { assetService } from '../../../services';
import { successToast } from '../../toasts';
import EditAssetForm from '../../forms/asset-forms/edit-asset-form';
import {
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from '../../../reducers/asset-reducer/asset.reducer';
import { assetModel } from '../../../models';
import {
    getAsset,
    getBuilding,
} from '../../../reducers/asset-reducer/asset.actions';

const ASSET_TYPES = {
    park: 'Park',
    building: 'Building',
    tenant: 'Tenant',
};

const EditAssetSidebar = ({ isShown, close, asset }) => {
    const submitEditAssetForm = assetService.updateAsset;

    const dispatch = useDispatch();

    const onEditAssetSuccess = async (updatedAsset) => {
        if (updatedAsset.type === 'park') {
            dispatch(setCurrentAssetAction(assetModel(updatedAsset)));
        }
        if (updatedAsset.type === 'building') {
            dispatch(setCurrentBuildingAction(assetModel(updatedAsset)));
            if (updatedAsset.parentId) {
                dispatch(getAsset(updatedAsset.parentId, false, true));
            }
        }
        if (updatedAsset.type === 'tenant') {
            dispatch(setCurrentTenantAction(assetModel(updatedAsset)));
            const building = await dispatch(getBuilding(updatedAsset.parentId));
            if (building.parentId) {
                dispatch(getAsset(building.parentId));
            }
        }
        close();
        successToast(`Successfully updated ${updatedAsset.name}`);
    };

    return (
        <SideSheet
            isShown={isShown}
            onCloseComplete={close}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
            }}
            preventBodyScrolling
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
        >
            <AutoFocusConsumer />
            <Pane
                zIndex={1}
                flexShrink={0}
                elevation={0}
                backgroundColor="white"
            >
                <Pane padding={16}>
                    <Heading size={600}>
                        Edit {ASSET_TYPES[asset?.type]}
                    </Heading>
                </Pane>
            </Pane>
            <Pane
                flex="1"
                overflowY="scroll"
                background={colors.tint1}
                padding={16}
            >
                <Pane maxWidth={400} marginX="auto">
                    {isShown && (
                        <EditAssetForm
                            submitForm={submitEditAssetForm}
                            onSuccess={onEditAssetSuccess}
                            asset={asset}
                            assetType={ASSET_TYPES[asset.type]}
                        />
                    )}
                </Pane>
            </Pane>
        </SideSheet>
    );
};

EditAssetSidebar.propTypes = {
    isShown: PropTypes.bool.isRequired,
    asset: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,

    close: PropTypes.func.isRequired,
};

export default EditAssetSidebar;
