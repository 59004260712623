import React from 'react';
import { Heading, Pane, Paragraph } from 'evergreen-ui';
import { useSelector } from 'react-redux';

import { userSelector } from '../../../reducers/user-reducer/user.reducer';
import UploadRequestsList from './upload-requests-list';

const UploadRequestInfoSidebar = () => {
    const { user } = useSelector(userSelector);

    if (!user) {
        return null;
    }

    return (
        <Pane
            width={368}
            backgroundColor="#FAFBFF"
            borderRight="0.5px solid #C1C4D6"
            height="100%"
            position="fixed"
            left={0}
        >
            <Pane
                paddingTop={32}
                paddingX={24}
                paddingBottom={24}
                borderBottom="0.5px solid #C1C4D6"
                height={148}
            >
                <Paragraph
                    color="#C1C4D6"
                    fontSize={16}
                    lineHeight="24px"
                    fontWeight="600"
                >
                    Welcome
                </Paragraph>
                <Heading
                    fontWeight={700}
                    fontSize={28}
                    marginY={8}
                    color="#8F95B2"
                >
                    {user.firstName}
                </Heading>
                <Paragraph
                    color="#8F95B2"
                    fontSize={11}
                    marginTop={12}
                    lineHeight="17px"
                >
                    {user.profession} at {user.businessName}
                </Paragraph>
                <Paragraph color="#8F95B2" fontSize={11} lineHeight="17px">
                    {user.mobileNumber}
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    {user.email}{' '}
                </Paragraph>
            </Pane>
            <Pane paddingX={24}>
                <Heading
                    textTransform="uppercase"
                    color="#0C2138"
                    fontSize={11}
                    fontWeight={600}
                    marginTop={28}
                    marginBottom={16}
                >
                    Pending Uploads
                </Heading>
                <UploadRequestsList />
            </Pane>
        </Pane>
    );
};

export default UploadRequestInfoSidebar;
