import React, { useEffect, useState } from 'react';
import { Heading, Pane, Text, Link, Spinner, Alert } from 'evergreen-ui';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AuthScreenLayout from '../../components/layouts/auth-screen-layout';
import signupImage from '../../assets/images/illustrations/signup.svg';
import { Image } from '../../components/atoms';
import {
    getAuthToken,
    register,
} from '../../reducers/auth-reducer/auth.actions';
import useQueryParams from '../../hooks/use-query-params';
import SignupForm from '../../components/forms/auth-forms/sign-up-form';
import { registerModel } from '../../models';

const SignUpScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token, email } = useQueryParams();

    const [didSubmit, setDidSubmit] = useState(false);
    const [firstName, setFirstName] = useState(false);

    const { isFetchingAuthToken, isAuthenticated, fetchAuthTokenError } =
        useSelector((state) => state.authReducer);

    useEffect(() => {
        if (token) {
            dispatch(getAuthToken(token, true));
        }
    }, [dispatch, token]);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/portfolio');
        }
    }, [navigate, isAuthenticated]);

    const submitRegisterForm = register;

    const onRegisterSuccess = (name) => {
        setDidSubmit(true);
        setFirstName(name);
    };
    const renderHeroComponent = () => (
        <Image src={signupImage} alt="signup illustration" maxWidth="300px" />
    );

    const renderFetchMagicTokenLoader = () => (
        <Pane
            height={350}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Spinner marginBottom="1rem" />
            <Text size={500}>Logging you in...</Text>
        </Pane>
    );

    const renderAlert = () => {
        if (!fetchAuthTokenError) {
            return null;
        }

        return (
            <Alert
                intent="danger"
                title="Email verification failed"
                marginTop={16}
            >
                {fetchAuthTokenError}
            </Alert>
        );
    };

    const renderLoginForm = () => {
        if (didSubmit) {
            return (
                <Pane
                    height={532}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Heading size={500}>
                        {firstName} you have successfully signed up! We have
                        sent you an email to confirm your email address.
                    </Heading>
                    <Text size={400} alignSelf="center" marginTop="24px">
                        Already confirmed your email?{' '}
                        <Link is={RouterLink} to="/login">
                            Log In
                        </Link>
                    </Text>
                </Pane>
            );
        }
        return (
            <>
                <Heading is="h3" size={600} marginBottom="0.5rem">
                    Convenient record keeping starts here{' '}
                    <span role="img" aria-label="waving">
                        🚀
                    </span>
                </Heading>
                <Text size={500}>
                    Sign up and start capturing your asset records in just
                    minutes.
                </Text>
                {renderAlert()}
                <SignupForm
                    onSuccess={onRegisterSuccess}
                    submitForm={submitRegisterForm}
                    initialValues={registerModel({ email })}
                />
                <Text size={400} alignSelf="center" marginTop="24px">
                    Already have an account?{' '}
                    <Link is={RouterLink} to="/login">
                        Log In
                    </Link>
                </Text>
            </>
        );
    };

    return (
        <AuthScreenLayout heroComponent={renderHeroComponent()}>
            <Pane
                display="flex"
                flexDirection="column"
                maxWidth="400px"
                width="100%"
            >
                {isFetchingAuthToken || (token && !fetchAuthTokenError)
                    ? renderFetchMagicTokenLoader()
                    : renderLoginForm()}
            </Pane>
        </AuthScreenLayout>
    );
};

export default SignUpScreen;
