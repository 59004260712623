import React, { useEffect, useState, useMemo } from 'react';
import { Alert, Heading, Link, Pane, Spinner, Text } from 'evergreen-ui';
import {
    Link as RouterLink,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    getAuthToken,
    login,
    getSystemStatus,
} from '../../reducers/auth-reducer/auth.actions';
import AuthScreenLayout from '../../components/layouts/auth-screen-layout';
import { Image } from '../../components/atoms';
import loginImage from '../../assets/images/illustrations/login.svg';

import LoginForm from '../../components/forms/auth-forms/login-form';
import { loginModel } from '../../models';
import { authSelector } from '../../reducers/auth-reducer/auth.reducer';

const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const token = useMemo(() => searchParams.get('token'), [searchParams]);

    const [didSubmit, setDidSubmit] = useState(false);

    const {
        email,
        isFetchingAuthToken,
        isAuthenticated,
        fetchAuthTokenError,
        maintenance: { isUnderMaintenance, message },
    } = useSelector(authSelector);

    useEffect(() => {
        dispatch(getSystemStatus());
        if (token) {
            dispatch(getAuthToken(token, false, setSearchParams));
        }
    }, [dispatch, token, setSearchParams]);

    useEffect(() => {
        if (isAuthenticated && !token) {
            navigate('/portfolio', { replace: true });
        }
    }, [navigate, isAuthenticated, token]);

    const onLoginSuccess = () => setDidSubmit(true);

    const submitLoginForm = login;

    const renderHeroComponent = () => (
        <Image src={loginImage} alt="signup illustration" maxWidth="300px" />
    );

    const renderFetchMagicTokenLoader = () => (
        <Pane
            height={350}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Spinner marginBottom="1rem" />
            <Text size={500}>Logging you in...</Text>
        </Pane>
    );

    const renderAlert = () => {
        if (!fetchAuthTokenError) {
            return null;
        }

        return (
            <Alert intent="danger" title="Login Failed">
                {fetchAuthTokenError}
            </Alert>
        );
    };

    const renderLoginForm = () => {
        if (isUnderMaintenance) {
            return (
                <>
                    <Heading size={600} marginBottom="0.5rem">
                        Welcome to Asset Records!{' '}
                        <span role="img" aria-label="waving">
                            👋
                        </span>
                    </Heading>
                    <Text size={500}>{message}</Text>
                </>
            );
        }
        return (
            <>
                <Heading size={600} marginBottom="0.5rem">
                    Welcome to Asset Records!{' '}
                    <span role="img" aria-label="waving">
                        👋
                    </span>
                </Heading>

                {didSubmit ? (
                    <Pane
                        height={198}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Heading size={500}>
                            Success! We have sent a magic link to your email to
                            login.
                        </Heading>
                    </Pane>
                ) : (
                    <>
                        <Text size={500} marginBottom={16}>
                            Please login to your account to get started
                        </Text>
                        {renderAlert()}
                        <LoginForm
                            onSuccess={onLoginSuccess}
                            submitForm={submitLoginForm}
                            initialValues={loginModel({ email })}
                        />
                        <Text size={400} alignSelf="center" marginTop="24px">
                            Don&apos;t have an account?{' '}
                            <Link is={RouterLink} to="/signup">
                                Sign Up
                            </Link>
                        </Text>
                    </>
                )}
            </>
        );
    };

    return (
        <AuthScreenLayout heroComponent={renderHeroComponent()}>
            <Pane
                display="flex"
                flexDirection="column"
                maxWidth="400px"
                width="100%"
            >
                {isFetchingAuthToken || (token && !fetchAuthTokenError)
                    ? renderFetchMagicTokenLoader()
                    : renderLoginForm()}
            </Pane>
        </AuthScreenLayout>
    );
};

export default LoginPage;
