import React from 'react';
import { Card, Heading, Pane, RecordIcon, Text } from 'evergreen-ui';
import { PropTypes } from 'prop-types';
import { colors } from '../../theme/theme';

function ChartCard({ children, heading, width, height, legend, centerText }) {
    return (
        <Card
            elevation={1}
            backgroundColor={colors.white}
            borderRadius={10}
            minHeight={400}
            maxHeight={height}
            margin={1}
            flex={1}
            display="flex"
            flexDirection="column"
            width={width}
            height={height}
            position="relative"
        >
            <Pane
                borderBottom={`1px solid ${colors.neutral500}`}
                padding="1.5rem"
            >
                {typeof heading === 'string' ? (
                    <Heading
                        fontSize={22}
                        fontWeight="700"
                        color={colors.primary500}
                    >
                        {heading}
                    </Heading>
                ) : (
                    heading
                )}
            </Pane>
            <Pane
                display="flex"
                alignItems="center"
                width="100%"
                height="100%"
                justifyContent="center"
            >
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="calc(100% - 40px)"
                    height="calc(100% - 40px)"
                >
                    <>
                        {typeof centerText === 'string' ? (
                            <Text
                                fontWeight={700}
                                fontSize={22}
                                color="#8f95b2"
                                position="absolute"
                            >
                                {centerText}
                            </Text>
                        ) : (
                            centerText
                        )}
                        {children}
                    </>
                </Pane>
            </Pane>

            {legend && legend.length ? (
                <Pane
                    borderTop={`1px solid ${colors.neutral500}`}
                    display="flex"
                    justifyContent="space-evenly"
                    height={150}
                    paddingX={20}
                    flexWrap="wrap"
                    paddingY={10}
                >
                    {legend.map(({ label, color, value }) => (
                        <Pane key={label} display="flex" alignItems="center">
                            <RecordIcon color={color} />
                            <Text>
                                {label} <Text fontWeight={800}>({value})</Text>
                            </Text>
                        </Pane>
                    ))}
                </Pane>
            ) : (
                <Pane
                    borderTop={`1px solid ${colors.neutral500}`}
                    justifyContent="space-evenly"
                    height={90}
                    paddingX={20}
                    flexWrap="wrap"
                    paddingY={8}
                >
                    <Pane>{legend}</Pane>
                </Pane>
            )}
        </Card>
    );
}

ChartCard.propTypes = {
    heading: PropTypes.string || PropTypes.element,
    children: PropTypes.element.isRequired,
    width: PropTypes.string,
    height: PropTypes.number,
    legend: PropTypes.arrayOf(PropTypes.shape({})) || PropTypes.shape({}),
    centerText: PropTypes.string,
};

ChartCard.defaultProps = {
    width: '',
    height: null,
    heading: '',
    legend: null,
    centerText: null,
};

export default ChartCard;
