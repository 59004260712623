import _ from 'lodash';

import { portfolioService } from '../../services';
import { assetModel, portfolioModel } from '../../models';
import {
    setIsLoadingAction,
    setPortfoliosAction,
    setSelectedPortfolioAction,
} from './portfolio.reducer';
import { setAssetsAction } from '../asset-reducer/asset.reducer';
import { setPortfolioIdsAction } from '../user-reducer/user.reducer';

export function getPortfolioById(id) {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));

        try {
            const portfolio = portfolioModel(
                await portfolioService.getPortfolioById(id)
            );
            dispatch(setSelectedPortfolioAction(portfolio));
        } finally {
            dispatch(setIsLoadingAction(false));
        }
    };
}

export function getAllPortfoliosForBusiness(shouldSetLoading = false) {
    return async (dispatch, getState) => {
        if (shouldSetLoading) {
            dispatch(setIsLoadingAction(true));
        }
        const { businessIds } = getState().userReducer;
        const currentBusinessId = _.first(businessIds);

        try {
            const portfolios = await portfolioService.getPortfoliosForBusiness(
                currentBusinessId
            );
            dispatch(setPortfoliosAction(portfolios));
        } finally {
            dispatch(setIsLoadingAction(false));
        }
    };
}

export function getAllPortfolios() {
    return async (dispatch, getState) => {
        const { portfolios } = getState().portfolioReducer;
        const { assets } = getState().assetReducer;

        dispatch(setIsLoadingAction(true));

        if (_.size(portfolios) > 0 && _.size(assets) > 0) {
            dispatch(setIsLoadingAction(false));
        }

        const portfolioIds = _.map(portfolios, (portfolio) => portfolio.id);
        dispatch(setPortfolioIdsAction(portfolioIds));

        try {
            const allApiPortfolios = await Promise.all(
                _.map(portfolioIds, (portfolioId) =>
                    portfolioService.getPortfolioById(portfolioId)
                )
            );
            let mergedAssets = [];
            _.forEach(allApiPortfolios, (portfolio) => {
                const portfolioAssets = _.map(
                    _.get(portfolio, 'assetinfo'),
                    (asset) => {
                        const cachedAsset = _.find(
                            assets,
                            (item) => String(item.id) === String(asset.id)
                        );
                        if (cachedAsset) {
                            return { assetName: asset.name, ...cachedAsset };
                        }
                        return assetModel(asset, portfolio);
                    }
                );
                mergedAssets = [...mergedAssets, ...portfolioAssets];
                _.unset(portfolio, 'assetinfo');
            });

            dispatch(setAssetsAction(mergedAssets));
            dispatch(setPortfoliosAction(allApiPortfolios));
            // dispatch(getFullDetailsForAllAssets(hasCachedPortfolios));
        } finally {
            dispatch(setIsLoadingAction(false));
        }
    };
}

export function createPortfolio(portfolioObject) {
    return async (dispatch, getState) => {
        const { businessIds } = getState().userReducer;

        const newPortfolio = portfolioModel(
            await portfolioService.createPortfolio(
                portfolioObject,
                _.first(businessIds)
            )
        );
        dispatch(setSelectedPortfolioAction(newPortfolio));
        return newPortfolio;
    };
}
