import React from 'react';
import PropTypes from 'prop-types';
import { Card, Heading, Pane, Text, Image } from 'evergreen-ui';

const OverviewCard = ({ heading, secondaryIcon, icon, text }) => (
    <Card
        elevation={1}
        backgroundColor="white"
        borderRadius={10}
        width="100%"
        height={90}
        margin={1}
        padding="1rem"
        display="flex"
        flexDirection="column"
    >
        <Pane
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            marginY="auto"
        >
            <Pane display="flex" flexDirection="column" gap={8} height="100%">
                <Pane display="flex" gap={6}>
                    {secondaryIcon && (
                        <Pane>
                            <Image
                                src={secondaryIcon}
                                alt={heading}
                                width={15}
                                height={15}
                            />
                        </Pane>
                    )}

                    <Heading fontSize={16} fontWeight="700" color="#0C2138">
                        {heading}
                    </Heading>
                </Pane>
                <Text flexShrink={1} marginTop="auto">
                    {text}
                </Text>
            </Pane>
            <Pane>
                <Image src={icon} width="40px" height="40px" />
            </Pane>
        </Pane>
    </Card>
);

OverviewCard.defaultProps = {
    secondaryIcon: null,
};

OverviewCard.propTypes = {
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    secondaryIcon: PropTypes.string,
};

export default OverviewCard;
