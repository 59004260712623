import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Pane, Heading, Text } from 'evergreen-ui';
import _ from 'lodash';

import { useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';

import { BuildingImage } from '../../molecules';

function LinkedAssetList({ errorData, asset }) {
    const { assets } = useSelector(assetSelector);
    const { isLoadingParams } = useSearchParams();
    const navigate = useNavigate();

    const assetError = useMemo(() => errorData, [errorData]);

    const getAssetImage = useCallback(
        (childAsset) => {
            const assetDetail = assets.find((ass) => ass.id === childAsset.id);
            if (isLoadingParams) return null;

            if (assetDetail) {
                const featuredImage = assetDetail.images?.find(
                    (image) => image.isFeatured
                );
                return (
                    featuredImage ||
                    (assetDetail.images.length > 0
                        ? assetDetail.images[0]
                        : null)
                );
            }
            return null;
        },
        [assets, isLoadingParams]
    );

    const handleAssetRedirect = useCallback(
        (linkAsset) => {
            const url = `/portfolio/properties/${linkAsset.type}s/${linkAsset.id}/info`;
            navigate(url);
        },
        [navigate]
    );

    const linkedAssets = useMemo(
        () =>
            assetError?.linked_building_data?.map((linkedAsset) => {
                const imageUse = getAssetImage(linkedAsset);
                return (
                    <Pane
                        display="flex"
                        marginTop={10}
                        key={`${linkedAsset.id}-lists`}
                        backgroundColor="#FAFBFF"
                        borderBottom="1.5px solid #E8E9EC"
                        padding={5}
                        gap={10}
                        alignItems="center"
                        onClick={() => handleAssetRedirect(linkedAsset)}
                        cursor="pointer"
                    >
                        <Pane
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Pane marginRight={20}>
                                <BuildingImage
                                    key={linkedAsset.name}
                                    width={40}
                                    height={40}
                                    borderRadius={30}
                                    image={imageUse}
                                    asset={asset}
                                />
                            </Pane>
                            <Text>
                                {`Linked ${_.capitalize(
                                    linkedAsset.type
                                )}: ${_.capitalize(linkedAsset.name)}`}
                            </Text>
                        </Pane>
                    </Pane>
                );
            }),
        [assetError, getAssetImage, handleAssetRedirect, asset]
    );

    return (
        <Pane>
            <Heading marginTop={10} marginBottom={10}>
                Linked Properties
            </Heading>
            <Pane height={85}>{linkedAssets}</Pane>
        </Pane>
    );
}

LinkedAssetList.propTypes = {
    errorData: PropTypes.string.isRequired,
    asset: PropTypes.string.isRequired,
};

export default LinkedAssetList;
