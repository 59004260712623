import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    uploadRequests: null,
    uploadRequestToken: null,
    uploadsCompleted: false,
};

const uploadRequestSlice = createSlice({
    name: 'upload-request',
    initialState,
    reducers: {
        setIsLoadingAction(state, action) {
            state.isLoading = action.payload;
        },
        setUploadRequestsAction(state, action) {
            state.uploadRequests = action.payload;
        },
        setUploadRequestTokenAction(state, action) {
            state.uploadRequestToken = action.payload;
        },
        setUploadsCompletedAction(state, action) {
            state.uploadsCompleted = action.payload;
        },
    },
});

export const {
    setIsLoadingAction,
    setUploadRequestsAction,
    setUploadRequestTokenAction,
    setUploadsCompletedAction,
} = uploadRequestSlice.actions;

export const uploadRequestSelector = (reducers) =>
    reducers.uploadRequestReducer;

export default uploadRequestSlice.reducer;
