import _ from 'lodash';
import PropTypes from 'prop-types';

export const loginShape = PropTypes.shape({
    email: PropTypes.string.isRequired,
});

export const loginModel = (loginObject = {}) => ({
    email: _.get(loginObject, 'email', ''),
});

export const registerModel = (registerObject = {}) => ({
    name: _.get(registerObject, 'firstName', ''),
    surname: _.get(registerObject, 'lastName', ''),
    email: _.get(registerObject, 'email', ''),
    mobileNumber: _.get(registerObject, 'mobileNumber', ''),
    businessName: _.get(registerObject, 'businessName', ''),
});
