const maintenanceItemsBaseUrl = '/maintenanceitems';
const maintenanceCategoriesURL = '/maintenancecategories';
const maintenanceGraphsURL = '/dashboard/graphs';
const maintenanceDocumentsURL = '/Documents';
const maintenanceOverviewCardsURL = '/dashboard/cards';

export default {
    getAssetRegisterDataByUrl: () => maintenanceItemsBaseUrl,
    deleteAssetRegisterItem: () => maintenanceItemsBaseUrl,
    addAssetRegisterItem: () => `${maintenanceItemsBaseUrl}/new`,
    editAssetRegisterItem: () => `${maintenanceItemsBaseUrl}/update`,
    getMaintenanceCategoriesURL: () => maintenanceCategoriesURL,
    deleteMaintenanceCategoryURL: () => `${maintenanceCategoriesURL}/delete`,
    createMaintenanceCategoryURL: () => `${maintenanceCategoriesURL}/create`,
    updateMaintenanceCategoryURL: () => `${maintenanceCategoriesURL}/update`,
    createMaintenanceTypeURL: () => `${maintenanceCategoriesURL}/type/create`,
    deleteMaintenanceTypeURL: () => `${maintenanceCategoriesURL}/type/delete`,
    updateMaintenanceTypeURL: () => `${maintenanceCategoriesURL}/type/update`,
    getMaintenanceItemDocuments: () => `${maintenanceDocumentsURL}`,
    getMaintenanceCategoriesGraphsURL: () => `${maintenanceGraphsURL}/category`,
    getScheduledMaintenanceGraphsURL: () =>
        `${maintenanceGraphsURL}/scheduledmaintenance`,
    setMaintenanceDocumentUploadedSuccessfullyURL: () =>
        `${maintenanceDocumentsURL}/docsuploaded/v2`,
    getMaintenanceUploadLinkRequest: () =>
        `${maintenanceDocumentsURL}/linkrequest`,
    getMaintenanceDocuments: () => `${maintenanceDocumentsURL}/v2`,
    getMaintenanceOverviewCardsURL: () => maintenanceOverviewCardsURL,
    markMaintenanceItemServicedURL: () => `${maintenanceItemsBaseUrl}/serviced`,
};
