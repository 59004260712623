import React from 'react';
import { Pane, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from '../../reducers/asset-reducer/asset.reducer';

function RelativeBuildingItem({ building, close, assets }) {
    const { id, name, type } = building;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = () => {
        if (building.type === 'tenant') {
            const currentTenant = assets.find(
                (asset) => Number(asset.id) === Number(id)
            );
            const currentBuilding = assets.find(
                (asset) => Number(asset.id) === Number(currentTenant.parentId)
            );
            if (currentBuilding.parentId) {
                const currentAsset = assets.find(
                    (asset) =>
                        Number(asset.id) === Number(currentBuilding.parentId)
                );
                dispatch(setCurrentAssetAction(currentAsset));
                dispatch(setCurrentBuildingAction(currentBuilding));
                dispatch(setCurrentTenantAction(currentTenant));
                navigate(
                    `/portfolio/properties/parks/${currentAsset.id}/buildings/${currentBuilding.id}/tenants/${currentTenant.id}/info`
                );
            } else {
                dispatch(setCurrentBuildingAction(currentBuilding));
                dispatch(setCurrentTenantAction(currentTenant));
                navigate(
                    `/portfolio/properties/buildings/${currentBuilding.id}/tenants/${currentTenant.id}/info`
                );
            }
        } else if (building.type === 'building') {
            const currentBuilding = assets.find(
                (asset) => Number(asset.id) === Number(id)
            );
            if (currentBuilding.parentId) {
                const currentAsset = assets.find(
                    (asset) =>
                        Number(asset.id) === Number(currentBuilding.parentId)
                );
                dispatch(setCurrentAssetAction(currentAsset));
                dispatch(setCurrentBuildingAction(currentBuilding));
                navigate(
                    `/portfolio/properties/parks/${currentAsset.id}/buildings/${currentBuilding.id}/info`
                );
            } else {
                dispatch(setCurrentBuildingAction(currentBuilding));
                navigate(
                    `/portfolio/properties/buildings/${currentBuilding.id}/info`
                );
            }
        } else if (building.type === 'park') {
            const currentAsset = assets.find(
                (asset) => Number(asset.id) === Number(id)
            );
            dispatch(setCurrentAssetAction(currentAsset));
            navigate(`/portfolio/properties/parks/${currentAsset.id}/info`);
        }

        close();
    };

    return (
        <StyledPane
            display="flex"
            flex={1}
            paddingX={10}
            paddingY={15}
            role="button"
            onClick={handleClick}
            hoverElevation={1}
        >
            <Text fontWeight={600} width="70%">
                {name}
            </Text>
            <Text textTransform="capitalize" textAlign="center" width="30%">
                {type}
            </Text>
        </StyledPane>
    );
}

const StyledPane = styled(Pane)`
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #f4f5f950;
    }
`;

RelativeBuildingItem.propTypes = {
    building: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string.isRequired,
    }).isRequired,
    close: PropTypes.func.isRequired,
    assets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default RelativeBuildingItem;
