import React, { useMemo, useEffect, useState, useRef } from 'react';
import {
    SelectField,
    Pane,
    Text,
    TextInputField,
    Button,
    Group,
    ErrorIcon,
    Textarea,
    SelectMenu,
    RadioGroup,
    InfoSignIcon,
    Tooltip,
} from 'evergreen-ui';
import DatePicker from 'react-datepicker';
import _, { get } from 'lodash';
import { useFormik } from 'formik';
import { useFilePicker } from 'use-file-picker';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { colors } from '../../../theme/theme';
import FileDragAndDrop from '../../atoms/file-drag-and-drop';
import { addAssetRegisterItem } from '../../../reducers/maintenance-reducer/maintenance-actions';
import DatePickerCustomHeader from '../date-picker/date-picker-custom-header';
import {
    getMaintenanceCategoryModel,
    getMaintenanceTypeModel,
} from '../../../models/maintenance.models';
import { maintenanceSelector } from '../../../reducers/maintenance-reducer/maintenance-reducer';
import { useUploadManager } from '../../../context/upload-manager-context';
import SchedulerModal from '../../maintenance/scheduler-modal';
import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';
import ContactsSidebar from '../../contacts/contacts-sidebar';
import { errorToast, successToast } from '../../toasts';
import SegmentedControl from '../../atoms/segmented-control/segmented-control';

const RADIO_OPTIONS = [
    {
        label: 'Tenant',
        value: 'tenant',
    },
    {
        label: 'Owner',
        value: 'owner',
    },
    {
        label: 'Consultant',
        value: 'consultant',
    },
];

const OWNERSHIP_OPTIONS = [
    { label: 'Leased', value: 'leased' },
    { label: 'Owned', value: 'owned' },
];

function RegisterAssetForm({
    close,
    openCategoriesModal,
    contacts,
    assignees,
}) {
    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const buildingId =
        currentTenant?.id || currentBuilding?.id || currentAsset?.id;

    const dispatch = useDispatch();

    const { categories } = useSelector(maintenanceSelector);
    const { enqueueUploads } = useUploadManager();
    const [types, setTypes] = useState([]);
    const [addContact, setAddContact] = useState(false);

    const [showMaintenanceRecurrenceModal, setShowMaintenanceRecurrenceModal] =
        useState(false);

    const [openFileSelector, { plainFiles }] = useFilePicker({
        accept: '*',
        multiple: true,
        readFilesContent: true,
    });

    const [loading, setLoading] = useState(false);

    const onFilesDropped = (droppedFiles) => {
        formik.setValues({
            ...formik.values,
            proofOfService: droppedFiles[0],
        });
    };

    const handleLastServiceDate = (date) => {
        const parsedDate = Date.parse(date);
        formik.setValues({
            ...formik.values,
            lastServiceDate: parsedDate,
        });
    };

    const handleNextServiceDate = (date) => {
        const parsedDate = Date.parse(date);
        formik.setValues({
            ...formik.values,
            nextServiceDate: parsedDate,
        });
    };

    const handleWarrantyExpireDate = (date) => {
        const parsedDate = Date.parse(date);
        formik.setValues({
            ...formik.values,
            warrantyExpire: parsedDate,
        });
    };

    const handleInstallationDate = (date) => {
        const parsedDate = Date.parse(date);
        formik.setValues({
            ...formik.values,
            installationDate: parsedDate,
        });
    };

    const handleIsNew = (value) => {
        formik.setValues({
            ...formik.values,
            isNew: value,
            lastServiceDate: null,
        });
    };

    const handleAssetLifeTime = ({ target }) => {
        const month = 2628000;
        const year = month * 12;

        if (target.value === 'years' || target.value === 'months') {
            if (target.value === 'years') {
                formik.setValues({
                    ...formik.values,
                    lifetime: {
                        ...formik.values.lifetime,
                        period: target.value,
                        duration: year * formik.values.lifetime.amount,
                    },
                });
            } else if (target.value === 'months') {
                formik.setValues({
                    ...formik.values,
                    lifetime: {
                        ...formik.values.lifetime,
                        period: target.value,
                        duration: month * formik.values.lifetime.amount,
                    },
                });
            }
            return;
        }
        if (formik.values.lifetime.period === 'years') {
            formik.setValues({
                ...formik.values,
                lifetime: {
                    ...formik.values.lifetime,
                    amount: Number(target.value),
                    duration: Number(target.value) * year,
                },
            });
        } else if (formik.values.lifetime.period === 'months') {
            formik.setValues({
                ...formik.values,
                lifetime: {
                    ...formik.values.lifetime,
                    amount: Number(target.value),
                    duration: Number(target.value) * month,
                },
            });
        }
    };

    const handleSubmit = async ({
        category,
        type,
        identifier,
        ownership,
        description,
        lastServiceDate,
        nextServiceDate,
        responsibleParty,
        lifetime,
        warrantyExpire,
        liabilityClause,
        serviceProvider,
        rrule: { rrule },
        isNew,
        installationDate,
        assignedUserIds,
    }) => {
        setLoading(true);
        try {
            dispatch(
                addAssetRegisterItem(
                    {
                        buildingId,
                        category,
                        type,
                        identifier,
                        ownership,
                        description,
                        lastServiceDate,
                        nextServiceDate,
                        responsibleParty,
                        lifetime,
                        warrantyExpire,
                        liabilityClause,
                        serviceProvider,
                        rrule,
                        isNew,
                        installationDate,
                        assignedUserIds,
                    },
                    plainFiles,
                    enqueueUploads
                )
            );
            successToast(
                `Successfully created new maintenance item "${description}"`
            );
            close();
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setLoading(false);
        }
    };

    const validate = ({
        category,
        type,
        ownership,
        lastServiceDate,
        nextServiceDate,
        responsibleParty,
        lifetime,
        warrantyExpire,
        rrule,
        isNew,
        installationDate,
        assignedUserIds,
    }) => {
        const errors = {};

        if (!category.id) {
            errors.category = 'Asset Category is Required';
        }
        if (!type.id) {
            errors.type = 'Asset Type is Required';
        }
        if (!ownership) {
            errors.ownership = 'Ownership is Required';
        }
        if (!isNew && !lastServiceDate) {
            errors.lastServiceDate = 'Last Service Date, is Required';
        }
        if (!nextServiceDate) {
            errors.nextServiceDate = 'Next Service Date is Required';
        }
        if (!installationDate) {
            errors.installationDate = 'Installation Date is Required';
        }
        if (!responsibleParty) {
            errors.responsibleParty = 'Responsible Party is Required';
        }
        if (!lifetime) {
            errors.lifetime = 'Asset Lifetime is Required';
        }
        if (!warrantyExpire) {
            errors.warrantyExpire = 'Warranty Expiry Date is Required';
        }

        if (!rrule.rrule) {
            errors.rrule = 'Recurrence is Required';
        }
        if (!assignedUserIds.length) {
            errors.assignedUserIds = 'Select at least one user';
        }

        return errors;
    };

    const formik = useFormik({
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            buildingId,
            category: { id: null, name: '' },
            type: { id: null, name: '' },
            identifier: '',
            ownership: OWNERSHIP_OPTIONS[0].value,
            description: '',
            isNew: true,
            installationDate: Date.now(),
            lastServiceDate: Date.now(),
            nextServiceDate: Date.now(),
            responsibleParty: 'tenant',
            lifetime: { amount: 1, period: 'years', duration: 31536000 },
            warrantyExpire: Date.now(),
            liabilityClause: '',
            serviceProvider: '',
            rrule: {
                rrule: '',
                humanReadable: '',
            },
            proofOfService: null,
            assignedUserIds: [],
        },
        validate,
        onSubmit: handleSubmit,
    });

    const selectCategory = (e) => {
        if (e.target.value === 'addNew') {
            openCategoriesModal();
            formik.setValues({
                ...formik.values,
                category: null,
                type: null,
            });
        } else {
            const newCategory = getMaintenanceCategoryModel(
                categories.find(
                    (category) => category.id === Number(e.target.value)
                )
            );

            if (newCategory) {
                formik.setValues({
                    ...formik.values,
                    category: newCategory,
                    type: newCategory.types[0],
                });
            }
            setTypes(newCategory?.types);
        }
    };

    const selectType = ({ target }) => {
        if (target.value === 'addNew') {
            openCategoriesModal();
            formik.setValues({
                ...formik.values,
                category: null,
                type: null,
            });
        } else {
            const newType = types.find(
                (type) => type.id === Number(target.value)
            );
            if (newType) {
                formik.setValues({ ...formik.values, type: newType });
            }
        }
    };

    const onSelect = ({ value }) => {
        formik.setValues({
            ...formik.values,
            assignedUserIds: [...formik.values.assignedUserIds, Number(value)],
        });
    };

    const onDeselect = ({ value }) => {
        formik.setValues({
            ...formik.values,
            assignedUserIds: formik.values.assignedUserIds.filter(
                (id) => Number(id) !== Number(value)
            ),
        });
    };

    const assignedUserText = useMemo(() => {
        if (!formik.values.assignedUserIds.length) {
            return 'Select user(s)';
        }
        return assignees
            .map(({ value, label }) =>
                formik.values.assignedUserIds.includes(Number(value))
                    ? label
                    : null
            )
            .filter((value) => !!value)
            .join(', ');
    }, [assignees, formik.values.assignedUserIds]);

    const setRecurrence = (rrule, nextServiceDate) => {
        formik.setValues({
            ...formik.values,
            rrule: {
                rrule: rrule.toString(),
                humanReadable: rrule.toText(),
            },
            nextServiceDate,
        });
        setShowMaintenanceRecurrenceModal(false);
    };

    const handleServiceProviderChange = ({ target: { value } }) => {
        if (value === 'new') {
            setAddContact(true);
            formik.setValues({ ...formik.values, serviceProvider: '' });
        } else {
            formik.setValues({
                ...formik.values,
                serviceProvider: Number(value),
            });
        }
    };

    useEffect(() => {
        if (_.size(plainFiles) > 0) {
            formik.setValues({
                ...formik.values,
                proofOfService: [...plainFiles],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plainFiles]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Pane
                    display="flex"
                    flexDirection="column"
                    justifyContent="start"
                    height="100%"
                >
                    <Pane>
                        <SelectField
                            name="category"
                            width="100%"
                            autoFocus
                            inputHeight={40}
                            label="Category"
                            validationMessage={formik.errors.category}
                            onChange={selectCategory}
                            value={formik.values.category?.id || ''}
                            required
                        >
                            <option value="" disabled>
                                Select Asset Category
                            </option>
                            {categories ? (
                                categories.map((category) => {
                                    const { id, name } =
                                        getMaintenanceCategoryModel(category);
                                    return (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    );
                                })
                            ) : (
                                <option value="none" selected>
                                    No Available Categories
                                </option>
                            )}
                            <option value="addNew">+ Add New</option>
                        </SelectField>

                        <div className="divider" />

                        <SelectField
                            name="type"
                            width="100%"
                            marginTop={20}
                            inputHeight={40}
                            label="Type"
                            validationMessage={formik.errors.type}
                            onChange={selectType}
                            value={formik.values.type?.id}
                            disabled={!types || !formik.values.category?.id}
                            required
                        >
                            <option value="" disabled>
                                Select Asset Type
                            </option>
                            {types.length ? (
                                types?.map((type) => {
                                    const { id, name } =
                                        getMaintenanceTypeModel(type);
                                    return (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    );
                                })
                            ) : (
                                <option value="">No Types Available</option>
                            )}
                            <option value="addNew">+ Add New Type</option>
                        </SelectField>
                    </Pane>

                    <div className="divider-no-margin" />

                    <Pane
                        display="flex"
                        flexDirection="column"
                        gap={12}
                        marginTop={20}
                    >
                        <Pane display="flex" gap={20}>
                            <Text fontWeight="500" color="#101840">
                                The Asset is New *
                            </Text>
                            <Tooltip content="If asset is not new the warranty is not compulsory.">
                                <InfoSignIcon color="#8F95B2" />
                            </Tooltip>
                        </Pane>
                        <SegmentedControl
                            name="segmented-control"
                            segments={[
                                { label: 'Yes', value: true, ref: useRef() },
                                { label: 'No', value: false, ref: useRef() },
                            ]}
                            callback={handleIsNew}
                        />
                    </Pane>

                    <div className="divider" />

                    <DatePicker
                        name="warrantyExpire"
                        placeholderText="Warranty Expiration"
                        calendarClassName="calendar"
                        popperClassName="calendar"
                        dateFormat="dd/MM/yyyy"
                        selected={formik.values.warrantyExpire}
                        onChange={handleWarrantyExpireDate}
                        required
                        customInput={
                            <TextInputField
                                label="Warranty Expiration"
                                value={formik.values.warrantyExpire}
                                validationMessage={formik.errors.warrantyExpire}
                                width="100%"
                                inputHeight={40}
                            />
                        }
                        renderCustomHeader={(props) => (
                            <DatePickerCustomHeader {...props} />
                        )}
                    />

                    <div className="divider-no-margin" />

                    <Pane
                        display="flex"
                        flexDirection="column"
                        gap={8}
                        marginTop={20}
                    >
                        <Text fontWeight="500" color="#101840">
                            Asset Lifetime *
                        </Text>
                        <Pane display="flex" gap={12}>
                            <TextInputField
                                name="amount"
                                label="Amount"
                                type="number"
                                placeholder="Asset Lifetime Amount"
                                value={formik.values.lifetime.amount}
                                onChange={handleAssetLifeTime}
                                validationMessage={formik.errors.lifetime}
                                width="100%"
                                inputHeight={40}
                                max={72}
                            />
                            <SelectField
                                name="period"
                                width="100%"
                                inputHeight={40}
                                label="Period"
                                validationMessage={formik.errors.lifetime}
                                value={formik.values.lifetime.period}
                                onChange={handleAssetLifeTime}
                            >
                                <option value="" disabled>
                                    Select Asset Lifetime Amount
                                </option>
                                {['Years', 'Months'].map((period) => (
                                    <option
                                        key={period.toLowerCase()}
                                        value={period.toLowerCase()}
                                    >
                                        {period}
                                    </option>
                                ))}
                            </SelectField>
                        </Pane>
                        {!!formik.errors.lifetime && (
                            <Text
                                lineHeight="18px"
                                fontSize="12px"
                                color="#D14343"
                            >
                                <ErrorIcon size={14} marginRight="6px" />
                                {formik.errors.lifetime}
                            </Text>
                        )}
                    </Pane>

                    <div className="divider-no-margin" />

                    <Pane
                        display="flex"
                        flexDirection="column"
                        marginTop={20}
                        marginBottom={5}
                    >
                        <Text fontWeight="500" color="#101840">
                            Asset Type *
                        </Text>
                        <RadioGroup
                            required
                            name="ownership"
                            options={OWNERSHIP_OPTIONS}
                            size={16}
                            value={formik.values.ownership}
                            onChange={(e) =>
                                formik.setValues({
                                    ...formik.values,
                                    ownership: e.target.value,
                                })
                            }
                            display="flex"
                            gap={18}
                        />
                    </Pane>

                    <div className="divider-no-margin" />

                    <Pane marginTop={20}>
                        <Text fontWeight="500" color="#101840">
                            Description *
                        </Text>
                        <Textarea
                            name="description"
                            placeholder="Description"
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            width="100%"
                            height={60}
                            minHeight={60}
                            rows={2}
                            resize="vertical"
                            required
                        />
                    </Pane>

                    <div className="divider" />

                    <DatePicker
                        name="installationDate"
                        placeholderText="Pick Date Installed"
                        calendarClassName="calendar"
                        popperClassName="calendar"
                        dateFormat="dd/MM/yyyy"
                        selected={formik.values.installationDate}
                        onChange={handleInstallationDate}
                        maxDate={Date.now()}
                        customInput={
                            <TextInputField
                                label="Installation Date *"
                                value={formik.values.installationDate}
                                validationMessage={
                                    formik.errors.installationDate
                                }
                                width="100%"
                                inputHeight={40}
                                required
                            />
                        }
                        renderCustomHeader={(props) => (
                            <DatePickerCustomHeader {...props} />
                        )}
                    />

                    {!formik.values.isNew && (
                        <>
                            <div className="divider-no-margin" />
                            <Pane marginTop={20}>
                                <DatePicker
                                    name="lastServicedDate"
                                    placeholderText="Pick Date Last Serviced"
                                    calendarClassName="calendar"
                                    popperClassName="calendar"
                                    maxDate={Date.now()}
                                    dateFormat="dd/MM/yyyy"
                                    selected={formik.values.lastServiceDate}
                                    onChange={handleLastServiceDate}
                                    disabled={formik.values.isNew}
                                    aria-label="test"
                                    customInput={
                                        <TextInputField
                                            label="Date Last Serviced *"
                                            value={
                                                formik.values.isNew
                                                    ? null
                                                    : formik.values
                                                          .lastServiceDate
                                            }
                                            validationMessage={
                                                formik.errors.lastServiceDate
                                            }
                                            width="100%"
                                            inputHeight={40}
                                        />
                                    }
                                    renderCustomHeader={(props) => (
                                        <DatePickerCustomHeader {...props} />
                                    )}
                                />
                            </Pane>
                        </>
                    )}

                    <div className="divider-no-margin" />

                    <TextInputField
                        marginTop={20}
                        required
                        name="rrule"
                        label="Service Recurrence"
                        onChange={formik.handleChange}
                        placeholder="Set Recurrence"
                        value={formik.values.rrule.humanReadable}
                        validationMessage={formik.errors.rrule}
                        width="100%"
                        inputHeight={40}
                        onClick={() => setShowMaintenanceRecurrenceModal(true)}
                    />

                    <div className="divider-no-margin" />

                    <DatePicker
                        name="nextServiceDate"
                        placeholderText="Select Next Service Date"
                        calendarClassName="calendar"
                        popperClassName="calendar"
                        dateFormat="dd/MM/yyyy"
                        selected={formik.values.nextServiceDate}
                        onChange={handleNextServiceDate}
                        minDate={Date.now()}
                        disabled
                        customInput={
                            <TextInputField
                                marginTop={20}
                                label="Next Service Date"
                                value={formik.values.nextServiceDate}
                                validationMessage={
                                    formik.errors.nextServiceDate
                                }
                                width="100%"
                                inputHeight={40}
                                hint="Will automatically be set from recurrence schedule"
                            />
                        }
                        renderCustomHeader={(props) => (
                            <DatePickerCustomHeader {...props} />
                        )}
                    />

                    <div className="divider-no-margin" />

                    <Pane display="flex" flexDirection="column" marginTop={20}>
                        <Text fontWeight="500" color="#101840">
                            Assign User *
                        </Text>
                        <SelectMenu
                            isMultiSelect
                            selected={formik.values.assignedUserIds}
                            onSelect={onSelect}
                            onDeselect={onDeselect}
                            options={assignees}
                            required
                            hasTitle={false}
                            filterPlaceholder="Search Users"
                        >
                            <Button
                                height={40}
                                marginTop={12}
                                justifyContent="start"
                                type="button"
                            >
                                {assignedUserText}
                            </Button>
                        </SelectMenu>
                    </Pane>

                    <div className="divider" />

                    <TextInputField
                        name="identifier"
                        label="Internal Identifier (Serial Number)"
                        placeholder="Internal Identifier"
                        value={formik.values.identifier}
                        onChange={formik.handleChange}
                        validationMessage={formik.errors.identifier}
                        width="100%"
                        inputHeight={40}
                    />

                    <div className="divider-no-margin" />

                    <Pane display="flex" flexDirection="column" marginTop={20}>
                        <Text fontWeight="500" color="#101840">
                            Responsibility to Repair & Maintain *
                        </Text>
                        <RadioGroup
                            name="responsibleParty"
                            options={RADIO_OPTIONS}
                            size={16}
                            marginTop={10}
                            marginBottom={20}
                            onChange={(e) =>
                                formik.setValues({
                                    ...formik.values,
                                    responsibleParty: e.target.value,
                                })
                            }
                            value={formik.values.responsibleParty}
                            display="flex"
                            justifyContent="space-between"
                        />
                    </Pane>

                    <div className="divider-no-margin" />

                    <TextInputField
                        marginTop={20}
                        name="liabilityClause"
                        label="Liability Clause"
                        onChange={formik.handleChange}
                        placeholder="Liability Clause"
                        value={formik.values.liabilityClause}
                        validationMessage={formik.errors.liabilityClause}
                        width="100%"
                        inputHeight={40}
                    />

                    <div className="divider-no-margin" />

                    <SelectField
                        marginTop={20}
                        name="serviceProvider"
                        width="100%"
                        inputHeight={40}
                        required
                        label="Service Provider"
                        validationMessage={formik.errors.serviceProvider}
                        value={formik.values.serviceProvider}
                        onChange={handleServiceProviderChange}
                    >
                        <option value="" disabled>
                            Select Service Provider
                        </option>
                        {contacts ? (
                            contacts.map(
                                ({
                                    createdAt,
                                    firstName,
                                    lastName,
                                    profession,
                                    companyName,
                                    id,
                                }) => (
                                    <option key={createdAt} value={id}>
                                        {`${firstName} ${lastName} - ${profession} - ${companyName}`}
                                    </option>
                                )
                            )
                        ) : (
                            <option disabled>
                                No Service Providers Available
                            </option>
                        )}
                        <option value="new">+ Add Service Provider</option>
                    </SelectField>

                    <div className="divider-no-margin" />

                    <Pane marginTop={20}>
                        <FileDragAndDrop onFilesDropped={onFilesDropped}>
                            {({ dragging }) => (
                                <Pane
                                    display="flex"
                                    flexDirection="column"
                                    gap="8px"
                                >
                                    <Text>Latest Proof of Service</Text>
                                    <Group
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        name="proofOfService"
                                    >
                                        <Pane
                                            height="40px"
                                            borderRadius={5}
                                            borderWidth={1}
                                            borderStyle={
                                                dragging ||
                                                _.size(plainFiles) > 0
                                                    ? 'solid'
                                                    : 'dashed'
                                            }
                                            borderColor={
                                                dragging ||
                                                _.size(plainFiles) > 0
                                                    ? colors.secondary500
                                                    : colors.gray500
                                            }
                                            label="Date Last Serviced"
                                            type="text"
                                            name="assetName"
                                            placeholder="Drag Image or browse"
                                            width="100%"
                                        >
                                            {formik.values.proofOfService ? (
                                                <Text
                                                    marginLeft="16px"
                                                    lineHeight="38px"
                                                >
                                                    {formik.values
                                                        .proofOfService.length >
                                                    1
                                                        ? `${formik.values.proofOfService.length} files`
                                                        : formik.values.proofOfService[
                                                              formik.values
                                                                  .proofOfService
                                                                  .length - 1
                                                          ]?.name?.split(
                                                              '.'
                                                          )[0]}
                                                </Text>
                                            ) : (
                                                <Text
                                                    marginLeft="16px"
                                                    lineHeight="38px"
                                                >
                                                    Drag images or browse
                                                </Text>
                                            )}
                                        </Pane>
                                        <Pane>
                                            <Button
                                                height="40px"
                                                type="button"
                                                onClick={openFileSelector}
                                                backgroundColor="#F4F5F9"
                                            >
                                                <Text fontSize={12}>
                                                    Browse
                                                </Text>
                                            </Button>
                                        </Pane>
                                    </Group>
                                    {!!formik.errors.proofOfService && (
                                        <Text
                                            lineHeight="18px"
                                            fontSize="12px"
                                            color="#D14343"
                                        >
                                            <ErrorIcon
                                                size={14}
                                                marginRight="6px"
                                            />{' '}
                                            {formik.errors.proofOfService}
                                        </Text>
                                    )}
                                </Pane>
                            )}
                        </FileDragAndDrop>
                    </Pane>
                    <div className="divider" />
                    <Pane display="flex" gap={15} paddingBottom={24}>
                        <Button width="100%" type="button" onClick={close}>
                            Cancel
                        </Button>
                        <Button
                            isLoading={loading}
                            type="submit"
                            width="100%"
                            appearance="primary"
                        >
                            Submit
                        </Button>
                    </Pane>
                </Pane>
            </form>
            <SchedulerModal
                isShown={showMaintenanceRecurrenceModal}
                close={() => setShowMaintenanceRecurrenceModal(false)}
                setRecurrence={setRecurrence}
            />
            <ContactsSidebar
                isShown={addContact}
                isEditing={false}
                canChooseBusiness
                close={() => setAddContact(false)}
            />
        </>
    );
}

RegisterAssetForm.propTypes = {
    close: PropTypes.func.isRequired,
    selectedBuilding: PropTypes.shape({ id: PropTypes.number }).isRequired,
    openCategoriesModal: PropTypes.func.isRequired,
    contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    assignees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default RegisterAssetForm;
