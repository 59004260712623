import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
    enqueueUploads as enqueueUploadsAction,
    retryUpload as retryUploadAction,
    cancelUpload as cancelUploadAction,
    cancelAllUploads as cancelAllUploadsAction,
    clearUploadQueue as clearUploadQueueAction,
} from '../../../reducers/upload-manager/upload-manager.actions';
import { uploadManagerSelector } from '../../../reducers/upload-manager/upload-manager.reducer';
import { useConfirmBrowserExit } from '../../../hooks/use-confirm-browser-exit';

const UploadManagerContext = createContext({});

const UploadManager = ({ children }) => {
    const dispatch = useDispatch();
    const confirmBrowserExit = useConfirmBrowserExit(
        false,
        'Are you sure you want to leave? Your active uploads will be canceled.'
    );

    const { uploads, uploadTimeRemaining, queuedUploadBytesRemaining } =
        useSelector(uploadManagerSelector);

    const [isExpanded, setIsExpanded] = useState(true);

    const activeUploadsCount = useMemo(
        () =>
            _.reduce(
                uploads,
                (count, upload) => {
                    if (
                        upload.isUploading ||
                        (!upload.uploadFailed &&
                            !upload.uploadDone &&
                            !upload.uploadCanceled)
                    ) {
                        return count + 1;
                    }
                    return count;
                },
                0
            ),
        [uploads]
    );

    useEffect(() => {
        if (activeUploadsCount > 0) {
            confirmBrowserExit.enable();
        } else {
            confirmBrowserExit.disable();
        }
    }, [activeUploadsCount, confirmBrowserExit]);

    const completedUploadsCount = useMemo(
        () =>
            _.reduce(
                uploads,
                (count, upload) => {
                    if (upload.uploadDone) {
                        return count + 1;
                    }
                    return count;
                },
                0
            ),
        [uploads]
    );

    const failedUploadsCount = useMemo(
        () =>
            _.reduce(
                uploads,
                (count, upload) => {
                    if (upload.uploadFailed) {
                        return count + 1;
                    }
                    return count;
                },
                0
            ),
        [uploads]
    );

    const canceledUploadsCount = useMemo(
        () =>
            _.reduce(
                uploads,
                (count, upload) => {
                    if (upload.uploadCanceled) {
                        return count + 1;
                    }
                    return count;
                },
                0
            ),
        [uploads]
    );

    const enqueueUploads = useCallback(
        (files, initializeUpload, uploadDocument, uploadSuccess, comment) => {
            setIsExpanded(true);
            dispatch(
                enqueueUploadsAction(
                    files,
                    initializeUpload,
                    uploadDocument,
                    uploadSuccess,
                    comment
                )
            );
        },
        [dispatch, setIsExpanded]
    );

    const retryUpload = useCallback(
        (uploadId) => {
            dispatch(retryUploadAction(uploadId));
        },
        [dispatch]
    );

    const cancelUpload = useCallback(
        (uploadId) => {
            dispatch(cancelUploadAction(uploadId));
        },
        [dispatch]
    );

    const cancelAllUploads = useCallback(() => {
        dispatch(cancelAllUploadsAction());
    }, [dispatch]);

    const clearUploadQueue = useCallback(() => {
        dispatch(clearUploadQueueAction());
    }, [dispatch]);

    const value = useMemo(
        () => ({
            uploads,
            enqueueUploads,
            retryUpload,
            cancelUpload,
            cancelAllUploads,
            clearUploadQueue,
            activeUploadsCount,
            completedUploadsCount,
            failedUploadsCount,
            canceledUploadsCount,
            uploadTimeRemaining,
            queuedUploadBytesRemaining,
            isExpanded,
            setIsExpanded,
        }),
        [
            uploads,
            enqueueUploads,
            retryUpload,
            cancelUpload,
            cancelAllUploads,
            clearUploadQueue,
            activeUploadsCount,
            completedUploadsCount,
            failedUploadsCount,
            canceledUploadsCount,
            uploadTimeRemaining,
            queuedUploadBytesRemaining,
            isExpanded,
            setIsExpanded,
        ]
    );

    return (
        <UploadManagerContext.Provider value={value}>
            {children}
        </UploadManagerContext.Provider>
    );
};

UploadManager.propTypes = {
    children: PropTypes.node.isRequired,
};

export default UploadManager;

export const useUploadManager = () => useContext(UploadManagerContext);
