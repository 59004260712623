export default function getDate(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-za', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
}

export function getDateDMYFormat(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${day < 10 ? `0${day}` : day}/${
        month < 10 ? `0${month}` : month
    }/${year}`;
}
