import React from 'react';
import { Pane, MoreIcon, Menu, Popover, Position, Text } from 'evergreen-ui';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../theme/theme';

const Options = ({ options, selectedItem }) => (
    <Pane
        position="relative"
        zIndex={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
    >
        <Popover
            position={Position.BOTTOM_RIGHT}
            content={
                <Menu paddingY={0}>
                    {options.map(
                        ({ label, icon, onClick, hasRight }, index) => {
                            const { props, Icon } = icon;
                            const isLast = index === options.length - 1;

                            return (
                                <Menu.Item
                                    key={label}
                                    icon={<Icon {...props} />}
                                    onSelect={() => onClick(selectedItem)}
                                    intent={
                                        label.toLowerCase() === 'delete'
                                            ? 'danger'
                                            : 'none'
                                    }
                                    borderTop={
                                        isLast && !!options[options.length - 2]
                                            ? '1px solid #696f8c'
                                            : ''
                                    }
                                    display={hasRight ? 'flex' : 'none'}
                                >
                                    <Text
                                        textTransform="capitalize"
                                        textAlign="start"
                                        color={
                                            label.toLowerCase() === 'delete'
                                                ? '#d14343'
                                                : '#696f8c'
                                        }
                                    >
                                        {label}
                                    </Text>
                                </Menu.Item>
                            );
                        }
                    )}
                </Menu>
            }
        >
            <StyledMoreIcon />
        </Popover>
    </Pane>
);

Options.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            hasRight: PropTypes.bool,
        })
    ).isRequired,
    selectedItem: PropTypes.shape({}).isRequired,
};

const StyledMoreIcon = styled(MoreIcon)`
    padding: 4px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: ${colors.neutral300};
    }
`;

export default Options;
