import React from 'react';
import PropTypes from 'prop-types';
import { Text, Card, Heading, Pane } from 'evergreen-ui';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from 'chart.js';
import { colors } from '../../theme/theme';

ChartJS.register(ArcElement, Legend, Tooltip);

const legendMarginRight = {
    id: 'legendMarginRight',
    afterInit(chart) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
            fitValue.bind(chart.legend)();
            let { width } = this;
            width += 50;
            return width;
        };
    },
};

const options = {
    padding: 20,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
            rtl: true,
            position: 'right',
            align: 'center',
            labels: {
                usePointStyle: true,
                boxWidth: 6,
                boxHeight: 6,
            },
        },
    },
};

const PieChart = ({ title, data }) => (
    <Card
        elevation={1}
        flex="1"
        backgroundColor={colors.white}
        borderRadius={10}
        height="400px"
        width="49%"
        margin={1}
        padding="1.5rem"
        display="flex"
        flexDirection="column"
        position="relative"
    >
        <Heading
            fontSize={22}
            marginBottom="8px"
            fontWeight="700"
            color={colors.primary500}
        >
            {title}
        </Heading>
        <Pane height="calc(100% - 53.5px)" margin="auto">
            <Pie
                data={data}
                options={options}
                plugins={[legendMarginRight]}
                width={400}
            />
        </Pane>
        {!data?.datasets[0]?.data?.length && (
            <Text
                fontWeight={800}
                fontSize={28}
                color="#8f95b2"
                position="absolute"
                textAlign="center"
                top="50%"
                left={0}
                width="calc(100%)"
            >
                No Categories
            </Text>
        )}
    </Card>
);

PieChart.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape({
        datasets: PropTypes.arrayOf(
            PropTypes.shape({
                data: PropTypes.arrayOf(PropTypes.shape()),
            })
        ),
    }).isRequired,
};

export default PieChart;
