import React, { useMemo, useState } from 'react';
import { Pane } from 'evergreen-ui';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import VerticalStepper from '../../molecules/vertical-stepper';
import VerticalStepperStep from '../../molecules/vertical-stepper-step';
import ContactPersonDetailsForm from './contact-person-details-form';
import ContactBusinessDetailsForm from './contact-business-details-form';
import {
    businessContactModel,
    contactBusinessDetailsFormModel,
    contactPersonDetailsFormModel,
} from '../../../models';
import { contactsService } from '../../../services';
import { userSelector } from '../../../reducers/user-reducer/user.reducer';
import {
    getAllContactsForBusiness,
    getBusinessContacts,
} from '../../../reducers/contacts-reducer/contacts.actions';
import { errorToast, successToast } from '../../toasts';

const CreateContactForm = ({ closeSidebar }) => {
    const { businessIds } = useSelector(userSelector);
    const dispatch = useDispatch();
    const currentBusinessId = useMemo(
        () => _.first(businessIds),
        [businessIds]
    );
    const [currentStep, setCurrentStep] = useState(0);
    const [businessDetails, setBusinessDetails] = useState(
        contactBusinessDetailsFormModel()
    );

    const createContact = async (personDetails) => {
        try {
            let business;
            if (!businessDetails.id) {
                business = businessContactModel(
                    await contactsService.createBusinessContact(
                        businessDetails,
                        currentBusinessId
                    )
                );
            } else {
                business = businessDetails;
            }

            await contactsService.createContact(personDetails, business.id);
            dispatch(getAllContactsForBusiness());
            dispatch(getBusinessContacts());
            successToast('Successfully created new business contact');
            closeSidebar();
        } catch (error) {
            errorToast(_.get(error, 'response.data.message', error.message));
        }
    };

    const submitBusinessDetailsForm = (values) => {
        const businessName = _.get(values, 'name.name', values.name);
        const businessId = _.get(values, 'name.id');
        setBusinessDetails(
            contactBusinessDetailsFormModel({
                ...values,
                id: businessId,
                name: businessName,
            })
        );
        setCurrentStep(1);
    };

    const submitPersonDetailsForm = (values) =>
        createContact(contactPersonDetailsFormModel(values));

    return (
        <VerticalStepper currentStep={currentStep}>
            <VerticalStepperStep title="Business Details">
                <Pane marginX={2}>
                    <ContactBusinessDetailsForm
                        handleFormSubmission={submitBusinessDetailsForm}
                        initialValues={contactBusinessDetailsFormModel()}
                    />
                </Pane>
            </VerticalStepperStep>
            <VerticalStepperStep title="Contact Details">
                <Pane marginX={2}>
                    <ContactPersonDetailsForm
                        initialValues={contactPersonDetailsFormModel()}
                        handleFormSubmission={submitPersonDetailsForm}
                        setCurrentStep={setCurrentStep}
                    />
                </Pane>
            </VerticalStepperStep>
        </VerticalStepper>
    );
};

CreateContactForm.propTypes = {
    closeSidebar: PropTypes.func.isRequired,
};

export default CreateContactForm;
