import React, { useMemo, useCallback } from 'react';
import {
    Pane,
    Text,
    WrenchIcon,
    HomeIcon,
    ClipboardIcon,
    InfoSignIcon,
    DocumentIcon,
    BookIcon,
} from 'evergreen-ui';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import { colors } from '../../theme/theme';
import { useUserAccess } from '../../context/user-access-context';
import { notificationsSelector } from '../../reducers/notifications-reducer/notifications.reducer';

const DashboardSideNavbar = () => {
    const { currentAsset, currentTenant, currentBuilding } =
        useSelector(assetSelector);
    const { notificationsPerModule } = useSelector(notificationsSelector);

    const { pathname } = useLocation();

    const { modules } = useUserAccess();

    const getTo = useCallback(() => {
        let url = '/portfolio/properties';

        if (currentAsset?.id) {
            url += `/parks/${currentAsset?.id}`;
        }
        if (currentBuilding?.id) {
            url += `/buildings/${currentBuilding?.id}`;
        }
        if (currentTenant?.id) {
            url += `/tenants/${currentTenant?.id}`;
        }

        return url;
    }, [currentAsset, currentBuilding, currentTenant]);

    const menuItems = useMemo(
        () => [
            {
                text: 'Overview',
                Icon: HomeIcon,
                to: '/portfolio',
                isShown: modules.portfolio,
                notificationCount: 0,
            },
            {
                text: 'Info',
                Icon: InfoSignIcon,
                to: `${getTo()}/info`,
                isShown:
                    (currentAsset || currentBuilding || currentTenant) &&
                    modules.info,
                notificationCount: notificationsPerModule?.info,
            },
            {
                text: 'Compliance',
                Icon: DocumentIcon,
                to: `${getTo()}/compliance`,
                isShown:
                    (currentAsset || currentBuilding || currentTenant) &&
                    modules.compliance,
                notificationCount: notificationsPerModule?.compliance,
            },
            {
                text: 'Maintenance',
                Icon: WrenchIcon,
                to: '/maintenance',
                isShown: modules.maintenance,
                notificationCount: notificationsPerModule?.maintenance,
            },
            {
                text: 'Tasks',
                Icon: ClipboardIcon,
                to: '/tasks',
                isShown: modules.tasks,
                notificationCount: notificationsPerModule?.tasks,
            },
            {
                text: 'Contacts',
                Icon: BookIcon,
                to: '/contacts',
                isShown: modules.contacts,
                notificationCount: notificationsPerModule?.contacts,
            },
        ],
        [
            currentAsset,
            currentBuilding,
            currentTenant,
            getTo,
            modules,
            notificationsPerModule,
        ]
    );

    const canViewBuildings = useMemo(() => modules.info, [modules]);

    const isActive = useCallback(
        (text) => {
            if (pathname.includes(text.toLowerCase())) {
                return colors.secondary500;
            }
            if (text === 'Overview' && pathname === '/portfolio') {
                return colors.secondary500;
            }
            return colors.neutral700;
        },
        [pathname]
    );

    return (
        <Pane
            paddingTop={canViewBuildings ? 148 : 68}
            position="fixed"
            zIndex={3}
            height="100vh"
            width={85}
            backgroundColor={colors.white}
            borderRight="0.5px solid #C1C4D6"
            display="flex"
            flexDirection="column"
            gap={12}
        >
            {menuItems.map(({ text, Icon, to, isShown, notificationCount }) => (
                <Link
                    key={to}
                    to={to}
                    style={{ display: isShown ? 'block' : 'none' }}
                >
                    <Pane
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height={80}
                        gap={8}
                        position="relative"
                    >
                        {!!notificationCount && (
                            <Pane
                                borderRadius={99999}
                                background="red"
                                position="absolute"
                                top={12}
                                right={16}
                                padding={2}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width={14}
                                height={14}
                                zIndex={10}
                            >
                                <Text fontSize={10} color="white">
                                    {notificationCount}
                                </Text>
                            </Pane>
                        )}
                        <Icon color={isActive(text)} size={18} />
                        <Text
                            color={isActive(text)}
                            textAlign="center"
                            fontSize={12}
                        >
                            {text}
                        </Text>
                    </Pane>
                </Link>
            ))}
        </Pane>
    );
};

export default DashboardSideNavbar;
