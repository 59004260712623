import React from 'react';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    Pane,
    Button,
    Text,
} from 'evergreen-ui';
import { PropTypes } from 'prop-types';

function DatepickerCustomHeader({
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    customHeaderCount,
    monthDate,
}) {
    return (
        <Pane
            display="flex"
            alignItems="center"
            justifyItems="space-evenly"
            paddingX={5}
            gap={10}
            marginBottom={5}
        >
            {customHeaderCount === 0 && (
                <Button
                    borderRadius={999}
                    width={20}
                    padding={0}
                    type="button"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                >
                    <ArrowLeftIcon size={14} />
                </Button>
            )}
            <Text lineHeight="40px" textAlign="center" width="100%">
                {monthDate.toLocaleString('en-US', {
                    month: 'long',
                    year: 'numeric',
                })}
            </Text>

            {customHeaderCount === 1 && (
                <Button
                    borderRadius={999}
                    width={20}
                    padding={0}
                    type="button"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                >
                    <ArrowRightIcon size={14} />
                </Button>
            )}
        </Pane>
    );
}

export default DatepickerCustomHeader;

DatepickerCustomHeader.propTypes = {
    decreaseMonth: PropTypes.func.isRequired,
    increaseMonth: PropTypes.func.isRequired,
    prevMonthButtonDisabled: PropTypes.bool.isRequired,
    nextMonthButtonDisabled: PropTypes.bool.isRequired,
    customHeaderCount: PropTypes.number.isRequired,
    monthDate: PropTypes.string.isRequired,
};
