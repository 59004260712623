import React, { useCallback } from 'react';
import { Pane, Text } from 'evergreen-ui';
import { PropTypes } from 'prop-types';
import { orderBy } from 'lodash';

function ComplianceChartLegend({ complianceDetails }) {
    const percentageColor = useCallback((value) => {
        if (value < 25) {
            return '#de2d2d';
        }
        if (value < 50) {
            return '#ff9262';
        }
        if (value < 75) {
            return '#ffd078';
        }
        return '#53bd94';
    }, []);

    return (
        <Pane
            flex={1}
            display="flex"
            flexDirection="column"
            gap={4}
            justifyContent="space-evenly"
        >
            {orderBy(complianceDetails, ['value', 'uploadsRequested'])
                .slice(0, 2)
                .map(({ label, value, uploadsRequested }) => (
                    <Pane
                        key={label}
                        display="flex"
                        alignItems="center"
                        gap={10}
                    >
                        <Pane
                            display="flex"
                            flexDirection="column"
                            flex={1}
                            gap={5}
                        >
                            <Pane
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text color="#3A3541DE">{label}</Text>
                                <Text
                                    fontWeight={900}
                                    fontSize={18}
                                    color={percentageColor(value)}
                                    marginTop="auto"
                                    paddingX={10}
                                >
                                    {value}%
                                </Text>
                            </Pane>
                            <Pane
                                width="100%"
                                maxWidth="100%"
                                height={6}
                                backgroundColor="#E6E8F0"
                                borderRadius={999}
                                overflow="hidden"
                                marginBottom={4}
                            >
                                <Pane
                                    width={`${value}%`}
                                    maxWidth="calc(100% - 40px)"
                                    backgroundColor="#474d66"
                                    height={6}
                                    position="absolute"
                                    zIndex={1}
                                    borderRadius={999}
                                />
                                <Pane
                                    width={`${uploadsRequested + value}%`}
                                    maxWidth="calc(100% - 40px)"
                                    backgroundColor="#ff9262"
                                    height={6}
                                    position="absolute"
                                    borderRadius={999}
                                />
                            </Pane>
                        </Pane>
                    </Pane>
                ))}
        </Pane>
    );
}

ComplianceChartLegend.propTypes = {
    complianceDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ComplianceChartLegend;
