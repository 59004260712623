import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
    isLoading: false,
    spacesAssetId: null,
    spaces: null,
    isLoadingCurrentSpace: false,
    currentSpace: null,
};

const spacesSlice = createSlice({
    name: 'spaces',
    initialState,
    reducers: {
        setIsLoadingAction(state, action) {
            state.isLoading = action.payload;
        },
        setSpacesAction(state, action) {
            state.spaces = action.payload;
        },
        setSpacesAssetIdAction(state, action) {
            state.spacesAssetId = action.payload;
        },
        setIsLoadingCurrentSpaceAction(state, action) {
            state.isLoadingCurrentSpace = action.payload;
        },
        setCurrentSpaceAction(state, action) {
            state.currentSpace = action.payload;
        },
        setSpaceComplianceProgressAction(state, action) {
            const spaceIndex = _.findIndex(
                state.spaces,
                (space) => space.id === action.payload.id
            );

            state.spaces[spaceIndex] = {
                ...state.spaces[spaceIndex],
                progress: {
                    completed: action.payload.completed,
                    uploadsRequested: action.payload.uploadsRequested,
                    notStarted: action.payload.notStarted,
                    total: action.payload.total,
                },
            };
        },
        updateSpaceComplianceType(state, action) {
            const complianceGroup = _.find(
                state.currentSpace.complianceGroups,
                (_complianceGroup) =>
                    String(_complianceGroup.id) ===
                    String(action.payload.complianceGroupId)
            );
            const complianceCategory = _.find(
                complianceGroup.complianceCategories,
                (_complianceCategory) =>
                    String(_complianceCategory.id) ===
                    String(action.payload.complianceCategoryId)
            );

            const complianceTypeIndex = _.findIndex(
                complianceCategory.complianceTypes,
                (_complianceType) =>
                    String(_complianceType.id) ===
                    String(action.payload.complianceTypeId)
            );

            complianceCategory.complianceTypes[complianceTypeIndex] =
                action.payload.updatedComplianceType;
        },
        removeSpaceComplianceType(state, action) {
            const complianceGroup = _.find(
                state.currentSpace.complianceGroups,
                (_complianceGroup) =>
                    String(_complianceGroup.id) ===
                    String(action.payload.complianceGroupId)
            );
            const complianceCategory = _.find(
                complianceGroup.complianceCategories,
                (_complianceCategory) =>
                    String(_complianceCategory.id) ===
                    String(action.payload.complianceCategoryId)
            );
            _.remove(
                complianceCategory.complianceTypes,
                (_complianceType) =>
                    String(_complianceType.id) ===
                    String(action.payload.complianceTypeId)
            );
        },
        addSpaceComplianceType(state, action) {
            const complianceGroup = _.find(
                state.currentSpace.complianceGroups,
                (_complianceGroup) =>
                    String(_complianceGroup.id) ===
                    String(action.payload.complianceGroupId)
            );
            const complianceCategory = _.find(
                complianceGroup.complianceCategories,
                (_complianceCategory) =>
                    String(_complianceCategory.id) ===
                    String(action.payload.complianceCategoryId)
            );
            complianceCategory.complianceTypes.push(
                action.payload.complianceType
            );
        },
    },
});

export const {
    setIsLoadingAction,
    setSpacesAction,
    setSpacesAssetIdAction,
    setIsLoadingCurrentSpaceAction,
    setCurrentSpaceAction,
    setSpaceComplianceProgressAction,
    updateSpaceComplianceType,
    removeSpaceComplianceType,
    addSpaceComplianceType,
} = spacesSlice.actions;

export const spacesSelector = (reducers) => reducers.spacesReducer;

export default spacesSlice.reducer;
