import React from 'react';
import { Button, Pane, SelectField, TextInputField } from 'evergreen-ui';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as Yup from 'yup';
import { Collapsible } from '../../atoms';

const PROFESSION_TYPES = [
    'Project Manager',
    'Principle Architect',
    'Principle Consultant',
    'Architect',
    'Interior Designer',
    'Townplanner',
    'Quantity Surveyor',
    'Structural Engineer',
    'Electrical Engineer',
    'Mechanical Engineer',
    'Fire Consultant',
    'Other',
];

const ContactPersonDetailsForm = ({
    initialValues,
    handleFormSubmission,
    isBasicDetailsOnly,
    setCurrentStep,
}) => {
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string()
            .required('Email is required')
            .email('Invalid email'),
        mobileNumber: Yup.string().when(() => {
            if (!isBasicDetailsOnly) {
                return Yup.string().required('Mobile number is required');
            }
            return Yup.string();
        }),
        registrationNumber: Yup.string().required(
            'Registration number is required'
        ),
        profession: Yup.string().when(() => {
            if (!isBasicDetailsOnly) {
                return Yup.string().required('Profession is required');
            }
            return Yup.string();
        }),
        professionName: Yup.string().when('profession', (profession) => {
            if (!isBasicDetailsOnly && profession === 'Other') {
                return Yup.string().required('Profession is required');
            }
            return Yup.string();
        }),
    });

    const goBack = () => {
        setCurrentStep(0);
    };

    const onSubmit = async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);

        try {
            if (values.profession === 'Other') {
                await handleFormSubmission({
                    ...values,
                    profession: values.professionName,
                });
            } else {
                await handleFormSubmission(values);
            }
        } catch (error) {
            setFieldError(
                'profession',
                _.get(error, 'response.data.message', error.message)
            );
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <TextInputField
                        label="Name"
                        type="text"
                        name="firstName"
                        placeholder="John"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.firstName && !!errors.firstName}
                        validationMessage={
                            touched.firstName && errors.firstName
                        }
                        spellCheck={false}
                        width="100%"
                        inputHeight={40}
                    />
                    <Pane marginTop={16}>
                        <TextInputField
                            label="Surname"
                            type="text"
                            name="lastName"
                            placeholder="Doe"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.lastName && !!errors.lastName}
                            validationMessage={
                                touched.lastName && errors.lastName
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                    </Pane>
                    <Pane marginTop="16px">
                        <TextInputField
                            label="Email"
                            type="email"
                            name="email"
                            placeholder="contact@example.com"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.email && !!errors.email}
                            validationMessage={touched.email && errors.email}
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                    </Pane>
                    {!isBasicDetailsOnly && (
                        <>
                            <Pane marginTop="16px">
                                <TextInputField
                                    label="Phone Number"
                                    type="text"
                                    name="mobileNumber"
                                    placeholder="+27712526996"
                                    value={values.mobileNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                        touched.mobileNumber &&
                                        !!errors.mobileNumber
                                    }
                                    validationMessage={
                                        touched.mobileNumber &&
                                        errors.mobileNumber
                                    }
                                    spellCheck={false}
                                    width="100%"
                                    inputHeight={40}
                                />
                            </Pane>
                            <Pane>
                                <SelectField
                                    name="profession"
                                    width="100%"
                                    inputHeight={40}
                                    label="Profession"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.profession}
                                    isInvalid={
                                        touched.profession &&
                                        !!errors.profession
                                    }
                                    validationMessage={
                                        touched.profession && errors.profession
                                    }
                                >
                                    <option value="" selected disabled>
                                        Select a Profession
                                    </option>
                                    {_.map(PROFESSION_TYPES, (item) => (
                                        <option
                                            key={item}
                                            value={item}
                                            selected
                                        >
                                            {item}
                                        </option>
                                    ))}
                                </SelectField>
                            </Pane>
                            <Collapsible isOpen={values.profession === 'Other'}>
                                <Pane marginTop="16px">
                                    <TextInputField
                                        label="Profession Name"
                                        type="text"
                                        name="professionName"
                                        placeholder="Plumber"
                                        value={values.professionName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={
                                            touched.professionName &&
                                            !!errors.professionName
                                        }
                                        validationMessage={
                                            touched.professionName &&
                                            errors.professionName
                                        }
                                        spellCheck
                                        width="100%"
                                        inputHeight={40}
                                    />
                                </Pane>
                            </Collapsible>
                            <Pane marginTop="16px">
                                <TextInputField
                                    label="Professional Registration Number"
                                    type="text"
                                    name="registrationNumber"
                                    placeholder="23234123"
                                    value={values.registrationNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                        touched.registrationNumber &&
                                        !!errors.registrationNumber
                                    }
                                    validationMessage={
                                        touched.registrationNumber &&
                                        errors.registrationNumber
                                    }
                                    spellCheck={false}
                                    width="100%"
                                    inputHeight={40}
                                />
                            </Pane>
                        </>
                    )}
                    <Pane marginTop="16px">
                        <Button
                            appearance="primary"
                            height={40}
                            type="submit"
                            width="100%"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            {isBasicDetailsOnly
                                ? 'Create Contact'
                                : 'Update Details'}
                        </Button>
                    </Pane>
                    <Button
                        appearance="minimal"
                        height={40}
                        width="100%"
                        marginTop={8}
                        onClick={goBack}
                        type="button"
                        disabled={isSubmitting}
                    >
                        Back
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

ContactPersonDetailsForm.propTypes = {
    initialValues: PropTypes.shape({}).isRequired,
    isBasicDetailsOnly: PropTypes.bool,

    handleFormSubmission: PropTypes.func.isRequired,
    setCurrentStep: PropTypes.func.isRequired,
};

ContactPersonDetailsForm.defaultProps = {
    isBasicDetailsOnly: false,
};

export default ContactPersonDetailsForm;
