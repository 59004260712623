import React from 'react';
import PropTypes from 'prop-types';
import { Pane } from 'evergreen-ui';
import _ from 'lodash';

const MultiColorProgressBar = ({ progressValues, height }) => (
    <Pane display="flex" height={height}>
        {_.map(progressValues, (item, index) => (
            <Pane
                key={`$progress-bar-item-${index}-${item.color}-${item.value}`}
                backgroundColor={item.color}
                width={`${item.value}%`}
                height={height}
            />
        ))}
    </Pane>
);

MultiColorProgressBar.propTypes = {
    progressValues: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            color: PropTypes.string,
        })
    ).isRequired,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MultiColorProgressBar.defaultProps = {
    height: 10,
};

export default MultiColorProgressBar;
