import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Badge,
    ChevronRightIcon,
    Pane,
    Paragraph,
    Popover,
    Text,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import { colors } from '../../../theme/theme';
import { Collapsible } from '../../atoms';
import ComplianceType from './compliance-type';
import { ContextMenu, ContextMenuItem } from '../../molecules';
import useQueryParams from '../../../hooks/use-query-params';
import AddComplianceTypeDialog from './add-compliance-type-dialog';
import ComplianceDialog from './compliance-dialog';
import { complianceService } from '../../../services';

const ComplianceCategory = ({ category, groupId, rights }) => {
    const { categoryId } = useQueryParams();

    const [isOpen, setIsOpen] = useState(false);
    const [complianceDialog, setComplianceDialog] = useState({
        isShown: false,
        isDeleting: false,
        complianceItem: {},
    });
    const [contextMenuShown, setContextMenuShown] = useState(false);
    const [initialContextMenuShown, setInitialContextMenuShown] =
        useState(false);
    const [
        isAddNewComplianceTypeDialogShown,
        setIsAddNewComplianceTypeDialogShown,
    ] = useState(false);

    const handleBodyContextClick = useCallback(() => {
        if (initialContextMenuShown) {
            setInitialContextMenuShown(false);
            setContextMenuShown(false);
        } else {
            setInitialContextMenuShown(true);
        }
    }, [
        initialContextMenuShown,
        setInitialContextMenuShown,
        setContextMenuShown,
    ]);

    useEffect(() => {
        if (categoryId === category.id.toString()) {
            setIsOpen(true);
        }
    }, [categoryId, category, setIsOpen]);

    useEffect(() => {
        if (contextMenuShown) {
            document.addEventListener('contextmenu', handleBodyContextClick);
        } else {
            document.removeEventListener('contextmenu', handleBodyContextClick);
        }

        return () => {
            document.removeEventListener('contextmenu', handleBodyContextClick);
        };
    }, [contextMenuShown, handleBodyContextClick]);

    const completedPercentage = useMemo(() => {
        const applicableFilteredTypesLength = _.filter(
            category.complianceTypes,
            (type) => type.applicable
        ).length;
        if (applicableFilteredTypesLength === 0) {
            return -1;
        }
        const completedCount = _.reduce(
            category.complianceTypes,
            (count, type) =>
                type.completed && type.applicable ? count + 1 : count,
            0
        );
        return Math.round(
            (completedCount / applicableFilteredTypesLength) * 100
        );
    }, [category]);

    const completedPercentageColor = (percentage) => {
        if (percentage >= 0 && percentage < 50) {
            return 'red';
        }
        if (percentage >= 50 && percentage < 90) {
            return 'orange';
        }
        if (percentage >= 90 && percentage < 100) {
            return 'yellow';
        }

        return 'green';
    };

    const toggleCollapsible = () => setIsOpen(!isOpen);

    const showContextMenu = (event) => {
        event.preventDefault();
        if (!(rights?.create || rights?.update || rights?.delete)) {
            return;
        }
        setContextMenuShown(true);
    };

    const hideContextMenu = () => {
        setContextMenuShown(false);
    };

    const showAddNewComplianceTypeDialogShown = () => {
        setIsAddNewComplianceTypeDialogShown(true);
    };

    const openRenameCategoryDialog = () => {
        setComplianceDialog({
            isShown: true,
            isDeleting: false,
            isEditing: true,
            complianceItem: category,
        });
    };

    const openDeleteCategoryDialog = () => {
        setComplianceDialog({
            isShown: true,
            isDeleting: true,
            isEditing: false,
            complianceItem: category,
        });
    };

    const closeComplianceDialog = () => {
        setComplianceDialog({
            isShown: false,
            isDeleting: false,
            complianceItem: null,
        });
    };

    const onConfirm = async (_complianceItem) => {
        const data = {
            ..._complianceItem,
            complianceGroupId: groupId,
            complianceCategoryId: category.id,
        };

        return complianceDialog.isDeleting
            ? complianceService.deleteComplianceCategory(_complianceItem.id)
            : complianceService.renameComplianceCategory(data);
    };

    return (
        <>
            <Pane>
                <Popover
                    isShown={contextMenuShown}
                    shouldCloseOnExternalClick
                    onClose={hideContextMenu}
                    id={`category-${category.id}`}
                    content={
                        (rights?.create ||
                            rights?.update ||
                            rights?.delete) && (
                            <ContextMenu preventHideOnContextMenu>
                                {rights?.update && (
                                    <ContextMenuItem
                                        onClick={openRenameCategoryDialog}
                                    >
                                        <Paragraph>
                                            Edit Category Name
                                        </Paragraph>
                                    </ContextMenuItem>
                                )}
                                {rights?.create && (
                                    <ContextMenuItem
                                        onClick={
                                            showAddNewComplianceTypeDialogShown
                                        }
                                    >
                                        <Paragraph>Add Type</Paragraph>
                                    </ContextMenuItem>
                                )}
                                {rights?.delete && (
                                    <ContextMenuItem
                                        onClick={openDeleteCategoryDialog}
                                    >
                                        <Paragraph color={colors.red500}>
                                            Delete Category
                                        </Paragraph>
                                    </ContextMenuItem>
                                )}
                            </ContextMenu>
                        )
                    }
                >
                    <Pane onContextMenu={showContextMenu}>
                        <Pane
                            display="flex"
                            justifyContent="space-between"
                            paddingTop={12}
                            alignItems="center"
                            cursor="pointer"
                            onClick={toggleCollapsible}
                            paddingX={30}
                        >
                            <Pane display="flex" alignItems="center">
                                <ChevronRightIcon
                                    color={colors.gray800}
                                    marginRight={6}
                                    transform={isOpen && 'rotate(90deg)'}
                                    transition="transform 250ms"
                                />

                                <Paragraph
                                    size={600}
                                    fontWeight="700"
                                    fontSize={14}
                                    color="#0C2138"
                                >
                                    {category.name}
                                </Paragraph>
                            </Pane>
                            <Badge
                                color={completedPercentageColor(
                                    completedPercentage
                                )}
                                minWidth={32}
                            >
                                {completedPercentage < 0
                                    ? 'NA'
                                    : `${completedPercentage}%`}
                            </Badge>
                        </Pane>
                    </Pane>
                </Popover>
                <Collapsible isOpen={isOpen}>
                    {_.map(
                        _.orderBy(
                            category.complianceTypes,
                            ['applicable', 'name'],
                            'desc'
                        ),
                        (type, typeIndex) => (
                            <ComplianceType
                                key={`${type.id}-${typeIndex}`}
                                type={type}
                                groupId={groupId}
                                categoryId={category.id}
                                rights={rights}
                            />
                        )
                    )}
                    {rights?.create && (
                        <AddTypeButton
                            marginLeft={58}
                            marginRight={30}
                            marginTop={14}
                            marginBottom={28}
                            onClick={showAddNewComplianceTypeDialogShown}
                        >
                            <Text>+ Add Compliance Type</Text>
                        </AddTypeButton>
                    )}
                </Collapsible>
            </Pane>
            {rights?.create && (
                <AddComplianceTypeDialog
                    complianceCategory={category}
                    isShown={isAddNewComplianceTypeDialogShown}
                    setIsShown={setIsAddNewComplianceTypeDialogShown}
                />
            )}
            {(rights?.create || rights?.update || rights?.delete) &&
                complianceDialog.isShown && (
                    <ComplianceDialog
                        key={`${categoryId}-${groupId}`}
                        isShown={complianceDialog.isShown}
                        isDeleting={complianceDialog.isDeleting}
                        isEditing={complianceDialog.isEditing}
                        complianceItem={complianceDialog.complianceItem}
                        onConfirm={onConfirm}
                        onClose={closeComplianceDialog}
                        isCategory
                    />
                )}
        </>
    );
};

ComplianceCategory.propTypes = {
    category: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        complianceTypes: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    groupId: PropTypes.string.isRequired,
    rights: PropTypes.shape({
        create: PropTypes.bool,
        read: PropTypes.bool,
        update: PropTypes.bool,
        delete: PropTypes.bool,
    }).isRequired,
};

export default ComplianceCategory;

const AddTypeButton = styled(Pane)`
    span {
        color: #5ec090;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;
