import React, { useMemo, useState } from 'react';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    Pane,
    Button,
    SelectField,
} from 'evergreen-ui';
import { PropTypes } from 'prop-types';
import _ from 'lodash';

function DatepickerCustomHeader({
    date,
    changeMonth,
    changeYear,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) {
    const months = useMemo(
        () => [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        []
    );

    const years = useMemo(() => _.range(1900, 2050), []);

    const d = new Date(date);

    const [month, setMonth] = useState(d.getMonth());
    const [year, setYear] = useState(d.getFullYear());

    const handleMonthChange = (e) => {
        if (typeof e === 'number') {
            setMonth(e);
            changeMonth(e);
        } else {
            setMonth(months.indexOf(e.target.value));
            changeMonth(months.indexOf(e.target.value));
        }
    };

    const handleYearChange = (e) => {
        const selectedYear = Number(e.target.value);
        setYear(selectedYear);
        changeYear(selectedYear);
    };

    const handleMonthDecrease = () => {
        if (!prevMonthButtonDisabled) {
            if (month === 0) {
                setMonth(11);
                decreaseMonth();
                setYear((prevYear) => prevYear - 1);
                changeYear(year - 1);
            } else {
                setMonth((prev) => prev - 1);
                decreaseMonth();
            }
        }
    };

    const handleMonthIncrease = () => {
        if (!nextMonthButtonDisabled) {
            if (month === 11) {
                setMonth(0);
                increaseMonth();
                setYear((prevYear) => prevYear + 1);
                changeYear(year + 1);
            } else {
                setMonth((prev) => prev + 1);
                increaseMonth();
            }
        }
    };

    return (
        <Pane
            display="flex"
            alignItems="center"
            justifyItems="space-evenly"
            paddingX={5}
            gap={10}
        >
            <Button
                borderRadius={999}
                width={20}
                padding={0}
                type="button"
                onClick={handleMonthDecrease}
                disabled={prevMonthButtonDisabled}
            >
                <ArrowLeftIcon size={14} />
            </Button>
            <SelectField
                marginTop={2}
                marginBottom={10}
                inputHeight={32}
                maxHeight={300}
                width="100%"
                value={months[month]}
                onChange={handleMonthChange}
            >
                {months.map((item) => (
                    <option key={item} value={item}>
                        {item}
                    </option>
                ))}
            </SelectField>
            <SelectField
                marginTop={2}
                marginBottom={10}
                inputHeight={32}
                width="100%"
                value={year}
                onChange={handleYearChange}
            >
                {years.map((item) => (
                    <option key={item} value={item}>
                        {item}
                    </option>
                ))}
            </SelectField>
            <Button
                borderRadius={999}
                width={20}
                padding={0}
                type="button"
                onClick={handleMonthIncrease}
                disabled={nextMonthButtonDisabled}
            >
                <ArrowRightIcon size={14} />
            </Button>
        </Pane>
    );
}

export default DatepickerCustomHeader;

DatepickerCustomHeader.propTypes = {
    date: PropTypes.number.isRequired,
    changeMonth: PropTypes.func.isRequired,
    changeYear: PropTypes.func.isRequired,
    decreaseMonth: PropTypes.func.isRequired,
    increaseMonth: PropTypes.func.isRequired,
    prevMonthButtonDisabled: PropTypes.bool.isRequired,
    nextMonthButtonDisabled: PropTypes.bool.isRequired,
};
