import React, { useMemo } from 'react';
import { Autocomplete, TextInputField } from 'evergreen-ui';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { contactsSelector } from '../../../reducers/contacts-reducer/contacts.reducer';

const BusinessNameAutocomplete = ({ label, placeholder, name, ...props }) => {
    const [field, meta] = useField({ name, ...props });
    const { setFieldValue } = useFormikContext();
    const { businesses } = useSelector(contactsSelector);
    const businessNames = useMemo(
        () => _.map(businesses, (business) => business.name),
        [businesses]
    );
    const selectedBusinessName = useMemo(() => {
        if (typeof field.value === 'object') {
            const selectedBusiness = _.find(
                businesses,
                (business) => business.name === field.value.name
            );
            return selectedBusiness.name;
        }

        return field.value;
    }, [businesses, field]);

    const onChange = (value) => {
        const selectedBusiness = _.find(
            businesses,
            (business) => value && _.get(business, 'name') === value
        );
        setFieldValue('name', selectedBusiness || value);
        if (selectedBusiness) {
            setFieldValue('mobileNumber', selectedBusiness.mobileNumber);
            setFieldValue('email', selectedBusiness.email);
        } else {
            setFieldValue('mobileNumber', '');
            setFieldValue('email', '');
        }
    };

    const onFocus = (autocompleteOnFocus) => (event) => {
        event.target.select();
        autocompleteOnFocus(event);
    };

    return (
        <Autocomplete
            onChange={onChange}
            items={businessNames}
            selectedItem={selectedBusinessName}
            allowOtherValues
        >
            {({ getInputProps, getRef, inputValue, openMenu }) => {
                const { onBlur } = getInputProps();
                return (
                    <TextInputField
                        {...field}
                        {...getInputProps()}
                        name={name}
                        onFocus={onFocus(openMenu)}
                        onBlur={(event) => {
                            onBlur(event);
                            field.onBlur(event);
                        }}
                        label={label}
                        placeholder={placeholder}
                        width="100%"
                        value={inputValue}
                        ref={getRef}
                        inputHeight={40}
                        isInvalid={meta.touched && !!meta.error}
                        validationMessage={meta.touched && meta.error}
                    />
                );
            }}
        </Autocomplete>
    );
};

BusinessNameAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
};

BusinessNameAutocomplete.defaultProps = {
    label: null,
    placeholder: null,
};

export default BusinessNameAutocomplete;
