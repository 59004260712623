import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
    Pane,
    Tablist,
    Tab,
    Card,
    Heading,
    Text,
    InfoSignIcon,
    Tooltip,
    ChevronRightIcon,
} from 'evergreen-ui';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { colors } from '../../theme/theme';
import { getMaintenanceCategories } from '../../reducers/maintenance-reducer/maintenance-actions';
import { maintenanceSelector } from '../../reducers/maintenance-reducer/maintenance-reducer';
import { getMaintenanceCategoryModel } from '../../models/maintenance.models';
import { taskSelector } from '../../reducers/task-reducer/task.reducer';
import { getTaskCategoryModel } from '../../models/task.models';
import PageLoading from '../../components/molecules/page-loading';
import { getTasksCategories } from '../../reducers/task-reducer/task.actions';
import MaintenanceTemplateForm from '../../components/molecules/maintenance-template-form';
import TaskTemplateForm from '../../components/molecules/task-template-form';
import { getNotifications } from '../../reducers/notifications-reducer/notifications.actions';
import { useUserAccess } from '../../context/user-access-context';

const SUBJECTS = ['maintenance', 'tasks'];

function TemplateManagement() {
    const [loading, setLoading] = useState(true);
    const [selectedSubject, setSelectedSubject] = useState('maintenance');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [types, setTypes] = useState([]);

    const dispatch = useDispatch();

    const { currentRights } = useUserAccess();

    const { create } = useMemo(
        () => currentRights || { create: false },
        [currentRights]
    );

    const { categories: maintenanceCategories } =
        useSelector(maintenanceSelector);
    const { categories: taskCategories } = useSelector(taskSelector);

    const categories = useMemo(() => {
        if (selectedSubject === 'maintenance') {
            return maintenanceCategories.map((category) =>
                getMaintenanceCategoryModel(category)
            );
        }
        return taskCategories.map((category) => getTaskCategoryModel(category));
    }, [maintenanceCategories, selectedSubject, taskCategories]);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            await Promise.all([
                dispatch(getTasksCategories()),
                dispatch(getMaintenanceCategories()),
                dispatch(getNotifications()),
            ]);
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    const handleSubjectChange = async (subject) => {
        setSelectedSubject(subject);
        setSelectedCategory(null);
        setSelectedType(null);
        setTypes([]);
        await getData();
    };
    const handleCategoryChange = (category) => {
        if (Number(category.id) === Number(selectedCategory?.id) && create) {
            setSelectedCategory(null);
            setTypes([]);
            return;
        }
        setTypes(category.types);
        setSelectedCategory(category);
        if (create) {
            setSelectedType(null);
        } else {
            setSelectedType(category.types[0]);
        }
    };
    const handleTypeChange = (type) => {
        if (Number(type.id) === Number(selectedType?.id) && create) {
            setSelectedType(null);
            return;
        }
        setSelectedType(type);
    };

    useEffect(() => {
        if (!create) {
            setSelectedCategory(categories[0]);
            setTypes(categories[0]?.types);
            setSelectedType(categories[0]?.types[0]);
        }
    }, [categories, create]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <Pane
            display="flex"
            width="95%"
            maxWidth={1440}
            marginX="auto"
            paddingTop={20}
            height="calc(100vh - 145px)"
        >
            <Pane
                width="100%"
                display="flex"
                flexDirection="column"
                position="sticky"
                top={0}
                zIndex={1}
                gap={12}
                backgroundColor="#f2f4fa"
            >
                <Pane display="flex" alignItems="center" gap={10}>
                    <Heading size={700} fontSize={24} paddingBottom={10}>
                        Template Management
                    </Heading>
                    <Tooltip content="Efficiently customize your dropdown categories and subcategories/types here">
                        <InfoSignIcon marginTop={-10} />
                    </Tooltip>
                </Pane>
                <Pane
                    display="flex"
                    width="100%"
                    background="white"
                    borderRadius={8}
                    overflow="hidden"
                >
                    <Tablist
                        display="flex"
                        alignItems="end"
                        height={35}
                        background="white"
                        width="100%"
                    >
                        {SUBJECTS.map((subject) => {
                            const isSelected = subject === selectedSubject;
                            return (
                                <StyledTab
                                    key={subject}
                                    borderRadius={0}
                                    onSelect={() =>
                                        handleSubjectChange(subject)
                                    }
                                    isSelected={isSelected}
                                    backgroundColor={colors.white}
                                    borderBottom={
                                        isSelected
                                            ? '3px solid #A3E6CD'
                                            : 'none'
                                    }
                                    fontWeight={isSelected ? '700' : '500'}
                                    fontSize={16}
                                    color="#0C2138"
                                >
                                    {subject}
                                </StyledTab>
                            );
                        })}
                    </Tablist>
                </Pane>
                <Card
                    display="flex"
                    width="100%"
                    height="calc(100% - 130px)"
                    background="white"
                    overflow="hidden"
                    borderRadius={8}
                >
                    {loading ? (
                        <PageLoading />
                    ) : (
                        <Pane width="100%">
                            <Pane
                                display="flex"
                                height={40}
                                background="#c2c4d660"
                            >
                                <Pane padding={10} paddingLeft={20} width={300}>
                                    <Text fontWeight={800} fontSize={16}>
                                        Categories
                                    </Text>
                                </Pane>
                                <Pane padding={10} paddingLeft={20} width={300}>
                                    <Text fontWeight={800} fontSize={16}>
                                        Types
                                    </Text>
                                </Pane>
                                <Pane padding={10} paddingLeft={20} width={300}>
                                    <Text fontWeight={800} fontSize={16}>
                                        Form
                                    </Text>
                                </Pane>
                            </Pane>
                            <Pane display="flex" flex={1} height="100%">
                                <Pane
                                    display="flex"
                                    flexDirection="column"
                                    width={300}
                                    borderRight="1px solid #c2c4d6"
                                >
                                    {categories.map((category) => (
                                        <Item
                                            key={category.id}
                                            isSelected={
                                                category.id ===
                                                selectedCategory?.id
                                            }
                                            onClick={() =>
                                                handleCategoryChange(category)
                                            }
                                            display="flex"
                                            alignItems="center"
                                        >
                                            {category.abbreviation}{' '}
                                            {category.name}
                                            {category.id ===
                                                selectedCategory?.id && (
                                                <ChevronRightIcon
                                                    color={colors.secondary500}
                                                    marginLeft="auto"
                                                />
                                            )}
                                        </Item>
                                    ))}
                                </Pane>
                                <Pane
                                    display="flex"
                                    flexDirection="column"
                                    width={300}
                                    borderRight="1px solid #c2c4d6"
                                >
                                    {!selectedCategory && (
                                        <Item textAlign="center">
                                            Select a Category to view Types
                                        </Item>
                                    )}
                                    {selectedCategory && !types?.length ? (
                                        <Item>No Types</Item>
                                    ) : (
                                        types.map((type) => (
                                            <Item
                                                key={`${type.id}-${type.name}`}
                                                isSelected={
                                                    Number(selectedType?.id) ===
                                                    Number(type.id)
                                                }
                                                onClick={() =>
                                                    handleTypeChange(type)
                                                }
                                            >
                                                {type.name}
                                            </Item>
                                        ))
                                    )}
                                </Pane>
                                <Pane
                                    display="flex"
                                    flex={1}
                                    paddingY={20}
                                    paddingX={50}
                                    marginBottom={50}
                                    overflowY="auto"
                                    overflowX="hidden"
                                >
                                    {selectedSubject === 'maintenance' && (
                                        <MaintenanceTemplateForm
                                            key={`${selectedCategory?.id}-${selectedType?.id}`}
                                            selectedCategory={selectedCategory}
                                            selectedType={selectedType}
                                            setSelectedCategory={
                                                setSelectedCategory
                                            }
                                            setSelectedType={setSelectedType}
                                            setTypes={setTypes}
                                            types={types}
                                            rights={currentRights}
                                        />
                                    )}
                                    {selectedSubject === 'tasks' && (
                                        <TaskTemplateForm
                                            key={`${selectedCategory?.id}-${selectedType?.id}`}
                                            selectedCategory={selectedCategory}
                                            selectedType={selectedType}
                                            setSelectedCategory={
                                                setSelectedCategory
                                            }
                                            setSelectedType={setSelectedType}
                                            setTypes={setTypes}
                                            types={types}
                                            rights={currentRights}
                                        />
                                    )}
                                </Pane>
                            </Pane>
                        </Pane>
                    )}
                </Card>
            </Pane>
        </Pane>
    );
}

const StyledTab = styled(Tab)`
    &[aria-selected='true'] {
        background-color: #ffffff;
    }

    &:hover {
        background-color: #ffffff;
    }

    &:focus {
        box-shadow: none;
    }
    text-transform: capitalize;
`;

const Item = styled(Text)`
    font-weight: 900;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    role: button;
    color: ${({ isSelected }) =>
        isSelected ? colors.secondary500 : '#696f8c'};
    background-color: ${({ isSelected }) =>
        isSelected ? '#fafbff' : 'transparent'};
    border-bottom: 1px solid
        ${({ isSelected }) =>
            isSelected ? colors.secondary500 : 'transparent'};

    &:hover {
        background-color: #fafbff;
    }
`;

export default TemplateManagement;
