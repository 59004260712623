import React, { useEffect } from 'react';
import { TextInputField } from 'evergreen-ui';
import { useField, useFormikContext } from 'formik';
import { usePlacesWidget } from 'react-google-autocomplete';
import _ from 'lodash';
import PropTypes from 'prop-types';

const placeValueTypes = {
    street_number: 'streetNumber',
    route: 'streetName',
    sublocality_level_1: 'suburb',
    locality: 'city',
    administrative_area_level_1: 'province',
    country: 'country',
    postal_code: 'postalCode',
};

const AddressAutoCompleteField = (props) => {
    const { value: initialValue } = props;
    const [field, , helpers] = useField(props);
    const { values, setFieldValue } = useFormikContext();

    const onSearchValueChange = (event) => {
        setFieldValue('searchValue', event.nativeEvent.target.value);
    };

    const onPlaceSelected = ({
        // eslint-disable-next-line camelcase
        address_components,
        // eslint-disable-next-line camelcase
        formatted_address,
        geometry,
    }) => {
        const address = {};
        _.forEach(address_components, (addressComponent) => {
            const type =
                placeValueTypes[_.get(addressComponent, 'types[0]', '')];
            const value = _.get(addressComponent, 'long_name', '');

            address[type] = value;
            setFieldValue(type, value);
        });

        setFieldValue(
            'addressLine1',
            `${_.get(address, 'streetNumber', '')} ${address.streetName}`
        );
        setFieldValue('lat', geometry.location.lat());
        setFieldValue('long', geometry.location.lng());

        helpers.setValue(address);
        setFieldValue('searchValue', formatted_address);
    };

    const { ref } = usePlacesWidget({
        onPlaceSelected,
        options: {
            types: ['address'],
            componentRestrictions: { country: 'za' },
        },
    });

    useEffect(() => {
        setFieldValue('searchValue', initialValue);
    }, [setFieldValue, initialValue]);

    return (
        <TextInputField
            {...props}
            {...field}
            ref={ref}
            value={values.searchValue}
            onChange={onSearchValueChange}
        />
    );
};

AddressAutoCompleteField.propTypes = {
    value: PropTypes.string,
};

AddressAutoCompleteField.defaultProps = {
    value: undefined,
};

export default AddressAutoCompleteField;
