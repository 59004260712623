import { useEffect, useState } from 'react';

/* eslint-disable import/prefer-default-export */
export const useConfirmBrowserExit = (
    defaultEnabled = true,
    message = 'Confirm leave page'
) => {
    const [msg, setMsg] = useState(message);
    const [enabled, setEnabled] = useState(defaultEnabled);

    useEffect(() => {
        function listener(e) {
            if (enabled) {
                e.preventDefault();
                e.returnValue = msg;
                return msg;
            }
            return '';
        }

        window.addEventListener('beforeunload', listener);

        return () => {
            window.removeEventListener('beforeunload', listener);
        };
    }, [msg, enabled]);

    return {
        enable() {
            setEnabled(true);
        },
        disable() {
            setEnabled(false);
        },
        setMessage(newMessage) {
            setMsg(newMessage);
        },
        getMessage() {
            return msg;
        },
        setEnabled(status) {
            setEnabled(status);
        },
        getEnabled() {
            return enabled;
        },
    };
};
