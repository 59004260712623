import React, { useState } from 'react';
import { Pane } from 'evergreen-ui';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import VerticalStepper from '../molecules/vertical-stepper';
import VerticalStepperStep from '../molecules/vertical-stepper-step';
import ContactBusinessDetailsForm from '../forms/contact-forms/contact-business-details-form';
import { contactBusinessDetailsFormModel } from '../../models';
import { editBusinessContact } from '../../reducers/contacts-reducer/contacts.actions';
import { errorToast, successToast } from '../toasts';

const CreateBusinessContactForm = ({
    closeSidebar,
    businessContact,
    isEditing,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useDispatch();

    const submitBusinessDetailsForm = async (values) => {
        try {
            setIsSubmitting(true);
            if (isEditing) {
                dispatch(
                    editBusinessContact(contactBusinessDetailsFormModel(values))
                );
            } else {
                dispatch(
                    editBusinessContact(contactBusinessDetailsFormModel(values))
                );
            }
            successToast(
                `Successfully ${isEditing ? 'updated' : 'created'} "${
                    values.name
                }"`
            );
            closeSidebar();
        } catch (error) {
            errorToast(
                ` An Error Occurred: ${get(
                    error,
                    'response.data.message',
                    `Failed to ${isEditing ? 'update' : 'create'} ${
                        values.name
                    }`
                )}`
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <VerticalStepper currentStep={0}>
            <VerticalStepperStep title="Business Details">
                <Pane marginX={2}>
                    <ContactBusinessDetailsForm
                        handleFormSubmission={submitBusinessDetailsForm}
                        initialValues={contactBusinessDetailsFormModel()}
                        businessContact={businessContact}
                        isSubmitting={isSubmitting}
                    />
                </Pane>
            </VerticalStepperStep>
        </VerticalStepper>
    );
};

CreateBusinessContactForm.propTypes = {
    closeSidebar: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    businessContact: PropTypes.shape({}).isRequired,
};

export default CreateBusinessContactForm;
