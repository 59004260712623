import _ from 'lodash';

export const linkRequestModel = (linkRequestObject = {}) => ({
    asset_id: _.get(linkRequestObject, 'assetId', ''),
    filetype: {
        thumbnail: _.get(linkRequestObject, 'filetype.thumbnail', ''),
        file: _.get(linkRequestObject, 'filetype.file', ''),
    },
});

export const uploadUrlsModel = (uploadUrlsObject = {}) => ({
    thumbnailUrl: _.get(uploadUrlsObject, 'thumbnail.location', ''),
    fileUrl: _.get(uploadUrlsObject, 'file.location', ''),
    thumbnailId: _.get(uploadUrlsObject, 'thumbnail_id', ''),
    fileId: _.get(uploadUrlsObject, 'file_id', ''),
});

export const setDocumentUploadSuccessfullyModel = (
    setDocumentUploadSuccessfullyObject = {}
) => ({
    compliance_type_id: _.get(
        setDocumentUploadSuccessfullyObject,
        'complianceTypeId',
        ''
    ),

    friendly_name: _.get(
        setDocumentUploadSuccessfullyObject,
        'friendlyName',
        ''
    ),
    file_id: _.get(setDocumentUploadSuccessfullyObject, 'fileId', ''),
    thumbnail_id: _.get(
        setDocumentUploadSuccessfullyObject,
        'thumbnailId',
        null
    ),
    file_key: _.get(setDocumentUploadSuccessfullyObject, 'fileKey', ''),
    notes: _.get(setDocumentUploadSuccessfullyObject, 'notes', ''),
    contacts_business_staff_id: _.get(
        setDocumentUploadSuccessfullyObject,
        'contactsBusinessStaffId',
        ''
    ),
    contacts_business_id: _.get(
        setDocumentUploadSuccessfullyObject,
        'contactsBusinessId',
        ''
    ),
    user_id: _.get(setDocumentUploadSuccessfullyObject, 'userId', ''),
    batch_id: _.get(setDocumentUploadSuccessfullyObject, 'batchId', null),
    request_type: _.get(setDocumentUploadSuccessfullyObject, 'requestType', ''),
    maintenance_items_id: _.get(
        setDocumentUploadSuccessfullyObject,
        'maintenanceItemId',
        ''
    ),
    task_items_id: _.get(setDocumentUploadSuccessfullyObject, 'taskItemId', ''),
    file_extension: _.get(
        setDocumentUploadSuccessfullyObject,
        'fileExtension',
        ''
    ),
    file_mime_type: _.get(
        setDocumentUploadSuccessfullyObject,
        'fileMimeType',
        ''
    ),
    thumbnail_key: _.get(
        setDocumentUploadSuccessfullyObject,
        'thumbnailKey',
        ''
    ),
    move_status: 'moved',
});

export const complianceItemDocumentsModel = (complianceItemDocumentsObjects) =>
    _.map(complianceItemDocumentsObjects, (document) => ({
        id: _.get(document, 'id'),
        createdAt: _.get(document, 'created_at'),
        friendlyName: _.get(document, 'friendly_name'),
        fileId: _.get(document, 'file_id'),
        thumbnailId: _.get(document, 'thumbnail_id'),
        notes: _.get(document, 'notes'),
        batchId: _.get(document, 'batch_id'),
        fileExtension: _.get(document, 'file_extension'),
        fileKey: _.get(document, 'file_key'),
        thumbnailKey: _.get(document, 'thumbnail_key'),
        uploaderDetails: _.get(document, 'contacts_business_staff')
            ? {
                  firstName: _.get(document, 'contacts_business_staff.name'),
                  lastName: _.get(document, 'contacts_business_staff.surname'),
                  profession: _.get(
                      document,
                      'contacts_business_staff.profesion'
                  ),
                  professionalCode: _.get(
                      document,
                      'contacts_business_staff.profesional_code'
                  ),
                  email: _.get(document, 'contacts_business_staff.email'),
                  mobileNumber: _.get(
                      document,
                      'contacts_business_staff.mobileno'
                  ),
                  businessesName: _.get(
                      document,
                      'contacts_business_staff.contacts_businessname.comp_name'
                  ),
              }
            : {
                  firstName: _.get(document, 'user.name'),
                  lastName: _.get(document, 'user.surname'),
                  profession: '',
                  professionalCode: '',
                  email: _.get(document, 'user.email'),
                  mobileNumber: _.get(document, 'user.mobileno'),
                  businessesName: '',
              },
    }));

export const documentDownloadLinkModel = (documentDownloadLinkObject) =>
    _.get(documentDownloadLinkObject, 'awslink.location');

export const setUpdateDocumentModel = (document) => ({
    documents_id: _.get(document, 'documentId', ''),
    friendly_name: _.get(document, 'friendlyName', ''),
    notes: _.get(document, 'notes', ''),
    request_type: _.get(document, 'requestType', ''),
});

export const getUpdateDocumentModel = (document) => ({
    id: _.get(document, 'id', ''),
    friendlyName: _.get(document, 'friendly_name', ''),
    notes: _.get(document, 'notes', ''),
    createdAt: _.get(document, 'created_at', 0),
    thumbnailId: _.get(document, 'thumbnail_id', ''),
    uploaderDetails: {
        firstName:
            _.get(document, 'staffMember.name') || _.get(document, 'user.name'),
        lastName:
            _.get(document, 'staffMember.surname') ||
            _.get(document, 'user.surname'),
        profession: _.get(document, 'staffMember.profesion', ''),
        professionalCode: _.get(document, 'staffMember.profesional_code', ''),
        email:
            _.get(document, 'staffMember.email') ||
            _.get(document, 'user.email'),
        mobileNumber:
            _.get(document, 'staffMember.mobileno') ||
            _.get(document, 'user.mobileno'),
    },
    maintenanceItemId: _.get(document, 'maintenance_items_id', ''),
    taskItemId: _.get(document, 'task_items_id', ''),
    fileExtension: _.get(document, 'file_extension', ''),
});

export const deleteDocumentModel = (data) => ({
    document_id: _.get(data, 'documentId', ''),
    asset_id: _.get(data, 'assetId', ''),
    request_type: _.get(data, 'requestType', 'compliance'),
});
