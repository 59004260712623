import thunk from 'redux-thunk';
import ReduxPromise from 'redux-promise';
import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';

const middleware = [thunk, ReduxPromise];

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: [
                    'upload-manager/enqueueUploadAction',
                    'upload-manager/setIsUploadingAction',
                    'upload-manager/setUploadProgressAction',
                    'upload-manager/setIdsAction',
                    'upload-manager/setUploadDoneAction',
                    'upload-manager/setUploadFailedAction',
                    'assets/setCurrentAssetAction',
                    'assets/setIsLoadingCurrentAssetAction',
                ],
                // Ignore these field paths in all actions
                // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                // Ignore these paths in the state
                ignoredPaths: ['upload-manager.uploads'],
            },
        }).concat(middleware),
});
