import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

const useQueryParams = () => {
    const location = useLocation();

    const [params, setParams] = useState({});

    useEffect(() => {
        let queryParamsString = location.search;
        queryParamsString = queryParamsString.substr(
            1,
            queryParamsString.length
        );
        const keyValuePairs = _.split(queryParamsString, '&');

        const queryParams = {};
        _.forEach(keyValuePairs, (keyValuePair) => {
            const [key, value] = _.split(keyValuePair, '=');
            queryParams[key] = value;
        });

        setParams(queryParams);
    }, [location]);

    return params;
};

export default useQueryParams;
