import {
    Link as RouterLink,
    useLocation,
    useParams,
    useNavigate,
    useSearchParams as useRouterSearchParams,
} from 'react-router-dom';
import { ChevronRightIcon, Link, Pane } from 'evergreen-ui';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import {
    assetSelector,
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from '../../reducers/asset-reducer/asset.reducer';
import { useSearchParams } from '../../context/search-params-context';

const mainLinks = [
    'maintenance',
    'portfolio',
    'tasks',
    'assets',
    'buildings',
    'properties',
    'overview',
];

const Breadcrumbs = () => {
    const navigate = useNavigate();
    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const { pathname } = useLocation();

    const { assetId, buildingId, tenantId } = useParams();

    const { currentAssetId, currentBuildingId, currentTenantId } =
        useSearchParams();

    const [searchParams] = useRouterSearchParams();

    const queryParams = useMemo(
        () =>
            pathname.includes('compliance')
                ? Object.fromEntries(searchParams.entries())
                : '',
        [searchParams, pathname]
    );

    const links = useLocation().pathname.split('/');
    const dispatch = useDispatch();

    const params = new URLSearchParams(queryParams).toString();

    const handleClick = useCallback(
        async (to) => {
            if (mainLinks.includes(to)) {
                navigate(`/${to}`);
                await Promise.all([
                    dispatch(setCurrentAssetAction(null)),
                    dispatch(setCurrentBuildingAction(null)),
                    dispatch(setCurrentTenantAction(null)),
                ]);
            }
            if (
                (currentBuilding || currentTenant) &&
                to === currentAsset?.assetName
            ) {
                dispatch(setCurrentBuildingAction(null));
                dispatch(setCurrentTenantAction(null));
            }
            if (currentTenant && to === currentBuilding.assetName) {
                dispatch(setCurrentTenantAction(null));
            }
        },
        [
            currentAsset?.assetName,
            currentBuilding,
            currentTenant,
            dispatch,
            navigate,
        ]
    );

    const memoLinks = useMemo(() => {
        if (links.join('/') === '/portfolio') {
            links[1] = 'overview';
        }
        if (!links.includes('info') || !links.includes('compliance')) {
            if (
                currentAssetId &&
                (currentAssetId !== 'undefined' || currentAssetId > 0)
            ) {
                links.push(currentAssetId);
            }
            if (
                currentBuildingId &&
                (currentBuildingId !== 'undefined' || currentBuildingId > 0)
            ) {
                links.push(currentBuildingId);
            }
            if (
                currentTenantId &&
                (currentTenantId !== 'undefined' || currentTenantId > 0)
            ) {
                links.push(currentTenantId);
            }
        }
        return links.map((link) => {
            if (Number(link) === Number(currentAsset?.id)) {
                return currentAsset?.assetName;
            }
            if (Number(link) === Number(currentBuilding?.id)) {
                return currentBuilding?.assetName;
            }
            if (Number(link) === Number(currentTenant?.id)) {
                return currentTenant?.assetName;
            }
            if (link.includes('-')) {
                return link.split('-').join(' ');
            }
            return link;
        });
    }, [
        links,
        currentTenantId,
        currentBuildingId,
        currentAssetId,
        currentAsset,
        currentBuilding,
        currentTenant,
    ]);

    const link = useCallback(
        (to) => {
            if (to === 'properties' || to === 'buildings' || to === 'parks') {
                return '/portfolio/properties';
            }
            if (to === 'tenants') {
                return '';
            }
            if (to === 'overview') {
                return '/portfolio';
            }
            if (links.includes('info') || links.includes('compliance')) {
                if (!currentAsset) {
                    if (to === currentTenant?.assetName) {
                        if (links.includes('info')) {
                            return `/portfolio/properties/buildings/${currentBuilding?.id}/tenants/${currentTenant?.id}/info`;
                        }
                        return `/portfolio/properties/buildings/${currentBuilding?.id}/tenants/${currentTenant?.id}/compliance?${params}`;
                    }
                    if (to === currentBuilding?.assetName) {
                        if (links.includes('info')) {
                            return `/portfolio/properties/buildings/${currentBuilding?.id}/info`;
                        }
                        return `/portfolio/properties/buildings/${currentBuilding?.id}/compliance?${params}`;
                    }
                }
                if (currentTenant && to === currentAsset?.assetName) {
                    if (links.includes('info')) {
                        return `/portfolio/properties/parks/${currentAsset?.id}/info`;
                    }
                    return `/portfolio/properties/parks/${currentAsset?.id}/compliance?${params}`;
                }
                if (to === currentAsset?.assetName) {
                    if (links.includes('info')) {
                        return `/portfolio/properties/parks/${currentAsset?.id}/info`;
                    }
                    return `/portfolio/properties/parks/${currentAsset?.id}/compliance?${params}`;
                }
                if (to === currentBuilding?.assetName) {
                    if (links.includes('info')) {
                        return `/portfolio/properties/parks/${currentAsset?.id}/buildings/${currentBuilding?.id}/info`;
                    }
                    return `/portfolio/properties/parks/${currentAsset?.id}/buildings/${currentBuilding?.id}/compliance?${params}`;
                }
                if (to === currentTenant?.assetName) {
                    if (links.includes('info')) {
                        return `/portfolio/properties/parks/${currentAsset?.id}/buildings/${currentBuilding?.id}/info`;
                    }
                    return `/portfolio/properties/parks/${currentAsset?.id}/buildings/${currentBuilding?.id}/compliance?${params}`;
                }
            }
            if (
                to === currentTenant?.assetName ||
                to === currentBuilding?.assetName ||
                to === currentAsset?.assetName
            ) {
                return '';
            }

            return `/${to}`;
        },
        [currentAsset, currentBuilding, currentTenant, links, params]
    );

    useEffect(() => {
        if (links.includes('info') || links.includes('compliance')) {
            if (!assetId || Number(assetId) === 0) {
                dispatch(setCurrentAssetAction(null));
            }
            if (!buildingId || Number(buildingId) === 0) {
                dispatch(setCurrentBuildingAction(null));
            }
            if (!tenantId || Number(tenantId) === 0) {
                dispatch(setCurrentTenantAction(null));
            }
        }
    }, [assetId, buildingId, dispatch, links, tenantId]);

    return (
        <Pane display="flex" alignItems="center">
            {memoLinks.map((to, index) => {
                if (index === links.length - 1) {
                    return <CurrentPath key={to}>{to}</CurrentPath>;
                }
                if (to) {
                    return (
                        <>
                            <BreadcrumbLink
                                key={to}
                                is={RouterLink}
                                to={link(to)}
                                onClick={() => handleClick(to)}
                            >
                                {to}
                            </BreadcrumbLink>
                            <ChevronRightIcon marginX={6} size={10} />
                        </>
                    );
                }
                return null;
            })}
        </Pane>
    );
};

export default Breadcrumbs;

const BreadcrumbLink = styled(Link)`
    font-size: 12px;
    color: #c1c4d6;
    text-transform: capitalize;
`;

const CurrentPath = styled.span`
    cursor: default;
    font-size: 12px;
    text-transform: capitalize;
`;
