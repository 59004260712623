import React from 'react';
import { Pane, Spinner, Text } from 'evergreen-ui';

function PageLoading() {
    return (
        <Pane
            height="100%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
        >
            <Spinner />
            <Text marginTop="1rem">Loading...</Text>
        </Pane>
    );
}

export default PageLoading;
