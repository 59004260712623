import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { XANO_ACCESS_TOKEN_KEY } from '../../local-storage-keys';
import { setIsAuthenticatedAction } from '../../reducers/auth-reducer/auth.reducer';

const AuthStateLoader = ({ children }) => {
    const dispatch = useDispatch();
    const [hasCheckedIsAuthenticated, setHasCheckedIsAuthenticated] =
        useState(false);

    useEffect(() => {
        if (hasCheckedIsAuthenticated) {
            return;
        }

        const hasAccessToken = !!localStorage.getItem(XANO_ACCESS_TOKEN_KEY);
        dispatch(setIsAuthenticatedAction(hasAccessToken));
        setHasCheckedIsAuthenticated(true);
    }, [dispatch, hasCheckedIsAuthenticated]);

    if (!hasCheckedIsAuthenticated) {
        return null;
    }

    return children;
};

AuthStateLoader.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthStateLoader;
