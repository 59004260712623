import React, { useEffect, useState, useMemo } from 'react';
import {
    SelectField,
    Pane,
    RadioGroup,
    Text,
    TextInputField,
    Button,
    Group,
    ErrorIcon,
    Textarea,
    SelectMenu,
    Badge,
    CaretDownIcon,
} from 'evergreen-ui';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useFilePicker } from 'use-file-picker';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import { colors } from '../../theme/theme';
import DatepickerCustomHeader from '../organisms/date-picker/date-picker-custom-header';
import FileDragAndDrop from '../atoms/file-drag-and-drop';
import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import { getTaskCategoryModel, setTaskModel } from '../../models/task.models';
import { createTask } from '../../reducers/task-reducer/task.actions';
import SchedulerModal from '../maintenance/scheduler-modal';
import TaskCategoriesModal from './task-categories-modal';
import { successToast } from '../toasts';
import { useUploadManager } from '../../context/upload-manager-context';

const TASK_TYPES = [
    {
        label: 'Once-off',
        value: 'once-off',
    },
    {
        label: 'Scheduled',
        value: 'scheduled',
    },
];

const PRIORITIES = [
    { label: 'High', value: 'high' },
    { label: 'Medium', value: 'medium' },
    { label: 'Low', value: 'low' },
];

const STATUSES = [
    { label: 'New', value: 'new' },
    { label: 'In Progress', value: 'in progress' },
    { label: 'Pending', value: 'pending' },
    { label: 'Overdue', value: 'overdue' },
    { label: 'Completed', value: 'completed' },
];

const STATUS_COLORS = {
    completed: { color: '#50C878', backgroundColor: '#50C87840' },
    overdue: { color: '#B3261E', backgroundColor: '#B3261E50' },
    pending: { color: '#FFA500', backgroundColor: '#FFA50050' },
    notified: { color: '#696F8C', backgroundColor: '#696F8C50' },
    'in progress': { color: '#6495ED', backgroundColor: '#6495ED50' },
    new: { color: '#7F00FF', backgroundColor: '#7F00FF30' },
    default: {
        badgeColor: '#D3F5F7',
        textColor: '#0F5156',
    },
};

function AddTaskForm({ close, categories, assignees, properties, user }) {
    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);
    const { enqueueUploads } = useUploadManager();

    const dispatch = useDispatch();

    const [openFileSelector, { plainFiles }] = useFilePicker({
        accept: '*',
        multiple: true,
        readFilesContent: true,
    });

    const [loading, setLoading] = useState(false);
    const [types, setTypes] = useState([]);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [showMaintenanceRecurrenceModal, setShowMaintenanceRecurrenceModal] =
        useState(false);

    const onFilesDropped = (droppedFiles) => {
        formik.setValues({
            ...formik.values,
            attachments: droppedFiles,
        });
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            await dispatch(
                createTask(
                    setTaskModel({
                        ...values,
                        rrule: values.rrule?.rrule,
                        progress: values.progress / 100,
                    }),
                    enqueueUploads
                )
            );
            successToast(
                `Successfully created a new task "${values?.description}"`
            );
            close();
        } catch (error) {
            formik.setErrors({
                ...formik.errors,
                attachments: _.get(
                    error,
                    'response.data.message',
                    error.message
                ),
            });
        } finally {
            setLoading(false);
        }
    };

    const validate = ({
        description,
        type,
        property,
        category,
        subCategory,
        priority,
        dueDate,
        assignee,
        status,
        progress,
    }) => {
        const errors = {};

        if (!description) {
            errors.description = 'Task Name is Required';
        }
        if (!type) {
            errors.type = 'Task Type is Required';
        }
        if (type === 'scheduled') {
            if (!formik.values.rrule.rrule) {
                errors.rrule = 'Task Recurrence is Required';
            }
        }
        if (!property) {
            errors.property = 'Assign Task to a building';
        }
        if (!category) {
            errors.category = 'Task Category is Required';
        }
        if (!subCategory) {
            errors.subCategory = 'Task Sub-Category is Required';
        }
        if (!priority) {
            errors.priority = 'Task Priority is Required';
        }
        if (!dueDate) {
            errors.dueDate = 'Task Due Date is Required';
        }
        if (!assignee) {
            errors.assignee = 'Task Assignee is Required';
        }
        if (progress > 100 || progress < 0) {
            errors.progress = 'Progress must be between 0 and 100';
        }
        if (status !== 'completed' && progress === 100) {
            errors.progress =
                'Progress cannot be 100% if status is not Completed';
        }
        if (!status) {
            errors.status = 'Task Status is Required';
        }
        return errors;
    };

    const initialProperty = useMemo(
        () => currentTenant || currentBuilding || currentAsset || properties[0],
        [currentAsset, currentBuilding, currentTenant, properties]
    );

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            description: '',
            type: 'once-off',
            property: {
                id: initialProperty?.id,
                name: initialProperty?.assetName || initialProperty?.name,
            },
            category: { id: categories[0]?.id, name: categories[0]?.name },
            subCategory: {
                id: categories[0]?.task_types[0]?.id,
                name: categories[0]?.task_types[0]?.name,
            },
            assignee: { id: assignees[0]?.id, name: assignees[0]?.name },
            status: 'new',
            priority: 'high',
            dueDate: Date.now(),
            notes: '',
            attachments: null,
            rrule: { rrule: null, humanReadable: '' },
            nextOccurrence: null,
            progress: 0,
        },
        validate,
        onSubmit: handleSubmit,
    });

    const handleChange = ({ target }) => {
        if (target.name === 'category') {
            if (target.value === 'add') {
                formik.setValues({
                    ...formik.values,
                    category: {
                        id: '',
                        name: '',
                    },
                    subCategory: {
                        id: '',
                        name: '',
                    },
                });
                setShowCategoryModal(true);
            } else {
                const newCategory = getTaskCategoryModel(
                    categories.find(
                        (category) => category.id === Number(target.value)
                    )
                );
                formik.setValues({
                    ...formik.values,
                    category: {
                        id: newCategory?.id,
                        name: newCategory?.name,
                    },
                    subCategory: {
                        id: newCategory?.types[0]?.id,
                        name: newCategory?.types[0]?.name,
                    },
                });
                setTypes(newCategory?.types || []);
            }
        } else if (target.name === 'subCategory') {
            if (target.value === 'add') {
                formik.setValues({
                    ...formik.values,
                    subCategory: {
                        id: '',
                        name: '',
                    },
                    category: {
                        id: '',
                        name: '',
                    },
                });
                setShowCategoryModal(true);
            } else {
                const newType = types.find(
                    (type) => type.id === Number(target.value)
                );
                formik.setValues({
                    ...formik.values,
                    subCategory: {
                        id: newType?.id,
                        name: newType?.name,
                    },
                });
            }
        } else if (target.name === 'assignee') {
            const newAssignee = assignees.find(
                (assignee) => assignee.id === Number(target.value)
            );
            formik.setValues({
                ...formik.values,
                assignee: {
                    id: newAssignee?.id,
                    name: newAssignee?.name,
                },
            });
        } else if (target.name === 'property') {
            const newProperty = properties.find(
                (property) => property.id === Number(target.value)
            );
            formik.setValues({
                ...formik.values,
                property: {
                    id: newProperty?.id,
                    name: newProperty?.name,
                },
            });
        } else if (target.name === 'progress') {
            let status = 'new';
            if (Number(target.value) === 100) {
                status = 'completed';
            } else if (
                Number(target.value) >= 1 &&
                Number(target.value) <= 99
            ) {
                if (
                    formik.values.status === 'new' ||
                    formik.status === 'completed'
                ) {
                    status = 'in progress';
                } else {
                    status = formik.values.status;
                }
            }

            formik.setValues({
                ...formik.values,
                status,
                progress: Number(target.value),
            });
        } else {
            formik.setValues({
                ...formik.values,
                [target.name]: target.value,
            });
        }
    };

    const handleDueDate = (date) => {
        const parsedDate = Date.parse(date);
        formik.setValues({
            ...formik.values,
            dueDate: parsedDate,
        });
    };

    const setRecurrence = (rrule, nextOccurrence) => {
        formik.setValues({
            ...formik.values,
            rrule: {
                rrule: rrule.toString(),
                humanReadable: rrule.toText(),
            },
            nextOccurrence,
        });
        setShowMaintenanceRecurrenceModal(false);
    };

    const handleStatusChange = ({ value }) => {
        if (value === 'completed') {
            formik.setValues({
                ...formik.values,
                progress: 100,
                status: value,
            });
        } else if (value === 'new') {
            formik.setValues({
                ...formik.values,
                progress: 0,
                status: value,
            });
        } else {
            formik.setValues({
                ...formik.values,
                progress:
                    formik.values.progress > 0 ? formik.values.progress : 1,
                status: value,
            });
        }
    };

    useEffect(() => {
        if (_.size(plainFiles) > 0) {
            formik.setValues({
                ...formik.values,
                attachments: plainFiles,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plainFiles]);

    useEffect(() => {
        setTypes(categories[0]?.task_types || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Pane
                    display="flex"
                    flexDirection="column"
                    justifyContent="start"
                    height="100%"
                >
                    <TextInputField
                        label="Name / Description"
                        name="description"
                        placeholder="Task Name"
                        value={formik.values.description}
                        onChange={handleChange}
                        validationMessage={formik.errors.description}
                        inputHeight={40}
                        required
                        autoFocus
                    />
                    <Pane>
                        <Text lineHeight="18px" color="#101840">
                            Type *
                        </Text>
                        <RadioGroup
                            name="type"
                            options={TASK_TYPES}
                            size={16}
                            validationMessage={formik.errors.type}
                            onChange={({ target }) =>
                                formik.setValues({
                                    ...formik.values,
                                    type: target.value,
                                    rrule: { rrule: '', humanReadable: '' },
                                    nextOccurrence: null,
                                })
                            }
                            value={formik.values.type}
                            display="flex"
                            justifyContent="start"
                            gap={32}
                        />
                        <div className="divider mb-0" />
                    </Pane>
                    <Pane
                        paddingTop={20}
                        display={
                            formik.values.type === 'scheduled'
                                ? 'block'
                                : 'none'
                        }
                    >
                        <DatePicker
                            placeholderText="Next Occurrence"
                            calendarClassName="calendar"
                            popperClassName="calendar"
                            dateFormat="dd/MM/yyyy"
                            selected={formik.values?.nextOccurrence}
                            onChange={handleDueDate}
                            disabled
                            customInput={
                                <TextInputField
                                    label="Next Occurrence"
                                    hint="Will Automatically be set by Recurrence Schedule"
                                    type="text"
                                    value={formik.values.dueDate}
                                    validationMessage={formik.errors.dueDate}
                                    width="100%"
                                    inputHeight={40}
                                />
                            }
                            renderCustomHeader={(props) => (
                                <DatepickerCustomHeader {...props} />
                            )}
                        />
                        <div className="divider mb-0" />
                        <TextInputField
                            name="rrule"
                            label="Recurrence"
                            onChange={formik.handleChange}
                            placeholder="Set Recurrence"
                            value={formik.values.rrule.humanReadable}
                            validationMessage={formik.errors.rrule}
                            width="100%"
                            inputHeight={40}
                            onClick={() =>
                                setShowMaintenanceRecurrenceModal(true)
                            }
                        />
                    </Pane>
                    <Pane>
                        <SelectField
                            required
                            name="property"
                            marginTop={20}
                            inputHeight={40}
                            label="Select Property"
                            validationMessage={formik.errors.property}
                            onChange={handleChange}
                            value={formik.values.property?.id}
                        >
                            <option disabled>Select Property</option>
                            {properties.length ? (
                                properties.map((item) => {
                                    const { id, name } = item;
                                    return (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    );
                                })
                            ) : (
                                <option>No Available Properties</option>
                            )}
                        </SelectField>
                        <div className="divider" />
                        <SelectField
                            required
                            name="category"
                            width="100%"
                            inputHeight={40}
                            label="Category"
                            validationMessage={formik.errors.category}
                            onChange={handleChange}
                            value={formik.values.category?.id}
                        >
                            <option value="" disabled>
                                Select Task Category
                            </option>

                            {categories && categories.length ? (
                                categories.map(({ id, name }) => (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled>
                                    No Available Categories
                                </option>
                            )}
                            <option value="add">+ Add New Category</option>
                        </SelectField>
                        <div className="divider" />
                        <SelectField
                            required
                            name="subCategory"
                            width="100%"
                            inputHeight={40}
                            label="Sub-Category"
                            validationMessage={formik.errors.subCategory}
                            onChange={handleChange}
                            value={formik.values.subCategory?.id}
                        >
                            <option value="" disabled>
                                Select Task Sub-Category
                            </option>
                            {types.length ? (
                                types.map(({ id, name }) => (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                ))
                            ) : (
                                <option value="" disabled selected>
                                    No Available Categories
                                </option>
                            )}
                            <option value="add">+ Add New Sub-Category</option>
                        </SelectField>
                        <div className="divider" />
                    </Pane>
                    <Pane>
                        <Text lineHeight="18px" color="#101840">
                            Priority *
                        </Text>
                        <RadioGroup
                            name="priority"
                            options={PRIORITIES}
                            size={16}
                            validationMessage={formik.errors.priority}
                            onChange={({ target }) =>
                                formik.setValues({
                                    ...formik.values,
                                    priority: target.value,
                                })
                            }
                            value={formik.values.priority}
                            display="flex"
                            justifyContent="start"
                            gap={32}
                        />
                        <div className="divider" />
                    </Pane>
                    <Pane
                        display="flex"
                        flexDirection="column"
                        alignItems="start"
                        gap="8px"
                    >
                        <Text width="100%" fontWeight="500" color="#101840">
                            Status *
                        </Text>
                        <SelectMenu
                            options={STATUSES}
                            hasFilter={false}
                            hasTitle={false}
                            selected={formik.values.status}
                            onSelect={handleStatusChange}
                            closeOnSelect
                        >
                            <MenuInputBox>
                                <Badge
                                    color={
                                        STATUS_COLORS[formik.values?.status]
                                            ?.backgroundColor ||
                                        STATUS_COLORS.default.backgroundColor
                                    }
                                    padding={12}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    cursor="pointer"
                                >
                                    <Text
                                        lineHeight={22}
                                        fontWeight={700}
                                        color={
                                            STATUS_COLORS[formik.values?.status]
                                                ?.color ||
                                            STATUS_COLORS.default.color
                                        }
                                    >
                                        {formik.values?.status}
                                    </Text>
                                </Badge>
                                <MenuInputCaret size={16} color="#696f8c" />
                            </MenuInputBox>
                        </SelectMenu>
                        <div className="divider" />
                        <TextInputField
                            name="progress"
                            placeholder="Progress"
                            type="number"
                            inputMode="num"
                            pattern="^[1-9][0-9]?$|^100$"
                            label="Progress %"
                            value={formik.values.progress}
                            width="100%"
                            inputHeight={40}
                            validationMessage={formik.errors.progress}
                            onChange={handleChange}
                        />
                        <div className="divider" />
                    </Pane>
                    <Pane>
                        <DatePicker
                            name="dueDate"
                            placeholderText="Pick a date"
                            calendarClassName="calendar"
                            popperClassName="calendar"
                            dateFormat="dd/MM/yyyy"
                            selected={formik.values.dueDate}
                            onChange={handleDueDate}
                            customInput={
                                <TextInputField
                                    label="Due Date *"
                                    type="text"
                                    value={formik.values.dueDate}
                                    validationMessage={formik.errors.dueDate}
                                    width="100%"
                                    inputHeight={40}
                                />
                            }
                            renderCustomHeader={(props) => (
                                <DatepickerCustomHeader {...props} />
                            )}
                        />
                        <div className="divider" />
                        <SelectField
                            name="assignee"
                            inputHeight={40}
                            label="Assign To"
                            validationMessage={formik.errors.assignee}
                            value={formik.values.assignee?.id}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>
                                Assign someone to this Task
                            </option>
                            {assignees.map(({ id, name }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </SelectField>

                        {formik.values.assignee?.id !== user?.id && (
                            <AssignToMeButton
                                marginLeft={8}
                                marginRight={30}
                                marginTop={14}
                                marginBottom={28}
                                name="assignee"
                                onClick={() =>
                                    handleChange({
                                        target: {
                                            name: 'assignee',
                                            value: user.id,
                                        },
                                    })
                                }
                            >
                                <Text>Assign To Me</Text>
                            </AssignToMeButton>
                        )}

                        <div className="divider" />
                    </Pane>
                    <Pane display="flex" flexDirection="column" gap={8}>
                        <Text>Additional Note</Text>
                        <Textarea
                            label="Additional Note"
                            name="notes"
                            minWidth="100%"
                            maxWidth="100%"
                            placeholder="Additional Note"
                            value={formik.values.notes}
                            onChange={handleChange}
                            validationMessage={formik.errors.notes}
                            spellCheck={false}
                            inputHeight={40}
                        />
                        <div className="divider" />
                    </Pane>

                    <Pane>
                        <FileDragAndDrop onFilesDropped={onFilesDropped}>
                            {({ dragging }) => (
                                <Pane
                                    display="flex"
                                    flexDirection="column"
                                    gap="8px"
                                >
                                    <Text>Upload an Attachment</Text>
                                    <Group
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        name="attachment"
                                    >
                                        <Pane
                                            height="40px"
                                            borderRadius={5}
                                            borderWidth={1}
                                            borderStyle={
                                                dragging ||
                                                _.size(plainFiles) > 0
                                                    ? 'solid'
                                                    : 'dashed'
                                            }
                                            borderColor={
                                                dragging ||
                                                _.size(plainFiles) > 0
                                                    ? colors.secondary500
                                                    : colors.gray500
                                            }
                                            label="Date Last Serviced"
                                            type="text"
                                            name="assetName"
                                            placeholder="Drag Image or browse"
                                            width="100%"
                                        >
                                            {formik.values.attachments ? (
                                                <Text
                                                    marginLeft="16px"
                                                    lineHeight="38px"
                                                >
                                                    {formik.values.attachments
                                                        .length > 1
                                                        ? `${formik.values.attachments.length} Files`
                                                        : formik.values.attachments[0]?.name?.split(
                                                              '.'
                                                          )[0]}
                                                </Text>
                                            ) : (
                                                <Text
                                                    marginLeft="16px"
                                                    lineHeight="38px"
                                                >
                                                    Drag files or browse
                                                </Text>
                                            )}
                                        </Pane>
                                        <Pane>
                                            <Button
                                                height="40px"
                                                type="button"
                                                onClick={openFileSelector}
                                                backgroundColor="#F4F5F9"
                                            >
                                                <Text fontSize={12}>
                                                    Browse
                                                </Text>
                                            </Button>
                                        </Pane>
                                    </Group>
                                    {!!formik.errors.attachments && (
                                        <Text
                                            lineHeight="18px"
                                            fontSize="12px"
                                            color="#D14343"
                                        >
                                            <ErrorIcon
                                                size={14}
                                                marginRight="6px"
                                            />{' '}
                                            {formik.errors.attachments}
                                        </Text>
                                    )}
                                </Pane>
                            )}
                        </FileDragAndDrop>
                    </Pane>
                    <div className="divider" />
                    <Pane display="flex" gap={15} paddingBottom={24}>
                        <Button width="100%" type="button" onClick={close}>
                            Cancel
                        </Button>
                        <Button
                            isLoading={loading}
                            type="submit"
                            width="100%"
                            appearance="primary"
                        >
                            Submit
                        </Button>
                    </Pane>
                </Pane>
            </form>
            <SchedulerModal
                isShown={showMaintenanceRecurrenceModal}
                close={() => setShowMaintenanceRecurrenceModal(false)}
                setRecurrence={setRecurrence}
            />
            <TaskCategoriesModal
                isShown={showCategoryModal}
                close={() => setShowCategoryModal(false)}
            />
        </>
    );
}

const MenuInputBox = styled(Pane)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(193, 196, 214);
    padding: 0px 12px 0px 13px;
    border-radius: 4px;
`;

const MenuInputCaret = styled(CaretDownIcon)``;

const AssignToMeButton = styled(Pane)`
    span {
        color: #5ec090;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

AddTaskForm.propTypes = {
    close: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number,
            task_types: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    id: PropTypes.number,
                })
            ),
        })
    ).isRequired,
    assignees: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number,
        })
    ).isRequired,
    properties: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })
    ).isRequired,
    user: PropTypes.shape({ id: PropTypes.string }).isRequired,
};

export default AddTaskForm;
