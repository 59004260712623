import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Pane, Heading, Button, PlusIcon } from 'evergreen-ui';
import { useDispatch, useSelector } from 'react-redux';

import UserManagementSidebar from '../../components/user-management/user-management-sidebar';
import PageLoading from '../../components/molecules/page-loading';
import UserManagementTable from '../../components/user-management/user-management-table';
import { userManagementSelector } from '../../reducers/user-management-reducer/user-management.reducer';
import {
    getProperties,
    getPropertyGroups,
    getRoles,
    getUsers,
} from '../../reducers/user-management-reducer/user-management.actions';
import { getNotifications } from '../../reducers/notifications-reducer/notifications.actions';
import { useUserAccess } from '../../context/user-access-context';
import UserRolesDialog from '../../components/user-management/user-roles-dialog';
import PropertyGroupsDialog from '../../components/user-management/property-groups-dialog';

function UserManagementPage() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showRolesDialog, setShowRolesDialog] = useState(false);
    const [showPropertyGroupsDialog, setShowPropertyGroupsDialog] =
        useState(false);
    const [userManagement, setUserManagement] = useState({
        isAdding: false,
        isEditing: false,
        user: null,
    });

    const { users, roles, properties, propertyGroups } = useSelector(
        userManagementSelector
    );
    const { currentRights } = useUserAccess();

    const { create, update } = useMemo(
        () =>
            currentRights || {
                create: false,
                update: false,
            },
        [currentRights]
    );

    const openAddUserSidebar = () => {
        setUserManagement({
            isAdding: true,
            isEditing: false,
            user: null,
        });
    };

    const openEditUserSidebar = (user) => {
        setUserManagement({
            isAdding: false,
            isEditing: true,
            user,
        });
    };

    const closeUserSidebar = () => {
        setUserManagement({
            isShown: false,
            isAdding: false,
            isEditing: false,
            user: null,
        });
    };

    const openRolesDialog = () => {
        setShowRolesDialog(true);
    };

    const closeRolesDialog = () => {
        setShowRolesDialog(false);
    };

    const openPropertyGroupsDialog = () => {
        setShowPropertyGroupsDialog(true);
    };

    const closePropertyGroupsDialog = () => {
        setShowPropertyGroupsDialog(false);
    };

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            await Promise.all([
                dispatch(getUsers()),
                dispatch(getProperties()),
                dispatch(getRoles()),
                dispatch(getPropertyGroups()),
                dispatch(getNotifications()),
            ]);
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    useEffect(() => {
        getData();
    }, [getData]);

    return loading ? (
        <PageLoading />
    ) : (
        <>
            <Pane
                display="flex"
                width="95%"
                maxWidth={1440}
                marginX="auto"
                paddingTop={24}
                height="100%"
            >
                <Pane
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width="100%"
                    gap={24}
                >
                    <Pane
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                        gap={24}
                    >
                        <Heading fontSize={24} fontWeight={700} color="#333333">
                            User Management
                        </Heading>

                        <Pane display="flex" gap={6}>
                            <Button
                                appearance="minimal"
                                onClick={openRolesDialog}
                            >
                                Manage Roles
                            </Button>
                            <Button
                                appearance="minimal"
                                onClick={openPropertyGroupsDialog}
                            >
                                Manage Property Groups
                            </Button>
                            {create && (
                                <Button
                                    appearance="primary"
                                    iconBefore={<PlusIcon />}
                                    onClick={openAddUserSidebar}
                                >
                                    Add new User
                                </Button>
                            )}
                        </Pane>
                    </Pane>
                    <Pane
                        display="flex"
                        width="100%"
                        height="calc100%"
                        marginBottom={24}
                    >
                        <UserManagementTable
                            users={users}
                            openEditUserSidebar={openEditUserSidebar}
                        />
                    </Pane>
                </Pane>
            </Pane>
            {(create || update) && (
                <UserManagementSidebar
                    isShown={
                        userManagement.isAdding || userManagement.isEditing
                    }
                    isEditing={userManagement.isEditing}
                    close={closeUserSidebar}
                    user={userManagement.user}
                    roles={roles}
                    properties={properties}
                    propertyGroups={propertyGroups}
                    setShowPropertyGroupsDialog={setShowPropertyGroupsDialog}
                    setShowRolesDialog={setShowRolesDialog}
                />
            )}
            <UserRolesDialog
                isShown={showRolesDialog}
                close={closeRolesDialog}
            />
            <PropertyGroupsDialog
                isShown={showPropertyGroupsDialog}
                close={closePropertyGroupsDialog}
            />
        </>
    );
}

export default UserManagementPage;
