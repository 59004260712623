import {
    setEmailAction,
    setIsAuthenticatedAction,
    setSessionExpiredAction,
} from './auth.reducer';

export default function cleanAuthState(setSessionDidExpire) {
    return (dispatch) => {
        dispatch(setEmailAction(''));
        dispatch(setIsAuthenticatedAction(false));
        dispatch(setSessionExpiredAction(setSessionDidExpire));
    };
}
