import styled from 'styled-components';
import { Pane } from 'evergreen-ui';

import { colors } from '../../theme/theme';

const StyledContextMenuItem = styled(Pane)`
    padding: 6px 20px;
    cursor: pointer;

    &:hover {
        background-color: ${colors.gray200};
    }
`;

export default StyledContextMenuItem;
