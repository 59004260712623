import React from 'react';
import { Pane, SelectField, Text } from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';

function DailyRecurrenceForm({ handleChange, recurrenceForm }) {
    return (
        <Pane display="flex" flexDirection="column">
            <Pane display="flex" alignItems="center" gap={20}>
                <Text textAlign="end" width="15%" marginTop={-16}>
                    Every
                </Text>
                <SelectField
                    name="interval"
                    width="35%"
                    inputHeight={40}
                    onChange={handleChange}
                    value={recurrenceForm.interval}
                >
                    <option value="" disabled>
                        Select Recurrence Period
                    </option>
                    {_.range(1, 100).map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </SelectField>
                <Text width="15%" marginTop={-16}>
                    Day(s)
                </Text>
            </Pane>
        </Pane>
    );
}

DailyRecurrenceForm.propTypes = {
    handleChange: PropTypes.func.isRequired,
    recurrenceForm: PropTypes.shape({
        recurrence: PropTypes.string.isRequired,
        interval: PropTypes.number.isRequired,
    }).isRequired,
};

export default DailyRecurrenceForm;
