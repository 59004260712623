import React, { useMemo } from 'react';
import { Pane, Heading, SideSheet } from 'evergreen-ui';
import PropTypes from 'prop-types';

import CreateContactForm from '../forms/contact-forms/create-contact-form';
import { colors } from '../../theme/theme';
import CreateStaffContactForm from './staff-form';
import CreateBusinessContactForm from './business-contact-form';

function ContactsSidebar({
    isShown,
    isEditing,
    addingStaff,
    businessContact,
    staffContact,
    close,
}) {
    const heading = useMemo(() => {
        if (addingStaff) {
            if (isEditing) {
                return 'Edit Contact';
            }
            return 'Create Contact';
        }
        if (isEditing) {
            return 'Edit Contact';
        }
        return 'Create Contact';
    }, [addingStaff, isEditing]);

    const renderForm = useMemo(() => {
        if (addingStaff) {
            return (
                <CreateStaffContactForm
                    closeSidebar={close}
                    staffContact={staffContact}
                    isEditing={isEditing}
                    businessContactId={businessContact?.id}
                />
            );
        }
        if (!addingStaff && isEditing) {
            return (
                <CreateBusinessContactForm
                    closeSidebar={close}
                    isEditing={isEditing}
                    businessContact={businessContact}
                />
            );
        }
        return <CreateContactForm closeSidebar={close} />;
    }, [addingStaff, isEditing, close, businessContact, staffContact]);

    return (
        <SideSheet
            isShown={isShown}
            onCloseComplete={close}
            containerProps={{
                background: colors.tint1,
            }}
        >
            <Pane>
                <Heading
                    width="max-content"
                    fontSize={22}
                    fontWeight="700"
                    color={colors.primary500}
                    marginLeft={24}
                    marginTop={24}
                >
                    {heading}
                </Heading>
                <div className="divider mb-0" />
                <Pane
                    paddingTop={20}
                    paddingX={24}
                    overflowY="auto"
                    background={colors.tint1}
                    height="100%"
                    display="flex"
                    flexDirection="column"
                >
                    {renderForm}
                </Pane>
            </Pane>
        </SideSheet>
    );
}

ContactsSidebar.propTypes = {
    isShown: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    addingStaff: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    businessContact: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    staffContact: PropTypes.shape({}).isRequired,
};

export default ContactsSidebar;
