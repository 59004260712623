import React from 'react';
import PropTypes from 'prop-types';

import { Paragraph, Pane, EditIcon, TrashIcon } from 'evergreen-ui';

import styled from 'styled-components';

function ListForm({ value, setEdit, deleteList }) {
    return (
        <Group display="flex" justifyContent="space-between">
            <Paragraph
                key={value.id}
                fontWeight="500"
                fontSize={14}
                color="#696F8C"
                cursor="default"
                paddingX={30}
                alignContent="center"
            >
                {value.Group}
            </Paragraph>
            <Pane display="flex" gap={5} marginLeft="auto">
                <Pane display="flex" gap={10}>
                    <Pane
                        alignItems="center"
                        justifyContent="center"
                        padding={8}
                        className="edit-contact"
                        hoverElevation={1}
                        role="button"
                        onClick={setEdit}
                    >
                        <EditIcon color="#696f8c" className="edit-contact" />
                    </Pane>
                </Pane>
                <Pane display="flex" gap={10}>
                    <Pane
                        alignItems="center"
                        justifyContent="center"
                        padding={8}
                        className="delete-contact"
                        hoverElevation={1}
                        role="button"
                        onClick={deleteList}
                    >
                        <TrashIcon color="#c60b0b" className="delete-contact" />
                    </Pane>
                </Pane>
            </Pane>
        </Group>
    );
}

ListForm.propTypes = {
    value: PropTypes.string.isRequired,
    setEdit: PropTypes.func.isRequired,
    deleteList: PropTypes.func.isRequired,
};

export default ListForm;

const Group = styled(Pane)`
    height: 40px;
    .edit-contact,
    .delete-contact {
        display: none;
        margin-left: auto;
        border-radius: 4px;
        background-color: #f5f6fa;
        cursor: pointer;
    }

    &:hover {
        .edit-contact {
            display: flex;
        }
        .delete-contact {
            display: flex;
        }
    }
`;
