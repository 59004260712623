import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ProgressBar = ({ color, progress, intermittent, height }) => (
    <Container height={height}>
        {intermittent ? (
            <Intermittent color={color} height={height} />
        ) : (
            <Progress
                progress={progress <= 3 ? 3 : progress}
                color={color}
                height={height}
            />
        )}
    </Container>
);

ProgressBar.propTypes = {
    color: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    intermittent: PropTypes.bool,
    height: PropTypes.number,
};

ProgressBar.defaultProps = {
    intermittent: false,
    height: 10,
};

export default ProgressBar;

const Container = styled.div`
    position: relative;
    background-color: #e0e0de;
    border-radius: 100px;
    height: ${(props) => props.height}px;
    overflow: hidden;
`;

const Progress = styled.div`
    height: ${(props) => props.height}px;
    width: ${(props) => props.progress}%;
    background-color: ${(props) => props.color};
    border-radius: inherit;
    transition: width 1s ease-in-out;
`;

const Intermittent = styled.div`
    position: absolute;
    height: ${(props) => props.height}px;
    width: 25%;
    background-color: ${(props) => props.color};
    border-radius: inherit;
    transition: width 1s ease-in-out;
    animation: intermittent-animation 1.5s infinite linear;

    @keyframes intermittent-animation {
        0% {
            left: -25%;
        }
        100% {
            left: 100%;
        }
    }
`;
