import React, { useState } from 'react';
import {
    Pane,
    Text,
    Avatar,
    Spinner,
    Tooltip,
    InfoSignIcon,
    EditIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { documentService } from '../../services';
import { colors } from '../../theme/theme';
import getDate from '../../helpers/get-date';
import EditDocumentModal from '../molecules/edit-document-modal';
import { getUpdateDocumentModel } from '../../models';

function TaskAttachments({ task }) {
    const [downloading, setDownloading] = useState(null);

    const [editDocument, setEditDocument] = useState({
        isShown: false,
        document: null,
    });

    const openEditDocument = (e, document) => {
        e.stopPropagation();
        setEditDocument({
            isShown: true,
            document: { ...getUpdateDocumentModel(document) },
        });
    };

    const closeEditDocument = () => {
        setEditDocument({
            isShown: false,
            document: null,
        });
    };

    const download = async (link) => {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await fetch(link.href, {
                method: 'GET',
                headers: {},
            });
            const buffer = await response.arrayBuffer();

            const url = window.URL.createObjectURL(new Blob([buffer]));
            link.href = url;
            link.setAttribute('download', link.title);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            throw error;
        }
    };

    const downloadDocument = async (file) => {
        setDownloading(file.id);

        const {
            building: { bucket_id: bucketId },
        } = task;
        try {
            const fileLink = Object.values(
                await documentService.getFilesDownloadLink([
                    {
                        assetId: bucketId,
                        fileId: file.file_id,
                        fileKey: file.file_key,
                    },
                ])
            )[0];

            const link = document.createElement('a');
            link.href = fileLink;
            link.setAttribute('download', file.friendly_name);
            link.setAttribute('title', file.friendly_name);
            link.setAttribute('target', '_blank');
            await download(link);
            link.parentNode.removeChild(link);
        } finally {
            setDownloading(null);
        }
    };

    return (
        <>
            <Pane background="#fafbff" height="calc(100vh - 160px)">
                <Pane marginBottom={22} display="flex" alignItems="center">
                    <Text>
                        All Attachments for {task.description || 'Task'}{' '}
                        (Including Updates){' '}
                        <Tooltip content="Click an Attachment to Download">
                            <InfoSignIcon marginTop={3} marginBottom={-3} />
                        </Tooltip>
                    </Text>
                </Pane>
                {task?.documents && task.documents.length ? (
                    <Pane
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        border={`1px solid ${colors.neutral500}`}
                        borderRadius={4}
                        overflowY="auto"
                    >
                        {task.documents.map((document, index) => {
                            const notLast = index !== task.documents.length - 1;
                            return (
                                <ReportCard
                                    download
                                    key={document.file_id}
                                    display="flex"
                                    alignItems="center"
                                    gap={10}
                                    borderBottom={`${
                                        notLast
                                            ? `1px solid ${colors.neutral500}`
                                            : 'none'
                                    }`}
                                    padding={8}
                                    role="button"
                                    onClick={() => downloadDocument(document)}
                                >
                                    {downloading === document.id ? (
                                        <Spinner marginX="auto" />
                                    ) : (
                                        <>
                                            <Avatar
                                                name={`${document?.user?.name} ${document.user.surname}`}
                                                size={36}
                                            />
                                            <Pane
                                                display="flex"
                                                flexDirection="column"
                                            >
                                                <Text fontWeight={600}>
                                                    {document.friendly_name}
                                                </Text>
                                                <Text fontSize={12}>
                                                    {getDate(
                                                        document.created_at
                                                    )}
                                                </Text>
                                            </Pane>
                                            <Pane
                                                role="button"
                                                className="edit-button"
                                                hoverElevation={1}
                                                onClick={(e) =>
                                                    openEditDocument(
                                                        e,
                                                        document
                                                    )
                                                }
                                            >
                                                <EditIcon color="#696f8c" />
                                            </Pane>
                                        </>
                                    )}
                                </ReportCard>
                            );
                        })}
                    </Pane>
                ) : (
                    <Text>No Attachments.</Text>
                )}
            </Pane>
            <EditDocumentModal
                isShown={editDocument.isShown}
                document={editDocument.document}
                close={closeEditDocument}
                isTask
            />
        </>
    );
}

TaskAttachments.propTypes = {
    task: PropTypes.shape({
        building: PropTypes.shape({
            bucket_id: PropTypes.string,
        }),
        documents: PropTypes.arrayOf(PropTypes.File),
        description: PropTypes.string,
    }).isRequired,
};

const ReportCard = styled(Pane)`
    cursor: pointer;
    .edit-button {
        display: none;
        padding: 10px;
        margin-left: auto;
        border-radius: 4px;
        background-color: white;
    }
    &:hover {
        background-color: ${colors.neutral300};
        .edit-button {
            display: block;
        }
    }
`;

export default TaskAttachments;
