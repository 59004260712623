import { combineReducers } from 'redux';

import authReducer from './auth-reducer/auth.reducer';
import userReducer from './user-reducer/user.reducer';
import portfolioReducer from './portfolio-reducer/portfolio.reducer';
import assetReducer from './asset-reducer/asset.reducer';
import contactsReducer from './contacts-reducer/contacts.reducer';
import uploadRequestReducer from './upload-request-reducer/upload-request-reducer';
import spacesReducer from './spaces/spaces.reducer';
import uploadManagerReducer from './upload-manager/upload-manager.reducer';
import maintenanceReducer from './maintenance-reducer/maintenance-reducer';
import taskReducer from './task-reducer/task.reducer';
import notificationsReducer from './notifications-reducer/notifications.reducer';
import complianceTemplateReducer from './compliance-template-reducer/compliance-template.reducer';
import userManagementReducer from './user-management-reducer/user-management.reducer';

const rootReducer = combineReducers({
    authReducer,
    userReducer,
    portfolioReducer,
    assetReducer,
    contactsReducer,
    uploadRequestReducer,
    spacesReducer,
    uploadManagerReducer,
    maintenanceReducer,
    taskReducer,
    notificationsReducer,
    complianceTemplateReducer,
    userManagementReducer,
});

export default rootReducer;
