import React from 'react';
import { Pane, SelectField, Text, Group, Checkbox } from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';

const BYWEEKDAY_OPTIONS = [
    { value: 'MO', label: 'Monday' },
    { value: 'TU', label: 'Tuesday' },
    { value: 'WE', label: 'Wednesday' },
    { value: 'TH', label: 'Thursday' },
    { value: 'FR', label: 'Friday' },
    { value: 'SA', label: 'Saturday' },
    { value: 'SU', label: 'Sunday' },
];

function WeeklyRecurrenceForm({ handleChange, recurrenceForm }) {
    return (
        <Pane display="flex" flexDirection="column">
            <Pane display="flex" alignItems="center" gap={20}>
                <Text textAlign="end" width="15%" marginTop={-16}>
                    Every
                </Text>
                <SelectField
                    name="interval"
                    width="35%"
                    inputHeight={40}
                    onChange={handleChange}
                    value={recurrenceForm.interval}
                >
                    <option value="" disabled>
                        Select Recurrence Period
                    </option>
                    {_.range(1, 100).map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </SelectField>
                <Text width="15%" marginTop={-16}>
                    Week(s)
                </Text>
            </Pane>
            <Group>
                <Pane
                    display="flex"
                    alignItems="center"
                    gap={18}
                    flexWrap="wrap"
                    paddingLeft="calc(15% + 20px)"
                    marginBottom={10}
                >
                    {BYWEEKDAY_OPTIONS.map(({ label, value }) => {
                        const isChecked =
                            recurrenceForm.byweekday.includes(value);
                        return (
                            <Pane display="flex" gap={8}>
                                <Checkbox
                                    key={value}
                                    value={value}
                                    onChange={handleChange}
                                    checked={isChecked}
                                    marginY={2}
                                />
                                <Text cursor="default" width="80px">
                                    {label}
                                </Text>
                            </Pane>
                        );
                    })}
                </Pane>
            </Group>
        </Pane>
    );
}

WeeklyRecurrenceForm.propTypes = {
    handleChange: PropTypes.func.isRequired,
    recurrenceForm: PropTypes.shape({
        interval: PropTypes.number,
        byweekday: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
};

export default WeeklyRecurrenceForm;
