import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, Heading, Pane, Paragraph, Text } from 'evergreen-ui';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { SelectedComplianceItem } from '../../../pages/asset-pages/selected-complianceI-item-context';
import UploadRequestIcon from '../../../assets/images/icons/upload-request-icon.svg';
import { Image } from '../../atoms';
import { assetUploadRequestModel } from '../../../models';
import { uploadRequestService } from '../../../services';
import {
    getAsset,
    getBuilding,
    getTenant,
} from '../../../reducers/asset-reducer/asset.actions';
import { errorToast, successToast } from '../../toasts';
import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';

const AssetSidebarUploadRequests = ({ isVisible }) => {
    const dispatch = useDispatch();
    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const asset = useMemo(
        () => currentTenant || currentBuilding || currentAsset,
        [currentAsset, currentBuilding, currentTenant]
    );

    const {
        selectedComplianceItem,
        selectedComplianceItemDocuments,
        isLoadingSelectedComplianceItemDocuments,
    } = useContext(SelectedComplianceItem);
    const [showUploadRequestDetails, setShowUploadRequestDetails] =
        useState(false);
    const [
        isDeleteConfirmationDialogShown,
        setIsDeleteConfirmationDialogShown,
    ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCanceling, setIsCanceling] = useState(false);

    const uploadRequest = useMemo(() => {
        if (!selectedComplianceItem || !selectedComplianceItem.uploadRequest) {
            return assetUploadRequestModel();
        }

        return selectedComplianceItem.uploadRequest;
    }, [selectedComplianceItem]);

    useEffect(() => {
        setShowUploadRequestDetails(
            _.size(selectedComplianceItemDocuments) <= 0
        );
    }, [uploadRequest, selectedComplianceItemDocuments]);

    const toggleUploadRequestDetails = () => {
        setShowUploadRequestDetails(!showUploadRequestDetails);
    };

    const showDeleteConfirmationDialog = () => {
        setIsDeleteConfirmationDialogShown(true);
    };

    const closeDeleteConfirmationDialog = () => {
        setIsDeleteConfirmationDialogShown(false);
    };

    const resendUploadRequest = async () => {
        setIsLoading(true);

        try {
            await uploadRequestService.cancelUploadRequest(uploadRequest.id);
            await uploadRequestService.sendUploadRequest({
                complianceTypeId: uploadRequest.complianceTypeId,
                note: uploadRequest.note,
                contactId: uploadRequest.contact.id,
                assetId: asset.id,
            });
            if (asset.type === 'park') {
                await dispatch(getAsset(asset.id));
            }
            if (asset.type === 'building') {
                await dispatch(getBuilding(asset.id));
            }
            if (asset.type === 'tenant') {
                await dispatch(getTenant(asset.id));
            }
            successToast(
                `Successfully resent upload request to ${uploadRequest.contact.firstName} ${uploadRequest.contact.lastName}`
            );
        } catch (error) {
            errorToast(_.get(error, 'response.data.message', error.message));
        } finally {
            setIsLoading(false);
        }
    };

    const cancelUploadRequest = async () => {
        setIsCanceling(true);

        try {
            await uploadRequestService.cancelUploadRequest(uploadRequest.id);
            if (asset.type === 'park') {
                await dispatch(getAsset(asset.id));
            }
            if (asset.type === 'building') {
                await dispatch(getBuilding(asset.id));
            }
            if (asset.type === 'tenant') {
                await dispatch(getTenant(asset.id));
            }
            successToast('Successfully canceled upload request.');
        } catch (error) {
            errorToast(_.get(error, 'response.data.message', error.message));
        } finally {
            setIsDeleteConfirmationDialogShown(false);
            setIsCanceling(false);
        }
    };

    if (
        !uploadRequest ||
        isLoadingSelectedComplianceItemDocuments ||
        !isVisible
    ) {
        return null;
    }

    return (
        <>
            <Pane
                display="flex"
                alignItems="center"
                onClick={toggleUploadRequestDetails}
                cursor="pointer"
            >
                <Image src={UploadRequestIcon} width={23} height={23} />
                <Text
                    fontSize={12}
                    fontWeight="700"
                    color="5EC090"
                    paddingLeft={8}
                >
                    Pending Upload Request
                </Text>
            </Pane>
            <Pane paddingTop={16} paddingBottom={16}>
                <Pane padding={8}>
                    <Heading
                        fontSize={11}
                        fontWeight={700}
                        color="#C1C4D6"
                        textTransform="uppercase"
                        lineHeight={1}
                    >
                        Name:
                    </Heading>
                    <Paragraph fontSize={14} fontWeight={400} color="#696F8C">
                        {uploadRequest.contact.firstName}
                    </Paragraph>
                </Pane>
                <Pane padding={8}>
                    <Heading
                        fontSize={11}
                        fontWeight={700}
                        color="#C1C4D6"
                        textTransform="uppercase"
                        lineHeight={1}
                    >
                        Surname
                    </Heading>
                    <Paragraph fontSize={14} fontWeight={400} color="#696F8C">
                        {uploadRequest.contact.lastName}
                    </Paragraph>
                </Pane>
                <Pane padding={8}>
                    <Heading
                        fontSize={11}
                        fontWeight={700}
                        color="#C1C4D6"
                        textTransform="uppercase"
                        lineHeight={1}
                    >
                        Company:
                    </Heading>
                    <Paragraph fontSize={14} fontWeight={400} color="#696F8C">
                        {uploadRequest.companyName}
                    </Paragraph>
                </Pane>
                <Pane padding={8}>
                    <Heading
                        fontSize={11}
                        fontWeight={700}
                        color="#C1C4D6"
                        textTransform="uppercase"
                        lineHeight={1}
                    >
                        Email:
                    </Heading>
                    <Paragraph fontSize={14} fontWeight={400} color="#696F8C">
                        {uploadRequest.contact.email}
                    </Paragraph>
                </Pane>
                <Pane padding={8}>
                    <Heading
                        fontSize={11}
                        fontWeight={700}
                        color="#C1C4D6"
                        textTransform="uppercase"
                        lineHeight={1}
                    >
                        Date Sent:
                    </Heading>
                    <Paragraph fontSize={14} fontWeight={400} color="#696F8C">
                        {DateTime.fromMillis(uploadRequest.createdAt).toFormat(
                            'd LLL yyyy  HH:mm'
                        )}
                    </Paragraph>
                </Pane>
                <Pane
                    display="flex"
                    flexDirection="column "
                    flex={1}
                    justifyContent="center"
                    marginTop={8}
                    marginBottom={4}
                    marginX={4}
                >
                    <Button
                        appearance="primary"
                        display="flex"
                        flex={1}
                        onClick={resendUploadRequest}
                        isLoading={isLoading}
                        disabled={isCanceling}
                    >
                        Resend Request
                    </Button>
                    <Button
                        appearance="minimal"
                        intent="danger"
                        display="flex"
                        flex={1}
                        marginTop={6}
                        onClick={showDeleteConfirmationDialog}
                        disabled={isLoading}
                    >
                        Cancel Request
                    </Button>
                </Pane>
            </Pane>
            <Dialog
                isShown={isDeleteConfirmationDialogShown}
                title="Are you sure?"
                intent="danger"
                onCloseComplete={closeDeleteConfirmationDialog}
                isConfirmLoading={isCanceling}
                confirmLabel="Cancel Upload Request"
                onConfirm={cancelUploadRequest}
            >
                Are you sure you want to cancel this upload request?
            </Dialog>
        </>
    );
};

AssetSidebarUploadRequests.propTypes = {
    isVisible: PropTypes.bool.isRequired,
};

export default AssetSidebarUploadRequests;
