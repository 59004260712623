import { taskService, documentService } from '../../services';
import { getUpdateDocumentModel } from '../../models';
import {
    setTasksAction,
    setTasksPaginationAction,
    setTasksCategoriesAction,
    setTasksPropertiesAction,
    setAvailableTaskAssigneesAction,
    setTasksOverviewDataAction,
    setTasksOverallGraphDataAction,
    setTaskToViewAction,
} from './task.reducer';

export async function getTasks() {
    return async (dispatch, getState) => {
        const {
            search,
            dateRange,
            statusFilter,
            tablePage,
            typeFilter,
            userFilter,
        } = getState().taskReducer;
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;
        const buildingId =
            currentTenant?.id || currentBuilding?.id || currentAsset?.id || 0;

        const { tasks } = await taskService.getTasks({
            search,
            sort: [
                { orderBy: 'asc', sortBy: 'task_items.due_date' },
                { orderBy: 'asc', sortBy: 'task_items.created_at' },
            ],
            page: tablePage,
            ...dateRange,
            statusFilter,
            typeFilter,
            buildingId,
            userFilter,
        });
        dispatch(setTasksAction(tasks));
        dispatch(setTasksPaginationAction(tasks));
        dispatch(getTasksOverviewData());
        dispatch(getTasksOverallGraphData());
    };
}

export async function createTask(task, enqueueUploads) {
    return async (dispatch, getState) => {
        const { user } = getState().userReducer;
        const {
            id,
            building: { bucket_id: bucketId },
        } = await taskService.createTask(task);

        await enqueueUploads(task.attachments, {
            assetId: bucketId,
            userId: user.id,
            requestType: 'task',
            taskItemId: id,
            onCompleted: () => dispatch(getTasks()),
        });
        dispatch(getTasks());
    };
}

export async function editTask(task) {
    return async (dispatch) => {
        await taskService.editTask(task);
        dispatch(getTasks());
    };
}

export async function updateTask(update) {
    return async (dispatch) => {
        await taskService.updateTask(update);
        await dispatch(getTasks());
    };
}

export async function deleteTask(id) {
    return async (dispatch) => {
        await taskService.deleteTask(id);
        await dispatch(getTasks());
    };
}

// Categories

export function getTasksCategories() {
    return async (dispatch) => {
        const categories = await taskService.getTasksCategories();
        dispatch(setTasksCategoriesAction(categories));
    };
}

export async function deleteTasksCategory(id) {
    return async (dispatch) => {
        await taskService.deleteTasksCategory(id);
        await dispatch(getTasksCategories());
    };
}

export async function createTasksCategory(category) {
    return async (dispatch) => {
        const newCategory = await taskService.createTasksCategory({
            name: category,
        });
        await dispatch(getTasksCategories());
        return newCategory;
    };
}

export async function updateTasksCategory(category) {
    return async (dispatch) => {
        const updatedCategory = await taskService.updateTasksCategory(category);
        await dispatch(getTasksCategories());
        return updatedCategory;
    };
}

// Types

export async function createTasksType(type) {
    return async (dispatch) => {
        const newType = await taskService.createTasksType(type);
        await dispatch(getTasksCategories());
        return newType;
    };
}

export async function updateTasksType(type) {
    return async (dispatch) => {
        const updatedType = await taskService.updateTasksType(type);
        await dispatch(getTasksCategories());
        return updatedType;
    };
}

export async function deleteTasksType(id) {
    return async (dispatch) => {
        await taskService.deleteTasksType(id);
        dispatch(getTasksCategories());
    };
}

// Buildings

export async function getAvailableBuildings() {
    return async (dispatch) => {
        const buildings = await taskService.getAvailableBuildings();
        dispatch(setTasksPropertiesAction(buildings));
    };
}

// Assignees

export async function getAvailableAssignees() {
    return async (dispatch) => {
        const assignees = await taskService.getAvailableAssignees();
        dispatch(setAvailableTaskAssigneesAction(assignees));
    };
}

// Dashboard

export async function getTasksOverviewData() {
    return async (dispatch, getState) => {
        const { dateRange, statusFilter } = getState().taskReducer;
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;

        const buildingId =
            currentTenant?.id || currentBuilding?.id || currentAsset?.id || 0;

        const data = await taskService.getTasksOverviewData({
            ...dateRange,
            statusFilter,
            buildingId,
        });
        dispatch(setTasksOverviewDataAction(data));
    };
}

export async function getTasksOverallGraphData() {
    return async (dispatch, getState) => {
        const { dateRange, statusFilter } = getState().taskReducer;
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;

        const buildingId =
            currentTenant?.id || currentBuilding?.id || currentAsset?.id || 0;

        const data = await taskService.getTasksOverallGraphData({
            ...dateRange,
            statusFilter,
            buildingId,
        });
        dispatch(setTasksOverallGraphDataAction(data));
    };
}

export function updateTaskAttachment(editDocument) {
    return async (dispatch, getState) => {
        const { currentAsset, currentBuilding, currentTenant } =
            getState().assetReducer;

        const { search, sort, dateRange, statusFilter, tablePage } =
            getState().taskReducer;

        const buildingId =
            currentTenant?.id || currentBuilding?.id || currentAsset?.id || 0;

        const updatedDocument = getUpdateDocumentModel(
            await documentService.updateDocument({
                documentId: editDocument?.id,
                friendlyName: editDocument.newName,
                notes: editDocument.notes,
                requestType: 'task',
            })
        );

        const { tasks } = await taskService.getTasks({
            search,
            sort,
            page: tablePage,
            ...dateRange,
            statusFilter,
            buildingId,
        });
        const taskToView = tasks.items.find(
            (task) => task.id === updatedDocument.taskItemId
        );
        dispatch(setTaskToViewAction(taskToView));
    };
}
