import React from 'react';
import { Heading, Pane, SideSheet } from 'evergreen-ui';
import PropTypes from 'prop-types';

import CreateContactForm from '../../forms/contact-forms/create-contact-form';

const CreateContactSidebar = ({ isShown, setIsShown, onClose }) => {
    const closeSidebar = () => {
        setIsShown(false);
        onClose();
    };

    return (
        <SideSheet
            isShown={isShown}
            onCloseComplete={closeSidebar}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                backgroundColor: '#F1F2F2',
            }}
            shouldCloseOnEscapePress={false}
        >
            <Pane
                zIndex={1}
                flexShrink={0}
                elevation={0}
                backgroundColor="white"
            >
                <Pane padding={16}>
                    <Heading size={600}>Create Contact</Heading>
                </Pane>
            </Pane>
            <Pane flex="1" overflowY="auto" background="tint1" padding={16}>
                <CreateContactForm closeSidebar={closeSidebar} />
            </Pane>
        </SideSheet>
    );
};

CreateContactSidebar.propTypes = {
    isShown: PropTypes.bool.isRequired,

    setIsShown: PropTypes.func.isRequired,
    onClose: PropTypes.func,
};

CreateContactSidebar.defaultProps = {
    onClose: () => {},
};

export default CreateContactSidebar;
