import React, { useMemo, useCallback, useState, useContext } from 'react';
import { Dialog, TextInputField, Pane, Text } from 'evergreen-ui';
import { get } from 'lodash';
import { useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { errorToast } from '../../toasts';
import {
    getTenant,
    getAsset,
    getBuilding,
} from '../../../reducers/asset-reducer/asset.actions';
import { SelectedComplianceItem } from '../../../pages/asset-pages/selected-complianceI-item-context';

function ComplianceDialog({
    isShown,
    onClose,
    onConfirm,
    isDeleting,
    isGroup,
    isCategory,
    complianceItem,
    isEditing,
}) {
    const [submitError, setSubmitError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [name, setName] = useState(complianceItem?.name);

    const { assetId: parkId, buildingId, tenantId } = useParams();
    // eslint-disable-next-line no-unused-vars
    const [_, setSearchParams] = useSearchParams();

    const { selectComplianceType } = useContext(SelectedComplianceItem);

    const assetId = useMemo(() => {
        if (tenantId) {
            return tenantId;
        }
        if (buildingId) {
            return buildingId;
        }
        return parkId;
    }, [tenantId, buildingId, parkId]);

    const dispatch = useDispatch();

    const close = useCallback(() => {
        setName('');
        setSubmitError(null);
        setIsSubmitting(false);
        onClose();
    }, [onClose]);

    const handleConfirm = useCallback(async () => {
        try {
            if (!name) {
                throw new Error('Please enter a name');
            }
            setIsSubmitting(true);
            await onConfirm({ ...complianceItem, name, assetId });
            if (tenantId) {
                dispatch(getTenant(tenantId));
            } else if (buildingId) {
                dispatch(getBuilding(buildingId));
            } else if (assetId) {
                dispatch(getAsset(assetId, false, true, false));
            }
            selectComplianceType(null, null, null, null);
            setSearchParams({});
            close();
        } catch (error) {
            if (isDeleting) {
                errorToast(
                    `An Error Occurred: ${get(
                        error,
                        'response.data.message',
                        error.message
                    )}`
                );
            } else {
                setSubmitError(
                    get(error, 'response.data.message', error.message)
                );
            }
        } finally {
            setIsSubmitting(false);
        }
    }, [
        name,
        onConfirm,
        complianceItem,
        assetId,
        tenantId,
        buildingId,
        selectComplianceType,
        setSearchParams,
        close,
        dispatch,
        isDeleting,
    ]);

    const handleChange = ({ target }) => {
        setSubmitError(null);
        setName(target.value);
    };

    const title = useMemo(() => {
        if (isDeleting) {
            if (isGroup) {
                return 'Delete Compliance Group';
            }
            if (isCategory) {
                return 'Delete Compliance Category';
            }
        }
        if (isEditing) {
            if (isGroup) {
                return 'Edit Compliance Group';
            }
            if (isCategory) {
                return 'Edit Compliance Category';
            }
        }

        if (isGroup) {
            return 'Add Compliance Group';
        }

        return 'Add Compliance Category';
    }, [isCategory, isDeleting, isEditing, isGroup]);

    const renderContent = useCallback(() => {
        const type = isGroup ? 'Group' : 'Category';

        if (isDeleting) {
            return (
                <Pane marginTop={20}>
                    <Text fontSize={18}>
                        Are you sure you want to delete{' '}
                        {complianceItem?.name || `this compliance ${type}`} and
                        all its content?
                    </Text>
                </Pane>
            );
        }
        return (
            <TextInputField
                isInvalid={!!submitError}
                required
                label={`Compliance ${type} name`}
                validationMessage={submitError}
                onChange={handleChange}
                tabindex="0"
                value={name}
                inputHeight={40}
                placeholder="New Name"
            />
        );
    }, [complianceItem, isDeleting, isGroup, name, submitError]);

    return (
        <Dialog
            isShown={isShown}
            hasClose={false}
            onConfirm={handleConfirm}
            intent={isDeleting ? 'danger' : 'none'}
            onCloseComplete={close}
            title={title}
            isConfirmLoading={isSubmitting}
            isConfirmDisabled={isSubmitting}
        >
            {renderContent()}
        </Dialog>
    );
}

ComplianceDialog.propTypes = {
    isShown: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool,
    isEditing: PropTypes.bool,
    isGroup: PropTypes.bool,
    isCategory: PropTypes.bool,
    complianceItem: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
    }),
};

ComplianceDialog.defaultProps = {
    isDeleting: false,
    isGroup: false,
    isCategory: false,
    isEditing: false,
    complianceItem: {},
};

export default ComplianceDialog;
