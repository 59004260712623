import _ from 'lodash';

export const imageLinkRequestModel = (linkRequestObject = {}) => ({
    asset_id: _.get(linkRequestObject, 'assetId', ''),
    filetype: {
        thumbnail: _.get(linkRequestObject, 'filetype.thumbnail', ''),
        file: _.get(linkRequestObject, 'filetype.file', ''),
    },
});

export const imageDownloadLinkRequestModel = (downloadLinkRequestObject = {}) =>
    _.get(downloadLinkRequestObject, 'awslink.location');

export const uploadImageUrlsModel = (uploadUrlsObject = {}) => ({
    thumbnailUrl: _.get(uploadUrlsObject, 'thumbnail.location', ''),
    fileUrl: _.get(uploadUrlsObject, 'file.location', ''),
    thumbnailId: _.get(uploadUrlsObject, 'thumbnail_id', ''),
    fileId: _.get(uploadUrlsObject, 'file_id', ''),
});

export const setImageUploadSuccessfullyApiModel = (
    setImageUploadSuccessfullyObject = {}
) => ({
    asset_id: _.get(setImageUploadSuccessfullyObject, 'assetId', ''),
    for_space: _.get(setImageUploadSuccessfullyObject, 'isSpace', ''),
    spaces_id: _.get(setImageUploadSuccessfullyObject, 'spaceId', ''),
    image: {
        file_id: _.get(setImageUploadSuccessfullyObject, 'fileId', ''),
        thumbnail_id: _.get(
            setImageUploadSuccessfullyObject,
            'thumbnailId',
            ''
        ),
        is_featured: _.get(
            setImageUploadSuccessfullyObject,
            'isFeatured',
            false
        ),
    },
});
