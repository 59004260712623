import React, { useState, useMemo } from 'react';
import {
    Pane,
    Text,
    SearchIcon,
    CrossIcon,
    GridViewIcon,
    TextInput,
    Tooltip,
    Spinner,
} from 'evergreen-ui';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import SearchResultModal from './dashboard-navbar-result-modal';
import { colors } from '../../../theme/theme';
import BuildingImage from '../building-image';
import TenantSelector from './tenant-selector';
import {
    assetSelector,
    setSearchAction,
    setCurrentAssetAction,
    setCurrentBuildingAction,
    setCurrentTenantAction,
} from '../../../reducers/asset-reducer/asset.reducer';
import { useSearchParams } from '../../../context/search-params-context';
import { useUserAccess } from '../../../context/user-access-context';

const DashboardNavbarSearch = () => {
    const { pathname } = useLocation();
    const { isLoadingParams, setSearchParams } = useSearchParams();
    const { modules } = useUserAccess();

    const canViewBuildings = useMemo(() => modules.info, [modules]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const { currentTenant, currentBuilding, currentAsset, search, assets } =
        useSelector(assetSelector);

    const { assetName } = useMemo(() => {
        if (isLoadingParams) {
            return { assetName: '' };
        }
        return (
            currentTenant ||
            currentBuilding ||
            currentAsset || { assetName: '' }
        );
    }, [isLoadingParams, currentTenant, currentBuilding, currentAsset]);

    const assetImage = useMemo(() => {
        if (isLoadingParams) {
            return null;
        }

        const asset = currentTenant || currentBuilding || currentAsset;

        if (asset) {
            const featuredImage = asset.images?.find(
                (image) => image.isFeatured
            );

            if (featuredImage) {
                return featuredImage;
            }
            if (asset.images.length > 0) {
                return asset.images[0];
            }

            return null;
        }

        return null;
    }, [isLoadingParams, currentTenant, currentBuilding, currentAsset]);

    const asset = useMemo(
        () => currentTenant || currentBuilding || currentAsset || [],
        [currentAsset, currentBuilding, currentTenant]
    );

    const [searchOverlayCheck, setSearchOverlayCheck] = useState(false);

    const searchResults = useMemo(() => {
        if (!search) {
            const parsedAssets = assets.filter(
                (filterAsset) => filterAsset.assetArchive !== true
            );

            return parsedAssets;
        }

        const parsedAssets = assets.filter(
            (filterAsset) => filterAsset.assetArchive !== true
        );
        const results = parsedAssets.filter((assetFilter) =>
            assetFilter.assetName.toLowerCase().includes(search.toLowerCase())
        );

        return results;
    }, [search, assets]);

    const removeOverlay = () => {
        setSearchOverlayCheck(false);
    };

    const handleSearch = async ({ target: { value } }) => {
        dispatch(setSearchAction(value));
    };

    const handleMyPortfolio = async () => {
        dispatch(setSearchAction(''));
        dispatch(setCurrentAssetAction(null));
        dispatch(setCurrentBuildingAction(null));
        dispatch(setCurrentTenantAction(null));
        setSearchParams({});
        navigate('/portfolio');
    };

    const handleMyProperty = async () => {
        setSearchOverlayCheck(false);
        handleClearBuilding();
        navigate('/portfolio/properties');
    };

    const handleClearBuilding = async () => {
        dispatch(setSearchAction(''));
        dispatch(setCurrentAssetAction(null));
        dispatch(setCurrentBuildingAction(null));
        dispatch(setCurrentTenantAction(null));
        setSearchParams({});
        navigate('/portfolio/properties');
    };

    if (pathname.includes('upload-request') || !canViewBuildings) {
        return null;
    }

    return (
        <>
            <Pane
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                position="fixed"
                top={65}
                zIndex={5}
                height={80}
                backgroundColor="white"
                borderBottom="1px solid #D8DAE5"
                width="100%"
            >
                {assetName ? (
                    <Pane
                        display="flex"
                        alignItems="center"
                        borderRight={`1px solid ${colors.neutral500}`}
                        height="100%"
                        width="40%"
                        position="relative"
                        zIndex={10}
                        paddingRight={10}
                    >
                        <Pane
                            display="flex"
                            alignItems="center"
                            flex={1}
                            paddingRight={16}
                            height={60}
                            gap={16}
                            width="100%"
                        >
                            <Pane
                                display="flex"
                                alignItems="center"
                                width="90%"
                            >
                                <Pane display="flex" padding={10}>
                                    <BuildingImage
                                        key={assetName}
                                        width={120}
                                        height={60}
                                        borderRadius={10}
                                        image={assetImage}
                                        asset={asset}
                                    />
                                </Pane>
                                <Pane
                                    display="flex"
                                    width="68%"
                                    cursor="default"
                                >
                                    <Tooltip
                                        content={assetName}
                                        showDelay={450}
                                    >
                                        <Title assetName={assetName}>
                                            {assetName}
                                        </Title>
                                    </Tooltip>
                                </Pane>
                            </Pane>
                            <Pane
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                role="button"
                                onClick={handleClearBuilding}
                                cursor="pointer"
                                width="10%"
                            >
                                <CrossIcon size={24} color={colors.gray700} />
                                <Text
                                    color={colors.gray700}
                                    textAlign="center"
                                    fontSize={12}
                                >
                                    Close
                                </Text>
                            </Pane>
                        </Pane>
                    </Pane>
                ) : (
                    <NavbarTitle onClick={handleMyPortfolio}>
                        <Pane
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <GridViewIcon />
                            <Text
                                marginTop={6}
                                textAlign="center"
                                fontSize={12}
                            >
                                My Portfolio
                            </Text>
                        </Pane>
                    </NavbarTitle>
                )}
                {assetName &&
                    !currentTenant &&
                    !loading &&
                    // eslint-disable-next-line react/no-children-prop
                    !isLoadingParams && (
                        <TenantSelector>{asset.children}</TenantSelector>
                    )}
                <Pane
                    height={80}
                    display="flex"
                    alignItems="center"
                    flex={1}
                    borderRight="1px solid #D8DAE5"
                    paddingRight={20}
                    width="80%"
                >
                    <SearchTextInput
                        placeholder={
                            !loading ? 'Search Park, Building or Tenant' : ''
                        }
                        size={32}
                        border="none"
                        width="100%"
                        height={79}
                        fontSize="16px"
                        borderRadius="none"
                        onClick={() => setSearchOverlayCheck(true)}
                        onChange={handleSearch}
                        paddingLeft={25}
                        outline="none"
                        value={search}
                    />

                    {loading && (
                        <Pane
                            display="flex"
                            justifyContent="center"
                            position="absolute"
                            left="calc(33%)"
                            right={87}
                        >
                            <Spinner />
                        </Pane>
                    )}

                    {!searchOverlayCheck ? (
                        <SearchIcon size={24} color={colors.neutral700} />
                    ) : (
                        <SearchResultModal
                            searchResults={searchResults}
                            searchInput={search}
                            setSearchOverlayCheck={setSearchOverlayCheck}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    )}
                </Pane>

                <NavbarTitle onClick={handleMyProperty}>
                    <Pane
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <GridViewIcon />
                        <Text marginTop={6} textAlign="center" fontSize={12}>
                            My Properties
                        </Text>
                    </Pane>
                </NavbarTitle>
            </Pane>

            {searchOverlayCheck && (
                <Pane
                    backgroundColor="#0C2138A6"
                    height="100%"
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    position="fixed"
                    zIndex={4}
                    onClick={removeOverlay}
                />
            )}
        </>
    );
};

export default DashboardNavbarSearch;

const SearchTextInput = styled(TextInput)`
    &:focus {
        outline: none;
        box-shadow: none;
        border: none;
    }
`;

const Title = styled.p`
    color: ${colors.neutral700};
    font-weight: bold;
    font-size: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: ${(props) => (props.assetName ? 'calc(100%)' : 'none')};
    display: inline-block;
    overflow: hidden;
`;

const NavbarTitle = styled.h2`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    color: ${colors.neutral700};
    font-weight: bold;
    font-size: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 140px;
    overflow: hidden;
    border-right: 1px solid ${colors.neutral500};

    &:hover {
        background-color: #4fb789;
        color: ${colors.white};
        svg {
            color: ${colors.white};
        }
    }
    svg {
        color: #4fb789;
    }
`;
