import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Card, Heading, Pane, Paragraph } from 'evergreen-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { imagesService } from '../../../services';
import { imageDownloadLinkRequestModel } from '../../../models';
import { MultiColorProgressBar } from '../../atoms';

const SpacesItem = ({ asset, onSpaceSelect }) => {
    const [imageUrl, setImageUrl] = useState(false);
    const { progress } = asset;
    const featuredImage = useMemo(() => {
        if (_.size(asset.images) <= 0 || imageUrl) {
            return null;
        }

        return _.find(asset.images, (image) => image.isFeatured);
    }, [asset, imageUrl]);

    const getImage = useCallback(async () => {
        if (!featuredImage) {
            return;
        }

        const url = imageDownloadLinkRequestModel(
            await imagesService.getImageDownloadLink({
                assetId: featuredImage.assetId,
                imageId: featuredImage.fileId,
            })
        );
        setImageUrl(url);
    }, [featuredImage]);

    useEffect(() => {
        if (featuredImage && !imageUrl) {
            getImage();
        }
    }, [featuredImage, imageUrl, getImage]);

    const select = () => {
        if (onSpaceSelect) {
            onSpaceSelect(asset);
        }
    };

    const { completed, uploadsRequested, notStarted, total } = useMemo(
        () =>
            progress || {
                completed: null,
                uploadsRequested: null,
                notStarted: null,
                total: null,
            },
        [progress]
    );
    const completedPercentage = useMemo(
        () => progress && (completed / total) * 100,
        [progress, completed, total]
    );
    const uploadsRequestedPercentage = useMemo(
        () => progress && (uploadsRequested / total) * 100,
        [progress, uploadsRequested, total]
    );
    const notStartedPercentage = useMemo(
        () => progress && (notStarted / total) * 100,
        [progress, notStarted, total]
    );

    const complianceProgress = useMemo(() => {
        if (!progress) {
            return [];
        }

        return [
            {
                value:
                    completedPercentage >= 100 || completedPercentage <= 0
                        ? completedPercentage
                        : completedPercentage - 5,
                color: '#58A81D',
            },
            {
                value:
                    completedPercentage < 100 && completedPercentage > 0
                        ? 5
                        : 0,
                color: '#8AC44D',
            },
            {
                value: uploadsRequestedPercentage,
                color: '#FFCE2F',
            },
            {
                value:
                    notStartedPercentage < 100 && notStartedPercentage > 0
                        ? 5
                        : 0,
                color: '#F69779',
            },
            {
                value:
                    notStartedPercentage >= 100 || notStartedPercentage <= 0
                        ? notStartedPercentage
                        : notStartedPercentage - 5,
                color: '#F16263',
            },
        ];
    }, [
        completedPercentage,
        uploadsRequestedPercentage,
        notStartedPercentage,
        progress,
    ]);

    return (
        <SpacesCard
            padding={16}
            display="flex"
            alignItems="center"
            borderRadius={0}
            onClick={select}
            cursor="pointer"
        >
            <Avatar name={asset.assetName} size={50} src={imageUrl} />
            <Pane paddingLeft={15} width="100%">
                <Paragraph
                    fontSize={11}
                    color="#282828"
                    lineHeight={1.5}
                    textTransform="uppercase"
                >
                    {asset.type} - {asset.buildingUse}
                </Paragraph>
                <Heading color="#282828" fontSize={16} fontWeight={600}>
                    {asset.assetName}
                </Heading>
                <Pane width="100%" paddingTop={5}>
                    <MultiColorProgressBar
                        progressValues={complianceProgress}
                    />
                </Pane>
            </Pane>
        </SpacesCard>
    );
};

SpacesItem.propTypes = {
    asset: PropTypes.objectOf({
        name: PropTypes.string,
        shopNumber: PropTypes.string,
        progress: PropTypes.shape,
        images: PropTypes.arrayOf(PropTypes.shape),
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,

    onSpaceSelect: PropTypes.func,
};

SpacesItem.defaultProps = {
    onSpaceSelect: null,
};

export default SpacesItem;

const SpacesCard = styled(Card)`
    &:hover {
        background-color: #ffffff;
    }
`;
