/* eslint-disable import/prefer-default-export */
import { get } from 'lodash';

export const notificationModel = (notification) => ({
    id: get(notification, 'id', ''),
    createdAt: get(notification, 'created_at', ''),
    userId: get(notification, 'user_id', ''),
    heading: get(notification, 'heading', ''),
    message: get(notification, 'message', ''),
    read: get(notification, 'read', false),
    category: get(notification, 'category', ''),
    metadata: {
        type: get(notification, 'metadata.type', ''),
        buildingId:
            get(notification, 'metadata.buildings_id') ||
            get(notification, 'metadata.building_id') ||
            '',

        taskItemId: get(notification, 'metadata.taskitem_id', ''),
        maintenanceItemId: get(
            notification,
            'metadata.maintenance_item_id',
            ''
        ),
        complianceTypeId: get(notification, 'metadata.compliance_type_id', ''),
        complianceCategoryId: get(
            notification,
            'metadata.compliance_category_id',
            ''
        ),
        complianceGroupId: get(
            notification,
            'metadata.compliance_group_id',
            ''
        ),
    },
});

export const notificationAPIModel = (notification) => ({
    id: get(notification, 'id', ''),
    created_at: get(notification, 'createdAt', ''),
    user_id: get(notification, 'userId', ''),
    heading: get(notification, 'heading', ''),
    message: get(notification, 'message', ''),
    read: get(notification, 'read', false),
    category: get(notification, 'category', ''),
});
