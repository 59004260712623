import _ from 'lodash';

import { complianceService } from '../../services';
import { setComplianceTemplateAction } from './compliance-template.reducer';

export async function getComplianceTemplate(type) {
    return async (dispatch, getState) => {
        const { businessIds } = getState().userReducer;
        const complianceTemplate =
            await complianceService.getComplianceTemplates(
                businessIds[0],
                type
            );
        dispatch(setComplianceTemplateAction(complianceTemplate));
    };
}

export function confirmComplianceTemplates(type) {
    return async (dispatch, getState) => {
        const { complianceTemplate } = getState().complianceTemplateReducer;
        const complianceTemplateClone = _.cloneDeep(complianceTemplate);

        await Promise.all(
            complianceTemplateClone.map(
                async ({ id, Group: group, order, category }) => {
                    await complianceService.editComplianceTemplate({
                        complianceItemId: id,
                        group,
                        order,
                        category,
                    });
                }
            )
        );
        dispatch(getComplianceTemplate(type));
    };
}

export function addComplianceGroup(template) {
    return async (dispatch, getState) => {
        const { complianceTemplate } = getState().complianceTemplateReducer;
        const complianceTemplateClone = _.cloneDeep(complianceTemplate);
        const newGroup = await complianceService.addComplianceTemplate(
            template
        );
        complianceTemplateClone.push(newGroup);
        dispatch(setComplianceTemplateAction(complianceTemplateClone));
    };
}

export function renameComplianceGroup(groupId, newName) {
    return async (dispatch, getState) => {
        const { complianceTemplate } = getState().complianceTemplateReducer;
        const complianceTemplateClone = _.cloneDeep(complianceTemplate);
        dispatch(
            setComplianceTemplateAction(
                complianceTemplateClone.map((group) => {
                    if (Number(groupId) === Number(group.id)) {
                        group.Group = newName;
                        return group;
                    }
                    return group;
                })
            )
        );
    };
}

export function deleteComplianceGroup(id) {
    return async (dispatch, getState) => {
        const { complianceTemplate } = getState().complianceTemplateReducer;
        const complianceTemplateClone = _.cloneDeep(complianceTemplate);
        await complianceService.deleteComplianceTemplateGroup(id);
        const groupIndex = complianceTemplateClone.findIndex(
            (group) => Number(group.id) === Number(id)
        );
        complianceTemplateClone.splice(groupIndex, 1);
        dispatch(setComplianceTemplateAction(complianceTemplateClone));
    };
}

export function addComplianceCategory(groupId, categoryName) {
    return async (dispatch, getState) => {
        const { complianceTemplate } = getState().complianceTemplateReducer;
        const complianceTemplateClone = _.cloneDeep(complianceTemplate);
        const groupIndex = complianceTemplateClone.findIndex(
            (group) => Number(group.id) === Number(groupId)
        );

        complianceTemplateClone[groupIndex].category.push({
            name: categoryName,
            order: 4,
            type: [],
        });
        dispatch(setComplianceTemplateAction(complianceTemplateClone));
    };
}

export function renameComplianceCategory(groupId, categoryName, newName) {
    return async (dispatch, getState) => {
        const { complianceTemplate } = getState().complianceTemplateReducer;
        const complianceTemplateClone = _.cloneDeep(complianceTemplate);
        dispatch(
            setComplianceTemplateAction(
                complianceTemplateClone.map((group) => {
                    if (Number(group.id) === Number(groupId)) {
                        group.category = group.category.map((category) => {
                            if (category.name === categoryName) {
                                category.name = newName;
                                return category;
                            }
                            return category;
                        });
                    }
                    return group;
                })
            )
        );
    };
}

export function deleteComplianceCategory(groupId, categoryName) {
    return (dispatch, getState) => {
        const { complianceTemplate } = getState().complianceTemplateReducer;
        const complianceTemplateClone = _.cloneDeep(complianceTemplate);

        const categories = complianceTemplateClone.find(
            (group) => Number(group.id) === Number(groupId)
        ).category;
        const categoryIndex = categories.findIndex(
            (category) => category.name === categoryName
        );
        categories.splice(categoryIndex, 1);
        dispatch(
            setComplianceTemplateAction(
                complianceTemplateClone.map((group) => {
                    if (group.id === groupId) {
                        group.category = categories;
                        return group;
                    }
                    return group;
                })
            )
        );
    };
}

export function addComplianceType(groupId, categoryName, typeName) {
    return async (dispatch, getState) => {
        const { complianceTemplate } = getState().complianceTemplateReducer;
        const complianceTemplateClone = _.cloneDeep(complianceTemplate);

        const groupIndex = complianceTemplateClone.findIndex(
            (group) => Number(group.id) === Number(groupId)
        );
        const categories = complianceTemplateClone[groupIndex].category;

        const categoryIndex = complianceTemplateClone[
            groupIndex
        ].category.findIndex((category) => category.name === categoryName);
        categories[categoryIndex].type.push(typeName);
        dispatch(setComplianceTemplateAction(complianceTemplateClone));
    };
}

export function renameComplianceType(
    groupId,
    categoryName,
    originalName,
    newName
) {
    return async (dispatch, getState) => {
        const { complianceTemplate } = getState().complianceTemplateReducer;
        const complianceTemplateClone = _.cloneDeep(complianceTemplate);

        const groupIndex = complianceTemplateClone.findIndex(
            (group) => Number(group.id) === Number(groupId)
        );
        const categories = complianceTemplateClone[groupIndex].category;
        const categoryIndex = categories.findIndex(
            (category) => category.name === categoryName
        );
        const typeIndex = categories[categoryIndex].type.findIndex(
            (type) => type === originalName
        );
        complianceTemplateClone[groupIndex].category[categoryIndex].type[
            typeIndex
        ] = newName;

        dispatch(setComplianceTemplateAction(complianceTemplateClone));
    };
}

export function deleteComplianceType(groupId, categoryName, typeName) {
    return async (dispatch, getState) => {
        const { complianceTemplate } = getState().complianceTemplateReducer;
        const complianceTemplateClone = _.cloneDeep(complianceTemplate);

        const groupIndex = complianceTemplateClone.findIndex(
            (group) => Number(group.id) === Number(groupId)
        );
        const categories = complianceTemplateClone[groupIndex].category;
        const categoryIndex = categories.findIndex(
            (category) => category.name === categoryName
        );
        const typeIndex = categories[categoryIndex].type.findIndex(
            (type) => type === typeName
        );
        complianceTemplateClone[groupIndex].category[categoryIndex].type.splice(
            typeIndex,
            1
        );
        dispatch(setComplianceTemplateAction(complianceTemplateClone));
    };
}
