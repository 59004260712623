import React, { useState, useMemo, useCallback } from 'react';
import DP from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { Pane, TextInputField, CrossIcon } from 'evergreen-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getDateDMYFormat } from '../../../helpers/get-date';
import DatePickerCustomHeader from './date-picker-custom-header';
import DatePickerMultiHeader from './date-picker-custom-multi-header';

function DatePicker({
    selectsRange,
    dateRange: { startDate, endDate },
    setDateRangeAction,
    getData,
    handleDateRange,
}) {
    const dispatch = useDispatch();

    const [stateDateRange, setStateDateRange] = useState({
        startDate: startDate ? new Date(startDate) : '',
        endDate: endDate ? new Date(endDate) : '',
    });

    const datePickerHumanValue = useMemo(() => {
        if (!stateDateRange.startDate) {
            return '';
        }
        if (stateDateRange.startDate && !stateDateRange.endDate) {
            return getDateDMYFormat(stateDateRange.startDate);
        }
        if (stateDateRange.startDate && stateDateRange.endDate) {
            return `${getDateDMYFormat(
                stateDateRange.startDate
            )} - ${getDateDMYFormat(stateDateRange.endDate)}`;
        }
        return 'Select Date Range';
    }, [stateDateRange]);

    const handleDateRangeChange = useCallback(
        async (dates) => {
            if (!handleDateRange) {
                dispatch(
                    setDateRangeAction({
                        startDate: Date.parse(dates[0]),
                        endDate: Date.parse(dates[1]),
                    })
                );
            } else {
                await handleDateRange(dates);
            }
            setStateDateRange({ startDate: dates[0], endDate: dates[1] });

            if (dates[0] && dates[1]) {
                await getData();
            }
        },
        [dispatch, getData, handleDateRange, setDateRangeAction]
    );

    const clearDates = useCallback(async () => {
        setStateDateRange({ startDate: '', endDate: '' });
        if (handleDateRange) {
            await handleDateRange([null, null]);
        } else {
            dispatch(
                setDateRangeAction({
                    startDate: null,
                    endDate: null,
                })
            );
        }
        getData();
    }, [dispatch, getData, handleDateRange, setDateRangeAction]);

    return (
        <Container
            display="flex"
            gap={12}
            alignItems="center"
            selectsRange={selectsRange}
        >
            {!!startDate && (
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    hoverElevation={1}
                    borderRadius={9999}
                    padding={4}
                    role="button"
                    onClick={clearDates}
                >
                    <CrossIcon size={20} color="#8f95b2" cursor="pointer" />
                </Pane>
            )}
            <DP
                placeholderText="Select Date Range"
                calendarClassName="calendar"
                popperClassName="calendar"
                dateFormat="dd/MM/yyyy"
                onChange={handleDateRangeChange}
                selectsRange={selectsRange}
                startDate={stateDateRange.startDate}
                endDate={stateDateRange.endDate}
                value={datePickerHumanValue}
                monthsShown={selectsRange ? 2 : 1}
                customInput={
                    <TextInputField
                        key={`${startDate}-${endDate}`}
                        width="100%"
                        inputHeight={32}
                        inputWidth={200}
                        marginY={-4}
                        marginTop={-12}
                    />
                }
                renderCustomHeader={(props) =>
                    selectsRange ? (
                        <DatePickerMultiHeader {...props} />
                    ) : (
                        <DatePickerCustomHeader {...props} />
                    )
                }
            />
        </Container>
    );
}

const Container = styled(Pane)`
    .react-datepicker__aria-live {
        display: none;
    }

    .react-datepicker__current-month {
        width: max-content;
        font-weight: 700;
        margin: 5px auto;
    }

    .react-datepicker__navigation {
        border-radius: 9999px;
        background: #c1c4d6;
        cursor: pointer;
    }

    .react-datepicker__navigation:hover {
        color: #5ec090;
    }

    .react-datepicker__month {
        display: flex;
        flex-direction: column;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
        display: flex;
        justify-content: space-evenly;
        gap: 2px 0;
        margin-bottom: 5px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
        width: 20px;
        border-radius: 4px;
        padding: 4px;
        border: 1px solid #c1c4d6;
        text-align: center;
        background-color: #c1c4d633;
    }

    .react-datepicker__day-names {
        background-color: #c1c4d650;
        border-radius: 4px;
    }

    .react-datepicker__day-name {
        background-color: transparent;
    }

    .react-datepicker__day--today {
        border: 1px solid #5ec090;
    }

    .react-datepicker__day--outside-month {
        background-color: #c1c4d610;
        color: #101840;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range-start {
        background: #5ec090;
        color: white;
    }

    .react-datepicker__day--in-selecting-range {
        background: #5ec09050;
    }

    .react-datepicker__day--disabled {
        background-color: white;
        color: lightgray;
    }

    .react-datepicker__day:hover {
        background: #5ec090;
        color: white;
        border-color: #5ec090;
        transition: all 0.1s ease-in;
        cursor: pointer;
    }
    .react-datepicker__day--outside-month:hover {
        background-color: ${({ selectsRange }) =>
            selectsRange ? '#c1c4d610' : '#c1c4d610'};
        color: #101840;
        border-color: ${({ selectsRange }) =>
            selectsRange ? '#c1c4d610' : '#5ec090'};
        cursor: ${({ selectsRange }) => (selectsRange ? 'default' : 'pointer')};
    }

    .react-datepicker__day--in-selecting-range.react-datepicker__day--outside-month {
        background-color: #c1c4d610;
        color: #101840;
    }

    .react-datepicker__day--disabled:hover {
        background-color: white;
        color: lightgray;
        border-color: #c1c4d6;
        cursor: not-allowed;
    }
    .react-datepicker__week {
        display: flex;
        gap: 5px;
    }

    .calendar {
        width: ${({ selectsRange }) => !selectsRange && '250px'};
        display: ${({ selectsRange }) => (selectsRange ? 'flex' : 'block')};
        gap: 5px;
        padding: 3px;
        background: white;
        margin: 0 auto;
        box-shadow: 0 0 10px rgba(67, 90, 111, 0.3);
        border-radius: 4px;
        color: #101840;
        font-size: 14px;
        z-index: 10;
    }
`;

DatePicker.propTypes = {
    selectsRange: PropTypes.bool.isRequired,
    dateRange: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }).isRequired,
    setDateRangeAction: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    handleDateRange: PropTypes.func.isRequired,
};
export default DatePicker;
