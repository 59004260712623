import React, { useState } from 'react';
import { Dialog, Pane, Text } from 'evergreen-ui';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { errorToast } from '../toasts';

function ConfirmDeleteModal({
    isShown,
    title,
    close,
    onConfirm,
    itemName,
    shouldDispatch,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleConfirm = async () => {
        setIsLoading(true);
        try {
            if (shouldDispatch) {
                await dispatch(onConfirm());
            } else {
                await onConfirm();
            }
        } catch (error) {
            errorToast(
                `${get(error, 'response.data.message', 'Something went wrong')}`
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog
            title={title}
            intent="danger"
            isShown={isShown}
            onCloseComplete={close}
            onConfirm={handleConfirm}
            isConfirmLoading={isLoading}
        >
            <Pane display="flex" flexDirection="column" justifyContent="center">
                <Text marginTop={20} fontSize={16}>
                    Are you sure you want to delete{' '}
                    <Text fontSize={18} fontWeight="bolder">
                        {itemName}
                    </Text>
                </Text>
            </Pane>
        </Dialog>
    );
}

ConfirmDeleteModal.defaultProps = {
    shouldDispatch: true,
};

ConfirmDeleteModal.propTypes = {
    isShown: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    itemName: PropTypes.string.isRequired,
    shouldDispatch: PropTypes.bool,
};

export default ConfirmDeleteModal;
