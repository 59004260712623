import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    assetId: null,
    uploads: {},
    timeStarted: null,
    uploadTimeRemaining: null,
    queuedUploadBytesRemaining: null,
};

const uploadManagerSlice = createSlice({
    name: 'upload-manager',
    initialState,
    reducers: {
        enqueueUploadAction(state, action) {
            state.uploads[action.payload.id] = action.payload;
        },
        setIsInitialisingUploadAction(state, action) {
            const upload = state.uploads[action.payload.uploadId];
            state.uploads[action.payload.uploadId] = {
                ...upload,
                ...action.payload.upload,
                isInitialising: action.payload.isInitialising,
            };
        },
        setIsUploadingAction(state, action) {
            const upload = state.uploads[action.payload.uploadId];
            state.uploads[action.payload.uploadId] = {
                ...upload,
                ...action.payload.upload,
                isUploading: action.payload.isUploading,
            };
        },
        setUploadProgressAction(state, action) {
            const upload = state.uploads[action.payload.uploadId];
            state.uploads[action.payload.uploadId] = {
                ...upload,
                progress: action.payload.progress,
                uploadedBytes: action.payload.uploadedBytes,
                totalBytes: action.payload.totalBytes,
            };
        },
        setUploadLocationAction(state, action) {
            const upload = state.uploads[action.payload.uploadId];
            state.uploads[action.payload.uploadId] = {
                ...upload,
                fileUrl: action.payload.fileUrl,
                fileId: action.payload.fileId,
                thumbnailUrl: action.payload.thumbnailUrl,
                thumbnailId: action.payload.thumbnailId,
            };
        },
        setAbortControllersAction(state, action) {
            const upload = state.uploads[action.payload.uploadId];
            state.uploads[action.payload.uploadId] = {
                ...upload,
                fileController: action.payload.fileController,
                thumbnailController: action.payload.thumbnailController,
            };
        },
        setUploadDoneAction(state, action) {
            const upload = state.uploads[action.payload.uploadId];
            state.uploads[action.payload.uploadId] = {
                ...upload,
                isUploading: false,
                uploadDone: action.payload.uploadDone,
            };
        },
        setUploadFailedAction(state, action) {
            const upload = state.uploads[action.payload.uploadId];
            state.uploads[action.payload.uploadId] = {
                ...upload,
                isUploading: false,
                isInitialising: false,
                uploadDone: false,
                uploadFailed: action.payload.uploadFailed,
            };
        },
        retryUploadAction(state, action) {
            const upload = state.uploads[action.payload.uploadId];
            state.uploads[action.payload.uploadId] = {
                ...upload,
                isUploading: false,
                isInitialising: false,
                uploadDone: false,
                uploadFailed: false,
                uploadCanceled: false,
                progress: 0,
                uploadedBytes: 0,
            };
        },
        cancelUploadAction(state, action) {
            const upload = state.uploads[action.payload.uploadId];
            state.uploads[action.payload.uploadId] = {
                ...upload,
                isUploading: false,
                isInitialising: false,
                uploadDone: false,
                uploadFailed: false,
                uploadCanceled: true,
                progress: 0,
                uploadedBytes: 0,
            };
        },
        clearUploadQueueAction(state) {
            state.uploads = {};
            state.timeStarted = null;
            state.uploadTimeRemaining = null;
            state.queuedUploadBytesRemaining = null;
        },
        setTimeStartedAction(state, action) {
            state.timeStarted = action.payload;
        },
        setUploadTimeRemainingAction(state, action) {
            state.uploadTimeRemaining = action.payload;
        },
        setQueuedUploadBytesRemainingAction(state, action) {
            state.queuedUploadBytesRemaining = action.payload;
        },
        setAssetIdAction(state, action) {
            state.assetId = action.payload;
        },
    },
});

export const {
    enqueueUploadAction,
    setIsInitialisingUploadAction,
    setIsUploadingAction,
    setUploadProgressAction,
    setUploadLocationAction,
    setAbortControllersAction,
    setUploadDoneAction,
    setUploadFailedAction,
    cancelUploadAction,
    clearUploadQueueAction,
    retryUploadAction,
    setTimeStartedAction,
    setUploadTimeRemainingAction,
    setQueuedUploadBytesRemainingAction,
    setAssetIdAction,
} = uploadManagerSlice.actions;

export const uploadManagerSelector = (reducers) =>
    reducers.uploadManagerReducer;

export default uploadManagerSlice.reducer;
