import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { useThrottledCallback } from 'use-debounce';

import { getFileType } from '../helpers/get-file-type';
import { useConfirmBrowserExit } from '../hooks/use-confirm-browser-exit';
import { documentService, fileUploadService } from '../services';
import { getThumbnail } from '../components/organisms/upload-manager/upload-manager-helpers';
import { errorToast } from '../components/toasts';

const MAX_CONCURRENT_UPLOADS = 10;

const UploadManagerContext = createContext({
    uploads: {},
    enqueueUploads: () => {},
});

function UploadManagerProvider({ children }) {
    const [uploads, setUploads] = useState({});
    const [isExpanded, setIsExpanded] = useState(false);
    const [uploadTimeRemaining, setUploadTimeRemaining] = useState('');
    const throttledSetUploadTimeRemaining = useThrottledCallback(
        setUploadTimeRemaining,
        1000
    );
    const [completedBatchIds, setCompletedBatchIds] = useState([]);
    const [shouldClearUploadQueue, setShouldClearUploadQueue] = useState(false);

    const confirmBrowserExit = useConfirmBrowserExit(
        false,
        'Are you sure you want to leave? Your active uploads will be canceled.'
    );

    const enqueueUploads = useCallback(
        (
            files,
            {
                assetId,
                comment,
                complianceTypeId,
                maintenanceItemId,
                taskItemId,
                contactsBusinessStaffId,
                contactsBusinessId,
                userId,
                requestType,
                onCompleted,
            }
        ) => {
            const newUploads = {};
            const batchId = uuid();

            if (!files || files.length === 0) {
                return;
            }

            files.forEach((file) => {
                const uploadId = uuid();
                newUploads[uploadId] = {
                    id: uploadId,
                    batchId,
                    assetId,
                    file,
                    fileId: null,
                    fileUrl: null,
                    thumbnailId: null,
                    thumbnailUrl: null,
                    fileType: getFileType(file),
                    comment,
                    isInitialising: false,
                    isUploading: false,
                    uploadDone: false,
                    uploadFailed: false,
                    fileController: null,
                    thumbnailController: null,
                    progress: 0,
                    totalBytes: file.size,
                    uploadedBytes: 0,
                    timeStarted: null,
                    complianceTypeId,
                    maintenanceItemId,
                    taskItemId,
                    contactsBusinessStaffId,
                    contactsBusinessId,
                    userId,
                    requestType,
                    onCompleted,
                };
            });

            setUploads((prevUploads) => ({
                ...prevUploads,
                ...newUploads,
            }));
            setIsExpanded(true);
        },
        []
    );

    const uploadDocument = async (
        file,
        fileUrl,
        fileController,
        thumbnail,
        thumbnailFileType,
        isThumbnailBase64,
        thumbnailUrl,
        thumbnailController,
        setUploadProgress
    ) => {
        await Promise.all([
            (async () => {
                if (!thumbnail) {
                    return;
                }
                if (isThumbnailBase64) {
                    await fileUploadService.uploadBase64Data(
                        thumbnail,
                        thumbnailFileType,
                        thumbnailUrl,
                        thumbnailController
                    );
                } else {
                    await fileUploadService.uploadFile(
                        thumbnail,
                        thumbnailUrl,
                        thumbnailController
                    );
                }
            })(),
            (async () => {
                await fileUploadService.uploadFile(
                    file,
                    fileUrl,
                    fileController,
                    setUploadProgress
                );
            })(),
        ]);
    };

    const setUploadProgress = useCallback((uploadId, progressEvent) => {
        setUploads((prevUploads) => {
            const upload = prevUploads[uploadId];
            const progress =
                (_.get(progressEvent, 'loaded') /
                    _.get(progressEvent, 'total')) *
                100;
            const uploadedBytes = _.get(progressEvent, 'loaded');
            const totalBytes = _.get(progressEvent, 'total');

            return {
                ...prevUploads,
                [uploadId]: {
                    ...upload,
                    progress,
                    uploadedBytes,
                    totalBytes,
                    timeFinished: progress === 100 ? Date.now() : null,
                },
            };
        });
    }, []);

    const finaliseUpload = useCallback(async (upload, hasThumbnail) => {
        try {
            await documentService.setDocumentUploadedSuccessfully({
                complianceTypeId: upload.complianceTypeId,
                friendlyName: upload.file.name,
                fileId: upload.fileId,
                thumbnailId: hasThumbnail ? upload.thumbnailId : null,
                notes: upload.comment,
                contactsBusinessStaffId: upload.contactsBusinessStaffId,
                contactsBusinessId: upload.contactsBusinessId,
                userId: upload.userId,
                batchId: upload.batchId,
                requestType: upload.requestType,
                maintenanceItemId: upload.maintenanceItemId,
                taskItemId: upload.taskItemId,
                fileExtension: getFileType(upload.file),
                fileKey: upload.fileKey,
                thumbnailKey: upload.thumbnailKey,
                fileMimeType: upload.fileMimeType,
            });
            upload.uploadDone = true;
            upload.isUploading = false;
        } catch (error) {
            upload.isUploading = false;
            upload.uploadCanceled = false;
            upload.uploadFailed = true;
        }

        setUploads((prevUploads) => ({
            ...prevUploads,
            [upload.id]: {
                ...upload,
            },
        }));
    }, []);

    const startUploads = useCallback(
        async (uploadIds) => {
            await Promise.all(
                uploadIds.map(async (uploadId) => {
                    const upload = uploads[uploadId];
                    const { assetId, file, fileType } = upload;

                    let newUpload = upload;

                    try {
                        setUploads((prevUploads) => ({
                            ...prevUploads,
                            [uploadId]: {
                                ...upload,
                                isInitialising: true,
                            },
                        }));

                        const {
                            file: thumbnail,
                            fileType: thumbnailFileType,
                            isBase64: isThumbnailBase64,
                            extension: thumbnailExtension,
                        } = await getThumbnail(fileType, file);

                        const { ids, keys, urls } =
                            await documentService.getUploadUrl(
                                assetId,
                                file.name,
                                thumbnailExtension
                            );

                        const thumbnailController = new AbortController();
                        const fileController = new AbortController();

                        newUpload = {
                            ...upload,
                            isInitialising: false,
                            uploadFailed: false,
                            uploadCanceled: false,
                            isUploading: true,
                            timeStarted: Date.now(),
                            timeFinished: null,
                            progress: 0,
                            uploadedBytes: 0,
                            fileId: ids.folder,
                            thumbnailId: ids.thumbnail,
                            fileUrl: urls.file,
                            thumbnail: urls.thumbnail,
                            thumbnailController,
                            fileController,
                            fileKey: keys.file,
                            thumbnailKey: keys.thumbnail,
                            fileMimeType: file.type,
                        };

                        setUploads((prevUploads) => ({
                            ...prevUploads,
                            [uploadId]: newUpload,
                        }));

                        await uploadDocument(
                            file,
                            urls.file,
                            fileController,
                            thumbnail,
                            thumbnailFileType,
                            isThumbnailBase64,
                            urls.thumbnail,
                            thumbnailController,
                            (progressEvent) =>
                                setUploadProgress(uploadId, progressEvent)
                        );

                        await finaliseUpload(newUpload, !!thumbnail);
                    } catch (error) {
                        if (!shouldClearUploadQueue) {
                            setUploads((prevUploads) => ({
                                ...prevUploads,
                                [uploadId]: {
                                    ...newUpload,
                                    isUploading: false,
                                    isInitialising: false,
                                    uploadFailed: error.message !== 'canceled',
                                    uploadCanceled:
                                        error.message === 'canceled',
                                },
                            }));
                        }

                        if (error.message !== 'canceled') {
                            const errorMessage = `An error occurred while uploading the documents. Error: ${_.get(
                                error,
                                'response.data.message',
                                'Upload Error'
                            )}`;

                            errorToast(errorMessage);
                        }
                    }
                })
            );
        },
        [shouldClearUploadQueue, finaliseUpload, setUploadProgress, uploads]
    );

    const retryUpload = useCallback(
        (uploadId) => {
            startUploads([uploadId]);
        },
        [startUploads]
    );

    const cancelUpload = useCallback(
        (uploadId) => {
            const upload = uploads[uploadId];
            if (upload.isUploading) {
                upload.fileController?.abort();
                upload.thumbnailController?.abort();
            }
        },
        [uploads]
    );

    const cancelAllUploads = useCallback(
        (clearQueue) => {
            Object.values(uploads).forEach((upload) => {
                if (upload.isUploading) {
                    upload.fileController?.abort();
                    upload.thumbnailController?.abort();
                }
            });
            setShouldClearUploadQueue(clearQueue);
        },
        [uploads]
    );

    const clearUploadQueue = useCallback(() => {
        setShouldClearUploadQueue(true);
    }, []);

    const activeUploadIds = useMemo(
        () =>
            Object.values(uploads)
                .filter((upload) => upload.isUploading || upload.isInitialising)
                .map((upload) => upload.id),
        [uploads]
    );

    const activeUploadsCount = useMemo(
        () => activeUploadIds.length,
        [activeUploadIds]
    );

    const waitingUploadsCount = useMemo(
        () =>
            Object.values(uploads).filter(
                (upload) =>
                    !upload.isUploading &&
                    !upload.uploadDone &&
                    !upload.uploadFailed &&
                    !upload.uploadCanceled
            ).length,
        [uploads]
    );

    const completedUploadsCount = useMemo(
        () =>
            Object.values(uploads).filter((upload) => upload.uploadDone).length,
        [uploads]
    );

    const failedUploadsCount = useMemo(
        () =>
            Object.values(uploads).filter((upload) => upload.uploadFailed)
                .length,
        [uploads]
    );

    const canceledUploadsCount = useMemo(
        () =>
            Object.values(uploads).filter((upload) => upload.uploadCanceled)
                .length,
        [uploads]
    );

    useEffect(() => {
        const activeAndCompletedUploads = Object.values(uploads).filter(
            (upload) => !upload.uploadFailed && !upload.uploadCanceled
        );

        const uploadSpeeds = activeAndCompletedUploads.map((upload) => {
            const toTime = upload.timeFinished ?? Date.now();
            const timeElapsed = toTime - upload.timeStarted;
            return upload.uploadedBytes / (timeElapsed / 1000);
        });

        const bytesPerSecond =
            _.mean(uploadSpeeds) * activeAndCompletedUploads.length;
        const bytesRemaining = activeAndCompletedUploads.reduce(
            (total, upload) => total + upload.totalBytes - upload.uploadedBytes,
            0
        );

        const secondsRemaining = bytesRemaining / bytesPerSecond;

        if (Number.isNaN(secondsRemaining) && bytesRemaining > 0) {
            throttledSetUploadTimeRemaining('Calculating...');
            return;
        }
        if (Number.isNaN(secondsRemaining) && bytesRemaining <= 0) {
            throttledSetUploadTimeRemaining('< 1s');
            return;
        }
        if (secondsRemaining < 60) {
            throttledSetUploadTimeRemaining(`${Math.round(secondsRemaining)}s`);
            return;
        }
        if (secondsRemaining < 3600) {
            throttledSetUploadTimeRemaining(
                `${Math.round(secondsRemaining / 60)}m ${Math.round(
                    secondsRemaining % 60
                )}s`
            );
            return;
        }
        if (secondsRemaining < 86400) {
            throttledSetUploadTimeRemaining(
                `${Math.round(secondsRemaining / 3600)}h ${Math.round(
                    (secondsRemaining % 3600) / 60
                )}m`
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploads, activeUploadsCount]);

    useEffect(() => {
        if (activeUploadIds.length > 0) {
            confirmBrowserExit.enable();
        } else {
            confirmBrowserExit.disable();
        }

        if (
            Object.keys(uploads).length > 0 &&
            activeUploadsCount < MAX_CONCURRENT_UPLOADS
        ) {
            const filteredArray = Object.values(uploads).filter(
                (item) =>
                    !item.isUploading &&
                    !item.isInitialising &&
                    !item.uploadDone &&
                    !item.uploadFailed &&
                    !item.uploadCanceled
            );

            const newUploadsToStart = filteredArray
                .slice(0, MAX_CONCURRENT_UPLOADS - activeUploadsCount)
                .map((item) => item.id);

            if (newUploadsToStart.length > 0) {
                startUploads(newUploadsToStart);
            }
        }
    }, [
        activeUploadIds,
        confirmBrowserExit,
        startUploads,
        uploads,
        activeUploadsCount,
    ]);

    useEffect(() => {
        if (shouldClearUploadQueue && activeUploadsCount <= 0) {
            setUploads({});
            setShouldClearUploadQueue(false);
        }
    }, [activeUploadsCount, shouldClearUploadQueue]);

    useEffect(() => {
        const groupedUploads = _.groupBy(uploads, 'batchId');

        Object.keys(groupedUploads).forEach((batchId) => {
            if (!completedBatchIds.includes(batchId)) {
                const batch = groupedUploads[batchId];
                const batchCompleted = batch.every(
                    (upload) => upload.uploadDone
                );
                if (batchCompleted) {
                    setCompletedBatchIds((prevCompletedBatchIds) => [
                        ...prevCompletedBatchIds,
                        batchId,
                    ]);
                    if (batch[0].onCompleted) {
                        batch[0].onCompleted(batch);
                    }
                }
            }
        });
    }, [completedBatchIds, uploads]);

    const value = useMemo(
        () => ({
            uploads,
            isExpanded,
            activeUploadsCount,
            waitingUploadsCount,
            completedUploadsCount,
            failedUploadsCount,
            canceledUploadsCount,
            uploadTimeRemaining,
            setIsExpanded,
            enqueueUploads,
            retryUpload,
            cancelUpload,
            cancelAllUploads,
            clearUploadQueue,
        }),
        [
            uploads,
            isExpanded,
            activeUploadsCount,
            waitingUploadsCount,
            completedUploadsCount,
            failedUploadsCount,
            canceledUploadsCount,
            uploadTimeRemaining,
            setIsExpanded,
            enqueueUploads,
            retryUpload,
            cancelUpload,
            cancelAllUploads,
            clearUploadQueue,
        ]
    );

    return (
        <UploadManagerContext.Provider value={value}>
            {children}
        </UploadManagerContext.Provider>
    );
}

UploadManagerProvider.propTypes = {
    children: PropTypes.element.isRequired,
};

const useUploadManager = () => useContext(UploadManagerContext);

export { useUploadManager, UploadManagerProvider };
