import { createSlice } from '@reduxjs/toolkit';
import { userModel } from '../../models/user-management.models';

const initialState = {
    users: [],
    roles: [],
    properties: [],
    propertyGroups: [],
};

const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        setUsersAction(state, { payload }) {
            state.users = payload.map((user) => userModel(user));
        },
        setRolesAction(state, { payload }) {
            state.roles = payload;
        },
        setPropertiesAction(state, { payload }) {
            state.properties = payload;
        },
        setPropertyGroupsAction(state, { payload }) {
            state.propertyGroups = payload;
        },
    },
});

export const {
    setUsersAction,
    setRolesAction,
    setPropertiesAction,
    setPropertyGroupsAction,
} = userManagementSlice.actions;

export const userManagementSelector = (reducers) =>
    reducers.userManagementReducer;

export default userManagementSlice.reducer;
