import React, { useCallback, useState, useMemo, useEffect } from 'react';
import {
    Button,
    SelectField,
    TextInputField,
    Pane,
    Text,
    ErrorIcon,
    Radio,
    SelectMenu,
} from 'evergreen-ui';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { PortfolioTypeSelect } from '../../molecules';
import { Collapsible } from '../../atoms';
import LinkSelectField from '../../molecules/link-select-field';
import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';
import { taskSelector } from '../../../reducers/task-reducer/task.reducer';
import { getAvailableAssignees } from '../../../reducers/task-reducer/task.actions';
import { contactsSelector } from '../../../reducers/contacts-reducer/contacts.reducer';
import { getAllContactsForBusiness } from '../../../reducers/contacts-reducer/contacts.actions';
import ContactsSidebar from '../../contacts/contacts-sidebar';

const PROPERTY_TYPES = [
    { label: 'Park', value: 'park' },
    { label: 'Building', value: 'building' },
    { label: 'Tenant', value: 'tenant' },
];

const RadioOptions = ({ options, onChange, setValues, values }) => {
    const { currentAsset, currentBuilding } = useSelector(assetSelector);

    const disabled = useMemo(() => {
        if (currentBuilding) {
            setValues({
                ...values,
                propertyType: 'tenant',
                parentId: currentBuilding?.id,
            });
            return { park: true, building: true, tenant: false };
        }
        if (currentAsset) {
            setValues({
                ...values,
                propertyType: 'building',
                parentId: currentAsset?.id,
            });
            return { park: true, building: false, tenant: true };
        }

        return { park: false, building: false, tenant: false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAsset, currentBuilding, setValues]);

    return (
        <Pane display="flex" gap={16}>
            {options.map(({ label, value }) => (
                <Radio
                    checked={values.propertyType === value}
                    onChange={onChange}
                    size={16}
                    value={value}
                    label={label}
                    disabled={disabled[value]}
                />
            ))}
        </Pane>
    );
};

RadioOptions.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onChange: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    values: PropTypes.shape({
        propertyType: PropTypes.string,
    }).isRequired,
};

const AssetBasicDetailsForm = ({
    initialValues,
    handleFormSubmission,
    openUserManagementSidebar,
}) => {
    const dispatch = useDispatch();
    const { contacts } = useSelector(contactsSelector);

    const validationSchema = Yup.object().shape({
        propertyType: Yup.string().required('Property type is required'),
        buildingUse: Yup.string().required('Building use is required'),
        name: Yup.string().required('Building name is required'),
        erfNumber: Yup.string(),
        buildingManagerIds: Yup.array(Yup.number()).min(
            1,
            'At least one manager is required'
        ),
        permissibleCoverage: Yup.string(),
        builtCoverage: Yup.string(),
        permissibleFar: Yup.string(),
        builtFar: Yup.string(),
        grossLettableArea: Yup.string(),
        grossBuildingArea: Yup.string(),
        storey: Yup.string(),
    });

    const [isMoreFieldsVisible, setIsMoreFieldsVisible] = useState(false);
    const [addContact, setAddContact] = useState(false);

    const { assignees } = useSelector(taskSelector);

    const toggleMoreFieldsVisible = () => {
        setIsMoreFieldsVisible(!isMoreFieldsVisible);
    };

    const handlePropertyType = useCallback(
        (setValues, values, value) =>
            setValues({ ...values, propertyType: value, parentId: '' }),
        []
    );

    const handleLinkedProperty = (setValues, values, value) =>
        setValues({ ...values, parentId: value });

    const inputLabelType = useCallback((type) => {
        const labels = PROPERTY_TYPES.map(({ label }) => label);

        const label = labels.find((_label) => _label.toLowerCase() === type);
        return label;
    }, []);

    const assigneeOptions = useMemo(
        () => [
            ...assignees.map(({ id, name: label }) => ({
                value: Number(id),
                label,
            })),
            { value: 'add', label: 'Add New User' },
        ],
        [assignees]
    );

    const selectedBuildingManagerIdsText = useCallback(
        ({ buildingManagerIds }) =>
            buildingManagerIds.length
                ? assigneeOptions
                      .map(({ value, label }) =>
                          buildingManagerIds.includes(Number(value))
                              ? label
                              : null
                      )
                      .filter((value) => !!value)
                      .join(', ')
                : 'Select Property Manager(s)',
        [assigneeOptions]
    );

    const onPropertyManagerSelect = ({ value }, setValues, values) => {
        if (value === 'add') {
            openUserManagementSidebar();
            return;
        }
        setValues({
            ...values,
            buildingManagerIds: [...values.buildingManagerIds, Number(value)],
        });
    };

    const onPropertyManagerDeselect = ({ value }, setValues, values) => {
        setValues({
            ...values,
            buildingManagerIds: values.buildingManagerIds.filter(
                (id) => Number(id) !== Number(value)
            ),
        });
    };

    useEffect(() => {
        dispatch(getAvailableAssignees());
        dispatch(getAllContactsForBusiness());
    }, [dispatch]);

    const handleLandlordChange = (value, setFieldValue) => {
        if (value === 'new') {
            setAddContact(true);
            setFieldValue('landlordContactId', '');
        } else {
            setFieldValue('landlordContactId', Number(value));
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmission}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setValues,
                    setFieldValue,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Pane marginBottom={16}>
                            <Text lineHeight="18px" color="#101840">
                                Property Type *
                            </Text>
                            <RadioOptions
                                options={PROPERTY_TYPES}
                                values={values}
                                onChange={({ target: { value } }) =>
                                    handlePropertyType(setValues, values, value)
                                }
                                setValues={setValues}
                            />
                            {errors.propertyType && (
                                <Text
                                    lineHeight="18px"
                                    fontSize="12px"
                                    color="#D14343"
                                >
                                    <ErrorIcon size={14} marginRight="6px" />
                                    {errors.propertyType}
                                </Text>
                            )}
                        </Pane>
                        <LinkSelectField
                            parentId={values.parentId}
                            propertyType={values.propertyType}
                            handleLinkedProperty={({ target: { value } }) =>
                                handleLinkedProperty(setValues, values, value)
                            }
                        />
                        <PortfolioTypeSelect
                            name="buildingUse"
                            value={values.buildingUse}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showAllOptions
                            label={`${inputLabelType(values.propertyType)} Use`}
                        />
                        <TextInputField
                            label={`${inputLabelType(
                                values.propertyType
                            )} Name`}
                            type="text"
                            name="name"
                            placeholder="My Building"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.name && errors.name}
                            validationMessage={touched.name && errors.name}
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            required
                        />
                        <TextInputField
                            label="Erf. Number"
                            type="text"
                            name="erfNumber"
                            placeholder="Erf. Number"
                            value={values.erfNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.erfNumber && errors.erfNumber}
                            validationMessage={
                                touched.erfNumber && errors.erfNumber
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                        <Pane display="flex" flexDirection="column">
                            <Text color="#101840" fontWeight={500}>
                                Property Manager(s) *
                            </Text>
                            <SelectMenu
                                isMultiSelect
                                options={assigneeOptions}
                                hasTitle={false}
                                filterPlaceholder="Search Users"
                                onSelect={(propertyManager) =>
                                    onPropertyManagerSelect(
                                        propertyManager,
                                        setValues,
                                        values
                                    )
                                }
                                onDeselect={(propertyManager) =>
                                    onPropertyManagerDeselect(
                                        propertyManager,
                                        setValues,
                                        values
                                    )
                                }
                                selected={values.buildingManagerIds}
                                validationMessage={errors.buildingManagerIds}
                            >
                                <Button
                                    type="button"
                                    height={40}
                                    marginY={12}
                                    marginBottom={
                                        errors.buildingManagerIds ? 8 : 20
                                    }
                                    justifyContent="start"
                                >
                                    {selectedBuildingManagerIdsText(values)}
                                </Button>
                            </SelectMenu>
                            {errors.buildingManagerIds && (
                                <Text
                                    lineHeight="18px"
                                    fontSize="12px"
                                    color="#D14343"
                                    marginBottom={12}
                                >
                                    <ErrorIcon size={14} marginRight="6px" />{' '}
                                    {errors.buildingManagerIds}
                                </Text>
                            )}
                        </Pane>

                        <SelectField
                            name="landlordContactId"
                            width="100%"
                            inputHeight={40}
                            label="Tenant"
                            onBlur={handleBlur}
                            isInvalid={
                                touched.landlordContactId &&
                                errors.landlordContactId
                            }
                            onChange={(e) =>
                                handleLandlordChange(
                                    e.target.value,
                                    setFieldValue
                                )
                            }
                            value={values.landlordContactId}
                            validationMessage={
                                touched.landlordContactId &&
                                errors.landlordContactId
                                    ? errors.landlordContactId
                                    : undefined
                            }
                        >
                            <option selected value="" disabled>
                                Select Tenant
                            </option>
                            {contacts ? (
                                contacts.map(
                                    ({
                                        createdAt,
                                        firstName,
                                        lastName,
                                        profession,
                                        companyName,
                                        id,
                                    }) => (
                                        <option key={createdAt} value={id}>
                                            {`${firstName} ${lastName} - ${profession} - ${companyName}`}
                                        </option>
                                    )
                                )
                            ) : (
                                <option disabled>No Tenants Available</option>
                            )}
                            <option value="new">+ Add Tenant</option>
                        </SelectField>

                        <MoreButton onClick={toggleMoreFieldsVisible}>
                            {isMoreFieldsVisible ? 'Hide' : 'Show'} Optional
                            Fields
                        </MoreButton>
                        <Collapsible isOpen={isMoreFieldsVisible}>
                            <TextInputField
                                label="Permissible Coverage"
                                type="text"
                                name="permissibleCoverage"
                                placeholder="Permissible Coverage"
                                value={values.permissibleCoverage}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    touched.permissibleCoverage &&
                                    errors.permissibleCoverage
                                }
                                validationMessage={
                                    touched.permissibleCoverage &&
                                    errors.permissibleCoverage
                                }
                                spellCheck={false}
                                width="100%"
                                inputHeight={40}
                            />
                            <TextInputField
                                label="Built Coverage"
                                type="text"
                                name="builtCoverage"
                                placeholder="Built Coverage"
                                value={values.builtCoverage}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    touched.builtCoverage &&
                                    errors.builtCoverage
                                }
                                validationMessage={
                                    touched.builtCoverage &&
                                    errors.builtCoverage
                                }
                                spellCheck={false}
                                width="100%"
                                inputHeight={40}
                            />
                            <TextInputField
                                label="Permissible FAR"
                                description="Floor Area Ratio"
                                type="text"
                                name="permissibleFar"
                                placeholder="Permissible FAR"
                                value={values.permissibleFar}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    touched.permissibleFar &&
                                    errors.permissibleFar
                                }
                                validationMessage={
                                    touched.permissibleFar &&
                                    errors.permissibleFar
                                }
                                spellCheck={false}
                                width="100%"
                                inputHeight={40}
                            />
                            <TextInputField
                                label="Built FAR"
                                description="Floor Area Ratio"
                                type="text"
                                name="builtFar"
                                placeholder="Built FAR"
                                value={values.builtFar}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.builtFar && errors.builtFar}
                                validationMessage={
                                    touched.builtFar && errors.builtFar
                                }
                                spellCheck={false}
                                width="100%"
                                inputHeight={40}
                            />
                            <TextInputField
                                label="GLA"
                                description="Gross Lettable Area"
                                type="text"
                                name="grossLettableArea"
                                placeholder="GLA"
                                value={values.grossLettableArea}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    touched.grossLettableArea &&
                                    errors.grossLettableArea
                                }
                                validationMessage={
                                    touched.grossLettableArea &&
                                    errors.grossLettableArea
                                }
                                spellCheck={false}
                                width="100%"
                                inputHeight={40}
                            />
                            <TextInputField
                                label="GBA"
                                description="Gross Building Area"
                                type="text"
                                name="grossBuildingArea"
                                placeholder="GBA"
                                value={values.grossBuildingArea}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    touched.grossBuildingArea &&
                                    errors.grossBuildingArea
                                }
                                validationMessage={
                                    touched.grossBuildingArea &&
                                    errors.grossBuildingArea
                                }
                                spellCheck={false}
                                width="100%"
                                inputHeight={40}
                            />
                            <SelectField
                                name="storey"
                                width="100%"
                                inputHeight={40}
                                label="Storey"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.storey}
                                isInvalid={touched.storey && errors.storey}
                                validationMessage={
                                    touched.storey && errors.storey
                                }
                            >
                                <option value="" disabled>
                                    Select storey
                                </option>
                                <option value="single">Single</option>
                                <option value="multi">Multi</option>
                            </SelectField>
                        </Collapsible>
                        <Button
                            appearance="primary"
                            height={40}
                            type="submit"
                            width="100%"
                            marginTop={12}
                        >
                            Next
                        </Button>
                    </Form>
                )}
            </Formik>
            <ContactsSidebar
                isShown={addContact}
                isEditing={false}
                canChooseBusiness
                close={() => setAddContact(false)}
            />
        </>
    );
};

AssetBasicDetailsForm.propTypes = {
    initialValues: PropTypes.shape({}).isRequired,
    openUserManagementSidebar: PropTypes.func.isRequired,
    handleFormSubmission: PropTypes.func.isRequired,
};

export default AssetBasicDetailsForm;

const MoreButton = styled.div`
    margin-bottom: 12px;
    color: #c1c4d6;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
