import { get, map } from 'lodash';

/* eslint-disable import/prefer-default-export */
export const userModels = (userObject = {}) => ({
    id: get(userObject, 'id', ''),
    email: get(userObject, 'email', ''),
    firstName: get(userObject, 'name', ''),
    lastName: get(userObject, 'surname', ''),
    mobileNumber: get(userObject, 'mobileno', ''),
    userAccess: {
        id: get(userObject, 'access_of_user.id', ''),
        role: get(userObject, 'access_of_user.role', ''),
        tenantId: get(userObject, 'access_of_user.tenant_id', ''),
        businessId: get(userObject, 'access_of_user.business_id', ''),
        userRoleId: get(userObject, 'access_of_user.user_roles_id', ''),
        propertyGroupsIds: get(
            userObject,
            'access_of_user.property_groups_id',
            []
        ),
        buildingIds: get(userObject, 'access_of_user.buildings_id', []),
        business: {
            id: get(userObject, 'access_of_user.business.id', ''),
            createdAt: get(
                userObject,
                'access_of_user.business.created_at',
                ''
            ),
            legalName: get(userObject, 'access_of_user.business.legalname', ''),
            tenantId: get(userObject, 'access_of_user.business.tenant_id', ''),
            friendlyName: get(
                userObject,
                'access_of_user.business.friendlyname',
                ''
            ),
            officeNumber: get(
                userObject,
                'access_of_user.business.officenumber',
                ''
            ),
            officeMobile: get(
                userObject,
                'access_of_user.business.officemobile',
                ''
            ),
            email: get(userObject, 'access_of_user.business.email', ''),
            regNumber: get(userObject, 'access_of_user.business.regnumber', ''),
            vatNumber: get(userObject, 'access_of_user.business.vatnumber', ''),
            billingContactName: get(
                userObject,
                'access_of_user.business.billing_contactname',
                ''
            ),
            billingContactEmail: get(
                userObject,
                'access_of_user.business.billing_contactemail',
                ''
            ),
            portfolioIds: get(
                userObject,
                'access_of_user.business.portfolio_id',
                []
            ),
            userIds: get(userObject, 'access_of_user.business.user_id', []),
            address: {
                addressLine1: get(
                    userObject,
                    'access_of_user.business.address.address1',
                    ''
                ),
                addressLine2: get(
                    userObject,
                    'access_of_user.business.address.address2',
                    ''
                ),
                suburb: get(
                    userObject,
                    'access_of_user.business.address.suburb',
                    ''
                ),
                city: get(
                    userObject,
                    'access_of_user.business.address.city',
                    ''
                ),
                province: get(
                    userObject,
                    'access_of_user.business.address.province',
                    ''
                ),
                postalCode: get(
                    userObject,
                    'access_of_user.business.address.postalcode',
                    ''
                ),
                country: get(
                    userObject,
                    'access_of_user.business.address.country',
                    ''
                ),
            },
        },
        userRoles: {
            id: get(userObject, 'access_of_user.user_roles.id'),
            createdAt: get(userObject, 'access_of_user.user_roles.created_at'),
            name: get(userObject, 'access_of_user.user_roles.name'),
            tenantId: get(userObject, 'access_of_user.user_roles.tenant_id'),
            access: map(
                get(userObject, 'access_of_user.user_roles.access', []),
                (access) => ({
                    item: get(access, 'item', ''),
                    create: get(access, 'create', false),
                    read: get(access, 'read', false),
                    update: get(access, 'update', false),
                    delete: get(access, 'delete', false),
                })
            ),
        },
    },
});
