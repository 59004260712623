import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
    Button,
    Dialog,
    Pane,
    PlusIcon,
    Text,
    Tooltip,
    InfoSignIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, get } from 'lodash';

import RolesItem from './roles-item';
import { userManagementSelector } from '../../reducers/user-management-reducer/user-management.reducer';
import PageLoading from '../molecules/page-loading';
import {
    createRole,
    deleteRole,
    getRoles,
} from '../../reducers/user-management-reducer/user-management.actions';
import userManagementService from '../../services/sub-services/user-management-service/user-management.service';
import AddRoleForm from './add-role-form';
import { errorToast, successToast } from '../toasts';
import { useUserAccess } from '../../context/user-access-context';

const RIGHTS = {
    create: 'Create',
    read: 'Read',
    update: 'Update',
    delete: 'Delete',
};

const MODULES = [
    {
        name: 'template-management',
        rights: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
    },
    {
        name: 'user-management',
        rights: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
    },
    {
        name: 'portfolio',
        rights: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
    },
    {
        name: 'info',
        rights: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
    },
    {
        name: 'compliance',
        rights: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
    },
    {
        name: 'maintenance',
        rights: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
    },
    {
        name: 'tasks',
        rights: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
    },
    {
        name: 'contacts',
        rights: {
            create: false,
            read: false,
            update: false,
            delete: false,
        },
    },
];

function UserRolesDialog({ isShown, close }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [isAddingRole, setIsAddingRole] = useState(false);
    const [deleteRoleModal, setDeleteRoleModal] = useState({
        isDeletingRole: false,
        role: null,
    });
    const [modules, setModules] = useState(MODULES);
    const [name, setName] = useState('');

    const { currentRights } = useUserAccess();

    const { create } = useMemo(
        () => currentRights || { create: false },
        [currentRights]
    );

    const handleChange = (moduleName, right) => {
        setModules((state) => {
            const moduleIndex = state.findIndex(
                (_module) => _module.name === moduleName
            );

            if (moduleIndex < 0) {
                throw Error('Module not Found');
            }
            let module = cloneDeep(state[moduleIndex]);

            if (right === 'read') {
                if (module.rights.read) {
                    module = {
                        ...module,
                        rights: {
                            create: false,
                            read: false,
                            update: false,
                            delete: false,
                        },
                    };
                } else {
                    module = {
                        ...module,
                        rights: {
                            ...module.rights,
                            read: true,
                        },
                    };
                }
            } else if (right === 'create') {
                if (module.rights.create) {
                    module = {
                        ...module,
                        rights: {
                            ...module.rights,
                            create: false,
                        },
                    };
                } else {
                    module = {
                        ...module,
                        rights: {
                            ...module.rights,
                            create: true,
                            update: true,
                            read: true,
                        },
                    };
                }
            } else if (!(module.rights[right] && module.rights.read)) {
                module = {
                    ...module,
                    rights: {
                        ...module.rights,
                        [right]: true,
                        read: true,
                    },
                };
            } else {
                module = {
                    ...module,
                    rights: {
                        ...module.rights,
                        [right]: !module.rights[right],
                    },
                };
            }

            return state.map((_module) => {
                if (_module.name === moduleName) {
                    return module;
                }
                return _module;
            });
        });
    };

    const isNoRightsChecked = useMemo(() => {
        let valuesArray = [];
        modules.forEach((module) => {
            valuesArray = [...valuesArray, ...Object.values(module.rights)];
        });
        return !valuesArray.some((value) => !!value);
    }, [modules]);

    const { roles } = useSelector(userManagementSelector);

    const dispatch = useDispatch();

    const getData = useCallback(async () => {
        try {
            await dispatch(getRoles());
        } catch (error) {
            throw Error("Couldn't fetch roles");
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    const submitRoles = useCallback(
        async () =>
            Promise.all(
                roles.map(async (role) => userManagementService.editRole(role))
            ),
        [roles]
    );

    const onClose = () => {
        setModules(MODULES);
        close();
    };

    const handleConfirm = useCallback(async () => {
        setIsConfirmLoading(true);
        try {
            if (isAddingRole) {
                await dispatch(createRole({ name, access: modules }));
                setName('');
                setModules(MODULES);
                setIsAddingRole(false);
                successToast(`Successfully created new role "${name}"`);
            } else if (deleteRoleModal.isDeletingRole) {
                await dispatch(deleteRole(deleteRoleModal.role?.id));
                successToast(
                    `Successfully deleted the role ${deleteRoleModal.role?.name}`
                );
                setDeleteRoleModal({
                    isDeletingRole: false,
                    role: null,
                });
            } else {
                await submitRoles();
                close();
            }
        } catch (error) {
            const errorMessage = `An Error Occurred: ${get(
                error,
                'response.data.message',
                `Failed to ${isAddingRole ? 'create' : 'delete'} role`
            )}`;
            errorToast(errorMessage);
        } finally {
            setIsConfirmLoading(false);
        }
    }, [
        isAddingRole,
        deleteRoleModal,
        dispatch,
        name,
        modules,
        submitRoles,
        close,
    ]);

    const handleCancel = useCallback(() => {
        if (deleteRoleModal.isDeletingRole) {
            setDeleteRoleModal({
                isDeletingRole: false,
                role: null,
            });
        }
        if (isAddingRole) {
            setIsAddingRole(false);
            setModules(MODULES);
        }
        if (!(deleteRoleModal.isDeletingRole || isAddingRole)) {
            close();
        }
    }, [close, isAddingRole, deleteRoleModal]);

    const renderContent = useCallback(() => {
        if (isLoading) {
            return (
                <Pane marginY={30}>
                    <PageLoading />
                </Pane>
            );
        }
        if (isAddingRole) {
            return (
                <AddRoleForm
                    RIGHTS={RIGHTS}
                    name={name}
                    setName={setName}
                    modules={modules}
                    handleChange={handleChange}
                />
            );
        }
        if (deleteRoleModal.isDeletingRole) {
            return (
                <Pane marginTop={20}>
                    <Text fontSize={18}>
                        Are you sure you want to delete the role{' '}
                        <strong style={{ textTransform: 'capitalize' }}>
                            {deleteRoleModal.role?.name}
                        </strong>
                        ?
                    </Text>
                </Pane>
            );
        }
        if (!roles.length) {
            return <Text>No Roles</Text>;
        }

        return (
            <Pane>
                {create && (
                    <Button
                        iconBefore={PlusIcon}
                        marginBottom={6}
                        onClick={() => setIsAddingRole(true)}
                    >
                        Add Role
                    </Button>
                )}
                {roles.map((role) => (
                    <RolesItem
                        key={role.id}
                        role={role}
                        RIGHTS={RIGHTS}
                        setDeleteRoleModal={setDeleteRoleModal}
                        currentRights={currentRights}
                    />
                ))}
            </Pane>
        );
    }, [
        isAddingRole,
        deleteRoleModal,
        isLoading,
        modules,
        name,
        roles,
        create,
        currentRights,
    ]);

    const heading = useMemo(() => {
        if (isAddingRole) {
            return 'Create Role';
        }
        if (deleteRoleModal.isDeletingRole) {
            return 'Delete Role';
        }
        return 'User Roles';
    }, [deleteRoleModal.isDeletingRole, isAddingRole]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <Dialog
            isShown={isShown}
            onCloseComplete={onClose}
            title={
                <Pane display="flex" alignItems="center" gap={6}>
                    <Text fontSize={18} fontWeight={700}>
                        {heading}
                    </Text>
                    <Tooltip
                        content="Here you can create a group of users who share the same role (Some Examples: Tenants, Super Admins, Operations Managers, Regional Managers, Etc.). Provide a descriptive name that represents the function of the grouped users."
                        showDelay={250}
                    >
                        <InfoSignIcon color="#444a61" size={12} />
                    </Tooltip>
                </Pane>
            }
            width={750}
            isConfirmLoading={isConfirmLoading}
            isConfirmDisabled={isAddingRole && (isNoRightsChecked || !name)}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            intent={deleteRoleModal.isDeletingRole ? 'danger' : 'none'}
            hasClose={false}
        >
            {renderContent()}
        </Dialog>
    );
}

UserRolesDialog.propTypes = {
    isShown: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
};

export default UserRolesDialog;
