import React, { useMemo, useState } from 'react';
import {
    Pane,
    Text,
    ChevronDownIcon,
    TrashIcon,
    NewPersonIcon,
    Button,
    EditIcon,
} from 'evergreen-ui';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { colors } from '../../theme/theme';
import { businessContactModel } from '../../models/contact.models';
import ContactCard from './contact-card';
import { contactsSelector } from '../../reducers/contacts-reducer/contacts.reducer';
import { Collapsible } from '../atoms';
import { useUserAccess } from '../../context/user-access-context';

function ContactStepper({
    business,
    handleEditBusinessContact,
    handleAddStaffContact,
    handleEditStaffContact,
    openConfirmModal,
    openRelativeBuildingsModal,
}) {
    const [showContacts, setShowContacts] = useState(false);
    const { professionFilter, search } = useSelector(contactsSelector);
    const { currentRights } = useUserAccess();

    const {
        create,
        update,
        delete: _delete,
    } = useMemo(
        () =>
            currentRights || {
                create: false,
                update: false,
                delete: false,
            },
        [currentRights]
    );

    const {
        contacts: staffContacts,
        mobileNumber,
        email,
        name,
        relativeBuildings,
    } = businessContactModel(business);

    const relativeBuildingsCount = useMemo(() => {
        const buildings = [];
        relativeBuildings.forEach(({ building }) => {
            const buildingIndex = buildings.findIndex(
                (_building) => Number(_building?.id) === Number(building?.id)
            );
            if (buildingIndex < 0) {
                if (building) {
                    buildings.push(building);
                }
            }
        });
        return buildings.length;
    }, [relativeBuildings]);

    const contacts = useMemo(() => {
        if (professionFilter) {
            return staffContacts.filter(
                (contact) => contact?.profession === professionFilter
            );
        }
        if (search) {
            const searchValue = search?.toLowerCase().replace(':*', '');
            return staffContacts.filter(
                (contact) =>
                    contact?.firstName?.toLowerCase().includes(searchValue) ||
                    contact?.lastName?.toLowerCase().includes(searchValue) ||
                    contact?.email?.toLowerCase().includes(searchValue) ||
                    contact?.mobileNumber
                        ?.toLowerCase()
                        .includes(searchValue) ||
                    contact?.profession?.toLowerCase().includes(searchValue) ||
                    contact?.registrationNumber
                        ?.toLowerCase()
                        .includes(searchValue)
            );
        }
        return staffContacts;
    }, [staffContacts, professionFilter, search]);

    return (
        <ContactContainer width="100%" showContacts={showContacts}>
            <Pane
                display="flex"
                justifyItems="space-evenly"
                alignItems="center"
                maxWidth="100%"
                flex={1}
                cursor="pointer"
                position="relative"
                onClick={() => setShowContacts(!showContacts)}
                paddingY={10}
                minHeight="80px"
                elevation={showContacts ? 1 : 0}
            >
                <Pane display="flex" minWidth={160} flex={1}>
                    <Pane
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        paddingLeft={15}
                    >
                        <ChevronDownIcon
                            marginRight={10}
                            className="icon"
                            transform={showContacts && 'rotate(90deg)'}
                            transition="transform 250ms"
                        />
                    </Pane>
                    <Pane
                        display="flex"
                        flex={1}
                        flexDirection="row"
                        minWidth={178}
                    >
                        <Pane
                            display="flex"
                            flexDirection="column"
                            paddingLeft={15}
                        >
                            <Text
                                textTransform="capitalize"
                                fontWeight={600}
                                color={colors.primary500}
                            >
                                {name}
                            </Text>
                        </Pane>
                    </Pane>
                </Pane>
                <Pane minWidth={160} display="flex" flex={1} paddingX={15}>
                    <Pane
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        gap={4}
                    >
                        <Text color="#3A3541DE">{email}</Text>
                    </Pane>
                </Pane>
                <Pane minWidth={160} flex={1} paddingLeft={15}>
                    <Text>{mobileNumber}</Text>
                </Pane>

                <Pane
                    display="flex"
                    minWidth={160}
                    flex={1}
                    paddingLeft={15}
                    justifyContent="center"
                >
                    <Pane
                        borderRadius="35%"
                        color="blue"
                        marginRight={50}
                        paddingY={2}
                        paddingX={8}
                        background="#d6e0ff"
                        role="button"
                        onClick={(e) =>
                            openRelativeBuildingsModal(
                                e,
                                name,
                                relativeBuildings
                            )
                        }
                    >
                        <Text fontSize={12} fontWeight={700} color="#2952cc">
                            {relativeBuildingsCount}
                        </Text>
                    </Pane>
                </Pane>

                {(update || _delete) && (
                    <Pane
                        display="flex"
                        paddingX={8}
                        flex={1}
                        justifyContent="center"
                        marginLeft="auto"
                        maxWidth={100}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Pane gap={5} marginLeft="auto" className="options">
                            {update && (
                                <Pane display="flex" gap={10}>
                                    <Pane
                                        alignItems="center"
                                        justifyContent="center"
                                        padding={12}
                                        className="edit-contact"
                                        hoverElevation={1}
                                        borderRadius="4px"
                                        role="button"
                                        background="#f5f6fa"
                                        onClick={() =>
                                            handleEditBusinessContact({
                                                ...businessContactModel(
                                                    business
                                                ),
                                            })
                                        }
                                    >
                                        <EditIcon
                                            color="#696f8c"
                                            className="edit-contact"
                                        />
                                    </Pane>
                                </Pane>
                            )}
                            {_delete && (
                                <Pane display="flex" gap={10}>
                                    <Pane
                                        alignItems="center"
                                        justifyContent="center"
                                        padding={12}
                                        className="delete-contact"
                                        background="#f5f6fa"
                                        hoverElevation={1}
                                        borderRadius="4px"
                                        role="button"
                                        onClick={() =>
                                            openConfirmModal({
                                                ...businessContactModel(
                                                    business
                                                ),
                                            })
                                        }
                                    >
                                        <TrashIcon
                                            color="#c60b0b"
                                            className="delete-contact"
                                        />
                                    </Pane>
                                </Pane>
                            )}
                        </Pane>
                    </Pane>
                )}
            </Pane>
            <Collapsible isOpen={showContacts}>
                <Pane
                    paddingLeft={55}
                    position="relative"
                    marginY={showContacts ? 10 : 0}
                    maxHeight="500px"
                    overflowY="auto"
                    display="flex"
                    flexDirection="column"
                    gap={10}
                >
                    {create && (
                        <Button
                            marginY={10}
                            marginTop={15}
                            width={120}
                            iconAfter={<NewPersonIcon />}
                            onClick={() => handleAddStaffContact(business)}
                        >
                            Add Contact
                        </Button>
                    )}
                    <Pane
                        display="flex"
                        flexDirection="column"
                        gap={15}
                        paddingRight={15}
                    >
                        {contacts.length >= 1 &&
                            contacts.map((contact) => (
                                <ContactCard
                                    key={contact.id}
                                    handleEditStaffContact={
                                        handleEditStaffContact
                                    }
                                    {...contact}
                                    openConfirmModal={openConfirmModal}
                                    rights={currentRights}
                                />
                            ))}
                    </Pane>
                </Pane>
            </Collapsible>
        </ContactContainer>
    );
}

const ContactContainer = styled(Pane)`
    transition: all 300ms ease 0;
    .options {
        display: none;
        flex-direction: row;
    }
    &:hover {
        .options {
            display: flex;
        }
    }
    .icon {
        transform: rotate(
            ${({ showContacts }) => (!showContacts ? '-90deg' : '0deg')}
        );
    }
`;

ContactStepper.propTypes = {
    business: PropTypes.shape({
        id: PropTypes.string,
        contacts: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    handleEditBusinessContact: PropTypes.func.isRequired,
    handleAddStaffContact: PropTypes.func.isRequired,
    handleEditStaffContact: PropTypes.func.isRequired,
    openConfirmModal: PropTypes.func.isRequired,
    openRelativeBuildingsModal: PropTypes.func.isRequired,
};

export default ContactStepper;
