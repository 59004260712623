import { defaultTheme } from 'evergreen-ui';

export const colors = {
    ...defaultTheme.colors,
    primary500: '#0C2138',
    secondary500: '#5EC090',
    secondary600: '#46b68d',
    secondary700: '#3aa37c',
    neutral300: '#F2F4FA',
    neutral500: '#C1C4D6',
    neutral700: '#696F8C',
    transparent: 'transparent',
    white: '#ffffff',
    black50: 'rgba(0, 0, 0, 0.5)',
};

const theme = {
    ...defaultTheme,
    fontFamilies: {
        ...defaultTheme.fontFamilies,
        display: "'Open Sans', sans-serif",
        ui: "'Open Sans', sans-serif",
    },
    colors: {
        ...defaultTheme.colors,
        blue500: colors.secondary500,
        blue600: colors.secondary600,
        blue700: colors.secondary700,
    },
};

export default theme;
