import React from 'react';
import { CrossIcon, Pane, TickIcon } from 'evergreen-ui';
import PropTypes from 'prop-types';
import EnvelopeIcon from '../../assets/images/icons/envelope.svg';
import { Image } from '../atoms';

const ComplianceTypeStatusIcon = ({ type }) => {
    if (type.uploadRequest) {
        return (
            <Pane marginRight={12} display="flex" justifyContent="center">
                <Image src={EnvelopeIcon} width={16} height={16} />
            </Pane>
        );
    }

    if (type.completed) {
        return <TickIcon color="success" marginRight={12} />;
    }

    return <CrossIcon color="danger" marginRight={12} />;
};

ComplianceTypeStatusIcon.propTypes = {
    type: PropTypes.shape({
        uploadRequest: PropTypes.shape({}),
        completed: PropTypes.bool,
    }).isRequired,
};

export default ComplianceTypeStatusIcon;
