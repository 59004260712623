import _ from 'lodash';
import { assetModel } from './asset.models';
import { getMaintenanceItemModel } from './maintenance.models';

export const createUploadRequestApiModel = (
    createUploadRequestApiObject = {}
) => ({
    compliance_type_id: _.get(
        createUploadRequestApiObject,
        'complianceTypeId',
        ''
    ),
    note: _.get(createUploadRequestApiObject, 'note', ''),
    contacts_business_staff_id: _.get(
        createUploadRequestApiObject,
        'contactId',
        ''
    ),
    asset_id: _.get(createUploadRequestApiObject, 'assetId', ''),
    maintenance_items_id: _.get(
        createUploadRequestApiObject,
        'maintenanceItemId',
        ''
    ),
    task_items_id: _.get(createUploadRequestApiObject, 'taskItemId', ''),
    request_type: _.get(
        createUploadRequestApiObject,
        'requestType',
        'compliance'
    ),
});

export const uploadRequestsModel = (uploadRequestsObject) =>
    _.map(uploadRequestsObject, (uploadRequest) =>
        uploadRequestModel(uploadRequest)
    );

export const uploadRequestModel = (uploadRequestObject) => ({
    id: _.get(uploadRequestObject, 'id', ''),
    createdAt: _.get(uploadRequestObject, 'created_at', ''),
    contact: {
        id: _.get(uploadRequestObject, 'contacts_business_staff.id', {}),
        email: _.get(uploadRequestObject, 'contacts_business_staff.email', {}),
        firstName: _.get(
            uploadRequestObject,
            'contacts_business_staff.name',
            {}
        ),
        lastName: _.get(
            uploadRequestObject,
            'contacts_business_staff.surname',
            {}
        ),
        mobileNumber: _.get(
            uploadRequestObject,
            'contacts_business_staff.mobileno',
            {}
        ),
        professionalCode: _.get(
            uploadRequestObject,
            'contacts_business_staff.profesional_code',
            {}
        ),
        profession: _.get(
            uploadRequestObject,
            'contacts_business_staff.profesion',
            {}
        ),
        businessName: _.get(
            uploadRequestObject,
            'contacts_business_staff.contacts_businessname.comp_name',
            ''
        ),
        business: _.get(
            uploadRequestObject,
            'contacts_business_staff.contacts_businessname',
            ''
        ),
    },
    complianceTypeId: _.get(uploadRequestObject, 'compliance_type_id', ''),
    maintenanceItemId: _.get(uploadRequestObject, 'maintenance_items_id', ''),
    taskItemId: _.get(uploadRequestObject, 'task_items_id', ''),
    requestType: _.get(uploadRequestObject, 'request_type', ''),
    requester: _.get(uploadRequestObject, 'requester', ''),
    status: _.get(uploadRequestObject, 'status', ''),
    note: _.get(uploadRequestObject, 'note', ''),
    uploadId: _.get(uploadRequestObject, 'uploadId', ''),
    complianceGroupName: _.get(
        uploadRequestObject,
        'compliance_type.compliance_category.compliance_group.name',
        ''
    ),
    complianceCategoryName: _.get(
        uploadRequestObject,
        'compliance_type.compliance_category.name',
        ''
    ),
    complianceTypeName: _.get(uploadRequestObject, 'compliance_type.name', ''),
    maintenanceItem: getMaintenanceItemModel(
        _.get(uploadRequestObject, 'maintenance_item', {})
    ),

    asset: assetModel(_.get(uploadRequestObject, 'asset', {})),
});
