import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BuildingPlaceholderImage from '../../assets/images/illustrations/placeholder-building.svg';
import { Image } from '../atoms';

const BuildingImage = ({
    image,
    width,
    height,
    maxWidth,
    borderRadius,
    asset,
}) => {
    const [buildingImage, setBuildingImage] = useState(null);
    const [buildingImageId, setBuildingImageId] = useState(null);

    const getImage = useCallback(async () => {
        if (!image) {
            return;
        }

        if (buildingImageId && buildingImageId === image.fileId) {
            return;
        }

        setBuildingImageId(image.fileId);
        setBuildingImage(image.url);
    }, [image, buildingImageId]);

    useEffect(() => {
        getImage();
    }, [image, getImage]);

    const renderPlaceholderImage = () => {
        if (buildingImage) {
            return null;
        }

        return (
            <AspectRatioImage
                src={BuildingPlaceholderImage}
                width={width}
                height={height}
                borderRadius={borderRadius}
            />
        );
    };

    const renderImage = () => {
        if (!buildingImage) {
            return null;
        }

        return (
            <AspectRatioImage
                src={buildingImage}
                maxWidth={maxWidth}
                width={width}
                height={height}
                borderRadius={borderRadius}
                archived={asset?.assetArchive}
            />
        );
    };

    return (
        <>
            {renderPlaceholderImage()}
            {renderImage()}
        </>
    );
};

BuildingImage.propTypes = {
    image: PropTypes.shape(),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    asset: PropTypes.string.isRequired,
};

BuildingImage.defaultProps = {
    width: null,
    height: null,
    maxWidth: null,
    image: null,
    borderRadius: null,
};

export default BuildingImage;

const AspectRatioImage = styled(Image)`
    object-fit: cover;
`;
