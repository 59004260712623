import networkService from '../network-service/network.service';
import businessUrls from './business-service-urls';
import { businessApiModel, minimalBusinessApiModel } from '../../../models';

function createBusiness(businessObject) {
    const url = businessUrls.createBusinessUrl();
    const data = businessApiModel(businessObject);
    return networkService.makePost(url, data);
}

function minimalCreateBusiness(minimalBusinessObject) {
    const url = businessUrls.createBusinessUrl();
    const data = minimalBusinessApiModel(minimalBusinessObject);
    return networkService.makePost(url, data);
}

export default {
    createBusiness,
    minimalCreateBusiness,
};
