import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { getFileType } from '../../helpers/get-file-type';
import {
    cancelUploadAction,
    clearUploadQueueAction,
    enqueueUploadAction,
    retryUploadAction,
    setAbortControllersAction,
    setIsInitialisingUploadAction,
    setIsUploadingAction,
    setQueuedUploadBytesRemainingAction,
    setTimeStartedAction,
    setUploadDoneAction,
    setUploadFailedAction,
    setUploadLocationAction,
    setUploadProgressAction,
    setUploadTimeRemainingAction,
} from './upload-manager.reducer';
import { getThumbnail } from '../../components/organisms/upload-manager/upload-manager-helpers';
import { errorToast } from '../../components/toasts';

const startNextUpload = () => (dispatch, getState) => {
    const { uploads } = getState().uploadManagerReducer;
    const activeUpload = _.find(uploads, (upload) => upload.isUploading);

    if (!activeUpload && _.size(uploads) > 0) {
        const uploadToStart = _.find(
            uploads,
            (upload) =>
                !upload.isInitialising &&
                !upload.isUploading &&
                !upload.uploadDone &&
                !upload.uploadFailed &&
                !upload.uploadCanceled
        );
        if (uploadToStart) {
            dispatch(calculateQueuedUploadBytesRemaining());
            dispatch(startUpload(uploadToStart.id));
            dispatch(setTimeStartedAction(new Date()));
        }
    }
};

const calculateQueuedUploadBytesRemaining = () => (dispatch, getState) => {
    const { uploads } = getState().uploadManagerReducer;

    const queuedUploadBytesRemaining = _.reduce(
        uploads,
        (sum, upload) => {
            if (
                upload.isUploading ||
                (!upload.uploadFailed && !upload.uploadDone)
            ) {
                return sum + upload.totalBytes;
            }
            return sum;
        },
        0
    );

    dispatch(setQueuedUploadBytesRemainingAction(queuedUploadBytesRemaining));
};

export const enqueueUploads =
    (files, _initializeUpload, uploadDocument, uploadSuccess, comment) =>
    (dispatch) => {
        const batchId = uuid();
        _.forEach(files, async (file) => {
            const uploadId = uuid();
            dispatch(
                enqueueUploadAction({
                    id: uploadId,
                    batchId,
                    file,
                    fileId: null,
                    fileUrl: null,
                    thumbnailId: null,
                    thumbnailUrl: null,
                    fileType: getFileType(file),
                    initializeUpload: _initializeUpload,
                    uploadDocument,
                    uploadSuccess,
                    comment,
                    isUploading: false,
                    uploadDone: false,
                    submitDone: false,
                    uploadFailed: false,
                    progress: 0,
                    totalBytes: file.size,
                    uploadedBytes: 0,
                })
            );
            dispatch(initializeUpload(uploadId));
        });
    };

export const retryUpload = (uploadId) => (dispatch, getState) => {
    const { uploads } = getState().uploadManagerReducer;
    const upload = uploads[uploadId];

    dispatch(retryUploadAction({ uploadId: upload.id }));
    dispatch(calculateQueuedUploadBytesRemaining());
    dispatch(initializeUpload(uploadId));
};

export const cancelUpload =
    (uploadId, shouldStartNextUpload = true) =>
    (dispatch, getState) => {
        const { uploads } = getState().uploadManagerReducer;
        const upload = uploads[uploadId];

        if (upload.fileController) {
            upload.fileController.abort();
        }
        if (upload.thumbnailController) {
            upload.thumbnailController.abort();
        }

        dispatch(cancelUploadAction({ uploadId: upload.id }));
        dispatch(calculateQueuedUploadBytesRemaining());

        if (shouldStartNextUpload) {
            dispatch(startNextUpload());
        }
    };

export const cancelAllUploads = () => (dispatch, getState) => {
    const { uploads } = getState().uploadManagerReducer;

    _.forEach(uploads, (upload) => {
        if (
            !upload.uploadDone &&
            !upload.uploadFailed &&
            !upload.uploadCanceled
        ) {
            dispatch(cancelUpload(upload.id, false));
        }
    });

    dispatch(startNextUpload());
};

export const clearUploadQueue = () => (dispatch) => {
    dispatch(clearUploadQueueAction());
};

export const onUploadProgress =
    (uploadId, progressEvent) => (dispatch, getState) => {
        const { uploads, timeStarted, queuedUploadBytesRemaining } =
            getState().uploadManagerReducer;
        const upload = uploads[uploadId];
        dispatch(
            setUploadProgressAction({
                uploadId: upload.id,
                progress:
                    (_.get(progressEvent, 'loaded') /
                        _.get(progressEvent, 'total')) *
                    100,
                uploadedBytes: _.get(progressEvent, 'loaded'),
                totalBytes: _.get(progressEvent, 'total'),
            })
        );

        const timeElapsed = new Date() - timeStarted;
        const uploadSpeed =
            _.get(progressEvent, 'loaded') / (timeElapsed / 1000);
        const timeRemaining = Math.round(
            (queuedUploadBytesRemaining - _.get(progressEvent, 'loaded')) /
                uploadSpeed
        );
        dispatch(setUploadTimeRemainingAction(timeRemaining));
    };

export const initializeUpload = (uploadId) => async (dispatch, getState) => {
    const { uploads } = getState().uploadManagerReducer;
    const upload = uploads[uploadId];
    dispatch(
        setIsInitialisingUploadAction({
            uploadId: upload.id,
            isInitialising: true,
        })
    );

    try {
        const { fileUrl, thumbnailUrl, fileId, thumbnailId } =
            await upload.initializeUpload(upload.file);

        dispatch(
            setUploadLocationAction({
                uploadId: upload.id,
                fileUrl,
                fileId,
                thumbnailUrl,
                thumbnailId,
            })
        );
        dispatch(
            setIsInitialisingUploadAction({
                uploadId: upload.id,
                isInitialising: false,
            })
        );
        dispatch(startNextUpload());
    } catch (error) {
        const errorMessage = `An error occurred while initialising the document upload. Error: ${_.get(
            error,
            'response.data.message',
            'Upload Error'
        )}`;

        errorToast(errorMessage);
        dispatch(
            setIsUploadingAction({
                uploadId: upload.id,
                isUploading: false,
            })
        );
        dispatch(
            setUploadFailedAction({
                uploadId: upload.id,
                uploadFailed: true,
            })
        );
    }
};

export const startUpload = (uploadId) => async (dispatch, getState) => {
    let { uploads } = getState().uploadManagerReducer;
    let upload = uploads[uploadId];
    dispatch(
        setIsUploadingAction({
            uploadId: upload.id,
            isUploading: true,
        })
    );

    const thumbnailController = new AbortController();
    const fileController = new AbortController();

    uploads = getState().uploadManagerReducer.uploads;
    upload = uploads[uploadId];

    dispatch(
        setAbortControllersAction({
            uploadId: upload.id,
            fileController,
            thumbnailController,
        })
    );

    const {
        file: thumbnail,
        fileType: thumbnailFileType,
        isBase64: isThumbnailBase64,
    } = await getThumbnail(upload.fileType, upload.file);

    const onProgress = (progressEvent) => {
        dispatch(onUploadProgress(upload.id, progressEvent));
    };

    try {
        await upload.uploadDocument(
            upload.file,
            upload.fileUrl,
            fileController,
            thumbnail,
            thumbnailFileType,
            isThumbnailBase64,
            upload.thumbnailUrl,
            thumbnailController,
            onProgress
        );

        dispatch(uploadDone(upload.id));
    } catch (error) {
        uploads = getState().uploadManagerReducer.uploads;
        upload = uploads[uploadId];

        if (!upload || upload.uploadCanceled) {
            return;
        }

        const errorMessage = `An error occurred while uploading the documents. Error: ${_.get(
            error,
            'response.data.message',
            'Upload Error'
        )}`;

        errorToast(errorMessage);
        dispatch(
            setUploadFailedAction({ uploadId: upload.id, uploadFailed: true })
        );
        dispatch(startNextUpload());
    }
};

export const uploadDone = (uploadId) => async (dispatch, getState) => {
    const { uploads } = getState().uploadManagerReducer;
    const upload = uploads[uploadId];

    try {
        await upload.uploadSuccess(
            upload.file,
            upload.fileId,
            upload.thumbnailId,
            upload.comment,
            upload.batchId
        );
        dispatch(
            setUploadDoneAction({ uploadId: upload.id, uploadDone: true })
        );
        dispatch(startNextUpload());
    } catch (error) {
        const errorMessage = `An error occurred while submitting your document. Error: ${_.get(
            error,
            'response.data.message',
            error.message
        )}`;
        errorToast(errorMessage);

        dispatch(
            setUploadFailedAction({ uploadId: upload.id, uploadFailed: true })
        );
        dispatch(startNextUpload());
    }
};
