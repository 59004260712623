import networkService from '../network-service/network.service';
import contactUrls from './contacts-service-urls';
import { businessContactApiModel, contactApiModel } from '../../../models';

function getContactsForBusiness(businessId) {
    const url = contactUrls.getPortfoliosForBusinessUrl(businessId);
    const config = { params: { business_id: businessId } };
    return networkService.makeGet(url, config);
}

// BusinessContacts

function getBusinessContacts({ search, page, profession }) {
    const url = contactUrls.getBusinessContactsUrl();
    return networkService.makeGet(
        `${url}?search=${search}&page=${page}&profession=${profession}`
    );
}

function createBusinessContact(businessContactObject, businessId) {
    const url = contactUrls.createBusinessContactUrl();
    const data = businessContactApiModel({
        ...businessContactObject,
        businessId,
    });
    return networkService.makePost(url, data);
}

function editBusinessContact(businessContactObject) {
    const url = contactUrls.editBusinessContactUrl(businessContactObject.id);
    const data = businessContactApiModel(businessContactObject);
    return networkService.makePut(url, data);
}

function deleteBusinessContact(businessContactId) {
    const url = contactUrls.deleteBusinessContactUrl(businessContactId);
    return networkService.makeDelete(url);
}

// BusinessContactsStaff

function createContact(contactObject, businessContactId) {
    const url = contactUrls.createContactUrl();
    const data = contactApiModel({ ...contactObject, businessContactId });
    return networkService.makePost(url, data);
}

function consultantUpdateContact(contactObject) {
    const url = contactUrls.updateContactUrl(contactObject.id);
    const data = contactApiModel(contactObject);
    return networkService.makePut(url, data);
}

function deleteContact(contactId) {
    const url = contactUrls.deleteContactUrl(contactId);
    return networkService.makeDelete(url);
}

export default {
    getContactsForBusiness,

    // BusinessContacts
    getBusinessContacts,
    createBusinessContact,
    editBusinessContact,
    deleteBusinessContact,

    // BusinessContactsStaff

    createContact,
    consultantUpdateContact,
    deleteContact,
};
