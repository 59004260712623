import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Pane, TextInputField } from 'evergreen-ui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { loginShape, registerModel } from '../../../models';

const SignupForm = ({ initialValues, submitForm, onSuccess }) => {
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string()
            .required('Email is required')
            .email('Invalid email'),
        mobileNumber: Yup.string().required('Mobile number is required'),
    });

    const dispatch = useDispatch();

    const handleFormSubmission = async (
        values,
        { setSubmitting, setFieldError }
    ) => {
        setSubmitting(true);

        try {
            await dispatch(submitForm(registerModel(values)));
            onSuccess(values.firstName);
        } catch (error) {
            setFieldError(
                'mobileNumber',
                _.get(error, 'response.data.message', error.message)
            );
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmission}
            enableReinitialize
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Pane marginTop="32px">
                        <TextInputField
                            label="First Name"
                            type="text"
                            name="firstName"
                            placeholder="John"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.firstName && errors.firstName}
                            validationMessage={
                                touched.firstName && errors.firstName
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            required
                        />
                    </Pane>
                    <Pane marginTop="32px">
                        <TextInputField
                            label="Last Name"
                            type="text"
                            name="lastName"
                            placeholder="Doe"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.lastName && errors.lastName}
                            validationMessage={
                                touched.lastName && errors.lastName
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            required
                        />
                    </Pane>
                    <Pane marginTop="32px">
                        <TextInputField
                            label="Email"
                            type="email"
                            name="email"
                            placeholder="john@example.com"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.email && errors.email}
                            validationMessage={touched.email && errors.email}
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            required
                        />
                    </Pane>
                    <Pane marginTop="32px">
                        <TextInputField
                            label="Mobile Number"
                            type="text"
                            name="mobileNumber"
                            placeholder="+27712526996"
                            value={values.mobileNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                touched.mobileNumber && errors.mobileNumber
                            }
                            validationMessage={
                                touched.mobileNumber && errors.mobileNumber
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            required
                        />
                    </Pane>
                    <Pane display="flex" flexDirection="column">
                        <Button
                            appearance="primary"
                            height={40}
                            marginTop="32px"
                            isLoading={isSubmitting}
                        >
                            Sign Up
                        </Button>
                    </Pane>
                </Form>
            )}
        </Formik>
    );
};

SignupForm.propTypes = {
    initialValues: loginShape.isRequired,

    submitForm: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default SignupForm;
