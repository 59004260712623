import React, { useCallback, useMemo, useState } from 'react';
import { Pane, Text } from 'evergreen-ui';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { colors } from '../../../theme/theme';
import ComplianceGroup from './compliance-group';
import { useUserAccess } from '../../../context/user-access-context';
import ComplianceDialog from './compliance-dialog';
import { complianceService } from '../../../services';

const ComplianceSidebar = ({ complianceGroups }) => {
    const [complianceDialog, setComplianceDialog] = useState({
        isShown: false,
        isDeleting: false,
        isEditing: false,
        isGroup: true,
        complianceItem: {},
    });
    const { currentRights, modules } = useUserAccess();

    const canViewBuildings = useMemo(() => modules?.info || false, [modules]);

    const openAddGroupComplianceDialog = () => {
        setComplianceDialog({
            isShown: true,
            isDeleting: false,
            isEditing: false,
            isGroup: true,
            complianceItem: {},
        });
    };

    const closeComplianceDialog = () => {
        setComplianceDialog({
            isShown: false,
            isDeleting: false,
            isEditing: false,
            isGroup: true,
            complianceItem: {},
        });
    };

    const onConfirm = useCallback(async (data) => {
        await complianceService.createComplianceGroup(data);
    }, []);

    return (
        <>
            <PaneWithScrollbar
                width={313}
                left={104}
                top={canViewBuildings ? 276 : 196}
                bottom={20}
                backgroundColor="#FAFBFF"
                border="0.5px solid #C1C4D6"
                borderRadius={4}
                position="fixed"
                overflowY="auto"
            >
                <Pane paddingBottom={38}>
                    {_.map(complianceGroups, (group, groupIndex) => (
                        <ComplianceGroup
                            key={`${group.id}-${groupIndex}`}
                            group={group}
                            rights={currentRights}
                            isFirst={groupIndex === 0}
                        />
                    ))}
                    {currentRights?.create && (
                        <AddGroupButton
                            marginLeft={30}
                            marginTop={14}
                            marginBottom={-14}
                            onClick={openAddGroupComplianceDialog}
                        >
                            <Text>+ Add Compliance Group</Text>
                        </AddGroupButton>
                    )}
                </Pane>
            </PaneWithScrollbar>
            {(currentRights?.create ||
                currentRights?.update ||
                currentRights?.delete) &&
                complianceDialog.isShown && (
                    <ComplianceDialog
                        isShown={complianceDialog.isShown}
                        isDeleting={complianceDialog.isDeleting}
                        isEditing={complianceDialog.isEditing}
                        complianceItem={complianceDialog.complianceItem}
                        onConfirm={onConfirm}
                        onClose={closeComplianceDialog}
                        isGroup
                    />
                )}
        </>
    );
};

ComplianceSidebar.propTypes = {
    complianceGroups: PropTypes.shape({}).isRequired,
};

export default ComplianceSidebar;

const PaneWithScrollbar = styled(Pane)`
    border-radius: 4px;
    border: 0.5px solid #c1c4d6;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: ${colors.transparent};
    }
    &::-webkit-scrollbar-thumb {
        background-color: #8f95b2;
        border-radius: 5px;
    }
`;

const AddGroupButton = styled(Pane)`
    span {
        color: #5ec090;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;
