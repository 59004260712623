import React, { useState } from 'react';
import {
    Avatar,
    Card,
    ChevronDownIcon,
    Heading,
    Pane,
    Paragraph,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../../theme/theme';
import { Collapsible } from '../../atoms';

const ContactItem = ({ contact, onContactSelect }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMoreInformation = (event) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
    };

    const select = () => {
        if (onContactSelect) {
            onContactSelect(contact);
        }
    };

    return (
        <ContactCard
            elevation={isOpen ? 1 : 0}
            padding={16}
            borderRadius={0}
            backgroundColor="#ffffff"
            cursor={onContactSelect ? 'pointer' : 'default'}
            onClick={select}
        >
            <Pane display="flex" flexDirection="row" alignItems="center">
                <Avatar
                    name={`${contact.firstName} ${contact.lastName}`}
                    size={50}
                />
                <Pane marginLeft={15}>
                    <Heading color="#282828" fontSize={13} fontWeight={600}>
                        {contact.firstName} {contact.lastName}
                    </Heading>
                    <Paragraph fontSize={12} color="#696F8C" lineHeight={1.5}>
                        {contact.email}
                    </Paragraph>
                    {contact.mobileNumber !== 'Mobile number not provided' && (
                        <Paragraph
                            fontSize={12}
                            color="#696F8C"
                            lineHeight={1.5}
                        >
                            {contact.mobileNumber}
                        </Paragraph>
                    )}
                </Pane>
                <Pane display="flex" flex={1} justifyContent="flex-end">
                    <ChevronDownContainer
                        onClick={toggleMoreInformation}
                        width={48}
                        height={45}
                        borderRadius={24}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ChevronDownIcon
                            color={colors.gray800}
                            marginTop={3}
                            size={20}
                            transform={isOpen && 'rotate(180deg)'}
                            transition="transform 300ms"
                            cursor="pointer"
                        />
                    </ChevronDownContainer>
                </Pane>
            </Pane>
            <Collapsible isOpen={isOpen}>
                <Pane padding={16}>
                    <Pane padding={8}>
                        <Heading
                            fontSize={11}
                            fontWeight={700}
                            color="#C1C4D6"
                            textTransform="uppercase"
                            lineHeight={1}
                        >
                            Company:
                        </Heading>
                        <Paragraph
                            fontSize={14}
                            fontWeight={400}
                            color="#696F8C"
                        >
                            {contact.companyName}
                        </Paragraph>
                    </Pane>
                    <Pane padding={8}>
                        <Heading
                            fontSize={11}
                            fontWeight={700}
                            color="#C1C4D6"
                            textTransform="uppercase"
                            lineHeight={1}
                        >
                            Email Address:
                        </Heading>
                        <Paragraph
                            fontSize={14}
                            fontWeight={400}
                            color="#696F8C"
                        >
                            {contact.email}
                        </Paragraph>
                    </Pane>
                    <Pane padding={8}>
                        <Heading
                            fontSize={11}
                            fontWeight={700}
                            color="#C1C4D6"
                            textTransform="uppercase"
                            lineHeight={1}
                        >
                            Cellphone Number:
                        </Heading>
                        <Paragraph
                            fontSize={14}
                            fontWeight={400}
                            color="#696F8C"
                        >
                            {contact.mobileNumber}
                        </Paragraph>
                    </Pane>
                    <Pane padding={8}>
                        <Heading
                            fontSize={11}
                            fontWeight={700}
                            color="#C1C4D6"
                            textTransform="uppercase"
                            lineHeight={1}
                        >
                            Profession:
                        </Heading>
                        <Paragraph
                            fontSize={14}
                            fontWeight={400}
                            color="#696F8C"
                        >
                            {contact.profession}
                        </Paragraph>
                    </Pane>
                    <Pane padding={8}>
                        <Heading
                            fontSize={11}
                            fontWeight={700}
                            color="#C1C4D6"
                            textTransform="uppercase"
                            lineHeight={1}
                        >
                            Professional Registration Number:
                        </Heading>
                        <Paragraph
                            fontSize={14}
                            fontWeight={400}
                            color="#696F8C"
                        >
                            {contact.registrationNumber}
                        </Paragraph>
                    </Pane>
                </Pane>
            </Collapsible>
        </ContactCard>
    );
};

ContactItem.propTypes = {
    contact: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        mobileNumber: PropTypes.string,
        companyName: PropTypes.string,
        profession: PropTypes.string,
        registrationNumber: PropTypes.string,
    }).isRequired,

    onContactSelect: PropTypes.func,
};

ContactItem.defaultProps = {
    onContactSelect: null,
};

export default ContactItem;

const ContactCard = styled(Card)`
    &:hover {
        background-color: #f3f6ff;
    }
`;

const ChevronDownContainer = styled(Pane)`
    &:hover {
        background-color: #ffffff;
    }
`;
