import _ from 'lodash';

import { userService } from '../../services';
import {
    setBusinessIdsAction,
    setBusinessFriendlyNameAction,
    setPortfolioIdsAction,
    setIsLoading,
    setUserAction,
} from './user.reducer';
import { userModels } from '../../models';
import { logout } from '../auth-reducer/auth.actions';

/* eslint-disable import/prefer-default-export */
export function getUser() {
    return async (dispatch) => {
        dispatch(setIsLoading(true));

        try {
            const user = await userService.getUser();
            dispatch(setUserAction(userModels(user)));

            const userAccess = _.get(user, 'access_of_user');
            const businessId = _.get(userAccess, 'business_id');
            const businessFriendlyName = _.get(
                userAccess,
                'business.friendlyname'
            );
            const businessIds = businessId ? [businessId] : [];
            const portfolioIds = _.map(
                _.get(userAccess, 'portfolio_of_business', []),
                (portfolio) => portfolio.id
            );

            dispatch(setBusinessIdsAction(businessIds));
            dispatch(setBusinessFriendlyNameAction(businessFriendlyName));
            dispatch(setPortfolioIdsAction(portfolioIds));
        } catch (error) {
            dispatch(logout(true));
        } finally {
            dispatch(setIsLoading(false));
        }
    };
}
