import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    useLocation,
    useSearchParams as useRouterSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { assetSelector } from '../reducers/asset-reducer/asset.reducer';
import {
    getAsset,
    getBuilding,
    getTenant,
    getAssets,
} from '../reducers/asset-reducer/asset.actions';

const SearchParamsContext = createContext({});

function SearchParamsProvider({ children }) {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useRouterSearchParams();
    const { pathname } = useLocation();

    const [isLoadingParams, setIsLoadingParams] = useState(true);

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const queryParams = useMemo(
        () => Object.fromEntries(searchParams.entries()),
        [searchParams]
    );

    const { currentTenantId, currentBuildingId, currentAssetId } = useMemo(
        () => queryParams,
        [queryParams]
    );

    const getBuildings = useCallback(async () => {
        if (!pathname.includes('info') && !pathname.includes('compliance')) {
            const ids = [];
            if (!!currentTenantId && !currentTenant) {
                ids.push(currentTenantId);
            }
            if (!!currentBuildingId && !currentBuilding) {
                ids.push(currentBuildingId);
            }
            if (!!currentAssetId && !currentAsset) {
                ids.push(currentAssetId);
            }

            await Promise.all(
                ids.map(async (id) => {
                    if (Number(id) === Number(currentTenantId)) {
                        await dispatch(getTenant(id));
                    }
                    if (Number(id) === Number(currentBuildingId)) {
                        await dispatch(getBuilding(id));
                    }
                    if (Number(id) === Number(currentAssetId)) {
                        await dispatch(getAsset(id, false, true, false));
                    }
                })
            );
        }
        setIsLoadingParams(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentAssetId,
        currentBuildingId,
        currentTenantId,
        dispatch,
        pathname,
    ]);

    const setParams = useCallback(() => {
        if (!pathname.includes('info') && !pathname.includes('compliance')) {
            if (
                currentTenant &&
                currentTenantId &&
                currentTenantId !== currentTenant.id
            ) {
                setSearchParams({
                    ...queryParams,
                    currentTenantId: currentTenant.id,
                });
            }
            if (
                currentBuilding &&
                currentBuildingId &&
                currentBuildingId !== currentBuilding.id
            ) {
                setSearchParams({
                    ...queryParams,
                    currentBuildingId: currentBuilding.id,
                });
            }
            if (
                currentAsset &&
                currentAssetId &&
                currentAssetId !== currentAsset.id
            ) {
                setSearchParams({
                    ...queryParams,
                    currentAssetId: currentAsset.id,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentAssetId,
        currentBuildingId,
        currentTenantId,
        pathname,
        queryParams,
        setSearchParams,
    ]);

    const value = useMemo(
        () => ({
            currentAssetId,
            currentBuildingId,
            currentTenantId,
            isLoadingParams,
            setSearchParams,
        }),
        [
            currentAssetId,
            currentBuildingId,
            currentTenantId,
            isLoadingParams,
            setSearchParams,
        ]
    );

    useEffect(() => {
        setParams();
    }, [setParams]);

    useEffect(() => {
        getBuildings();
    }, [getBuildings]);

    useEffect(() => {
        dispatch(getAssets());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SearchParamsContext.Provider value={value}>
            {children}
        </SearchParamsContext.Provider>
    );
}

SearchParamsProvider.propTypes = {
    children: PropTypes.element.isRequired,
};

export const useSearchParams = () => useContext(SearchParamsContext);

export default SearchParamsProvider;
