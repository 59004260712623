import React, { useMemo, useState } from 'react';
import { Pane, Text, Dialog } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import {
    deleteContact,
    deleteBusinessContact,
} from '../../reducers/contacts-reducer/contacts.actions';
import { errorToast, successToast } from '../toasts';

function ConfirmContactDeleteModal({ isShown, close, contact }) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const name = useMemo(() => {
        if (contact?.isStaff) {
            return `${contact?.firstName} ${contact?.lastName}`;
        }
        return `${contact?.name}`;
    }, [contact]);

    const handleConfirm = async () => {
        try {
            setIsLoading(true);
            if (contact?.isStaff) {
                dispatch(deleteContact(contact?.id));
            } else {
                dispatch(deleteBusinessContact(contact?.id));
            }
            successToast(`Successfully deleted "${name}"`);
            close();
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog
            title="Delete Contact"
            intent="danger"
            isShown={isShown}
            onCloseComplete={close}
            onConfirm={handleConfirm}
            isConfirmLoading={isLoading}
        >
            <Pane display="flex" flexDirection="column" justifyContent="center">
                <Text marginTop={20} fontSize={16}>
                    Are you sure you want to delete{' '}
                    <Text fontSize={18} fontWeight="bolder">
                        {name}
                    </Text>
                </Text>
            </Pane>
        </Dialog>
    );
}

ConfirmContactDeleteModal.propTypes = {
    isShown: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    contact: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        isStaff: PropTypes.bool,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }).isRequired,
};

export default ConfirmContactDeleteModal;
