import React, { useContext, useMemo } from 'react';
import { Pane } from 'evergreen-ui';
import _ from 'lodash';

import { SelectedUploadRequest } from '../../../pages/upload-request/selected-upload-request-context';
import AssetSidebarAssetDetails from '../assets-list/asset-sidebar-asset-details';

const UploadRequestAssetInfoSidebar = () => {
    const { selectedUploadRequest } = useContext(SelectedUploadRequest);

    const asset = useMemo(
        () =>
            _.get(selectedUploadRequest, 'asset') || {
                address: '',
                assetName: '',
                buildingUse: '',
                builtCoverage: '',
                builtFAR: '',
                erfNumber: '',
                grossBuildingArea: '',
                grossLettableArea: '',
                permissibleCoverage: '',
                permissibleFAR: '',
            },
        [selectedUploadRequest]
    );

    return (
        <Pane
            width={313}
            height="calc(100% - 65px)"
            position="fixed"
            right={0}
            top={65}
            backgroundColor="#FAFBFF"
            borderLeft="0.5px solid #C1C4D6"
            zIndex={20}
        >
            <Pane
                height="calc(100% - 64px - 70px)"
                padding={28}
                overflowY="auto"
                overflowX="hidden"
            >
                <AssetSidebarAssetDetails
                    asset={asset}
                    isVisible
                    canEditAsset={false}
                />
            </Pane>
        </Pane>
    );
};

export default UploadRequestAssetInfoSidebar;
