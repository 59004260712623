const contactsBaseUrl = '/Contacts';

export default {
    getPortfoliosForBusinessUrl: () => `${contactsBaseUrl}/business`,

    // BusinessesContacts

    getBusinessContactsUrl: () => `${contactsBaseUrl}/businesses`,
    createBusinessContactUrl: () => `${contactsBaseUrl}/business`,
    editBusinessContactUrl: (businessContactId) =>
        `${contactsBaseUrl}/business/${businessContactId}`,
    deleteBusinessContactUrl: (businessContactId) =>
        `${contactsBaseUrl}/business/${businessContactId}`,

    // BusinessContactsStaff

    createContactUrl: () => `${contactsBaseUrl}/staff`,
    updateContactUrl: (contactId) => `${contactsBaseUrl}/staff/${contactId}`,
    deleteContactUrl: (contactId) => `${contactsBaseUrl}/staff/${contactId}`,
};
