import notificationUrls from './notification-service-urls';
import networkService from '../network-service/network.service';

const baseURL = 'https://xkrn-4n4o-aozy.e2.xano.io/api:KpcKwOZs';

export function getNotifications() {
    const url = notificationUrls.getNotifications();
    return networkService.makeGet(url, { baseURL });
}

export function markNotificationRead(notificationId) {
    const url = notificationUrls.markNotificationRead();
    return networkService.makePost(
        url,
        { notifications_id: notificationId },
        { baseURL }
    );
}

export default {
    getNotifications,
    markNotificationRead,
};
