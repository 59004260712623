import React from 'react';
import { toast } from 'react-toastify';
import { TickCircleIcon } from 'evergreen-ui';

function showSuccessToastMessage(message) {
    toast.success(message, {
        icon: <TickCircleIcon color="success" size={16} />,
    });
}

export default showSuccessToastMessage;
