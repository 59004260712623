import React from 'react';
import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';

import Logo from '../atoms/logo';

import useWindowDimensions from '../../hooks/use-window-dimensions';
import { colors } from '../../theme/theme';

const AuthScreenLayout = ({ children, heroComponent }) => {
    const { width } = useWindowDimensions();

    return (
        <Pane width="100%" backgroundColor="white">
            <Pane display="flex" flex={1} padding="56px" position="fixed">
                <Logo size="large" />
            </Pane>
            <Pane
                display="flex"
                flexDirection="row"
                height="100%"
                width="100%"
                zIndex={2}
            >
                <Pane
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={width * 0.35}
                    backgroundColor={colors.gray200}
                >
                    {heroComponent}
                </Pane>
                <Pane
                    display="flex"
                    flex={1}
                    justifyContent="center"
                    alignItems="center"
                    width={width - width * 0.65}
                    zIndex={2}
                >
                    {children}
                </Pane>
            </Pane>
        </Pane>
    );
};

AuthScreenLayout.propTypes = {
    children: PropTypes.node.isRequired,
    heroComponent: PropTypes.node.isRequired,
};

export default AuthScreenLayout;
