import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Button,
    CrossIcon,
    Dialog,
    Heading,
    Pane,
    Paragraph,
    Text,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFilePicker } from 'use-file-picker';
import _ from 'lodash';

import { colors } from '../../theme/theme';
import documentUpload from '../../assets/images/illustrations/document-upload-dialog.svg';
import { FileDragAndDrop, Image } from '../atoms';
import ProgressBar from '../atoms/progress-bar.component';
import { errorToast } from '../toasts';
import getFileTypeIcon from '../../helpers/get-file-type-icon';

const ImageUploadDialog = ({
    isShown,
    setIsShown,
    uploadDocument,
    uploadSuccess,
}) => {
    const [openFileSelector, { plainFiles, clear }] = useFilePicker({
        accept: 'image/*',
        multiple: false,
        readFilesContent: true,
    });

    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [abortController, setAbortController] = useState(null);

    const fileType = useMemo(() => {
        if (!file) {
            return null;
        }

        if (file.type) {
            return file.type.substring(
                file.type.indexOf('/') + 1,
                file.type.length
            );
        }

        return file.name.substring(
            file.type.indexOf('.') + 1,
            file.type.length
        );
    }, [file]);

    useEffect(() => {
        if (_.size(plainFiles) > 0) {
            setFile(plainFiles[0]);
        }
    }, [plainFiles]);

    const closeDialog = useCallback(() => {
        if (isUploading) {
            abortController.abort();
        }
        setFile(null);
        setIsUploading(false);
        setUploadProgress(0);
        clear();
        setIsShown(false);
    }, [
        isUploading,
        abortController,
        setFile,
        setIsUploading,
        setUploadProgress,
        clear,
        setIsShown,
    ]);

    const uploadDone = useCallback(
        async (_fileId, _thumbnailId) => {
            try {
                await uploadSuccess(file, _fileId, _thumbnailId);
                setFile(null);
                setIsUploading(false);
                setUploadProgress(0);
                closeDialog();
            } catch (error) {
                const errorMessage = `An error occurred while submitting your document. Error: ${_.get(
                    error,
                    'response.data.message',
                    error.message
                )}`;
                errorToast(errorMessage);
                closeDialog();
            }
        },
        [file, uploadSuccess, closeDialog]
    );

    const upload = useCallback(async () => {
        setIsUploading(true);

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        try {
            const { fileId: _fileId, thumbnailId: _thumbnailId } =
                await uploadDocument(
                    file,
                    newAbortController,
                    onUploadProgress
                );
            await uploadDone(_fileId, _thumbnailId);
        } catch (error) {
            if (error.message !== 'canceled') {
                const errorMessage = `An error occurred while uploading the image. Error: ${_.get(
                    error,
                    'response.data.message',
                    'Upload Error'
                )}`;
                errorToast(errorMessage);
            }

            closeDialog();
        }
    }, [file, uploadDocument, uploadDone, closeDialog]);

    useEffect(() => {
        if (file && !isUploading) {
            upload();
        }
    }, [file, upload, isUploading]);

    const onUploadProgress = (progressEvent) => {
        setUploadProgress(
            (_.get(progressEvent, 'loaded') / _.get(progressEvent, 'total')) *
                100
        );
    };

    const onFilesDropped = (droppedFiles) => {
        const imageType = /^image\//;
        if (!imageType.test(droppedFiles[0].type)) {
            return;
        }
        setFile(droppedFiles[0]);
    };

    const renderDropZone = () => {
        if (isUploading) {
            return null;
        }

        return (
            <>
                <Image
                    src={documentUpload}
                    alt="document upload illustration"
                    maxWidth="150px"
                    marginY={12}
                />

                <Heading>
                    Drop your image here, or{' '}
                    <BrowseButton onClick={openFileSelector}>
                        browse
                    </BrowseButton>
                </Heading>
                <Paragraph color={colors.gray500}>
                    Supports any image file type.
                </Paragraph>
            </>
        );
    };

    const renderUploadProgress = () => {
        if (!isUploading) {
            return null;
        }

        return (
            <Pane display="flex" flexDirection="column" width="100%">
                <Pane
                    display="flex"
                    flexDirection="row"
                    flex={1}
                    width="100%"
                    height="100%"
                >
                    <Image src={getFileTypeIcon(fileType)} width={50} />
                    <Pane
                        display="flex"
                        flex={1}
                        flexDirection="column"
                        justifyContent="center"
                        marginLeft={18}
                    >
                        <Text
                            size={500}
                            color={colors.gray500}
                            marginBottom={8}
                        >
                            {_.get(file, 'name')}
                        </Text>
                        <ProgressBar
                            progress={uploadProgress}
                            color={colors.secondary500}
                            intermittent={uploadProgress < 3}
                        />
                    </Pane>
                </Pane>
            </Pane>
        );
    };

    return (
        <Dialog
            isShown={isShown}
            onCloseComplete={closeDialog}
            hasHeader={false}
            hasFooter={false}
            contentContainerProps={{
                padding: 0,
            }}
            topOffset="auto"
            shouldCloseOnEscapePress
            shouldCloseOnOverlayClick
        >
            <>
                <CloseButton
                    position="fixed"
                    right={-32}
                    top={-32}
                    borderRadius={16}
                    height={32}
                    width={32}
                    onClick={closeDialog}
                >
                    <CrossIcon size={16} color="white" />
                </CloseButton>
                {isShown && (
                    <FileDragAndDrop onFilesDropped={onFilesDropped}>
                        {({ dragging }) => (
                            <Pane
                                margin={24}
                                borderWidth={3}
                                borderRadius={5}
                                borderStyle={
                                    dragging || _.size(plainFiles) > 0
                                        ? 'solid'
                                        : 'dashed'
                                }
                                borderColor={
                                    dragging || _.size(plainFiles) > 0
                                        ? colors.secondary500
                                        : colors.gray500
                                }
                                display="flex"
                                flexDirection="column"
                                flex={1}
                                padding={24}
                                alignItems="center"
                            >
                                {isUploading
                                    ? renderUploadProgress()
                                    : renderDropZone()}
                            </Pane>
                        )}
                    </FileDragAndDrop>
                )}
            </>
        </Dialog>
    );
};

ImageUploadDialog.propTypes = {
    isShown: PropTypes.bool.isRequired,

    setIsShown: PropTypes.func.isRequired,
    uploadDocument: PropTypes.func.isRequired,
    uploadSuccess: PropTypes.func.isRequired,
};

export default ImageUploadDialog;

const BrowseButton = styled.span`
    cursor: pointer;
    color: ${colors.secondary500};
    tab-index: 1;
`;

const CloseButton = styled(Button)`
    border: none;
    background-color: rgba(255, 255, 255, 0.4);
    transition: background-color 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    tab-index: 2;

    &:hover {
        background-color: rgba(255, 255, 255, 0.6) !important;
        border: none;
    }

    &:focus {
        box-shadow: none !important;
    }
`;
