const assetBaseUrl = '/asset';
const buildingsBaseUrl = '/buildings';

export default {
    createAssetUrl: () => `${buildingsBaseUrl}/new`,
    updateAssetUrl: () => `${assetBaseUrl}/edit`,
    getAssetByIdUrl: () => `${buildingsBaseUrl}/data`,
    getAssetsUrl: () => buildingsBaseUrl,
    migrateAssetUrl: (id) => `${buildingsBaseUrl}/${id}`,
    linkingAssetUrl: (id) => `${buildingsBaseUrl}/linking/${id}`,
    deleteAssetUrl: (id) => `${buildingsBaseUrl}/${id}`,
    updateArchivedAssetUrl: (id) => `${buildingsBaseUrl}/archive/${id}`,
    updateUnarchivedAssetUrl: (id) => `${buildingsBaseUrl}/unarchive/${id}`,
};
