const authBaseUrl = '/auth';

export default {
    loginUrl: () => `${authBaseUrl}/magic-link`,
    registerUrl: () => `${authBaseUrl}/signup`,
    confirmEmailUrl: () => `${authBaseUrl}/confirmation-v2`,
    getAuthTokenUrl: () => `${authBaseUrl}/magic-login-v2`,
    refreshAuthToken: () => `${authBaseUrl}/refresh-v2`,
    systemStatusUrl: () => '/system/status',
};
