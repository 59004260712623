import React from 'react';
import { Heading, Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';

import { Collapsible, StepperCount } from '../atoms';

const VerticalStepperStep = ({
    title,
    children,
    stepNumber,
    isOpen,
    isCompleted,
    isLastStep,
    open,
}) => (
    <Pane>
        <Pane
            onClick={open}
            cursor={open ? 'pointer' : 'default'}
            display="flex"
            alignItems="center"
        >
            <StepperCount count={stepNumber} isCompleted={isCompleted} />
            <Heading size={600} paddingLeft={16}>
                {title}
            </Heading>
        </Pane>
        <Pane display="flex">
            <Pane
                marginX={44}
                width={2}
                backgroundColor={isLastStep ? 'transparent' : '#474D66'}
            />
            <Pane maxWidth={400} width="100%" marginX="auto" paddingY="1rem">
                <Collapsible isOpen={isOpen}>{children}</Collapsible>
            </Pane>
        </Pane>
    </Pane>
);

VerticalStepperStep.defaultProps = {
    stepNumber: 0,
    isOpen: false,
    isLastStep: false,
    isCompleted: false,
    open: undefined,
};

VerticalStepperStep.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    stepNumber: PropTypes.number,
    isOpen: PropTypes.bool,
    isCompleted: PropTypes.bool,
    isLastStep: PropTypes.bool,

    open: PropTypes.func,
};

export default VerticalStepperStep;
