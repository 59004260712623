import React, { useEffect } from 'react';
import { Pane } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import AuthScreenLayout from '../../components/layouts/auth-screen-layout';
import BusinessNameForm from '../../components/forms/business-forms/business-name-form';
import { businessService } from '../../services/index';
import { Image } from '../../components/atoms';
import createBusinessImage from '../../assets/images/illustrations/create-business.svg';
import { minimalBusinessModel } from '../../models';
import { refreshAuthToken } from '../../reducers/auth-reducer/auth.actions';
import { getUser } from '../../reducers/user-reducer/user.actions';
import { userSelector } from '../../reducers/user-reducer/user.reducer';

const CreateBusinessPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitBusinessForm = businessService.minimalCreateBusiness;
    const { businessIds } = useSelector(userSelector);

    const onSuccess = async () => {
        await dispatch(refreshAuthToken());
        await dispatch(getUser());
        navigate('/portfolio');
    };

    const renderHeroComponent = () => (
        <Image
            src={createBusinessImage}
            alt="create business illustration"
            maxWidth="300px"
        />
    );

    useEffect(() => {
        if (!_.isEmpty(businessIds)) {
            navigate('/portfolio', { replace: true });
        }
    }, [businessIds, navigate]);

    return (
        <AuthScreenLayout heroComponent={renderHeroComponent()}>
            <Pane
                display="flex"
                flexDirection="column"
                maxWidth="600px"
                width="100%"
            >
                <BusinessNameForm
                    initialValues={minimalBusinessModel()}
                    submitForm={submitBusinessForm}
                    onSuccess={onSuccess}
                />
            </Pane>
        </AuthScreenLayout>
    );
};

export default CreateBusinessPage;
