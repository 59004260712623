const createAttachTokenInterceptor = (axiosInstance, getAccessToken) => {
    const attachAccessToken = async (config) => {
        const accessToken = await getAccessToken();
        if (accessToken) {
            config.headers.Authorization =
                config.headers && config.headers.Authorization !== undefined
                    ? config.headers.Authorization
                    : accessToken;
        }
        return config;
    };

    return axiosInstance.interceptors.request.use(attachAccessToken);
};

export default createAttachTokenInterceptor;
