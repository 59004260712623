import React from 'react';
import { Pane } from 'evergreen-ui';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import VerticalStepper from '../molecules/vertical-stepper';
import VerticalStepperStep from '../molecules/vertical-stepper-step';
import ContactPersonDetailsForm from '../forms/contact-forms/contact-person-details-form';
import { contactPersonDetailsFormModel } from '../../models';
import {
    createContact,
    updateContact,
} from '../../reducers/contacts-reducer/contacts.actions';
import { errorToast, successToast } from '../toasts';

const CreateStaffContactForm = ({
    closeSidebar,
    staffContact,
    isEditing,
    businessContactId,
}) => {
    const dispatch = useDispatch();

    const submitContact = async (personDetails) => {
        try {
            if (isEditing) {
                dispatch(
                    updateContact({
                        ...contactPersonDetailsFormModel(personDetails),
                        id: personDetails.id,
                    })
                );
            } else {
                dispatch(
                    createContact(
                        contactPersonDetailsFormModel(personDetails),
                        businessContactId
                    )
                );
            }
            successToast(
                `Successfully ${isEditing ? 'updated' : 'created'} "${
                    personDetails.firstName
                } ${personDetails.lastName}"`
            );
            closeSidebar();
        } catch (error) {
            errorToast(
                ` An Error Occurred: ${get(
                    error,
                    'response.data.message',
                    `Failed to ${isEditing ? 'update' : 'create'}"${
                        personDetails.firstName
                    } ${personDetails.lastName}"`
                )}`
            );
        }
    };

    return (
        <VerticalStepper currentStep={0}>
            <VerticalStepperStep title="Contact Details">
                <Pane marginX={2}>
                    <ContactPersonDetailsForm
                        initialValues={
                            staffContact || contactPersonDetailsFormModel()
                        }
                        handleFormSubmission={submitContact}
                        isEditing={isEditing}
                    />
                </Pane>
            </VerticalStepperStep>
        </VerticalStepper>
    );
};

CreateStaffContactForm.propTypes = {
    closeSidebar: PropTypes.func.isRequired,
    staffContact: PropTypes.shape({}).isRequired,
    isEditing: PropTypes.bool.isRequired,
    businessContactId: PropTypes.number.isRequired,
};

export default CreateStaffContactForm;
