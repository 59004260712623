import _ from 'lodash';

export const portfolioModel = (portfolioObject = {}) => ({
    id: _.get(portfolioObject, 'id', 0).toString(),
    portfolioType: _.get(portfolioObject, 'name', ''),
});

export const portfolioApiModel = (portfolioObject = {}) => ({
    name: _.get(portfolioObject, 'portfolioType', ''),
    business_id: _.get(portfolioObject, 'businessId', ''),
});
