import React from 'react';

const VerticalStepper = ({ children, currentStep }) =>
    React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
            stepNumber: index + 1,
            isOpen: currentStep === index,
            isCompleted: currentStep > index,
            isLastStep: index === React.Children.count(children) - 1,
        })
    );

export default VerticalStepper;
