import React, { useContext, useMemo } from 'react';
import { Pane, Text, Radio, Badge } from 'evergreen-ui';
import _ from 'lodash';
import { DateTime } from 'luxon';
import styled from 'styled-components';

import { SelectedComplianceItem } from '../../../pages/asset-pages/selected-complianceI-item-context';

const ComplianceDocumentUploadHistory = () => {
    const {
        showDocumentUpload,
        selectedComplianceItemDocuments,
        isLoadingSelectedComplianceItemDocuments,
        selectedBatchId,
        setSelectedBatchId,
    } = useContext(SelectedComplianceItem);

    const radioOptions = useMemo(
        () =>
            _.map(
                _.groupBy(selectedComplianceItemDocuments, 'batchId'),
                (group) => ({
                    label: DateTime.fromMillis(group[0].createdAt).toFormat(
                        'd LLL yyyy  HH:mm'
                    ),
                    value: group[0].batchId,
                    hasMultiple: _.size(group) > 1,
                })
            ),
        [selectedComplianceItemDocuments]
    );

    const selectBatch = (value) => () => {
        if (value === 'all') {
            setSelectedBatchId(null);
            return;
        }
        setSelectedBatchId(value);
    };

    if (!showDocumentUpload || _.isEmpty(radioOptions)) {
        return null;
    }

    if (isLoadingSelectedComplianceItemDocuments) {
        return (
            <Pane
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Text marginTop="1rem">Loading...</Text>
            </Pane>
        );
    }

    return (
        <Pane>
            <Pane marginBottom={8}>
                <Text fontSize={16} fontWeight={400} color="#0C2138">
                    Previous Uploads
                </Text>
            </Pane>
            <StyledRadioGroup
                backgroundColor="#ffffff"
                border="1px solid #E6E8F0"
                borderRadius={8}
                aria-label="Radio Group Label 16"
                role="group"
            >
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingX={18}
                    paddingY={10}
                    onClick={selectBatch('all')}
                    cursor="pointer"
                >
                    <Radio
                        checked={!selectedBatchId}
                        size={16}
                        value="all"
                        name="show-all"
                        label="All Uploads"
                        onChange={selectBatch}
                    />
                </Pane>
                {_.map(radioOptions, (option) => (
                    <Pane
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        paddingX={18}
                        paddingY={10}
                        onClick={selectBatch(option.value)}
                        cursor="pointer"
                    >
                        <Radio
                            checked={selectedBatchId === option.value}
                            size={16}
                            value={option.value}
                            name={option.value}
                            label={option.label}
                        />
                        {option.hasMultiple && (
                            <Badge color="neutral" fontSize={11}>
                                Multiple
                            </Badge>
                        )}
                    </Pane>
                ))}
            </StyledRadioGroup>
        </Pane>
    );
};

export default ComplianceDocumentUploadHistory;

const StyledRadioGroup = styled(Pane)`
    &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-child {
        border-bottom: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    div {
        margin: 0;
        border-bottom: 1px solid #e6e8f0;

        &:hover {
            background-color: #f3f6ff;
        }

        *:checked + div {
            background-color: #474d66;
            box-shadow: inset 0 0 0 -1px #474d66;
        }

        *:not([disabled]):checked:hover + div {
            background-color: #474d66;
            box-shadow: inset 0 0 0 -1px #474d66;
        }

        span {
            font-size: 12px;
            font-weight: 600;
            color: #0c2138;
        }
    }
`;
