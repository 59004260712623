import React, { useMemo, useCallback, useEffect, useState } from 'react';
import {
    Button,
    ErrorIcon,
    Heading,
    Pane,
    Paragraph,
    SelectField,
    TextInput,
    TextInputField,
    Text,
    Radio,
    SelectMenu,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { AddressAutoCompleteField, PortfolioTypeSelect } from '../../molecules';
import { getAsset } from '../../../reducers/asset-reducer/asset.actions';
import { portfolioSelector } from '../../../reducers/portfolio-reducer/portfolio.reducer';
import {
    createPortfolio,
    getAllPortfoliosForBusiness,
} from '../../../reducers/portfolio-reducer/portfolio.actions';
import { taskSelector } from '../../../reducers/task-reducer/task.reducer';
import { getAvailableAssignees } from '../../../reducers/task-reducer/task.actions';
import { contactsSelector } from '../../../reducers/contacts-reducer/contacts.reducer';
import { getAllContactsForBusiness } from '../../../reducers/contacts-reducer/contacts.actions';
import ContactsSidebar from '../../contacts/contacts-sidebar';

const PROPERTY_TYPES = [
    { label: 'Park', value: 'park' },
    { label: 'Building', value: 'building' },
    { label: 'Tenant', value: 'tenant' },
];

const EditAssetForm = ({ submitForm, onSuccess, asset, assetType }) => {
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        portfolioType: Yup.string().required('Building use is required'),
        propertyType: Yup.string().required('Property type is required'),
        assetName: Yup.string().required('Building name is required'),
        buildingManagerIds: Yup.array(Yup.number()).min(
            1,
            'At least one manager is required'
        ),
        landlordContactId: Yup.number(),
        erfNumber: Yup.string(),
        permissibleCoverage: Yup.string(),
        builtCoverage: Yup.string(),
        permissibleFar: Yup.string(),
        builtFar: Yup.string(),
        grossLettableArea: Yup.string(),
        grossBuildingArea: Yup.string(),
        storey: Yup.string(),
        addressLine1: Yup.string().required('Street address is required'),
        addressLine2: Yup.string(),
        suburb: Yup.string(),
        city: Yup.string().required('City is required'),
        province: Yup.string().required('Province is required'),
        postalCode: Yup.string().required('Postal code is required'),
        country: Yup.string().required('Country is required'),
        lat: Yup.string(),
        long: Yup.string(),
    });

    const { contacts } = useSelector(contactsSelector);
    const { portfolios } = useSelector(portfolioSelector);
    const { assignees } = useSelector(taskSelector);

    const [addContact, setAddContact] = useState(false);

    const assigneeOptions = useMemo(
        () =>
            assignees.map(({ id, name: label }) => ({
                value: Number(id),
                label,
            })),
        [assignees]
    );

    const selectedBuildingManagerIdsText = useCallback(
        ({ buildingManagerIds }) =>
            buildingManagerIds?.length
                ? assigneeOptions
                      .map(({ value, label }) =>
                          buildingManagerIds.includes(Number(value))
                              ? label
                              : null
                      )
                      .filter((value) => !!value)
                      .join(', ')
                : 'Select Property Manager(s)',
        [assigneeOptions]
    );

    const onPropertyManagerSelect = ({ value }, setValues, values) => {
        setValues({
            ...values,
            buildingManagerIds: [...values.buildingManagerIds, Number(value)],
        });
    };

    const onPropertyManagerDeselect = ({ value }, setValues, values) => {
        setValues({
            ...values,
            buildingManagerIds: values.buildingManagerIds.filter(
                (id) => Number(id) !== Number(value)
            ),
        });
    };

    const editAsset = async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);

        try {
            let portfolio = _.find(portfolios, {
                name: values.portfolioType,
            });
            if (!portfolio) {
                portfolio = await dispatch(
                    createPortfolio({
                        portfolioType: values.portfolioType,
                    })
                );
                await dispatch(getAllPortfoliosForBusiness());
            }

            await submitForm({
                ...values,
                name: values.assetName,
                portfolioId: portfolio.id,
                address: {
                    addressLine1: values.addressLine1,
                    addressLine2: values.addressLine2,
                    suburb: values.suburb,
                    city: values.city,
                    province: values.province,
                    postalCode: values.postalCode,
                    country: values.country,
                    lat: values.lat,
                    long: values.long,
                },
            });
            const updatedAsset = await dispatch(
                getAsset(asset.id, false, true, false)
            );
            onSuccess(updatedAsset);
        } catch (error) {
            const errorMessage = _.get(
                error,
                'response.data.message',
                error.message
            );
            setFieldError('searchAddress', errorMessage);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        dispatch(getAvailableAssignees());
        dispatch(getAllContactsForBusiness());
    }, [dispatch]);

    const handleLandlordChange = (value, setFieldValue) => {
        if (value === 'new') {
            setAddContact(true);
            setFieldValue('landlordContactId', '');
        } else {
            setFieldValue('landlordContactId', Number(value));
        }
    };

    return (
        <Pane>
            <Formik
                initialValues={{
                    ...asset,
                    ...asset.address,
                    portfolioType: asset.portfolioName,
                    searchAddress: `${asset.address.addressLine1}, ${asset.address.suburb}, ${asset.address.city}, ${asset.address.postalCode}, ${asset.address.province}, ${asset.address.country}`,
                    propertyType: asset.type,
                }}
                validationSchema={validationSchema}
                onSubmit={editAsset}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setValues,
                    setFieldValue,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Pane marginBottom={18}>
                            <Heading>{assetType} Details</Heading>
                        </Pane>
                        <Pane marginBottom={16}>
                            <Text lineHeight="18px" color="#101840">
                                Property Type *
                            </Text>
                            <Pane
                                aria-label="Radio Group Label 12"
                                role="group"
                                display="flex"
                                gap={32}
                                marginTop={8}
                            >
                                {PROPERTY_TYPES.map(({ label, value }) => (
                                    <Radio
                                        disabled={
                                            !(value === values.propertyType)
                                        }
                                        label={label}
                                        checked={value === values.propertyType}
                                    />
                                ))}
                            </Pane>
                        </Pane>
                        <PortfolioTypeSelect
                            name="buildingUse"
                            value={values.buildingUse}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showAllOptions
                            label={`${values.propertyType} use`}
                        />
                        <TextInputField
                            label={`${assetType} Name`}
                            type="text"
                            name="assetName"
                            placeholder="My Building"
                            value={values.assetName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                !!touched.assetName && !!errors.assetName
                            }
                            validationMessage={
                                touched.assetName && errors.assetName
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                            required
                        />
                        <TextInputField
                            label="Erf. Number"
                            type="text"
                            name="erfNumber"
                            placeholder="Erf. Number"
                            value={values.erfNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                !!touched.erfNumber && !!errors.erfNumber
                            }
                            validationMessage={
                                touched.erfNumber && errors.erfNumber
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                        <Pane display="flex" flexDirection="column">
                            <Text color="#101840" fontWeight={500}>
                                Property Manager(s) *
                            </Text>
                            <SelectMenu
                                isMultiSelect
                                options={assigneeOptions}
                                hasTitle={false}
                                filterPlaceholder="Search Users"
                                onSelect={(propertyManager) =>
                                    onPropertyManagerSelect(
                                        propertyManager,
                                        setValues,
                                        values
                                    )
                                }
                                onDeselect={(propertyManager) =>
                                    onPropertyManagerDeselect(
                                        propertyManager,
                                        setValues,
                                        values
                                    )
                                }
                                selected={values.buildingManagerIds}
                                validationMessage={errors.buildingManagerIds}
                            >
                                <Button
                                    type="button"
                                    height={40}
                                    marginY={12}
                                    marginBottom={
                                        errors.buildingManagerIds ? 8 : 20
                                    }
                                    justifyContent="start"
                                >
                                    {selectedBuildingManagerIdsText(values)}
                                </Button>
                            </SelectMenu>
                            {errors.buildingManagerIds && (
                                <Text
                                    lineHeight="18px"
                                    fontSize="12px"
                                    color="#D14343"
                                    marginBottom={12}
                                >
                                    <ErrorIcon size={14} marginRight="6px" />{' '}
                                    {errors.buildingManagerIds}
                                </Text>
                            )}
                        </Pane>
                        <SelectField
                            name="landlordContactId"
                            width="100%"
                            inputHeight={40}
                            label="Tenant"
                            onBlur={handleBlur}
                            isInvalid={
                                touched.landlordContactId &&
                                errors.landlordContactId
                            }
                            onChange={(e) =>
                                handleLandlordChange(
                                    e.target.value,
                                    setFieldValue
                                )
                            }
                            value={values.landlordContactId}
                            validationMessage={
                                touched.landlordContactId &&
                                errors.landlordContactId
                                    ? errors.landlordContactId
                                    : undefined
                            }
                        >
                            <option selected value="" disabled>
                                Select Tenant
                            </option>
                            {contacts ? (
                                contacts.map(
                                    ({
                                        createdAt,
                                        firstName,
                                        lastName,
                                        profession,
                                        companyName,
                                        id,
                                    }) => (
                                        <option key={createdAt} value={id}>
                                            {`${firstName} ${lastName} - ${profession} - ${companyName}`}
                                        </option>
                                    )
                                )
                            ) : (
                                <option disabled>No Tenants Available</option>
                            )}
                            <option value="new">+ Add Tenant</option>
                        </SelectField>
                        <TextInputField
                            label="Permissible Coverage"
                            type="text"
                            name="permissibleCoverage"
                            placeholder="Permissible Coverage"
                            value={values.permissibleCoverage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                !!touched.permissibleCoverage &&
                                !!errors.permissibleCoverage
                            }
                            validationMessage={
                                touched.permissibleCoverage &&
                                errors.permissibleCoverage
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                        <TextInputField
                            label="Built Coverage"
                            type="text"
                            name="builtCoverage"
                            placeholder="Built Coverage"
                            value={values.builtCoverage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                !!touched.builtCoverage &&
                                !!errors.builtCoverage
                            }
                            validationMessage={
                                touched.builtCoverage && errors.builtCoverage
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                        <TextInputField
                            label="Permissible FAR"
                            description="Floor Area Ratio"
                            type="text"
                            name="permissibleFar"
                            placeholder="Permissible FAR"
                            value={values.permissibleFar}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                !!touched.permissibleFar &&
                                !!errors.permissibleFar
                            }
                            validationMessage={
                                touched.permissibleFar && errors.permissibleFar
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                        <TextInputField
                            label="Built FAR"
                            description="Floor Area Ratio"
                            type="text"
                            name="builtFar"
                            placeholder="Built FAR"
                            value={values.builtFar}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!touched.builtFar && !!errors.builtFar}
                            validationMessage={
                                touched.builtFar && errors.builtFar
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                        <TextInputField
                            label="GLA"
                            description="Gross Lettable Area"
                            type="text"
                            name="grossLettableArea"
                            placeholder="GLA"
                            value={values.grossLettableArea}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                !!touched.grossLettableArea &&
                                !!errors.grossLettableArea
                            }
                            validationMessage={
                                touched.grossLettableArea &&
                                errors.grossLettableArea
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                        <TextInputField
                            label="GBA"
                            description="Gross Building Area"
                            type="text"
                            name="grossBuildingArea"
                            placeholder="GBA"
                            value={values.grossBuildingArea}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                                !!touched.grossBuildingArea &&
                                !!errors.grossBuildingArea
                            }
                            validationMessage={
                                touched.grossBuildingArea &&
                                errors.grossBuildingArea
                            }
                            spellCheck={false}
                            width="100%"
                            inputHeight={40}
                        />
                        <SelectField
                            name="storey"
                            width="100%"
                            inputHeight={40}
                            label="Storey"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.storey}
                            isInvalid={!!touched.storey && !!errors.storey}
                            validationMessage={touched.storey && errors.storey}
                        >
                            <option value="" disabled>
                                Select storey
                            </option>
                            <option value="single">Single</option>
                            <option value="multi">Multi</option>
                        </SelectField>
                        <Pane marginTop={38} marginBottom={14}>
                            <Heading>Asset Address</Heading>
                        </Pane>
                        <Pane>
                            <AddressAutoCompleteField
                                label="Building Address"
                                type="text"
                                name="searchAddress"
                                placeholder="Search Address"
                                width="100%"
                                inputHeight={40}
                                required
                                value={values.searchAddress}
                            />
                        </Pane>
                        <Pane marginTop="32px">
                            <TextInput
                                type="text"
                                name="addressLine2"
                                placeholder="Unit Number and Building Name"
                                value={values.addressLine2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    !!touched.addressLine2 &&
                                    !!errors.addressLine2
                                }
                                validationMessage={
                                    touched.addressLine2 && errors.addressLine2
                                }
                                spellCheck={false}
                                width="100%"
                                height={40}
                            />
                        </Pane>
                        <Pane marginTop={12}>
                            <TextInput
                                type="text"
                                name="addressLine1"
                                placeholder="Street Address"
                                value={values.addressLine1}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    !!touched.addressLine1 &&
                                    !!errors.addressLine1
                                }
                                validationMessage={
                                    touched.addressLine1 && errors.addressLine1
                                }
                                spellCheck={false}
                                width="100%"
                                height={40}
                            />
                        </Pane>
                        <Pane marginTop={12}>
                            <TextInput
                                type="text"
                                name="suburb"
                                placeholder="Suburb"
                                value={values.suburb}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={!!touched.suburb && !!errors.suburb}
                                validationMessage={
                                    touched.suburb && errors.suburb
                                }
                                spellCheck={false}
                                width="100%"
                                height={40}
                            />
                        </Pane>
                        <Pane marginTop={12} display="flex">
                            <Pane flex={1} marginRight={6}>
                                <TextInput
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!touched.city && !!errors.city}
                                    validationMessage={
                                        touched.city && errors.city
                                    }
                                    spellCheck={false}
                                    width="100%"
                                    height={40}
                                />
                            </Pane>
                            <Pane flex={1} marginLeft={6}>
                                <TextInput
                                    type="text"
                                    name="province"
                                    placeholder="Province"
                                    value={values.province}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                        !!touched.province && !!errors.province
                                    }
                                    validationMessage={
                                        touched.province && errors.province
                                    }
                                    spellCheck={false}
                                    width="100%"
                                    height={40}
                                />
                            </Pane>
                        </Pane>
                        <Pane marginTop={12} display="flex">
                            <Pane flex={1} marginRight={6}>
                                <TextInput
                                    type="text"
                                    name="postalCode"
                                    placeholder="Postal Code"
                                    value={values.postalCode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                        !!touched.postalCode &&
                                        !!errors.postalCode
                                    }
                                    validationMessage={
                                        touched.postalCode && errors.postalCode
                                    }
                                    spellCheck={false}
                                    width="100%"
                                    height={40}
                                />
                            </Pane>
                            <Pane flex={1} marginLeft={6}>
                                <TextInput
                                    type="text"
                                    name="country"
                                    placeholder="Country"
                                    value={values.country}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                        !!touched.country && !!errors.country
                                    }
                                    validationMessage={
                                        touched.country && errors.country
                                    }
                                    spellCheck={false}
                                    width="100%"
                                    height={40}
                                />
                            </Pane>
                        </Pane>
                        <TextInput
                            type="text"
                            name="lat"
                            value={values.lat}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            display="none"
                        />
                        <TextInput
                            type="text"
                            name="long"
                            value={values.long}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            display="none"
                        />
                        {!!errors.searchAddress && (
                            <Pane
                                display="flex"
                                marginTop={12}
                                alignItems="center"
                            >
                                <ErrorIcon color="#D14343" size={14} />
                                <Paragraph color="#D14343" marginLeft={6}>
                                    {errors.searchAddress}
                                </Paragraph>
                            </Pane>
                        )}
                        <Button
                            appearance="primary"
                            height={40}
                            type="submit"
                            width="100%"
                            marginTop={48}
                            marginBottom={32}
                            isLoading={isSubmitting}
                        >
                            Update {assetType}
                        </Button>
                    </Form>
                )}
            </Formik>
            <ContactsSidebar
                isShown={addContact}
                isEditing={false}
                canChooseBusiness
                close={() => setAddContact(false)}
            />
        </Pane>
    );
};

EditAssetForm.propTypes = {
    asset: PropTypes.shape().isRequired,
    submitForm: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    assetType: PropTypes.string.isRequired,
};

export default EditAssetForm;
