/* eslint-disable import/prefer-default-export */
import taskURLs from './task-service-urls';
import networkService from '../network-service/network.service';

const baseURL = 'https://xkrn-4n4o-aozy.e2.xano.io/api:JwKSQXJV';

export function getTasks({
    search = '',
    sort = null,
    page = 1,
    statusFilter = null,
    typeFilter = null,
    userFilter = null,
    startDate = null,
    endDate = null,
    buildingId = 0,
}) {
    const url = taskURLs.getTasksURL();
    const config = {
        baseURL,
        params: {
            search,
            sort,
            page,
            statusFilter,
            typeFilter,
            startDate,
            endDate,
            buildingId,
            user_id: userFilter,
        },
    };
    return networkService.makeGet(url, config);
}

export async function createTask(task) {
    const url = taskURLs.createTaskURL();
    return networkService.makePost(url, task, { baseURL });
}

export async function editTask(task) {
    const url = taskURLs.editTaskURL();
    return networkService.makePut(url, task, { baseURL });
}

export function updateTask(update) {
    const url = taskURLs.updateTaskURL();
    return networkService.makePost(url, update, { baseURL });
}

export function deleteTask(id) {
    const url = taskURLs.deleteTaskURL(id);
    return networkService.makeDelete(
        url,
        {
            task_items_id: id,
        },
        { baseURL }
    );
}

// Categories

export function getTasksCategories() {
    const url = taskURLs.getTasksCategoriesURL();
    return networkService.makeGet(url, { baseURL });
}

export function deleteTasksCategory(id) {
    const url = `${taskURLs.deleteTasksCategoriesURL()}?tasks_categories_id=${id}`;
    return networkService.makeDelete(url, null, { baseURL });
}

export function createTasksCategory(category) {
    const url = taskURLs.createTasksCategoriesURL();
    return networkService.makePost(url, category, {
        baseURL,
    });
}

export function updateTasksCategory({ categoryId, name }) {
    const url = taskURLs.updateTasksCategoriesURL();
    return networkService.makePut(
        url,
        {
            tasks_categories_id: categoryId,
            name,
        },
        {
            baseURL,
        }
    );
}

// Types

export function createTasksType({ categoryId, newTypeName: name }) {
    const url = taskURLs.createTasksTypeURL();
    return networkService.makePost(
        url,
        {
            tasks_categories_id: categoryId,
            name,
        },
        { baseURL }
    );
}

export function updateTasksType({ typeId, value: name }) {
    const url = taskURLs.updateTasksTypeURL();
    return networkService.makePut(
        url,
        {
            task_types_id: typeId,
            name,
        },
        { baseURL }
    );
}

export function deleteTasksType(id) {
    const url = taskURLs.deleteTasksTypeURL(id);
    return networkService.makeDelete(url, null, { baseURL });
}

// Buildings

export function getAvailableBuildings() {
    const url = taskURLs.getAvailableBuildingsURL();
    return networkService.makeGet(url, { baseURL });
}

// Assignees

export function getAvailableAssignees() {
    const url = taskURLs.getAvailableAssigneesURL();
    return networkService.makeGet(url, { baseURL });
}

// Attachments

export function getAttachmentUploadLink(taskId, fileType) {
    const url = taskURLs.getAttachmentUploadLinkRequestURL();
    return networkService.makePost(
        url,
        { task_id: taskId, file_type: fileType },
        { baseURL }
    );
}

export function setAttachmentsUploadedSuccessfully({
    taskId,
    fileName,
    fileId,
    thumbnailId,
    batchId,
}) {
    const url = taskURLs.setAttachmentsUploadedSuccessfullyURL();
    return networkService.makePost(
        url,
        {
            request_type: 'task',
            task_items_id: taskId,
            friendly_name: fileName,
            file_name: fileName,
            file_id: fileId,
            thumbnail_id: thumbnailId,
            batch_id: batchId,
        },
        { baseURL: 'https://xkrn-4n4o-aozy.e2.xano.io/api:KIF-JcPf' }
    );
}

// Dashboard

export function getTasksOverviewData({
    startDate,
    endDate,
    statusFilter,
    buildingId = 0,
}) {
    let url = `${taskURLs.getTasksOverviewData()}?statusFilter=${statusFilter}&buildingId=${buildingId}`;
    if (startDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
    }
    return networkService.makeGet(url, { baseURL });
}

export function getTasksOverallGraphData({
    startDate,
    endDate,
    statusFilter,
    buildingId = 0,
}) {
    let url = `${taskURLs.getTasksOverallGraphData()}?statusFilter=${statusFilter}&buildingId=${buildingId}`;
    if (startDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
    }
    return networkService.makeGet(url, { baseURL });
}

export default {
    getTasks,
    createTask,
    editTask,
    updateTask,
    deleteTask,

    getTasksCategories,
    deleteTasksCategory,
    createTasksCategory,
    updateTasksCategory,

    createTasksType,
    updateTasksType,
    deleteTasksType,

    getAvailableBuildings,

    getAvailableAssignees,

    getAttachmentUploadLink,
    setAttachmentsUploadedSuccessfully,

    getTasksOverviewData,
    getTasksOverallGraphData,
};
