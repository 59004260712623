import React, { useState, useEffect } from 'react';
import {
    Pane,
    Text,
    Button,
    PlusIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    TextInputField,
    CrossIcon,
    EditIcon,
    TrashIcon,
    Spinner,
    ErrorIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash';

import {
    createTasksType,
    getTasksCategories,
    updateTasksType,
    deleteTasksType,
} from '../../reducers/task-reducer/task.actions';
import { colors } from '../../theme/theme';
import '../maintenance/maintenance.css';
import { successToast, errorToast } from '../toasts';

function CategoryItem({
    name,
    abbreviation,
    types,
    categoryId,
    handleCategoryDelete,
    setEditCategoryForm,
    handleCancelAddTypes,
    handleAddTypes,
    isDefault,
    deletingCategory,
    confirmError,
    setConfirmError,
}) {
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState(!types.length);
    const [addingType, setAddingType] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showCategoryOptions, setShowCategoryOptions] = useState(false);
    const [showTypeOptionsId, setShowTypeOptionsId] = useState(null);
    const [deletingType, setDeletingType] = useState(null);
    const [typeLoading, setTypeLoading] = useState(false);
    const [newTypeName, setNewTypeName] = useState('');
    const [editTypeValue, setEditTypeValue] = useState({
        id: null,
        original: '',
        new: '',
    });

    useEffect(() => {
        if (confirmError && !types.length) setExpanded(true);

        return () => {
            setConfirmError(false);
        };
    }, [confirmError, types.length, setConfirmError]);

    const handleEnter = async ({ key, target: { value } }) => {
        if (key === 'Enter' && value.length > 1) {
            try {
                setTypeLoading(true);
                setLoading(true);
                if (editTypeValue.new && editTypeValue.id) {
                    await dispatch(
                        updateTasksType({
                            typeId: editTypeValue.id,
                            value: editTypeValue.new,
                        })
                    );
                } else {
                    await dispatch(
                        createTasksType({ categoryId, newTypeName })
                    );
                }
                successToast(
                    `Successfully ${
                        editTypeValue.new && editTypeValue.id
                            ? 'updated'
                            : 'created'
                    } ${editTypeValue.original || newTypeName || 'New Type'}`
                );
                handleCancelAddTypes(categoryId);
                dispatch(getTasksCategories());
                setEditTypeValue({ original: '', new: '', id: null });
                setNewTypeName('');
                setAddingType(false);
            } catch (error) {
                errorToast(get(error, 'response.data.message', error.message));
            } finally {
                setTypeLoading(null);
                setLoading(false);
            }
        }
    };

    const clearNewType = () => {
        setNewTypeName('');
        setEditTypeValue('');
        setAddingType(false);
    };

    const handleTypeChange = ({ target: { value } }) => {
        if (editTypeValue.original) {
            setEditTypeValue((state) => ({ ...state, new: value }));
        } else {
            setNewTypeName(value);
        }
        handleAddTypes(categoryId, value);
    };

    const handleTypeDelete = async (typeId) => {
        try {
            setDeletingType(typeId);
            await dispatch(deleteTasksType(typeId));
            successToast('Type Successfully Deleted');
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setDeletingType(null);
        }
    };

    const fullName = `${abbreviation ? `${abbreviation} - ` : ''} ${name}`;

    return (
        <Pane display="flex" flex={1} flexDirection="column" marginY={4}>
            <Pane
                display="flex"
                width="100%"
                height={40}
                justifyContent="space-between"
                alignItems="center"
                cursor="pointer"
                paddingRight={15}
                elevation={1}
                onClick={() => {
                    setAddingType(false);
                    setExpanded(!expanded);
                }}
                onMouseEnter={() => setShowCategoryOptions(true)}
                onMouseLeave={() => setShowCategoryOptions(false)}
            >
                <Pane
                    display="flex"
                    justifyContent="space-between"
                    paddingX={10}
                    width="100%"
                    alignItems="center"
                >
                    <CategoryName role="button" marginRight="auto" width="100%">
                        {fullName}
                    </CategoryName>
                    <Pane
                        display={showCategoryOptions ? 'flex' : 'none'}
                        justifyContent="start"
                        gap={8}
                    >
                        <Action
                            alignItems="center"
                            justifyContent="start"
                            role="button"
                            display={!isDefault ? 'flex' : 'none'}
                            onClick={(e) =>
                                setEditCategoryForm(
                                    e,
                                    name,
                                    abbreviation,
                                    categoryId
                                )
                            }
                        >
                            <EditIcon color={`${colors.neutral700}`} />
                        </Action>

                        <Action
                            display="flex"
                            alignItems="center"
                            justifyContent="start"
                            role="button"
                            onClick={(e) => handleCategoryDelete(e, categoryId)}
                        >
                            {Number(deletingCategory) === Number(categoryId) ? (
                                <Spinner size={14} margin="auto" />
                            ) : (
                                <TrashIcon className="trash" color="#B90E0A" />
                            )}
                        </Action>
                    </Pane>
                </Pane>

                {expanded ? (
                    <ChevronUpIcon color={colors.neutral700} />
                ) : (
                    <ChevronDownIcon color={colors.neutral700} />
                )}
            </Pane>
            {expanded && (
                <Pane
                    display="flex"
                    flexDirection="column"
                    alignItems="start"
                    gap={4}
                    paddingBottom={4}
                    paddingLeft={50}
                    marginTop={8}
                >
                    {types.length ? (
                        types.map((type) => (
                            <Pane
                                key={type.id}
                                borderBottom={`1px solid ${colors.neutral300}`}
                                display="flex"
                                width="100%"
                                height={40}
                                justifyContent="space-between"
                                alignItems="center"
                                onMouseEnter={() =>
                                    setShowTypeOptionsId(type.id)
                                }
                                onMouseLeave={() => setShowTypeOptionsId(null)}
                            >
                                <Text fontWeight={500} fontSize={15}>
                                    {type.name || type}
                                </Text>
                                <Pane
                                    display={
                                        showTypeOptionsId === type.id
                                            ? 'flex'
                                            : 'none'
                                    }
                                    justifyContent="start"
                                    gap={8}
                                    marginRight={16}
                                >
                                    <Action
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="start"
                                        role="button"
                                        onClick={() => {
                                            handleAddTypes(
                                                categoryId,
                                                type.name,
                                                type.id
                                            );
                                            setEditTypeValue({
                                                original: type.name || type,
                                                new: type.name || type,
                                                id: type.id || null,
                                            });
                                        }}
                                        cursor="pointer"
                                    >
                                        <EditIcon
                                            color={`${colors.neutral700}`}
                                        />
                                    </Action>
                                    <Action
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="start"
                                        role="button"
                                        onClick={() =>
                                            handleTypeDelete(type.id)
                                        }
                                        cursor="pointer"
                                        className="action trash"
                                    >
                                        {Number(deletingType) ===
                                        Number(type.id) ? (
                                            <Spinner size={14} margin="auto" />
                                        ) : (
                                            <TrashIcon color="#B90E0A" />
                                        )}
                                    </Action>
                                </Pane>
                            </Pane>
                        ))
                    ) : (
                        <>
                            <Pane
                                borderBottom={`1px solid ${colors.neutral500}`}
                                display="flex"
                                width="100%"
                                height={40}
                                alignItems="center"
                            >
                                <Text fontWeight={500} fontSize={15}>
                                    There are no Types in this Category
                                </Text>
                            </Pane>
                            <Pane>
                                {!types.length && (
                                    <Text
                                        display="flex"
                                        alignItems="center"
                                        lineHeight="18px"
                                        fontSize="12px"
                                        color="#D14343"
                                    >
                                        <ErrorIcon
                                            size={14}
                                            marginRight="6px"
                                        />
                                        Type is Required
                                    </Text>
                                )}
                            </Pane>
                        </>
                    )}
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {addingType || editTypeValue.original ? (
                        typeLoading ? (
                            <Spinner size={18} marginX="auto" marginTop={15} />
                        ) : (
                            <Pane
                                display="flex"
                                width="100%"
                                paddingTop={20}
                                alignItems="center"
                                justifyContent="center"
                                gap={15}
                                paddingRight={10}
                            >
                                <TextInputField
                                    className="add-category-input"
                                    name={categoryId}
                                    autoFocus
                                    width="100%"
                                    label={
                                        editTypeValue.original
                                            ? `Editing ${editTypeValue.original}`
                                            : 'New Type'
                                    }
                                    inputHeight={40}
                                    onKeyDown={handleEnter}
                                    value={editTypeValue.new || newTypeName}
                                    onChange={handleTypeChange}
                                    placeholder="Press Enter Key to Add"
                                />
                                <Pane
                                    role="button"
                                    cursor="pointer"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    onClick={() => {
                                        clearNewType();
                                        handleCancelAddTypes(categoryId);
                                    }}
                                    hoverElevation={1}
                                    padding={5}
                                    borderRadius={9999}
                                >
                                    <CrossIcon color="#8f95b2" />
                                </Pane>
                            </Pane>
                        )
                    ) : (
                        <Button
                            iconBefore={<PlusIcon />}
                            isLoading={loading}
                            onClick={() => {
                                setAddingType(true);
                                handleAddTypes(categoryId, newTypeName);
                            }}
                            appearance="minimal"
                        >
                            Add New Type
                        </Button>
                    )}
                </Pane>
            )}
        </Pane>
    );
}

const CategoryName = styled(Text)`
    font-weight: 600;
    font-size: 16px;
`;

const Action = styled(Pane)`
    border-radius: 4px;
    width: 28px;
    align-items: center;
    justify-content: center;
    height: 28px;
    padding: 2px;
    &:hover {
        background-color: ${colors.neutral300};
    }
`;

CategoryItem.propTypes = {
    name: PropTypes.string.isRequired,
    abbreviation: PropTypes.string.isRequired,
    categoryId: PropTypes.number.isRequired,
    types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleCategoryDelete: PropTypes.func.isRequired,
    setEditCategoryForm: PropTypes.func.isRequired,
    handleAddTypes: PropTypes.func.isRequired,
    handleCancelAddTypes: PropTypes.func.isRequired,
    isDefault: PropTypes.bool.isRequired,
    deletingCategory: PropTypes.string.isRequired,
    confirmError: PropTypes.bool.isRequired,
    setConfirmError: PropTypes.func.isRequired,
};

export default CategoryItem;
