/* eslint-disable no-underscore-dangle */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    SideSheet,
    Pane,
    Heading,
    Text,
    ErrorIcon,
    SearchInput,
    Checkbox,
    Tooltip,
    InfoSignIcon,
    CloudUploadIcon,
    OfficeIcon,
    Spinner,
    ArrowRightIcon,
} from 'evergreen-ui';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { colors } from '../../theme/theme';
import getDate from '../../helpers/get-date';
import { notificationModel } from '../../models/notifications.models';
import { markNotificationRead } from '../../reducers/notifications-reducer/notifications.actions';
import {
    assetSelector,
    setCurrentTenantAction,
    setCurrentBuildingAction,
    setCurrentAssetAction,
} from '../../reducers/asset-reducer/asset.reducer';
import {
    setSearchAction as setMaintenanceSearch,
    setPageAction as setMaintenancePage,
    setDateRangeAction as setMaintenanceDates,
} from '../../reducers/maintenance-reducer/maintenance-reducer';
import {
    setTablePageAction as setTaskPage,
    setStatusFilterAction as setTaskFilter,
    setDateRangeAction as setTaskDates,
} from '../../reducers/task-reducer/task.reducer';
import { useSearchParams } from '../../context/search-params-context';

const NOTIFICATION_ICONS = {
    Warning: <ErrorIcon color="#f3403b" marginBottom="auto" />,
    Info: <InfoSignIcon color="#ecbc00" marginBottom="auto" />,
    General: <ErrorIcon color="#b2c9df" marginBottom="auto" />,
    compliance: (
        <CloudUploadIcon
            color={colors.secondary500}
            marginBottom="auto"
            size={18}
        />
    ),
    park: (
        <OfficeIcon color={colors.secondary500} marginBottom="auto" size={18} />
    ),
    building: (
        <OfficeIcon color={colors.secondary500} marginBottom="auto" size={18} />
    ),
    tenant: (
        <OfficeIcon color={colors.secondary500} marginBottom="auto" size={18} />
    ),
};

function NotificationsSidebar({ isShown, close, notifications }) {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const { setSearchParams } = useSearchParams();

    const [notificationLoading, setNotificationLoading] = useState(null);

    const { assets } = useSelector(assetSelector);

    const navigate = useNavigate();

    const handleSearch = ({ target: { value } }) => {
        setSearch(value);
    };

    const markAsRead = async (id) => {
        setNotificationLoading(id);
        await dispatch(markNotificationRead(id));
        setNotificationLoading(null);
    };

    const memoNotifications = useMemo(() => {
        if (!search) {
            return [...notifications].reverse();
        }
        return [...notifications]
            .reverse()
            .filter(
                (notification) =>
                    notification.message
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    notification.heading
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    notification.category
                        .toLowerCase()
                        .includes(search.toLowerCase())
            );
    }, [search, notifications]);

    const getPropertyById = (propertyId) => {
        const asset = assets.find(
            (_asset) => Number(_asset.id) === Number(propertyId)
        );
        if (asset) {
            return asset;
        }
        return null;
    };

    const setProperties = (propertyId) => {
        const params = {};
        const properties = {};

        const property = getPropertyById(propertyId);
        if (!property) {
            setNotificationLoading(null);
            return;
        }
        if (property.type === 'tenant') {
            if (property.parentId) {
                const building = getPropertyById(property.parentId);

                if (building.parentId) {
                    const park = getPropertyById(building.parentId);

                    params.currentAssetId = park?.id;
                    params.currentBuildingId = building?.id;
                    params.currentTenantId = property?.id;

                    properties.park = park;
                    properties.building = building;
                    properties.tenant = property;
                } else {
                    params.currentBuildingId = building?.id;
                    params.currentTenantId = property?.id;

                    properties.building = building;
                    properties.tenant = property;
                }
            }
        } else if (property.type === 'building') {
            if (property.parentId) {
                const park = getPropertyById(property.parentId);

                params.currentAssetId = park?.id;
                params.currentBuildingId = property?.id;

                properties.park = park;
                properties.building = property;
            } else {
                params.currentBuildingId = property?.id;

                properties.building = property;
            }
        } else if (property.type === 'park') {
            params.currentAssetId = property?.id;

            properties.park = property;
        }

        dispatch(setCurrentTenantAction(properties.tenant || null));
        dispatch(setCurrentBuildingAction(properties.building || null));
        dispatch(setCurrentAssetAction(properties.park || null));

        setSearchParams({});

        // eslint-disable-next-line consistent-return
        return {
            properties,
            params,
        };
    };

    const handleViewNotification = async (metadata) => {
        const { type } = metadata;

        const { properties, params } = setProperties(metadata.buildingId);

        if (
            type === 'compliance' ||
            type === 'park' ||
            type === 'building' ||
            type === 'tenant'
        ) {
            let navigationString = '/portfolio/properties';
            if (properties.park) {
                navigationString += `/parks/${properties.park.id}`;
            }
            if (properties.building) {
                navigationString += `/buildings/${properties.building.id}`;
            }
            if (properties.tenant) {
                navigationString += `/tenants/${properties.tenant.id}`;
            }
            navigationString += `${
                type === 'compliance' ? '/compliance' : '/info'
            }`;
            if (type === 'compliance') {
                navigationString += `?groupId=${metadata.complianceGroupId}&categoryId=${metadata.complianceCategoryId}&typeId=${metadata.complianceTypeId}`;
            }
            navigate(navigationString);
        } else if (type === 'task' || type === 'maintenance') {
            if (type === 'maintenance') {
                params.maintenanceItemId = metadata.maintenanceItemId;
                await Promise.all([
                    dispatch(setMaintenancePage(1)),
                    dispatch(setMaintenanceSearch('')),
                    dispatch(
                        setMaintenanceDates({ startDate: '', endDate: '' })
                    ),
                ]);
            } else {
                params.taskItemId = metadata.taskItemId;
                await Promise.all([
                    dispatch(setTaskPage(1)),
                    dispatch(setTaskFilter('')),
                    dispatch(setTaskDates({ startDate: '', endDate: '' })),
                ]);
            }

            navigate(
                `${type === 'task' ? `${type}s` : type}?${new URLSearchParams(
                    params
                ).toString()}`
            );
        }

        setNotificationLoading(null);
        close();
    };

    return (
        <SideSheet
            isShown={isShown}
            onCloseComplete={close}
            preventBodyScrolling
            containerProps={{
                position: 'relative',
            }}
        >
            <Pane>
                <Heading
                    width="max-content"
                    fontSize={22}
                    fontWeight="700"
                    color={colors.primary500}
                    marginLeft={24}
                    marginTop={24}
                >
                    Notifications
                </Heading>

                <div className="divider mb-0" />
                <Pane
                    paddingX={24}
                    overflowY="auto"
                    background={colors.tint1}
                    height="calc(100vh - 65px)"
                    display="flex"
                    flexDirection="column"
                >
                    <>
                        <Pane
                            position="sticky"
                            top={0}
                            background="#fafbff"
                            zIndex={1}
                        >
                            <SearchInput
                                placeholder="Search Notifications..."
                                width="100%"
                                marginY={10}
                                marginBottom={20}
                                onChange={handleSearch}
                            />
                        </Pane>
                        {memoNotifications.length ? (
                            memoNotifications.map((notification, index) => {
                                const {
                                    id,
                                    createdAt,
                                    heading,
                                    message,
                                    read,
                                    category,
                                    metadata,
                                } = notificationModel(notification);

                                const notLast =
                                    index !== notifications.length - 1;

                                return (
                                    <Pane
                                        display="flex"
                                        key={id}
                                        borderBottom={
                                            notLast && '1px solid #8c98a3'
                                        }
                                        padding={5}
                                        paddingY={10}
                                        gap={10}
                                        alignItems="center"
                                    >
                                        {notificationLoading === id ? (
                                            <Pane
                                                display="flex"
                                                flex={1}
                                                height={70}
                                            >
                                                <Spinner margin="auto" />
                                            </Pane>
                                        ) : (
                                            <>
                                                {NOTIFICATION_ICONS[
                                                    metadata.type
                                                ] ||
                                                    NOTIFICATION_ICONS[
                                                        category
                                                    ]}
                                                <Pane
                                                    display="flex"
                                                    flexDirection="column"
                                                    gap={4}
                                                    flex={1}
                                                >
                                                    <Text
                                                        fontWeight={700}
                                                        fontSize={16}
                                                    >
                                                        {heading}{' '}
                                                    </Text>
                                                    <Text>{message}</Text>
                                                    <Pane
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        width="100%"
                                                    >
                                                        <Text fontSize={10}>
                                                            {getDate(createdAt)}
                                                        </Text>
                                                        <NotificationLink
                                                            role="button"
                                                            display="flex"
                                                            alignItems="center"
                                                            gap={6}
                                                            onClick={() => {
                                                                setNotificationLoading(
                                                                    id
                                                                );
                                                                handleViewNotification(
                                                                    metadata
                                                                );
                                                            }}
                                                        >
                                                            View
                                                            <ArrowRightIcon
                                                                size={12}
                                                            />
                                                        </NotificationLink>
                                                    </Pane>
                                                </Pane>
                                                <Pane
                                                    display="flex"
                                                    alignItems="center"
                                                    gap={8}
                                                    marginLeft="auto"
                                                >
                                                    <Tooltip
                                                        content="Mark as Read"
                                                        position="left"
                                                    >
                                                        <Checkbox
                                                            checked={read}
                                                            onChange={() =>
                                                                markAsRead(id)
                                                            }
                                                        />
                                                    </Tooltip>
                                                </Pane>
                                            </>
                                        )}
                                    </Pane>
                                );
                            })
                        ) : (
                            <Text textAlign="center">No Notifications</Text>
                        )}
                    </>
                </Pane>
            </Pane>
        </SideSheet>
    );
}

const NotificationLink = styled(Text)`
    color: ${colors.secondary500};
    border-bottom: 0.5px solid transparent;
    &:hover {
        border-bottom: 0.5px solid ${colors.secondary500};
        cursor: pointer;
    }
`;

NotificationsSidebar.propTypes = {
    isShown: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default NotificationsSidebar;
