import React, { useEffect } from 'react';
import {
    Button,
    Heading,
    Pane,
    Paragraph,
    TextInputField,
    Spinner,
    Text,
} from 'evergreen-ui';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { getUser } from '../../../reducers/user-reducer/user.actions';
import { userSelector } from '../../../reducers/user-reducer/user.reducer';

const BusinessNameForm = ({ initialValues, submitForm, onSuccess }) => {
    const dispatch = useDispatch();
    const { user, isLoading } = useSelector(userSelector);

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    const validationSchema = Yup.object().shape({
        legalName: Yup.string().required('Business legal name is required'),
        friendlyName: Yup.string(),
    });

    const handleFormSubmission = async (
        values,
        { setSubmitting, setFieldError }
    ) => {
        setSubmitting(true);

        try {
            await submitForm(values);
            await onSuccess();
        } catch (error) {
            setFieldError(
                'friendlyName',
                _.get(error, 'response.data.message', error.message)
            );
        } finally {
            setSubmitting(false);
        }
    };

    if (!user || isLoading) {
        return (
            <Pane
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Spinner />
                <Text marginTop="1rem">Loading...</Text>
            </Pane>
        );
    }

    return (
        <>
            <Heading size={900}>
                {user.firstName}, let&apos;s add your business details to your
                profile
            </Heading>
            <Paragraph size={400} marginTop="0.5rem">
                Before continuing you need to load your business details. This
                information will be used to create your unique ID and for
                billing.
            </Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmission}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Pane marginTop="32px">
                            <TextInputField
                                label="Legal Name"
                                type="text"
                                name="legalName"
                                placeholder="My Company (Pty) Ltd"
                                value={values.legalName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    touched.legalName && errors.legalName
                                }
                                validationMessage={
                                    touched.legalName && errors.legalName
                                }
                                spellCheck={false}
                                width="100%"
                                inputHeight={40}
                                required
                            />
                        </Pane>
                        <Pane marginTop="32px">
                            <TextInputField
                                label="Friendly Name"
                                type="text"
                                name="friendlyName"
                                placeholder="My Company"
                                value={values.friendlyName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                    touched.friendlyName && errors.friendlyName
                                }
                                validationMessage={
                                    touched.friendlyName && errors.friendlyName
                                }
                                spellCheck={false}
                                width="100%"
                                inputHeight={40}
                            />
                        </Pane>
                        <Pane display="flex" flexDirection="column">
                            <Button
                                appearance="primary"
                                height={40}
                                marginTop="32px"
                                type="submit"
                                isLoading={isSubmitting}
                            >
                                Create Business
                            </Button>
                        </Pane>
                    </Form>
                )}
            </Formik>
        </>
    );
};

BusinessNameForm.propTypes = {
    initialValues: PropTypes.shape({}).isRequired,

    submitForm: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default BusinessNameForm;
