import * as PDFjs from 'pdfjs-dist/legacy/build/pdf';
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

PDFjs.GlobalWorkerOptions.workerSrc = PDFJSWorker;

const generatePdfThumbnail = async (pdfFile) => {
    const binaryFile = await pdfFile.arrayBuffer();
    const loadedPdf = await PDFjs.getDocument(binaryFile).promise;
    const firstPage = await loadedPdf.getPage(1);
    const viewport = firstPage.getViewport({ scale: 1 });
    const pdfCanvas = document.createElement('canvas');
    pdfCanvas.width = viewport.width;
    pdfCanvas.height = viewport.height;
    await firstPage.render({
        canvasContext: pdfCanvas.getContext('2d'),
        viewport,
    }).promise;
    return pdfCanvas.toDataURL('image/jpeg', 0.8);
};

const generateSvgThumbnail = (svg) =>
    new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            const svgCanvas = document.createElement('canvas');
            svgCanvas.width = 595;
            svgCanvas.height = 841;
            svgCanvas
                .getContext('2d')
                .drawImage(img, 0, 0, 448, 512, 241.5, 356.5, 112, 128);
            resolve(svgCanvas.toDataURL('image/png', 80));
        };
        img.src = svg;
    });

export { generatePdfThumbnail, generateSvgThumbnail };
