import React, { useCallback, useMemo, useState } from 'react';
import { Heading, Pane, Paragraph, Spinner, Text } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Image } from '../../atoms';
import EmptyPortfolioImage from '../../../assets/images/illustrations/empty-portfolio.svg';
import CreateAssetSidebarForm from '../create-asset-sidebar-form/create-asset-sidebar-form';
import AssetCard from './asset-card';
import { userSelector } from '../../../reducers/user-reducer/user.reducer';
import useWindowDimensions from '../../../hooks/use-window-dimensions';
import SelectedAssetSidebarPortfolioPage from './selected-asset-sidebar-portfolio-page';
import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';
import AssetUnarchiving from '../asset-archiving/AssetUnarchiving';

const AssetsList = ({
    selectedPortfolio,
    showAddAssetSideSheet,
    closeAddAssetSideSheet,
    showAssetDetailsSideSheet,
    isRolledUp,
    handleDefautSelectedIndex,
}) => {
    const { width } = useWindowDimensions();

    const [selectedAssetId, setSelectedAssetId] = useState(null);
    const [openUnarchiving, setOpenUnarchiving] = useState(false);
    const [unArchiveAsset, setUnArchiveAsset] = useState({});
    const { user } = useSelector(userSelector);
    const {
        isLoading: isLoadingAssets,
        currentTenant,
        currentBuilding,
        currentAsset,
    } = useSelector(assetSelector);

    const { assets } = useSelector(assetSelector);

    const handleOpenUnarchive = useCallback(
        (asset) => {
            setOpenUnarchiving(true);
            if (!unArchiveAsset || unArchiveAsset.id !== asset.id) {
                setUnArchiveAsset(asset);
            }
        },
        [unArchiveAsset]
    );

    const handleCloseUnarchive = useCallback(() => {
        setOpenUnarchiving(false);
    }, []);

    const filteredAssets = useMemo(() => {
        let properties = assets;

        if (currentTenant) {
            properties = [
                ..._.filter(
                    properties,
                    (asset) =>
                        Number(asset.parentId) === Number(currentTenant.id)
                ),
            ];
        } else if (currentBuilding) {
            properties = [
                ..._.filter(
                    properties,
                    (asset) =>
                        Number(asset.parentId) === Number(currentBuilding.id)
                ),
            ];
        } else if (currentAsset) {
            properties = [
                ..._.filter(
                    properties,
                    (asset) =>
                        Number(asset.parentId) === Number(currentAsset.id)
                ),
            ];
        }

        if (selectedPortfolio !== 'ALL') {
            properties = properties.filter(
                (asset) => asset.buildingUse === selectedPortfolio
            );
        }

        if (isRolledUp) {
            properties = _.filter(properties, (asset) => !asset.parentId);
        }

        if (currentTenant) {
            properties = [...properties, currentTenant];
        } else if (currentBuilding) {
            properties = [...properties, currentBuilding];
        } else if (currentAsset) {
            properties = [...properties, currentAsset];
        }

        return properties;
    }, [
        assets,
        currentAsset,
        currentBuilding,
        currentTenant,
        isRolledUp,
        selectedPortfolio,
    ]);

    const emptyCards = useMemo(() => {
        if (_.isEmpty(filteredAssets)) {
            return [];
        }

        const cardsPerRow = Math.floor((width - 80 - 32) / 342);
        const numberOfRows = _.size(filteredAssets) / cardsPerRow;

        if (numberOfRows === Math.ceil(numberOfRows)) {
            return [];
        }
        const numberOfEmptyCards =
            Math.ceil(numberOfRows) * cardsPerRow - _.size(filteredAssets);

        return [...Array(numberOfEmptyCards).map(() => null)];
    }, [filteredAssets, width]);

    const renderLoading = () => {
        if (!isLoadingAssets) {
            return null;
        }

        return (
            <Pane
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Spinner />
                <Text marginTop="1rem">Loading...</Text>
            </Pane>
        );
    };

    const renderEmptyState = () => {
        if (!_.isEmpty(assets) || isLoadingAssets) {
            return null;
        }

        return (
            <Pane
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Pane
                    display="flex"
                    marginY="8%"
                    flexDirection="column"
                    alignItems="center"
                >
                    <Heading size={900}>
                        {user.firstName}, welcome to your Asset Records
                        portfolio!{' '}
                        <span role="img" aria-label="celebrate">
                            🎉
                        </span>
                    </Heading>
                    <Paragraph marginY={12}>
                        Get started by adding your first asset.
                    </Paragraph>
                </Pane>
                <Image
                    src={EmptyPortfolioImage}
                    alt="Empty Dashboard"
                    maxWidth="45%"
                />
            </Pane>
        );
    };

    const renderAssetList = () => {
        if (_.isEmpty(assets) || isLoadingAssets) {
            return null;
        }

        return (
            <Pane display="flex" flexDirection="column">
                <Pane
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-evenly"
                    rowGap={24}
                    paddingBottom={24}
                >
                    {_.map(filteredAssets, (asset) => (
                        <AssetCard
                            key={`$asset-${asset.id}`}
                            asset={asset}
                            setSelectedAssetId={setSelectedAssetId}
                            showAssetDetails={showAssetDetailsSideSheet}
                            handleOpenUnarchive={() =>
                                handleOpenUnarchive(asset)
                            }
                        />
                    ))}
                    {_.map(emptyCards, (card, index) => (
                        <Pane
                            width={318}
                            margin={12}
                            key={`empty-card-${index}`}
                        />
                    ))}
                </Pane>
            </Pane>
        );
    };

    return (
        <Pane height="100%">
            {renderLoading()}
            {renderEmptyState()}
            {renderAssetList()}
            <CreateAssetSidebarForm
                close={closeAddAssetSideSheet}
                isShown={showAddAssetSideSheet}
            />
            <SelectedAssetSidebarPortfolioPage
                selectedAssetId={selectedAssetId}
                setSelectedAssetId={setSelectedAssetId}
            />
            <AssetUnarchiving
                asset={unArchiveAsset}
                onOpen={openUnarchiving}
                handCloseArchive={handleCloseUnarchive}
                handleDefautSelectedIndex={handleDefautSelectedIndex}
            />
        </Pane>
    );
};

AssetsList.propTypes = {
    selectedPortfolio: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    showAddAssetSideSheet: PropTypes.bool.isRequired,
    isRolledUp: PropTypes.bool.isRequired,
    closeAddAssetSideSheet: PropTypes.func.isRequired,
    showAssetDetailsSideSheet: PropTypes.func.isRequired,
    handleDefautSelectedIndex: PropTypes.func.isRequired,
};

export default AssetsList;
