import axios from 'axios';
import _ from 'lodash';

import { XANO_ACCESS_TOKEN_KEY } from '../../../local-storage-keys';
import createAttachTokenInterceptor from './attach-auth-token.interceptor';

const XANO_BASE_URL = 'https://xkrn-4n4o-aozy.e2.xano.io/api:KIF-JcPf';
export const API_BASE_URL =
    process.env.REACT_APP_API_ENV === 'test'
        ? 'https://apidev.assetrecords.io'
        : 'https://api.assetrecords.io';

const axiosConfig = {
    baseURL: XANO_BASE_URL,
};

const axiosInstance = axios.create(axiosConfig);
createAttachTokenInterceptor(axiosInstance, () =>
    localStorage.getItem(XANO_ACCESS_TOKEN_KEY)
);

async function getMergedConfigs(customConfig) {
    const defaultConfig = await getDefaultConfig();

    const responseManipulator = _.get(
        customConfig,
        'responseManipulator',
        defaultConfig.responseManipulator
    );
    const { defaultRequestConfig } = defaultConfig;
    const requestConfig = _.merge(defaultRequestConfig, customConfig);

    return { requestConfig, responseManipulator };
}

async function makeGet(url, config) {
    const { requestConfig, responseManipulator } = await getMergedConfigs(
        config
    );

    return axiosInstance
        .get(url, requestConfig)
        .then((response) => responseManipulator(response));
}

async function makePost(url, data, config) {
    const { requestConfig, responseManipulator } = await getMergedConfigs(
        config
    );

    return axiosInstance
        .post(url, data, requestConfig)
        .then((response) => responseManipulator(response));
}

async function makePut(url, data, config) {
    const { requestConfig, responseManipulator } = await getMergedConfigs(
        config
    );

    return axiosInstance
        .put(url, data, requestConfig)
        .then((response) => responseManipulator(response));
}

async function makeDelete(url, data, config) {
    const { requestConfig, responseManipulator } = await getMergedConfigs(
        config
    );

    return axiosInstance
        .delete(url, { ...requestConfig, data })
        .then((response) => responseManipulator(response));
}

async function getDefaultConfig() {
    const defaultRequestConfig =
        process.env.REACT_APP_API_ENV === 'test'
            ? {
                  headers: {
                      'X-Data-Source': 'test',
                  },
              }
            : {};

    return {
        defaultRequestConfig,
        responseManipulator: (response) => response.data,
    };
}

export default {
    makeGet,
    makePost,
    makeDelete,
    makePut,
};
