import React, { useEffect, useState } from 'react';
import {
    CrossIcon,
    ErrorIcon,
    Pane,
    RepeatIcon,
    Text,
    TickCircleIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Image } from '../../atoms';
import getFileTypeIcon from '../../../helpers/get-file-type-icon';
import ProgressBar from '../../atoms/progress-bar.component';
import { colors } from '../../../theme/theme';
import { useUploadManager } from '../../../context/upload-manager-context';

const UploadManagerMenuItem = ({ upload }) => {
    const { retryUpload, cancelUpload } = useUploadManager();

    const [isHoveringIcon, setIsHoveringIcon] = useState(false);
    const [isHoveringRow, setIsHoveringRow] = useState(false);

    useEffect(() => {
        if (upload.isUploading) {
            setIsHoveringIcon(false);
        }
    }, [upload]);

    const getStatusText = () => {
        if (upload.isInitialising) {
            return (
                <Pane display="flex" flex={1} justifyContent="flex-end">
                    <Text fontSize={10} color="#5EC090" marginLeft={8}>
                        Initialising
                    </Text>
                </Pane>
            );
        }
        if (upload.uploadCanceled) {
            return (
                <Pane display="flex" flex={1} justifyContent="flex-end">
                    <Text fontSize={10} color="#5EC090" marginLeft={8}>
                        Canceled
                    </Text>
                </Pane>
            );
        }
        if (
            !upload.isInitialising &&
            !upload.isUploading &&
            !upload.uploadDone &&
            !upload.uploadCanceled &&
            !upload.uploadFailed
        ) {
            return (
                <Pane display="flex" flex={1} justifyContent="flex-end">
                    <Text fontSize={10} color="#5EC090" marginLeft={8}>
                        Waiting
                    </Text>
                </Pane>
            );
        }

        return null;
    };

    const onMouseOverIcon = () => {
        setIsHoveringIcon(true);
    };

    const onMouseOutIcon = () => {
        setIsHoveringIcon(false);
    };

    const onMouseOverRow = () => {
        setIsHoveringRow(true);
    };

    const onMouseOutRow = () => {
        setIsHoveringRow(false);
    };

    const retry = () => {
        retryUpload(upload.id);
    };

    const cancel = () => {
        cancelUpload(upload.id);
    };

    return (
        <Row
            display="flex"
            alignItems="center"
            paddingY={12}
            paddingX={15}
            onMouseOver={onMouseOverRow}
            onMouseOut={onMouseOutRow}
        >
            <Image src={getFileTypeIcon(upload.fileType)} width={25} />
            <Pane
                marginLeft={10}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                width="100%"
            >
                <Pane display="flex" justifyContent="space-between">
                    <Text
                        fontSize={13}
                        cursor="default"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        minWidth={0}
                    >
                        {upload.file.name}
                    </Text>
                    {getStatusText()}
                </Pane>
                {upload.isUploading && (
                    <ProgressBar
                        progress={upload.progress}
                        color={colors.secondary500}
                        height={5}
                    />
                )}
            </Pane>
            {upload.isUploading && (
                <Pane marginLeft={12}>
                    {isHoveringRow ? (
                        <Pane
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width={25}
                            height={25}
                            backgroundColor="#696F8C"
                            borderRadius={12.5}
                            cursor="pointer"
                            onClick={cancel}
                        >
                            <CrossIcon size={12} color="white" />
                        </Pane>
                    ) : (
                        <Pane
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width={25}
                            height={25}
                            borderRadius={12.5}
                            cursor="pointer"
                        />
                    )}
                </Pane>
            )}
            {upload.uploadDone && (
                <TickCircleIcon size={25} color="#5EC090" marginLeft={12} />
            )}
            {upload.uploadCanceled && (
                <Pane
                    onMouseOver={onMouseOverIcon}
                    onMouseOut={onMouseOutIcon}
                    marginLeft={12}
                >
                    <Pane
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={25}
                        height={25}
                        backgroundColor="#696F8C"
                        borderRadius={12.5}
                        cursor="pointer"
                        onClick={retry}
                    >
                        <RepeatIcon size={12} color="white" />
                    </Pane>
                </Pane>
            )}
            {upload.uploadFailed && (
                <Pane
                    onMouseOver={onMouseOverIcon}
                    onMouseOut={onMouseOutIcon}
                    marginLeft={12}
                >
                    {isHoveringIcon ? (
                        <Pane
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width={25}
                            height={25}
                            backgroundColor="#696F8C"
                            borderRadius={12.5}
                            cursor="pointer"
                            onClick={retry}
                        >
                            <RepeatIcon size={12} color="white" />
                        </Pane>
                    ) : (
                        <Pane
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width={25}
                            height={25}
                        >
                            <ErrorIcon size={25} color="danger" />
                        </Pane>
                    )}
                </Pane>
            )}
        </Row>
    );
};

UploadManagerMenuItem.propTypes = {
    upload: PropTypes.shape().isRequired,
};

export default UploadManagerMenuItem;

const Row = styled(Pane)`
    &:hover {
        background-color: #ececf1;
    }
`;
