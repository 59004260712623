import React, { useMemo, useState } from 'react';
import { Pane, Select } from 'evergreen-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    assetSelector,
    setCurrentTenantAction,
    setCurrentBuildingAction,
} from '../../../reducers/asset-reducer/asset.reducer';

const TenantSelector = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentAsset, currentBuilding } = useSelector(assetSelector);

    const { pathname: currentPath } = useLocation();
    const { assetId, buildingId } = useParams();

    const [selectorId, setSelectorId] = useState('');

    const childrenType = useMemo(() => {
        if (currentBuilding) {
            return 'Tenants';
        }
        return 'Buildings';
    }, [currentBuilding]);

    const handleChange = ({ target: { value } }) => {
        const child = children.find(
            (_child) => Number(_child.id) === Number(value)
        );
        if (child) {
            setSelectorId(child.id);
            const newPath = currentPath.split('/');
            if (child.type === 'tenant') {
                setSelectorId('');
                dispatch(setCurrentTenantAction(child));

                if (
                    currentPath.includes('info') ||
                    currentPath.includes('compliance')
                ) {
                    if (!assetId) {
                        navigate(
                            `/portfolio/properties/buildings/${buildingId}/tenants/${
                                child.id
                            }/${newPath.at(-1)}`
                        );
                    } else {
                        navigate(
                            `/portfolio/properties/parks/${assetId}/buildings/${buildingId}/tenants/${
                                child.id
                            }/${newPath.at(-1)}`
                        );
                    }
                    return;
                }
                if (!currentAsset) {
                    navigate(
                        `${currentPath}?currentBuildingId=${currentBuilding?.id}&currentTenantId=${child.id}`
                    );
                } else {
                    navigate(
                        `${currentPath}?currentAssetId=${currentAsset?.id}&currentBuildingId=${currentBuilding?.id}&currentTenantId=${child.id}`
                    );
                }
            } else {
                setSelectorId('');
                dispatch(setCurrentBuildingAction(child));
                if (
                    currentPath.includes('info') ||
                    currentPath.includes('compliance')
                ) {
                    if (assetId) {
                        navigate(
                            `/portfolio/properties/parks/${assetId}/buildings/${
                                child.id
                            }/${newPath.at(-1)}`
                        );
                    } else {
                        navigate(
                            `/portfolio/properties/buildings/${
                                child.id
                            }/${currentPath.at(-1)}`
                        );
                    }
                    return;
                }
                if (!currentAsset) {
                    navigate(
                        `${currentPath}?currentBuildingId=${currentBuilding?.id}&currentTenantId=${child.id}`
                    );
                } else {
                    navigate(
                        `${currentPath}?currentAssetId=${currentAsset?.id}&currentBuildingId=${child?.id}`
                    );
                }
            }
        }
    };

    return (
        <Pane
            width="33%"
            height={80}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRight="1px solid #D8DAE5"
            paddingRight={20}
            paddingLeft={20}
        >
            <Pane display="flex" flex={1} backgroundColor="#EBF0FF">
                <Select
                    value={selectorId}
                    style={{ height: 52 }}
                    onChange={handleChange}
                >
                    <option value="" disabled>
                        {childrenType} ({children.length})
                    </option>
                    {children.map(({ id, assetName }) => (
                        <option key={id} value={id}>
                            {assetName}
                        </option>
                    ))}
                </Select>
            </Pane>
        </Pane>
    );
};

TenantSelector.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TenantSelector;
