import {
    generatePdfThumbnail,
    generateSvgThumbnail,
} from '../../../helpers/generate-thumbnail';

/* eslint-disable import/prefer-default-export */
export const getThumbnail = async (fileType, file) => {
    const fileCategory = file.type.substring(0, file.type.indexOf('/'));

    if (fileCategory === 'image' && fileType !== 'tif' && fileType !== 'tiff') {
        const extension = file.name.split('.').pop();
        return { file, fileType: file.type, isBase64: false, extension };
    }

    if (fileType === 'application/pdf' || fileType === 'pdf') {
        try {
            const thumbnail = await generatePdfThumbnail(file);
            return {
                file: thumbnail,
                fileType: 'image/jpeg',
                isBase64: true,
                extension: 'jpg',
            };
        } catch (error) {
            return {
                file: null,
                fileType: null,
                isBase64: null,
                extension: null,
            };
        }
    } else if (fileType === 'application/svg' || fileType === 'svg') {
        const thumbnail = await generateSvgThumbnail(file);
        return {
            file: thumbnail,
            fileType: 'image/jpeg',
            isBase64: true,
            extension: 'jpg',
        };
    } else {
        return {
            file: null,
            fileType: null,
            isBase64: null,
            extension: null,
        };
    }
};
