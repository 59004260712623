import _ from 'lodash';

import { contactsService } from '../../services';
import { businessContactsModel, staffContactModel } from '../../models';
import {
    setBusinessesAction,
    setContactsAction,
    setBusinessContactsAction,
    setBusinessContactsPaginationAction,
} from './contacts.reducer';

// eslint-disable-next-line import/prefer-default-export
export function getAllContactsForBusiness() {
    return async (dispatch, getState) => {
        const { businessIds } = getState().userReducer;
        const currentBusinessId = _.first(businessIds);

        const businessContacts = businessContactsModel(
            await contactsService.getContactsForBusiness(currentBusinessId)
        );

        let contacts = [];
        const businesses = [];
        _.forEach(businessContacts, (businessContact) => {
            contacts = [...contacts, ...businessContact.contacts];

            const business = _.clone(businessContact);
            _.unset(business, 'contacts');
            businesses.push(business);
        });

        dispatch(setBusinessesAction(businesses));
        dispatch(
            setContactsAction(_.orderBy(contacts, 'firstName', 'lastName'))
        );
    };
}

// BusinessContacts

export function getBusinessContacts() {
    return async (dispatch, getState) => {
        const { page, search, professionFilter } = getState().contactsReducer;
        const data = await contactsService.getBusinessContacts({
            page,
            search,
            profession: professionFilter,
        });
        dispatch(setBusinessContactsAction(data));

        let contacts = [];
        data.items.forEach((business) => {
            contacts = [...contacts, ...business.contacts];
        });
        dispatch(
            setContactsAction(
                contacts.map((contact) => staffContactModel(contact))
            )
        );
        dispatch(setBusinessContactsPaginationAction(data));
    };
}

export function createBusinessContact(businessContactObject, businessId) {
    return async (dispatch) => {
        const businessContact = await contactsService.createBusinessContact(
            businessContactObject,
            businessId
        );
        dispatch(getBusinessContacts());
        return businessContact;
    };
}

export function editBusinessContact(businessContactObject) {
    return async (dispatch) => {
        const editedBusinessContact = await contactsService.editBusinessContact(
            businessContactObject
        );
        dispatch(getBusinessContacts());
        return editedBusinessContact;
    };
}

export function deleteBusinessContact(businessContactId) {
    return async (dispatch) => {
        await contactsService.deleteBusinessContact(businessContactId);
        dispatch(getBusinessContacts());
    };
}

// BusinessContactsStaff

export function createContact(contactObject, businessContactId) {
    return async (dispatch) => {
        const contact = await contactsService.createContact(
            contactObject,
            businessContactId
        );
        dispatch(getBusinessContacts());
        return contact;
    };
}

export function updateContact(contactObject) {
    return async (dispatch) => {
        const editedContact = await contactsService.consultantUpdateContact(
            contactObject
        );
        dispatch(getBusinessContacts());
        return editedContact;
    };
}

export function deleteContact(contactId) {
    return async (dispatch) => {
        await contactsService.deleteContact(contactId);
        dispatch(getBusinessContacts());
    };
}
