import React from 'react';
import { TextInputField, Pane, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';

function MaintenanceCategoryForm({
    form: {
        values: { name, abbreviation },
        errors: { name: nameError, abbreviation: abbreviationError },
    },
    handleChange,
    isEditing,
}) {
    return (
        <form>
            <Text fontWeight={600} fontSize={16}>
                {isEditing ? 'Editing Category' : 'Add New Category'}
            </Text>
            <Pane display="flex" flexDirection="column" gap={12} marginTop={14}>
                <TextInputField
                    className="add-category-input"
                    name="name"
                    width="100%"
                    label="Name"
                    inputHeight={40}
                    value={name}
                    onChange={handleChange}
                    validationMessage={nameError}
                    placeholder="Category Name"
                />

                <TextInputField
                    className="add-category-input"
                    name="abbreviation"
                    width="100%"
                    label="Abbreviation"
                    inputHeight={40}
                    value={abbreviation}
                    onChange={handleChange}
                    validationMessage={abbreviationError}
                    placeholder="Category Abbreviation"
                />
            </Pane>
        </form>
    );
}

MaintenanceCategoryForm.propTypes = {
    form: PropTypes.shape({
        values: PropTypes.shape({
            name: PropTypes.string,
            abbreviation: PropTypes.string,
        }),
        errors: PropTypes.shape({
            name: PropTypes.string,
            abbreviation: PropTypes.string,
        }),
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
};

export default MaintenanceCategoryForm;
