import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
    Pane,
    Heading,
    Card,
    Text,
    SelectField,
    Spinner,
    Pagination,
    MoreIcon,
} from 'evergreen-ui';
import { useTable } from 'react-table';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import { colors } from '../../theme/theme';
import TaskStepper from './task-stepper';
import {
    getAvailableAssignees,
    getTasks,
    getTasksCategories,
} from '../../reducers/task-reducer/task.actions';
import {
    setStatusFilterAction,
    setTablePageAction,
    setTypeFilterAction,
    setUserFilterAction,
    taskSelector,
} from '../../reducers/task-reducer/task.reducer';
import { userSelector } from '../../reducers/user-reducer/user.reducer';

const STATUSES = [
    { value: 'new', label: 'New' },
    { value: 'in progress', label: 'In Progress' },
    { value: 'completed', label: 'Completed' },
    { value: 'overdue', label: 'Overdue' },
    { value: 'due', label: 'Due' },
    { value: 'notified', label: 'Notified' },
];

const TYPES = [
    { value: 'once-off', label: 'Once-Off' },
    { value: 'scheduled', label: 'Scheduled' },
];

function TasksTable({
    data,
    openAddTaskSidesheet,
    openEditTaskSidesheet,
    openUpdateTaskSidesheet,
    openViewTasksDocumentsSidesheet,
    closeTaskSidesheet,
    deleteTask,
}) {
    const dispatch = useDispatch();

    const {
        userFilter,
        statusFilter,
        typeFilter,
        tasksPagination,
        tablePage,
        assignees,
    } = useSelector(taskSelector);
    const { isAdmin } = useSelector(userSelector);

    const [searchParams, setSearchParams] = useSearchParams({});

    const queryParams = useMemo(
        () => Object.fromEntries(searchParams.entries()),
        [searchParams]
    );

    const memoAssignees = useMemo(
        () =>
            assignees.map(({ user_id: userId, name }) => ({
                id: userId,
                name,
            })),
        [assignees]
    );

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getAvailableAssignees());
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                Header: 'Task Name & Type',
                accessor: 'name',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Progress',
                accessor: 'progress',
            },
            {
                Header: 'Due',
                accessor: 'dueDate',
            },
            {
                Header: <MoreIcon marginX="auto" color="#8f95b2" />,
                accessor: 'seen',
            },
        ],
        []
    );

    const tableInstance = useTable({
        columns,
        data,
    });

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            return await Promise.all([
                dispatch(getTasks()),
                dispatch(getTasksCategories()),
            ]);
        } finally {
            setLoading(false);
        }
    }, [dispatch]);

    const handleFilterChange = ({ target: { value } }) => {
        if (value) {
            dispatch(setStatusFilterAction(value));
            setSearchParams({ ...queryParams, statusFilter: value });
        } else {
            dispatch(setStatusFilterAction(''));
            setSearchParams({ ...queryParams, statusFilter: '' });
        }
        getData();
    };

    const handleTypesFilterChange = ({ target: { value } }) => {
        if (value) {
            dispatch(setTypeFilterAction(value));
            setSearchParams({ ...queryParams, typeFilter: value });
        } else {
            dispatch(setTypeFilterAction(''));
            setSearchParams({ ...queryParams, typeFilter: '' });
        }
        getData();
    };

    const handleUserFilterChange = ({ target: { value } }) => {
        if (value) {
            dispatch(setUserFilterAction(value));
            setSearchParams({ ...queryParams, userFilter: value });
        } else {
            dispatch(setUserFilterAction(''));
            setSearchParams({ ...queryParams, userFilter: '' });
        }
        getData();
    };

    const handlePrevPage = async () => {
        if (tablePage > 1) {
            dispatch(setTablePageAction(tablePage - 1));
            getData();
        }
    };

    const handleNextPage = async () => {
        if (tablePage < tasksPagination.pageTotal) {
            dispatch(setTablePageAction(tablePage + 1));
            getData();
        }
    };

    const handlePageChange = async (_page) => {
        dispatch(setTablePageAction(_page));
        getData();
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <Card
            elevation={1}
            backgroundColor="white"
            borderRadius={10}
            height="100%"
            margin={1}
            flex="1"
            display="flex"
            flexDirection="column"
            maxWidth={1175}
            maxHeight={994}
        >
            <Pane
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="1.125rem"
                borderBottom={`1px solid ${colors.neutral500}`}
            >
                <Heading
                    fontSize={22}
                    fontWeight="700"
                    color={colors.primary500}
                >
                    Tasks
                </Heading>
                <Pane display="flex" gap={5}>
                    {memoAssignees.length > 1 && isAdmin && (
                        <Pane display="flex" gap={16} alignItems="center">
                            <SelectField
                                onChange={handleUserFilterChange}
                                marginY={-5}
                                marginTop={-9}
                                value={userFilter}
                            >
                                <option value="">Everyone</option>
                                {memoAssignees.map(({ id, name }) => (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                ))}
                            </SelectField>
                        </Pane>
                    )}
                    <Pane display="flex" gap={16} alignItems="center">
                        <SelectField
                            onChange={handleTypesFilterChange}
                            marginY={-5}
                            marginTop={-9}
                            value={typeFilter}
                        >
                            <option value="">All Types</option>
                            {TYPES.map(({ value, label }) => (
                                <option value={value} key={label}>
                                    {label}
                                </option>
                            ))}
                        </SelectField>
                    </Pane>
                    <Pane display="flex" gap={16} alignItems="center">
                        <SelectField
                            onChange={handleFilterChange}
                            marginY={-5}
                            marginTop={-9}
                            value={statusFilter}
                        >
                            <option value="">All Statuses</option>
                            {STATUSES.map(({ value, label }) => (
                                <option value={value} key={label}>
                                    {label}
                                </option>
                            ))}
                        </SelectField>
                    </Pane>
                </Pane>
            </Pane>
            <StyledTable>
                <table
                    {...getTableProps()}
                    style={{
                        width: '100%',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        padding: 0,
                        borderSpacing: 0,
                        borderCollapse: 'collapse',
                        border: 'none',
                    }}
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    borderBottom: `1px solid ${colors.neutral500}`,
                                    backgroundColor: '#F9FAFC',
                                    height: 55,
                                }}
                            >
                                {headerGroup.headers.map((column, index) => {
                                    const isLastColumn =
                                        index ===
                                        headerGroup.headers.length - 1;
                                    const isSecondColumn = index === 1;
                                    const isThirdColumn = index === 2;
                                    const isFourthColumn = index === 3;

                                    let maxWidth = 'none';

                                    if (isLastColumn) {
                                        maxWidth = 150;
                                    } else if (isSecondColumn) {
                                        maxWidth = 120;
                                    } else if (isThirdColumn) {
                                        maxWidth = 200;
                                    } else if (isFourthColumn) {
                                        maxWidth = 120;
                                    }

                                    return (
                                        <th
                                            style={{
                                                minWidth: 100,
                                                maxWidth,
                                                display: 'flex',
                                                flex: 1,
                                                justifyContent: isFourthColumn
                                                    ? 'center'
                                                    : 'start',
                                                alignItems: 'center',
                                                outline: 'none',
                                            }}
                                            {...column.getHeaderProps()}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    color: '#3A3541DE',
                                                    borderRight:
                                                        index !==
                                                        headerGroup.headers
                                                            .length -
                                                            1
                                                            ? `1px solid ${colors.neutral500}`
                                                            : null,
                                                    paddingLeft: isLastColumn
                                                        ? 0
                                                        : 15,
                                                }}
                                            >
                                                {column.render('Header')}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody
                        {...getTableBodyProps()}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: 'calc(100vh - 205px)',
                            overflowY: 'auto',
                        }}
                    >
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {loading ? (
                            <tr style={{ display: 'flex' }}>
                                <td
                                    style={{
                                        display: 'flex',
                                        borderBottom: `1px solid ${colors.neutral500}`,
                                        flex: 1,
                                        justifyItems: 'stretch',
                                        height: 80,
                                    }}
                                >
                                    <Pane
                                        display="flex"
                                        width="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Spinner />
                                    </Pane>
                                </td>
                            </tr>
                        ) : rows.length ? (
                            _.orderBy(
                                rows,
                                ['original.due_date', 'original.progress'],
                                'asc'
                            ).map((row) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        style={{ display: 'flex' }}
                                    >
                                        <td
                                            style={{
                                                display: 'flex',
                                                borderBottom: `1px solid ${colors.neutral500}`,
                                                flex: 1,
                                                justifyItems: 'stretch',
                                            }}
                                        >
                                            <TaskStepper
                                                task={row.original}
                                                openAddTaskSidesheet={
                                                    openAddTaskSidesheet
                                                }
                                                openEditTaskSidesheet={
                                                    openEditTaskSidesheet
                                                }
                                                openUpdateTaskSidesheet={
                                                    openUpdateTaskSidesheet
                                                }
                                                closeTaskSidesheet={
                                                    closeTaskSidesheet
                                                }
                                                openViewTasksDocumentsSidesheet={
                                                    openViewTasksDocumentsSidesheet
                                                }
                                                deleteTask={deleteTask}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr style={{ display: 'flex' }}>
                                <td
                                    style={{
                                        display: 'flex',
                                        borderBottom: `1px solid ${colors.neutral500}`,
                                        flex: 1,
                                        justifyItems: 'stretch',
                                        height: 80,
                                    }}
                                >
                                    <Pane
                                        display="flex"
                                        width="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Text textAlign="center">
                                            No Available Tasks
                                        </Text>
                                    </Pane>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </StyledTable>
            {tasksPagination.pageTotal > 1 && (
                <Pagination
                    marginLeft="auto"
                    marginRight={10}
                    marginY={15}
                    page={tablePage}
                    totalPages={tasksPagination.pageTotal}
                    onPageChange={handlePageChange}
                    onPreviousPage={handlePrevPage}
                    onNextPage={handleNextPage}
                />
            )}
        </Card>
    );
}

const StyledTable = styled.div`
    tr {
        width: 100%;
    }
`;

TasksTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    getData: PropTypes.func.isRequired,
    openAddTaskSidesheet: PropTypes.func.isRequired,
    openEditTaskSidesheet: PropTypes.func.isRequired,
    openUpdateTaskSidesheet: PropTypes.func.isRequired,
    closeTaskSidesheet: PropTypes.func.isRequired,
    openViewTasksDocumentsSidesheet: PropTypes.func.isRequired,
    deleteTask: PropTypes.func.isRequired,
};

export default TasksTable;
