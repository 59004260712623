import networkService from '../network-service/network.service';
import userUrls from './user-service-urls';
import { defaultAuthConfig } from '../auth-service/auth.service';

function getUser() {
    const url = userUrls.getUserUrl();
    return networkService.makeGet(url, defaultAuthConfig);
}

export default {
    getUser,
};
