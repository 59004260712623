import React, { useCallback, useState } from 'react';
import { Pane, TextInputField, Button, Heading } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import {
    createTasksCategory,
    createTasksType,
    deleteTasksCategory,
    deleteTasksType,
    updateTasksCategory,
    updateTasksType,
} from '../../reducers/task-reducer/task.actions';
import { getTaskCategoryModel } from '../../models/task.models';
import { errorToast, successToast } from '../toasts';

function TaskTemplateForm({
    selectedCategory,
    selectedType,
    setSelectedCategory,
    setSelectedType,
    types,
    setTypes,
    rights,
}) {
    const [categoryConfirmLoading, setCategoryConfirmLoading] = useState(false);
    const [categoryDeleteLoading, setCategoryDeleteLoading] = useState(false);
    const [typeConfirmLoading, setTypeConfirmLoading] = useState(false);
    const [typeDeleteLoading, setTypeDeleteLoading] = useState(false);
    const [form, setForm] = useState({
        values: {
            category: {
                name: selectedCategory?.name || '',
            },
            type: {
                name: selectedType?.name || '',
                categoryId: selectedCategory?.id || '',
            },
        },
        errors: {
            category: {
                name: '',
            },
            type: {
                name: '',
            },
        },
    });

    const dispatch = useDispatch();

    const heading = useCallback(
        (isCategory = true) => {
            if (isCategory) {
                if (!(rights?.update || rights?.create)) {
                    return 'Category';
                }
                return selectedCategory ? 'Edit Category' : 'Add Category';
            }
            if (!(rights?.update || rights?.create)) {
                return 'Type';
            }
            return selectedType ? 'Edit Type' : 'Add Type';
        },
        [rights, selectedCategory, selectedType]
    );

    const isInvalid = (isCategory = true) => {
        const { values } = form;
        if (isCategory) {
            if (!values.category.name) {
                setForm((state) => ({
                    ...state,
                    errors: {
                        category: {
                            name: 'Category Name is Required',
                        },
                    },
                }));
                return true;
            }
        }
        if (!isCategory) {
            if (!values.type.name) {
                setForm((state) => ({
                    ...state,
                    errors: {
                        ...state.errors,
                        type: {
                            name: 'Type Name is Required',
                        },
                    },
                }));
                return true;
            }
        }
        return false;
    };

    const submitCategory = async () => {
        setCategoryConfirmLoading(true);
        try {
            if (isInvalid()) {
                return;
            }

            const category = getTaskCategoryModel(
                await dispatch(
                    selectedCategory
                        ? updateTasksCategory({
                              categoryId: selectedCategory?.id,
                              name: form.values.category.name,
                          })
                        : createTasksCategory(form.values.category.name)
                )
            );
            successToast(
                `Successfully ${
                    selectedCategory ? 'updated' : 'created'
                } category "${selectedCategory?.name || category?.name}"`
            );
            setSelectedCategory(category);
        } catch (error) {
            setForm((state) => ({
                ...state,
                errors: {
                    ...state.errors,
                    category: {
                        name: get(
                            error,
                            'response.data.message',
                            error.message
                        ),
                    },
                },
            }));
        } finally {
            setCategoryConfirmLoading(false);
        }
    };

    const deleteCategory = async () => {
        setCategoryDeleteLoading(true);
        try {
            await dispatch(deleteTasksCategory(selectedCategory.id));
            setSelectedCategory(null);
            setTypes([]);
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setCategoryDeleteLoading(false);
        }
    };

    const submitType = async () => {
        setTypeConfirmLoading(true);
        try {
            if (isInvalid(false)) {
                return;
            }
            const type = await dispatch(
                selectedType
                    ? updateTasksType({
                          typeId: selectedType.id,
                          value: form.values.type.name,
                      })
                    : createTasksType({
                          categoryId: selectedCategory.id,
                          newTypeName: form.values.type.name,
                      })
            );
            setForm((_form) => ({
                ..._form,
                values: {
                    ..._form.values,
                    type: {
                        name: '',
                    },
                },
            }));
            if (!selectedType) {
                setTypes((_types) => [..._types, type]);
            } else {
                setTypes(
                    types.map((_type) => {
                        if (Number(_type.id) === Number(type.id)) {
                            return type;
                        }
                        return _type;
                    })
                );
            }
            setSelectedType(null);
            successToast(
                `Successfully ${selectedType ? 'updated' : 'created'} type "${
                    selectedType?.name || type?.name
                }"`
            );
        } catch (error) {
            setForm((state) => ({
                ...state,
                errors: {
                    ...state.errors,
                    type: {
                        name: get(
                            error,
                            'response.data.message',
                            error.message
                        ),
                    },
                },
            }));
        } finally {
            setTypeConfirmLoading(false);
        }
    };

    const deleteType = async () => {
        setTypeDeleteLoading(true);
        try {
            dispatch(deleteTasksType(selectedType.id));
            setTypes((state) =>
                state.filter(
                    (type) => Number(type.id) !== Number(selectedType.id)
                )
            );
            setSelectedType(null);
            successToast(`Successfully deleted type`);
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setTypeDeleteLoading(false);
        }
    };

    const handleChange = ({ target }, isCategory = true) => {
        if (isCategory) {
            setForm((state) => ({
                ...state,
                values: {
                    ...state.values,
                    category: {
                        ...state.values.category,
                        [target.name]: target.value,
                    },
                },
                errors: {
                    ...state.errors,
                    category: {
                        ...state.errors.category,
                        [target.name]: '',
                    },
                },
            }));
        } else {
            setForm((state) => ({
                ...state,
                values: {
                    ...state.values,
                    type: {
                        ...state.values.type,
                        [target.name]: target.value,
                    },
                },
                errors: {
                    type: {
                        [target.name]: '',
                    },
                },
            }));
        }
    };

    return (
        <Pane display="flex" flexDirection="column" flex={2}>
            <Pane display="flex" flexDirection="column" gap={10}>
                <Heading>{heading()}</Heading>
                <Pane paddingLeft={10} paddingTop={10}>
                    <TextInputField
                        required
                        name="name"
                        label="Name"
                        placeholder="Mechanical"
                        value={form.values.category?.name}
                        validationMessage={
                            !!form.errors.category?.name &&
                            form.errors.category?.name
                        }
                        width="100%"
                        inputHeight={40}
                        onChange={handleChange}
                        disabled={!(rights?.update || rights?.create)}
                    />
                    <Pane display="flex" gap={10} justifyContent="end">
                        {selectedCategory && rights?.delete && (
                            <Button
                                appearance="minimal"
                                isLoading={categoryDeleteLoading}
                                type="button"
                                intent="danger"
                                onClick={deleteCategory}
                            >
                                Delete
                            </Button>
                        )}
                        {(rights?.update || rights?.create) && (
                            <Button
                                appearance="primary"
                                minWidth={125}
                                isLoading={categoryConfirmLoading}
                                type="button"
                                onClick={submitCategory}
                            >
                                {selectedCategory ? 'Update ' : 'Add '} Category
                            </Button>
                        )}
                    </Pane>
                </Pane>
            </Pane>
            {selectedCategory && (
                <Pane
                    display="flex"
                    flexDirection="column"
                    gap={10}
                    paddingTop={30}
                >
                    <Heading>{heading(false)}</Heading>
                    <Pane paddingLeft={10} paddingTop={10}>
                        <TextInputField
                            required
                            name="name"
                            label="Name"
                            placeholder="Generators"
                            value={form.values.type?.name}
                            validationMessage={
                                !!form.errors.type?.name &&
                                form.errors.type?.name
                            }
                            width="100%"
                            inputHeight={40}
                            onChange={(e) => handleChange(e, false)}
                            disabled={!(rights?.update || rights?.create)}
                        />
                        <Pane display="flex" gap={10} justifyContent="end">
                            {selectedType && rights?.delete && (
                                <Button
                                    appearance="minimal"
                                    marginLeft="auto"
                                    intent="danger"
                                    isLoading={typeDeleteLoading}
                                    onClick={deleteType}
                                >
                                    Delete
                                </Button>
                            )}
                            {(rights?.update || rights?.create) && (
                                <Button
                                    appearance="primary"
                                    minWidth={125}
                                    isLoading={typeConfirmLoading}
                                    onClick={submitType}
                                >
                                    {selectedType ? 'Update ' : 'Add '} Type
                                </Button>
                            )}
                        </Pane>
                    </Pane>
                </Pane>
            )}
        </Pane>
    );
}

TaskTemplateForm.propTypes = {
    selectedCategory: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        abbreviation: PropTypes.string,
    }),
    selectedType: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        abbreviation: PropTypes.string,
    }),
    setSelectedCategory: PropTypes.func.isRequired,
    setSelectedType: PropTypes.func.isRequired,
    setTypes: PropTypes.func.isRequired,
    types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rights: PropTypes.shape({
        create: PropTypes.bool,
        read: PropTypes.bool,
        update: PropTypes.bool,
        delete: PropTypes.bool,
    }).isRequired,
};

TaskTemplateForm.defaultProps = {
    selectedCategory: null,
    selectedType: null,
};

export default TaskTemplateForm;
